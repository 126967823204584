import React from 'react';
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@material-ui/core';
import { useStyles } from './useStyles';

export interface CheckboxProps {
  label: string;
  checkboxProps: MuiCheckboxProps;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, checkboxProps }) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      label={label}
      className={classes.controlLabel}
      control={
        <MuiCheckbox
          color="primary"
          {...checkboxProps}
          classes={{ root: classes.root }}
          TouchRippleProps={{ classes: { root: 'ripple-root' } }}
        />
      }
    />
  );
};
