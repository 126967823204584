import React, { VFC } from 'react';
import { CellProps } from 'react-table';
import { makeStyles, Theme } from '@material-ui/core';
import moment from 'moment';

import { formats } from '../../../../../data/date';

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    whiteSpace: 'nowrap',
  },
}));

export const DateCell: VFC<CellProps<any>> = ({ cell }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>{moment(cell.value).format(formats[0])}</div>
  );
};
