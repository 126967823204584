import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  OrderQueries,
  WhereQueries,
} from 'my-firebase-wrapper/dist/firestore/types';
import _ from 'lodash';
import moment from 'moment';
import { v4 } from 'uuid';

import { publishActualResultsRepository } from '../../repositories/firebase/publishActualResultsRepository';
import { ActualImageType, ActualResultType } from '../actualResult/types';
import { themesRepository } from '../../repositories/firebase/themes';
import { MakerType } from '../maker/types';
import { formats } from '../../data/date';
import isDataUrlImage from '../../utils/isDataUrlImage';
import nanoid from '../../utils/nanoid';
import { OpenSnackbar } from '../../presentation/hooks/useSnackbar';
import { storage } from '../../repositories/firebase';
import { actualResultsRepository } from '../../repositories/firebase/actualResults';
import { actualImagesRepository } from '../../repositories/firebase/actualImages';
import { AsyncThunkConfig } from '../store';
import {
  PublishActualResult,
  PublishActualResultsTableData,
  PUBLISHING_STATUS,
  ThemeActualResult,
  ThemeType,
} from './types';
import { initialState } from './slice';
import { formatValue } from '../../utils/formatValue';
import { makersRepository } from '../../repositories/firebase/makers';

export const fetchThemes = createAsyncThunk<
  ThemeType[],
  {
    whereQueries: WhereQueries<ThemeType>;
    orderQueries: OrderQueries<ThemeType>;
  },
  AsyncThunkConfig<undefined>
>('themes/fetchThemes', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = themesRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });
    const themesData: ThemeType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    return themesData || [];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchActualResults = createAsyncThunk<
  ActualResultType[],
  {
    whereQueries: WhereQueries<ActualResultType>;
    orderQueries: OrderQueries<ActualResultType>;
  },
  AsyncThunkConfig<undefined>
>('themes/fetchActualResults', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = actualResultsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });
    const actualResultsData: ActualResultType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    return actualResultsData || [];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchActualImages = createAsyncThunk<
  {
    [actualResultId: string]: ActualImageType[];
  },
  {
    actualResultId: string | undefined;
  },
  AsyncThunkConfig<undefined>
>('themes/fetchActualImages', async (args, thunkAPI) => {
  try {
    const { actualResultId } = args;
    if (!actualResultId) return {};

    let collectionRef: any = actualImagesRepository;
    collectionRef = collectionRef
      .where('actualResultId', '==', actualResultId)
      .where('publishing', '==', true);
    const actualImagesData: ActualImageType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    return { [actualResultId]: actualImagesData || [] };
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

const uploadImages = async (theme: ThemeType): Promise<ThemeType> => {
  const { slug, imageUrl, bnrUrl } = theme;

  let newTheme = theme;

  if (isDataUrlImage(imageUrl)) {
    const timestamp = moment().format('YYYYMMDD_HHmmssSS');
    const snapshot = await storage
      .ref(`/themes/${slug}_${timestamp}.jpg`)
      .putString(imageUrl, 'data_url');
    let url = await snapshot.ref.fullPath;
    url = `/${url}`;

    newTheme = { ...newTheme, imageUrl: url };
  }

  if (isDataUrlImage(bnrUrl)) {
    const timestamp = moment().format('YYYYMMDD_HHmmssSS');
    const snapshot = await storage
      .ref(`/themes/${slug}_bnr_${timestamp}.jpg`)
      .putString(bnrUrl, 'data_url');
    let url = await snapshot.ref.fullPath;
    url = `/${url}`;

    newTheme = { ...newTheme, bnrUrl: url };
  }

  return newTheme;
};

export const createPublishActualResults = createAsyncThunk<
  PublishActualResult[],
  {
    themeId: string;
    themeActualResults: ThemeActualResult[];
  },
  AsyncThunkConfig<undefined>
>('themes/createPublishActualResults', async (args, thunkAPI) => {
  try {
    const { themeActualResults, themeId } = args;
    const results: PublishActualResult[] = await Promise.all(
      themeActualResults.map(async (themeActualResult) => {
        const newValue: PublishActualResult = {
          agreementMemo: '',
          requestMemo: '',
          uid: themeActualResult.uid,
          themeId,
          publishingStatus: themeActualResult.publishingStatus,
          billingStatus: themeActualResult.billingStatus,
          staff: themeActualResult.staff,
          actualResultId: themeActualResult.actualResultUid,
          makerId: themeActualResult.makerId,
          startDate: themeActualResult.startDate,
          endDate: themeActualResult.endDate,
        };
        if (!newValue.uid) {
          const uid = await publishActualResultsRepository.add(newValue);
          newValue.uid = uid;
        } else {
          await publishActualResultsRepository.update(newValue);
        }

        return newValue;
      }),
    );

    return results;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(undefined);
  }
});

export const createTheme = createAsyncThunk<
  ThemeType,
  {
    formValue: ThemeType;
    openSnackbar: OpenSnackbar;
  },
  AsyncThunkConfig<undefined>
>('themes/createTheme', async (args, thunkAPI) => {
  try {
    const { formValue, openSnackbar } = args;
    const currentValue: ThemeType = thunkAPI.getState().theme.theme;
    const newValue: ThemeType = await uploadImages({
      ...initialState.theme,
      ...currentValue,
      ...formValue,
    });
    if (!newValue.uid) {
      const uid = await themesRepository.add(newValue);
      newValue.uid = uid;
      openSnackbar({
        variant: 'success',
        message: 'テーマの作成が完了しました',
      });
      thunkAPI.dispatch(push('/theme'));
    } else {
      await themesRepository.update(newValue);
      openSnackbar({
        variant: 'success',
        message: 'テーマの更新が完了しました',
      });
    }

    thunkAPI.dispatch(
      createPublishActualResults({
        themeId: newValue.uid,
        themeActualResults: newValue.actualResults,
      }) as any,
    );

    return newValue;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        'テーマの更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const deleteTheme = createAsyncThunk<
  {
    themes: ThemeType[];
  },
  ThemeType,
  AsyncThunkConfig<undefined>
>('themes/deleteTheme', async (args, thunkAPI) => {
  try {
    const {
      theme: { themes },
    } = thunkAPI.getState();
    await themesRepository.delete(args.uid);

    return {
      themes: themes.filter((theme) => theme.uid !== args.uid),
    };
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const sortThemes = createAsyncThunk<
  ThemeType[],
  ThemeType[],
  AsyncThunkConfig<undefined>
>('themes/sortThemes', async (args, thunkAPI) => {
  try {
    const themes = await Promise.all(
      args.map(async (theme) => {
        await themesRepository.update(theme);

        return theme;
      }),
    );

    return themes;
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchPublishActualResults = createAsyncThunk<
  PublishActualResult[],
  {
    whereQueries: WhereQueries<PublishActualResult>;
    orderQueries: OrderQueries<PublishActualResult>;
  },
  AsyncThunkConfig<undefined>
>('themes/fetchPublishActualResults', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = publishActualResultsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });
    const themesData: PublishActualResult[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    return themesData || [];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchPublishActualResultsTableData = createAsyncThunk<
  PublishActualResultsTableData[],
  {
    whereQueries: WhereQueries<PublishActualResult>;
    orderQueries: OrderQueries<PublishActualResult>;
  },
  AsyncThunkConfig<undefined>
>('themes/fetchPublishActualResultsTableData', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = publishActualResultsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });

    const publishActualResults: PublishActualResult[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    const tableData = await Promise.all(
      publishActualResults.map(async (publishActualResult) => {
        const actualResult = await actualResultsRepository.fetchByDocId(
          publishActualResult.actualResultId,
        );
        const theme = await themesRepository.fetchByDocId(
          publishActualResult.themeId,
        );

        return {
          uid: publishActualResult.uid,
          themeName: theme?.label || '',
          actualResultName: actualResult?.title || '',
          makerName: actualResult?.makerName || '',
          startDate: publishActualResult.startDate,
          endDate: publishActualResult.endDate,
          publishingStatus: publishActualResult.publishingStatus || 'waiting',
          billingStatus: publishActualResult.billingStatus || 'unclaimed',
          staff: publishActualResult.staff || '崎本',
          agreementMemo: publishActualResult.agreementMemo,
          requestMemo: publishActualResult.requestMemo,
          publishActualResult,
        };
      }),
    );

    return tableData || [];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const updateRow = createAsyncThunk<
  PublishActualResultsTableData,
  {
    publishActualResult: PublishActualResult;
    openSnackbar: OpenSnackbar;
  },
  AsyncThunkConfig<undefined>
>('theme/updateRow', async (args, thunkAPI) => {
  try {
    const { themes } = thunkAPI.getState().theme;
    const theme = themes.find(
      (t) => t.uid === args.publishActualResult.themeId,
    );
    const actualResultDoc = await actualResultsRepository
      .doc(args.publishActualResult.actualResultId)
      .get();
    const actualResult = actualResultDoc.data();

    await publishActualResultsRepository.update(args.publishActualResult);

    if (theme) {
      const actualResults: ThemeActualResult[] = theme.actualResults.reduce(
        (prev: ThemeActualResult[], current) => {
          return [
            ...prev,
            args.publishActualResult.actualResultId === current.actualResultUid
              ? ({
                  ...current,
                  ...args.publishActualResult,
                } as ThemeActualResult)
              : current,
          ];
        },
        [],
      );
      await themesRepository.update({ ...theme, actualResults });
    }

    args.openSnackbar({
      variant: 'success',
      message: '掲載履歴の更新が完了しました',
    });

    const { publishActualResult } = args;

    return {
      uid: publishActualResult.uid,
      themeName: theme?.label || '',
      actualResultName: actualResult?.title || '',
      makerName: actualResult?.makerName || '',
      startDate: publishActualResult.startDate,
      endDate: publishActualResult.endDate,
      publishingStatus: publishActualResult.publishingStatus || 'waiting',
      billingStatus: publishActualResult.billingStatus || 'unclaimed',
      staff: publishActualResult.staff || '崎本',
      agreementMemo: publishActualResult.agreementMemo,
      requestMemo: publishActualResult.requestMemo,
      publishActualResult,
    };
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        'テーマの更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});
