import clsx from 'clsx';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

import { selectCollaborator } from '../../../../re-ducks/collaborator/selector';
import { toggleDrawer } from '../../../../re-ducks/app/slice';
import { useStyles } from './useStyles';
import useAdminLinks from '../../../hooks/useAdminLinks';
import useDrawerOpening from '../../../hooks/useDrawerOpening';

export const Drawer: React.FC = () => {
  const collaborator = useSelector(selectCollaborator);
  const dispatch = useDispatch();
  const classes = useStyles();
  const adminLinks = useAdminLinks();
  const { opening } = useDrawerOpening();

  return (
    <MuiDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: opening,
        [classes.drawerClose]: !opening,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: opening,
          [classes.drawerClose]: !opening,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <IconButton
          onClick={() => dispatch(toggleDrawer())}
          className={classes.linkColor}
        >
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider classes={{ root: classes.divider }} />
      <List>
        {adminLinks.map(
          ({
            pathName,
            Icon,
            viewableRoles,
            primaryText,
            isSideMenu,
            onClick,
          }) => {
            if (
              !collaborator.uid ||
              !viewableRoles.includes(collaborator.role) ||
              !isSideMenu
            )
              return null;

            return (
              <Link
                component={RouterLink}
                underline="none"
                to={pathName}
                className={clsx(classes.linkColor, {
                  [classes.linkBorder]: pathName === '/makerContacts',
                })}
                key={primaryText}
                onClick={onClick}
              >
                <ListItem button className={classes.linkButton}>
                  <ListItemIcon className={classes.drawerIcon}>
                    {Icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={primaryText}
                    className={classes.linkText}
                  />
                </ListItem>
              </Link>
            );
          },
        )}
      </List>
    </MuiDrawer>
  );
};
