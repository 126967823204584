import React, { FC } from 'react';
import { ButtonProps } from '@material-ui/core';

import { ButtonPrimary } from '../../../base/Button';

export type PwForgetButtonProps = Omit<ButtonProps, 'children'>;

export const PwResetButton: FC<PwForgetButtonProps> = (props) => {
  return <ButtonPrimary {...props}>パスワードをリセット</ButtonPrimary>;
};
