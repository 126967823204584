import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  OrderQueries,
  WhereQueries,
} from 'my-firebase-wrapper/dist/firestore/types';
import _ from 'lodash';
import moment from 'moment';
import { v4 } from 'uuid';

import { MakerType } from '../maker/types';
import { formats } from '../../data/date';
import isDataUrlImage from '../../utils/isDataUrlImage';
import nanoid from '../../utils/nanoid';
import { OpenSnackbar } from '../../presentation/hooks/useSnackbar';
import { storage } from '../../repositories/firebase';
import { actualResultsRepository } from '../../repositories/firebase/actualResults';
import { actualImagesRepository } from '../../repositories/firebase/actualImages';
import { AsyncThunkConfig } from '../store';
import {
  ActualImagesFormType,
  ActualImageType,
  ActualResultFormType,
  ActualResultType,
} from './types';
import { initialState } from './slice';
import { formatValue } from '../../utils/formatValue';

export const fetchActualResults = createAsyncThunk<
  ActualResultType[],
  {
    whereQueries: WhereQueries<ActualResultType>;
    orderQueries: OrderQueries<ActualResultType>;
  },
  AsyncThunkConfig<undefined>
>('actualResult/fetchActualResults', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = actualResultsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });
    const actualResultData: ActualResultType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    return actualResultData || [];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const setActualResult = createAsyncThunk<
  { actualResult: ActualResultType; actualImages: ActualImageType[] },
  ActualResultType,
  AsyncThunkConfig<undefined>
>('actualResult/setActualResult', async (args, thunkAPI) => {
  try {
    if (!args.uid) {
      thunkAPI.dispatch(push('/project/edit'));

      return {
        actualResult: initialState.actualResult,
        actualImages: initialState.actualImages,
      };
    }
    let images: ActualImageType[] = [];
    let collectionRef: any = actualImagesRepository;
    if (args.images && args.images.length > 10) {
      const actualImagesData = await Promise.all<ActualImageType>(
        args.images.map(async (imageUid: string) => {
          const imagesData = await actualImagesRepository
            .where('uid', '==', imageUid)
            .fetch();

          return imagesData?.length && imagesData[0]
            ? imagesData[0]
            : initialState.actualImage;
        }),
      );
      images = actualImagesData;
    } else if (args.images.length) {
      collectionRef = collectionRef.where('uid', 'in', args.images);
      const actualImagesData: ActualImageType[] =
        'fetch' in collectionRef
          ? await collectionRef.fetch()
          : await collectionRef.fetchAll();
      images = actualImagesData;
    }

    thunkAPI.dispatch(push(`/project/edit/${args.uid}`));

    return {
      actualResult: args,
      actualImages: images
        ? images.slice().sort((a, b) => {
            return args.images.findIndex((v) => v === a.uid) >
              args.images.findIndex((v) => v === b.uid)
              ? 1
              : -1;
          })
        : [],
    };
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

const uploadActualResultImages = async (
  actualResult: ActualResultType,
): Promise<ActualResultType> => {
  const { uid, makerId } = actualResult;
  const makerUid =
    typeof makerId === 'string' ? makerId : (makerId as any).value;

  const contents = await Promise.all(
    actualResult.contents.map(async (content, index) => {
      const timestamp = moment().format('YYYYMMDD_HHmmssSS');

      if (isDataUrlImage(content.image)) {
        const snapshot = await storage
          .ref(
            `/makers/${makerUid}/actualImages/${uid}/content_${index}_${timestamp}.jpg`,
          )
          .putString(content.image, 'data_url');
        let url = await snapshot.ref.fullPath;
        url = `/${url}`;

        return { ...content, image: url };
      }

      return content;
    }),
  );

  return {
    ...actualResult,
    contents,
  };
};

export const createActualResult = createAsyncThunk<
  ActualResultType,
  {
    formValue: ActualResultFormType;
    maker: MakerType | undefined;
    openSnackbar: OpenSnackbar;
  },
  AsyncThunkConfig<undefined>
>('actualResult/createActualResult', async (args, thunkAPI) => {
  try {
    const { formValue, maker, openSnackbar } = args;
    const currentValue = thunkAPI.getState().actualResult.actualResult;
    const newValue = await uploadActualResultImages({
      ...currentValue,
      ...formValue,
    });
    newValue.slug = newValue.slug || nanoid();
    if (maker) {
      newValue.makerId = maker.uid;
      newValue.makerSlug = maker.slug;
      newValue.makerName = maker.name;
    }
    if (!newValue.publishing) {
      newValue.savedUsers = [];
      newValue.numOfSavedUsers = 0;
    }
    if (!newValue.uid) {
      newValue.createDate = moment().format(formats[1]);
      newValue.updateDate = moment().format(formats[1]);
      const uid = await actualResultsRepository.add(newValue);
      newValue.uid = uid;
      openSnackbar({
        variant: 'success',
        message: '建築実例の作成が完了しました',
      });
      thunkAPI.dispatch(push('/project'));
    } else {
      newValue.updateDate = moment().format(formats[1]);
      await actualResultsRepository.update(newValue);
      openSnackbar({
        variant: 'success',
        message: '建築実例の更新が完了しました',
      });
    }

    return newValue;
  } catch (error) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        'アカウントの更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});

const uploadActualImages = async (
  actualResult: ActualResultType,
  actualImages: ActualImagesFormType,
): Promise<{
  actualResult: ActualResultType;
  actualImages: ActualImageType[];
}> => {
  const { images } = actualImages;

  const newImages = await Promise.all(
    images.map(async (image) => {
      const uid = image.uid || v4();
      const newValue = {
        ...initialState.actualImage,
        ...image,
        uid,
        publishing: actualResult.publishing,
        makerId: actualResult.makerId,
        actualResultId: actualResult.uid,
        makerName: actualResult.makerName,
        actualResultTitle: actualResult.title,
        createDate: image.createDate || moment().format(formats[1]),
        updateDate: moment().format(formats[1]),
        clippedUsers: [],
        slug: image.slug || nanoid(),
      };
      if (isDataUrlImage(image.imageUrl)) {
        const snapshot = await storage
          .ref(
            `/makers/${actualResult.makerId}/actualImages/${actualResult.uid}/${uid}.jpg`,
          )
          .putString(image.imageUrl, 'data_url');
        let url = await snapshot.ref.fullPath;
        url = `/${url}`;

        newValue.imageUrl = url;
      }

      actualImagesRepository.set(newValue);

      return newValue;
    }),
  );

  const imgs = newImages.map((image) => image.uid);
  const newActualResult = {
    ...actualResult,
    images: imgs,
    primaryImage: imgs.length ? newImages[0].imageUrl : '',
    updateDate: moment().format(formats[1]),
  };

  const deleteImageIds = actualResult.images.filter(
    (imageUid) => !newActualResult.images.includes(imageUid),
  );
  Promise.all(
    deleteImageIds.map(async (imageUid) => {
      return await actualImagesRepository.delete(imageUid);
    }),
  );

  return { actualResult: newActualResult, actualImages: newImages };
};

export const createActualImages = createAsyncThunk<
  {
    actualImages: ActualImageType[];
    actualResult: ActualResultType;
  },
  {
    formValue: ActualImagesFormType;
    openSnackbar: OpenSnackbar;
  },
  AsyncThunkConfig<undefined>
>('actualResult/createActualImages', async (args, thunkAPI) => {
  try {
    const { formValue, openSnackbar } = args;
    const { actualResult } = thunkAPI.getState().actualResult;
    if (!actualResult?.uid) {
      throw new Error('先に建築実例の基本情報を入力してください');
    }
    const newValue = await uploadActualImages(actualResult, formValue);

    await actualResultsRepository.update(newValue.actualResult);
    openSnackbar({
      variant: 'success',
      message: '建築実例の更新が完了しました',
    });

    return {
      actualResult: newValue.actualResult,
      actualImages: newValue.actualImages.map((image) => {
        if (!image.publishing) {
          image.clippedUsers = [];
          image.numOfClippedUsers = 0;
        }

        return {
          ...image,
          styleTags: image.styleTags.map((value: any) => formatValue(value)),
          roomPlace: formatValue(image.roomPlace),
          wallLooks: image.wallLooks.map((value: any) => formatValue(value)),
          floorLooks: image.floorLooks.map((value: any) => formatValue(value)),
          facilitiesAndFunctions: image.facilitiesAndFunctions.map(
            (value: any) => formatValue(value),
          ),
          exteriorNumOfFloors: image.exteriorNumOfFloors.map((value: any) =>
            formatValue(value),
          ),
          exteriorRoofTypes: image.exteriorRoofTypes.map((value: any) =>
            formatValue(value),
          ),
          kitchenTypes: image.kitchenTypes.map((value: any) =>
            formatValue(value),
          ),
          kitchenStorages: image.kitchenStorages.map((value: any) =>
            formatValue(value),
          ),
          ldkTypes: image.ldkTypes.map((value: any) => formatValue(value)),
          roomTypes: image.roomTypes.map((value: any) => formatValue(value)),
        };
      }) as ActualImageType[],
    };
  } catch (error) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        '建築実例の更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});
