import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MakerType } from './types';
import { fetchMakerTableData, createMaker } from './actions';

export type CollaboratorState = {
  makerTableData: MakerType[];
  maker: MakerType;
  fetching: boolean;
  creating: boolean;
};

export const initialState: CollaboratorState = {
  makerTableData: [],
  maker: {
    name: '',
    companyName: '',
    companyType: null,
    postalCode: '',
    address: '',
    googleMapUrl: '',
    coverageAreas: [],
    coverageWorks: [],
    approximateAmounts: [],
    concept: {
      title: '',
      youtube: '',
      image: '',
      text: '',
    },
    features: [],
    establishmentDate: null,
    mailAddress: '',
    phoneNumber: '',
    inquiryAnnotation: '',
    capitalStock: '',
    numberOfEmployees: '',
    representative: '',
    webSiteUrl: '',
    fbUrl: '',
    twUrl: '',
    igUrl: '',
    business: '',
    approvalLicenses: '',
    logoImage: '',
    images: [],
    documents: [],
    salesOffices: [],
    slug: '',
    publishing: false,
    collaborators: [],
    plan: 'free',
    uid: '',
    createDate: 0,
    updateDate: 0,
    followers: [],
    numOfFollowers: 0,
    performanceStandards: [],
    supportMeetings: [],
    supportMonies: [],
    supportLands: [],
    tsuboPriceCustomHouse: [0, 0],
    tsuboPriceRenovation: [0, 0],
    tsuboPriceReform: [0, 0],
    strength1st: null,
    strength2nd: null,
    specializedField1st: null,
    specializedField2nd: null,
    specializedField3rd: null,
    wordComment: {
      title: '',
      description: '',
    },
    personName: '',
    personTel: '',
    businessHours: '',
    individualMeetingPlaces: [],
  },
  fetching: false,
  creating: false,
};

const makerSlice = createSlice({
  name: 'makers',
  initialState,
  reducers: {
    setMaker: (state, action: PayloadAction<MakerType>) => {
      state.maker = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchMakerTableData
    builder.addCase(fetchMakerTableData.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchMakerTableData.fulfilled, (state, action) => {
      state.makerTableData = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchMakerTableData.rejected, (state) => {
      state.makerTableData = [];
      state.fetching = false;
    });

    // createMaker
    builder.addCase(createMaker.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(createMaker.fulfilled, (state, action) => {
      state.maker = action.payload;
      state.creating = false;
    });
    builder.addCase(createMaker.rejected, (state) => {
      state.creating = false;
    });
  },
});

export const { setMaker } = makerSlice.actions;

export default makerSlice;
