import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    whiteSpace: 'nowrap',
    '& table': {
      minWidth: 2000,
      width: '100%',
      '& .cell-reservations': {
        textAlign: 'center',
      },
      '& .cell-title': {
        whiteSpace: 'normal',
        wordBreak: 'break-all',
      },
      '& .button': {
        width: '100%',
      },
    },
  },
  eventToolBox: {
    position: 'relative',
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
  eventLimit: {
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  numEvent: {
    marginLeft: theme.spacing(2),
    fontSize: 24,
    letterSpacing: '0.2em',
  },
}));
