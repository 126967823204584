import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: '100%',
    boxShadow: `0 0 0 1px ${theme.palette.grey['400']}`,
    '& th': {
      whiteSpace: 'nowrap',
    },
    '& th, & td': {
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey['400']}`,
      backgroundColor: theme.palette.grey['100'],
    },
    '& tr:last-child': {
      '& th, & td': {
        borderBottom: 'none',
      },
    },
  },
}));
