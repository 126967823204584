import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import parse from 'html-react-parser';

import { useStyles } from './useStyles';
import { fetchTheInformation } from '../../../../re-ducks/wordpress/actions';
import {
  selectFetching,
  selectInformation,
} from '../../../../re-ducks/wordpress/selector';
import { formats } from '../../../../data/date';
import './style.scss';

const InformationDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const fetching = useSelector(selectFetching);
  const information = useSelector(selectInformation);
  const classes = useStyles();

  useEffectOnce(() => {
    dispatch(fetchTheInformation({ postId: id }));
  });

  return (
    <div className={classes.root}>
      {fetching ? <CircularProgress /> : null}
      {information ? (
        <div className={classes.content}>
          <Typography variant="body2" className={classes.date}>
            {moment(information.date).format(formats[1])}
          </Typography>
          <Typography variant="h5" component="h2" className={classes.title}>
            {information.title}
          </Typography>
          <picture className={classes.image}>
            <img src={information.thumbnail.url} alt={information.title} />
          </picture>

          <div className="content-area magazine-content">
            {parse(information.content)}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InformationDetail;
