import { useSelector } from 'react-redux';
import { ObjectUid, Where } from 'my-firebase-wrapper/dist/firestore/types';

import { selectRole } from '../../re-ducks/collaborator/selector';

const useQueryWithMyRole = <T extends ObjectUid>(
  query: Where<T>,
): Where<T> | undefined => {
  const myRole = useSelector(selectRole);

  return myRole === 'admin' ? query : undefined;
};

export default useQueryWithMyRole;
