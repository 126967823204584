import React, { ReactElement, VFC } from 'react';
import Button from '@material-ui/core/Button';
import useStyles from './useStyles';

export interface ButtonProps {
  id: string;
  label: string | ReactElement;
  setFunc: (event: React.MouseEvent<HTMLButtonElement>) => void;
  siblingElement?: ReactElement;
}

interface Props {
  buttonItems: ButtonProps[];
}

const EditorButtonGroup: VFC<Props> = ({ buttonItems }) => {
  const classes = useStyles();

  return (
    <div className={classes.buttonGroup}>
      {buttonItems.map(({ id, label, setFunc, siblingElement }) => {
        return (
          <div key={id}>
            <Button
              variant="outlined"
              onMouseDown={(event) => {
                setFunc(event);
                event.preventDefault();
              }}
              className={classes.button}
            >
              {label}
            </Button>
            {siblingElement}
          </div>
        );
      })}
    </div>
  );
};

export default EditorButtonGroup;
