import React, { VFC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar as MuiAvatar, Box } from '@material-ui/core';
import { CellProps } from 'react-table';
import BoringAvatar from 'boring-avatars';

const useStyles = makeStyles({
  root: {
    width: 60,
    height: 60,
  },
});

export interface AvatarProps {
  userId: string;
  size?: string;
  src?: string;
}

const PhotoCell: VFC<CellProps<any>> = ({ cell, row }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {cell.value ? (
        <MuiAvatar src={cell.value} className={classes.root} />
      ) : (
        <BoringAvatar
          size={60}
          name={row.original.uid}
          variant="beam"
          colors={['#ed7700', '#ffd803', '#fdf0d3', '#dedede', '#a3ddcb']}
        />
      )}
    </Box>
  );
};

export default PhotoCell;
