import React, { useCallback } from 'react';
import { Close, AddAPhoto } from '@material-ui/icons';
import { useDropzone } from 'react-dropzone';
import Compressor from 'compressorjs';

import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';
import getStorageFIleUrl from '../../../../../utils/getStorageFIleUrl';
import { useStyles } from './useStyles';

export interface AddPhotoProps {
  name: string;
  jpegUrl?: string;
  setJpegUrl: (url: string) => void;
  disabled?: boolean;
  accept?: string | string[];
  passPng?: boolean;
  registerOption?: RegisterOptions;
  onClose?: () => void;
}

export const AddPhoto: React.FC<AddPhotoProps> = ({
  name,
  jpegUrl,
  setJpegUrl,
  accept,
  disabled,
  passPng,
  registerOption,
  onClose,
}) => {
  const { control, register } = useFormContext();
  const imageUrl: string =
    useWatch({ name: name || 'image', control, defaultValue: jpegUrl }) || '';

  const handleUploadFile = async (
    acceptedFiles: File[],
    rejectedFiles: File[],
  ) => {
    if (rejectedFiles.length) window.alert('ファイル形式が違います');

    const option = {
      maxWidth: 2000,
      maxHeight: 2000,
      quality: 0.9,
    };

    await Promise.all(
      acceptedFiles.map((file) => {
        const jpeg = new Compressor(file, {
          ...option,
          mimeType: passPng ? file.type : 'image/jpeg',
          success(b: Blob) {
            const reader = new FileReader();
            reader.readAsDataURL(b);
            reader.onloadend = () => {
              const { result } = reader;
              if (typeof result === 'string') {
                setJpegUrl(result);
              }
            };
          },
        });

        return jpeg;
      }),
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled: disabled || false,
    onDrop: useCallback((acceptedFiles, rejectedFiles) => {
      handleUploadFile(acceptedFiles, rejectedFiles);
    }, []),
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {imageUrl ? (
        <Close
          fontSize="small"
          className={classes.cancel}
          onClick={() => {
            if (onClose) {
              onClose();
            } else {
              setJpegUrl('');
            }
          }}
        />
      ) : null}
      <input
        type="hidden"
        name={name}
        value={imageUrl}
        ref={register(registerOption)}
      />
      {imageUrl ? (
        <div className={classes.imageRoot}>
          <img
            src={
              imageUrl && !imageUrl.includes('data:image')
                ? getStorageFIleUrl(imageUrl)
                : imageUrl
            }
            alt="追加画像"
            className={classes.image}
          />
        </div>
      ) : (
        <div className={classes.box} {...getRootProps()}>
          <input {...getInputProps()} />
          <AddAPhoto />
        </div>
      )}
    </div>
  );
};
