import React, { FC } from 'react';
import {
  Typography,
  TypographyProps,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&::after': {
      content: '"※"',
      color: theme.palette.error.main,
    },
  },
}));

export const RequiredText: FC<TypographyProps> = (props) => {
  const classes = useStyles();

  return <Typography {...props} className={classes.root} />;
};
