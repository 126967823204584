import React, { VFC } from 'react';
import { ColumnInstance, FilterValue, IdType, Row } from 'react-table';
import { BILLING_STATUS } from '../../../../../re-ducks/themes/types';

export const BillingStatusTypeFilter = (
  rows: Array<Row<any>>,
  columnIds: Array<IdType<any>>,
  filterValue: FilterValue,
): Array<Row<any>> => {
  return rows.filter((row) => {
    return filterValue ? row.values[columnIds[0]] === filterValue : true;
  });
};

export const BillingStatusFilter: VFC<{ column: ColumnInstance<never> }> = ({
  column,
}) => {
  const { id, render, setFilter, filterValue } = column;

  return (
    <div>
      <div className="label">{render('Header')}</div>
      <div className="control select is-block">
        <select
          className="select column is-full"
          name={id}
          value={filterValue || ''}
          onChange={(event) => setFilter(event.target.value || '')}
        >
          <option value="">選択してくだい</option>
          {Object.entries(BILLING_STATUS).map(([key, value]) => (
            <option key={key} value={key}>
              {value}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
