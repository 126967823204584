import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    height: '100%',
  },
  contents: {
    flex: 1,
    overflow: 'hidden',
    overflowY: 'scroll',
    height: '100%',
    minHeight: 500,
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 10,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.5)',
      borderRadius: 20,
      boxShadow: '0 0 1px hsl(0deg 0% 100% / 50%)',
    },
    '&::-webkit-scrollbar-track': {
      background: 'hsla(0,0%,100%,.1)',
      border: 'none',
    },
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: theme.spacing(-1),
  },
  media: {
    width: 150,
    height: 150,
    position: 'relative',
    margin: theme.spacing(1),
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      boxShadow: `inset 0 0 0 1px ${theme.palette.grey['500']}`,
      zIndex: 2,
    },
    '&.is-select::before': {
      boxShadow: `inset 0 0 0 3px ${theme.palette.primary.main}`,
    },
    '& img': {
      position: 'relative',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      zIndex: 1,
    },
  },
  sidebar: {
    width: 200,
    borderLeft: `1px solid ${theme.palette.grey['300']}`,
    paddingLeft: theme.spacing(3),
  },
  selectedImage: {
    width: '100%',
    height: 120,
    marginBottom: theme.spacing(2),
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  fileMeta: {
    fontSize: 14,
    wordBreak: 'break-all',
  },
  fileName: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  fileSize: {
    marginBottom: theme.spacing(1),
  },
}));

export default useStyles;
