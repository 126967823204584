import React, { VFC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { Checkbox } from '../../../base/form/Checkbox';
import { ALL_AREA, NIIGATA_AREA } from '../../../../../data/area';
import { selectMaker } from '../../../../../re-ducks/maker/selector';
import { useStyles } from './useStyles';

export const MakerCoverageAreas: VFC = () => {
  const { register } = useFormContext();
  const maker = useSelector(selectMaker);
  const [coverageAreas, setCoverageAreas] = useState(maker.coverageAreas);
  const [areaChecked, setAreaChecked] = useState<{ [key: string]: boolean }>(
    NIIGATA_AREA.reduce((prev, currentArea) => {
      const children = ALL_AREA.filter((a) => a.parent === currentArea);

      return {
        ...prev,
        [currentArea]: children.every((child) =>
          coverageAreas.includes(child?.id || ''),
        ),
      };
    }, {}),
  );
  const classes = useStyles();

  useEffect(() => {
    NIIGATA_AREA.forEach((area) => {
      const children = ALL_AREA.filter((a) => a.parent === area);
      setAreaChecked((prevState) => ({
        ...prevState,
        [area]: !!children.every((child) =>
          coverageAreas.includes(child?.id || ''),
        ),
      }));
    });
  }, [coverageAreas]);

  return (
    <div>
      {NIIGATA_AREA.map((area) => {
        const areaTitle = ALL_AREA.find((a) => a.name === area);
        const children = ALL_AREA.filter((a) => a.parent === area);

        return (
          <div key={area}>
            <div className={classes.subTitle}>
              <Checkbox
                label={areaTitle?.label || ''}
                checkboxProps={{
                  name: area,
                  checked: area in areaChecked ? areaChecked[area] : false,
                  onChange: (event) => {
                    setAreaChecked((prevState) => ({
                      ...prevState,
                      [area]: event.target.checked,
                    }));

                    if (event.target.checked) {
                      setCoverageAreas((prevState) => {
                        const newValue = prevState;

                        return newValue.concat(
                          children
                            .filter(
                              (child) => !newValue.includes(child?.id || ''),
                            )
                            .map((child) => child?.id || ''),
                        );
                      });
                    } else {
                      setCoverageAreas((prevState) => {
                        return [
                          ...prevState.filter(
                            (a) => !children.some((child) => child.id === a),
                          ),
                        ];
                      });
                    }
                  },
                }}
              />
            </div>
            <div className={classes.areaChecks}>
              {children.map((child) => {
                return (
                  <Checkbox
                    key={child.id}
                    label={child.label}
                    checkboxProps={{
                      name: 'coverageAreas',
                      value: child.id,
                      checked: coverageAreas.includes(child?.id || ''),
                      inputRef: register,
                      onChange: (event) => {
                        setCoverageAreas((prevState) => {
                          if (event.target.checked) {
                            return [...prevState, event.target.value];
                          }

                          return prevState.filter(
                            (a) => a !== event.target.value,
                          );
                        });
                      },
                    }}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
