import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) => ({
  dl: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dt: {
    display: 'flex',
    alignItems: 'center',
    width: '20%',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 0,
      paddingTop: theme.spacing(3),
      paddingBottom: 0,
    },
    '&.flex-start': {
      alignItems: 'flex-start',
    },
    '&.flex-end': {
      alignItems: 'flex-end',
    },
  },
  dd: {
    width: '80%',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
  },
}));

export default useStyles;
