import React, { FC } from 'react';
import MuiInputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import classNames from 'classnames';
import { useStyles } from './useStyles';

interface Props {
  noAsterisk?: boolean;
}

export const InputLabel: FC<Props & InputLabelProps> = ({
  noAsterisk,
  ...props
}) => {
  const classes = useStyles();

  return (
    <MuiInputLabel
      className={classNames({ [classes.noAsterisk]: noAsterisk })}
      classes={{
        root: classes.labelRoot,
        required: classes.required,
        asterisk: classes.asterisk,
      }}
      {...props}
    />
  );
};

InputLabel.defaultProps = {
  noAsterisk: false,
};
