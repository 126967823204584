import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonWrap: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(3)}px auto 0`,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));
