import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  item: {
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    '&:first-child': {
      marginTop: 0,
    },
  },
  addButton: {
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: 0,
    },
  },
  closeButton: {
    display: 'block',
    marginLeft: 'auto',
  },
  image: {
    width: `min(100%, 600px)`,
    aspectRatio: '3/2',
  },
}));
