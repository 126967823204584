import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    whiteSpace: 'normal',
    '& table': {
      minWidth: 1040,
    },
  },
}));
