import { Editor, EditorState } from 'draft-js';
import React, {
  createContext,
  Dispatch,
  RefObject,
  SetStateAction,
} from 'react';

interface State {
  editorState: EditorState;
  setEditorState: Dispatch<SetStateAction<EditorState>>;
  editorRef: RefObject<Editor> | null;
  isModalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  anchorEle: HTMLButtonElement | null;
  setAnchorEle: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

export const EditorContext = createContext<State>({
  editorState: EditorState.createEmpty(),
  setEditorState: () => '',
  editorRef: null,
  isModalOpen: false,
  setModalOpen: () => '',
  anchorEle: null,
  setAnchorEle: () => '',
});
