import { makeStyles, Theme } from '@material-ui/core';
import indigo from '@material-ui/core/colors/indigo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 360,
    padding: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    '& ol': {
      marginLeft: '1em',
    },
    '& ul': {
      marginLeft: '1em',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  icon: {
    color: indigo[400],
  },
}));

export default useStyles;
