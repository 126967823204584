import React, { VFC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Email } from '@material-ui/icons';

import useQueryWithMyRole from '../../../../hooks/useQueryWithMyRole';
import LinkText from '../../../base/LinkText';
import { DashboardAccordion } from '../../../case/DashboardAccordion';
import { setMakerContact } from '../../../../../re-ducks/makerContacts/slice';
import { MakerContactType } from '../../../../../re-ducks/makerContacts/types';
import { fetchMakerContacts } from '../../../../../re-ducks/makerContacts/actions';
import {
  selectFetching,
  selectMakerContacts,
} from '../../../../../re-ducks/makerContacts/selector';
import { selectSelectedMaker } from '../../../../../re-ducks/app/selector';
import { useStyles } from './useStyles';
import { DotProgress } from '../../../case/feedback/DotProgress';
import { selectRole } from '../../../../../re-ducks/collaborator/selector';

export interface Props {
  limit?: number;
}

export const MakerContactList: VFC<Props> = ({ limit }) => {
  const dispatch = useDispatch();
  const myRole = useSelector(selectRole);
  const selectedMaker = useSelector(selectSelectedMaker);
  const makerContacts = useSelector(selectMakerContacts);
  const fetching = useSelector(selectFetching);
  const classes = useStyles();

  const onlyMyMakersContactsQuery = useQueryWithMyRole<MakerContactType>({
    fieldPath: 'makerId',
    opStr: '==',
    value: selectedMaker?.uid,
  });

  useEffect(() => {
    dispatch(
      fetchMakerContacts({
        whereQueries: onlyMyMakersContactsQuery
          ? [onlyMyMakersContactsQuery]
          : [],
      }),
    );
  }, []);

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  return (
    <div>
      <DashboardAccordion
        heading={
          <div className={classes.heading}>
            <Email color="secondary" />
            お問い合わせ
          </div>
        }
        detail={
          <div>
            <div>
              {!makerContacts || !makerContacts?.length
                ? null
                : makerContacts
                    .slice()
                    .sort((a, b) => {
                      return a.date < b.date ? 1 : -1;
                    })
                    .slice(0, limit)
                    .map((contact) => {
                      return (
                        <button
                          key={contact.uid}
                          className={classes.contactItem}
                          onClick={() => {
                            dispatch(setMakerContact(contact));
                            dispatch(
                              push(`/makerContacts/detail/${contact.uid}`),
                            );
                          }}
                        >
                          <div className={classes.contactFrom}>
                            {contact.name}様から
                            {contact.inquiryType || 'お問い合わせ'}がありました
                          </div>
                          <div className={classes.contactMeta}>
                            {myRole === 'cunelwork' ? (
                              <div>{contact?.makerName}</div>
                            ) : null}
                            <div>{contact.date}</div>
                          </div>
                        </button>
                      );
                    })}
            </div>
            <div className={classes.footer}>
              <LinkText href="/makerContacts" />
            </div>
          </div>
        }
      />
    </div>
  );
};
