import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { Typography } from '@material-ui/core';

import { TextField } from '../../../base/form/TextField';
import { useStyles } from './useStyles';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import FormSection from '../../../case/form/FormSection';
import { MakerDocuments } from './MakerDocuments';
import { IndividualMeetingPlaces } from './IndividualMeetingPlaces';

const Main: FC = () => {
  const classes = useStyles();
  const formMethods = useFormContext();
  const { errors, register } = formMethods;

  return (
    <div className="main">
      <FormSection sectionTitle="お問い合わせ受付担当">
        <Typography variant="body2" className="mb-5">
          お問い合わせを受け付けるメールアドレスや電話番号を入力してください。
        </Typography>
        <div className={classes.group}>
          <TextField
            label="メールアドレス"
            type="email"
            name="mailAddress"
            inputRef={register({
              required: 'メールアドレスを入力してください',
            })}
            error={!!errors?.mailAddress}
            required
          />
          <ErrorMessage error={errors?.mailAddress} />
        </div>
        <div className={classes.group}>
          <TextField
            label="電話番号"
            type="tel"
            name="personTel"
            inputRef={register({ required: '電話番号を入力してください' })}
            required
            error={!!errors?.personTel}
          />
          <ErrorMessage error={errors?.personTel} />
        </div>
        <div className={classes.group}>
          <TextField
            label="担当者名"
            type="text"
            name="personName"
            inputRef={register()}
            error={!!errors?.personName}
          />
          <ErrorMessage error={errors?.personName} />
        </div>
        <div className={classes.group}>
          <TextField
            label="営業時間"
            type="text"
            name="businessHours"
            placeholder="10:00〜17:00（〇曜定休）"
            inputRef={register()}
            error={!!errors?.businessHours}
          />
          <ErrorMessage error={errors?.businessHours} />
        </div>
      </FormSection>
      <FormSection sectionTitle="資料請求">
        <Typography variant="body2" className="mb-5">
          資料請求後に送付する資料の詳細情報を入力してください。
        </Typography>
        <MakerDocuments />
      </FormSection>
      <FormSection sectionTitle="相談方法・場所">
        <Typography variant="body2" className="mb-2">
          個別相談予約フォームに表示する相談方法や場所、会場を入力してください。
        </Typography>
        <Typography variant="body2" className="mb-5">
          ・〇〇営業所
          <br />
          ・オンラインでの面談希望
          <br />
          ・メールや電話での連絡希望 など…
        </Typography>
        <IndividualMeetingPlaces />
      </FormSection>
    </div>
  );
};

export default Main;
