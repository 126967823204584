import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { RiAdvertisementFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { DashboardAccordion } from '../../case/DashboardAccordion';
import IconInformation from '../../base/Icon/IconInformation';
import { AdminWrapper } from '../../domain/AdminWrapper';
import InformationList from '../../domain/InformationList';
import { MakerContactList } from '../../domain/MakerContacts/MakerContactList';
import { useStyles } from './useStyles';
import LinkText from '../../base/LinkText';
import AdBox from '../../domain/AdBox';
import BnrTop1 from '../../../../assets/images/bnr_top_01.jpg';
import BnrTop2 from '../../../../assets/images/bnr_top_02.jpg';

export const Top: FC = () => {
  const classes = useStyles();

  return (
    <AdminWrapper>
      <Box mb={4}>
        <DashboardAccordion
          heading={
            <Box
              display="flex"
              alignItems="center"
              gridGap={8}
              className={classes.iconWrap}
            >
              <IconInformation size={24} fill />
              お知らせ
            </Box>
          }
          detail={
            <div className={classes.detail}>
              <InformationList limit={5} />
              <footer className={classes.footer}>
                <LinkText href="/information" />
              </footer>
            </div>
          }
        />
      </Box>
      <MakerContactList limit={3} />
      <Box mt={4}>
        <Typography variant="body1" className={classes.adTitle}>
          その他お役立ちサービス
        </Typography>
        <div className={classes.cwBnrArea}>
          <a
            href="https://cunelwork.co.jp/downloads/niigata_subsidy/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.cwBnr}
          >
            <img src={BnrTop1} alt="" />
          </a>
          <a
            href="https://cunelwork.co.jp/works/business_type/housing/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.cwBnr}
          >
            <img src={BnrTop2} alt="" />
          </a>
        </div>
      </Box>
    </AdminWrapper>
  );
};
