import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color: theme.palette.grey.A400,
    background: theme.palette.grey['500'],
    transition: `${theme.transitions.duration.shortest}ms`,
    transitionTimingFunction: theme.transitions.easing.easeIn,
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.grey['400'],
    },
    '& svg': {
      fontSize: '1.5rem',
    },
  },
  imageRoot: {
    display: 'block',
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.grey['300']}`,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  cancel: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    borderRadius: '50%',
    padding: 2,
    color: theme.palette.common.white,
    background: theme.palette.common.black,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));
