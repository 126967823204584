import moment from 'moment';
import { Timestamp, TimestampType } from '../repositories/firebase';
import { formats } from '../data/date';

export const convertToDateFromTimestamp = (
  date: any,
  format: string = formats[1],
): string | null => {
  if (typeof date === 'string') {
    return moment(date).format(format);
  }

  return date ? moment(date.toDate()).format(format) : null;
};

export const convertToTimestampFromString = (
  date: any,
  format: string = formats[1],
): TimestampType => {
  if (
    date &&
    typeof date === 'object' &&
    'nanoseconds' in date &&
    'seconds' in date
  ) {
    return date;
  }
  if (date && date instanceof Date) {
    return Timestamp.fromDate(date);
  }

  return date
    ? Timestamp.fromDate(moment(date, format).toDate())
    : Timestamp.now();
};

export const timestamp2Number = (date: any): number | null => {
  if (!date) return null;

  return date instanceof Timestamp ? date.toMillis() : 0;
};

export const dateFormat = (date: number | Date): Date | null => {
  if (!date) return null;

  if (typeof date === 'number') {
    return Timestamp.fromMillis(date).toDate();
  }

  if (date instanceof Date) {
    return date;
  }

  return new Date();
};
