import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    border: `1px dashed ${theme.palette.grey['400']}`,
    borderRadius: theme.shape.borderRadius,
    boxSizing: 'border-box',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey['50'],
    },
  },
  content: {
    '& h3': {
      marginBottom: theme.spacing(2),
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 1,
    },
    '& p': {
      marginBottom: theme.spacing(2),
      fontSize: 14,
      lineHeight: 1,
    },
  },
  button: {
    padding: `5px ${theme.spacing(3)}px`,
    backgroundColor: theme.palette.grey['200'],
  },
}));

export default useStyles;
