import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Typography } from '@material-ui/core';

import useSnackbar from '../../../../hooks/useSnackbar';
import { useStyles } from './useStyles';
import { Snackbar } from '../../feedback/Snackbar';
import { SaveButton } from '../../button/SaveButton';
import { TextBox } from '../../../base/form/TextBox';
import { PasswordStrengthField } from '../../PasswordStrengthField';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { DefinitionList } from '../../../base/DefinitionList';
import { setMyAccount } from '../../../../../re-ducks/collaborator/actions';
import { MyAccountFormType } from '../../../../../re-ducks/collaborator/types';
import { selectCollaborator } from '../../../../../re-ducks/collaborator/selector';
import { pwChecker } from '../../../../../utils/PasswordValidate';

export const MyAccountForm: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const collaborator = useSelector(selectCollaborator);
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();
  const { register, handleSubmit, watch, errors } = useForm<MyAccountFormType>({
    defaultValues: {
      uid: collaborator.uid,
      name: collaborator.name,
      role: collaborator.role,
      mailAddress: collaborator.mailAddress,
      password: '',
      currentPassword: '',
    },
  });
  const onSubmit = handleSubmit((data) => {
    dispatch(setMyAccount({ formValue: data, openSnackbar }));
  });

  const currentPasswordValidation =
    watch('mailAddress') !== collaborator.mailAddress || watch('password')
      ? { required: '現在のパスワードを入力してください' }
      : {};

  return (
    <form onSubmit={onSubmit} noValidate>
      <input type="hidden" name="uid" ref={register} />
      <DefinitionList
        components={[
          {
            dt: <Typography>名前</Typography>,
            dd: (
              <div>
                <TextBox
                  type="text"
                  name="name"
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({ required: '名前を入力してください' })}
                />
                <ErrorMessage error={errors?.name} />
              </div>
            ),
          },
          {
            dt: <Typography>権限</Typography>,
            dd: (
              <div>
                <TextBox
                  type="text"
                  name="role"
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({ required: '権限を入力してください' })}
                  disabled
                />
                <ErrorMessage error={errors?.role} />
              </div>
            ),
          },
          {
            dt: <Typography>メールアドレス</Typography>,
            dd: (
              <div>
                <TextBox
                  type="text"
                  name="mailAddress"
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({
                    required: 'メールアドレスを入力してください',
                  })}
                />
                <ErrorMessage error={errors?.mailAddress} />
              </div>
            ),
          },
          {
            dt: <Typography>新しいパスワード</Typography>,
            dd: (
              <div>
                <PasswordStrengthField
                  textFieldProps={{
                    name: 'password',
                    value: watch('password'),
                    InputLabelProps: { shrink: true },
                    inputRef: register({
                      validate: {
                        min: (value: string) =>
                          value.length === 0 || pwChecker.validateMin(value)
                            ? true
                            : 'パスワードは6文字以上を設定してください',
                        max: (value: string) =>
                          pwChecker.validateMax(value)
                            ? true
                            : 'パスワードは64文字以内を設定してください',
                        hasNotSpace: (value: string) =>
                          pwChecker.hasNotSpace(value)
                            ? true
                            : 'パスワードにスペースを設定しないでください',
                      },
                    }),
                  }}
                />
                <ErrorMessage error={errors?.password} />
              </div>
            ),
          },
        ]}
      />
      <div className={classes.currentPassword}>
        <TextBox
          type="password"
          name="currentPassword"
          label="現在のパスワード"
          InputLabelProps={{ shrink: true }}
          inputRef={register(currentPasswordValidation)}
        />
        <ErrorMessage error={errors?.currentPassword} />
      </div>
      <ul className={classes.note}>
        <li>
          ※メールアドレスやパスワードを変更するには、現在のパスワードを入力してください。
        </li>
      </ul>
      <div className={classes.buttonWrap}>
        <SaveButton type="submit">アカウントを更新する</SaveButton>
      </div>
      <Snackbar
        variant={snackbarState.variant}
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        onClose={closeSnackbar}
      />
    </form>
  );
};
