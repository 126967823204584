import React, { FC } from 'react';
import { Alert } from '@material-ui/lab';

import { AdminWrapper } from '../../domain/AdminWrapper';

export const FreePlan: FC = () => {
  return (
    <AdminWrapper>
      <Alert severity="info">
        現在「Freeプラン」です。
        <br />
        担当者に連絡して有料プランを契約してください。
      </Alert>
    </AdminWrapper>
  );
};
