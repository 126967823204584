import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  tableTitle: {
    marginBottom: 10,
    paddingBottom: 10,
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
  tableContainer: {
    overflow: 'visible',
  },
  table: {
    tableLayout: 'auto',
  },
  tableLabel: {
    width: 220,
    paddingLeft: 0,
    fontWeight: 500,
    lineHeight: 1.8,
    boxSizing: 'border-box',
  },
  tableLabelTop: {
    verticalAlign: 'top',
  },
  tableDetail: {
    paddingRight: 0,
    lineHeight: 1.8,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-all',
    boxSizing: 'border-box',
    '& p': {
      margin: 0,
    },
    '& p + p': {
      marginTop: 20,
      [theme.breakpoints.up('md')]: {
        marginTop: 30,
      },
    },
  },
}));
