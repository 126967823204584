import React, { VFC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { AiOutlineInfoCircle, AiFillInfoCircle } from 'react-icons/ai';
import clsx from 'clsx';
import { ReactComponent as Icon } from '../../../../assets/images/icon/icon_collaborator.svg';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .target': {
      fill: '#4a4a4a',
    },
  },
}));

interface Props {
  fill?: boolean;
  size?: string | number;
}

const IconInformation: VFC<Props> = ({ size, fill }) => {
  const classes = useStyles();

  return fill ? (
    <AiFillInfoCircle className={classes.root} size={size} />
  ) : (
    <AiOutlineInfoCircle className={classes.root} size={size} />
  );
};

export default IconInformation;
