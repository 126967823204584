import clsx from 'clsx';
import React, { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import useQueryWithMyRole from '../../../../hooks/useQueryWithMyRole';
import { Table } from '../../../base/Table';
import { DefaultColumnFilter } from '../../../base/Table/DefaultColumnFilter';
import { BooleanColumnFilter } from '../../../base/Table/BooleanColumnFilter';
import { DotProgress } from '../../../case/feedback/DotProgress';
import { PublishingCell } from '../../../case/table/PublishingCell';
import { ImageCell } from '../../../case/table/ImageCell';
import { DateCell } from '../../../case/table/DateCell';
import { MakerType } from '../../../../../re-ducks/maker/types';
import { setMaker } from '../../../../../re-ducks/maker/slice';
import {
  selectMakerTableData,
  selectFetching,
} from '../../../../../re-ducks/maker/selector';
import { selectUid } from '../../../../../re-ducks/collaborator/selector';
import { fetchMakerTableData } from '../../../../../re-ducks/maker/actions';
import { useStyles } from './useStyles';

export const MakerListTable: VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const myUid = useSelector(selectUid);
  const collaboratorTableData = useSelector(selectMakerTableData);
  const fetching = useSelector(selectFetching);

  const onlyMyMakersQuery = useQueryWithMyRole<MakerType>({
    fieldPath: 'collaborators',
    opStr: 'array-contains',
    value: myUid,
  });

  useEffect(() => {
    dispatch(
      fetchMakerTableData({
        whereQueries: onlyMyMakersQuery ? [onlyMyMakersQuery] : [],
        orderQueries: [],
      }),
    );
  }, []);

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  return (
    <Table<MakerType>
      tableOptions={{
        data: collaboratorTableData,
        columns: [
          {
            Header: '',
            accessor: 'logoImage',
            Filter: DefaultColumnFilter,
            disableSortBy: true,
            disableFilters: true,
            Cell: ImageCell,
            width: 80,
          },
          {
            Header: 'ブランド名',
            accessor: 'name',
            Filter: DefaultColumnFilter,
          },
          {
            Header: '公開状態',
            accessor: 'publishing',
            Filter: BooleanColumnFilter,
            Cell: PublishingCell,
            width: 160,
          },
          {
            Header: 'Slug',
            accessor: 'slug',
            Filter: DefaultColumnFilter,
            width: 160,
          },
          {
            Header: '作成日',
            accessor: 'createDate',
            disableFilters: true,
            width: 160,
            Cell: DateCell,
          },
          {
            Header: '更新日',
            accessor: 'updateDate',
            disableFilters: true,
            width: 160,
            Cell: DateCell,
          },
        ],
        initialState: {
          pageSize: 50,
          sortBy: [{ id: 'slug' }],
          hiddenColumns: [
            'uid',
            'companyName',
            'companyType',
            'postalCode',
            'address',
            'coverageAreas',
            'coverageWorks',
            'approximateAmounts',
            'concept',
            'features',
            'establishmentDate',
            'mailAddress',
            'phoneNumber',
            'inquiryAnnotation',
            'capitalStock',
            'numberOfEmployees',
            'representative',
            'webSiteUrl',
            'fbUrl',
            'twUrl',
            'igUrl',
            'business',
            'approvalLicenses',
            'images',
            'salesOffices',
            'collaborators',
            'childMakers',
            'followers',
            'plan',
            'performanceStandards',
            'supportMeetings',
            'supportMonies',
            'supportLands',
            'tsuboPriceCustomHouse',
            'tsuboPriceRenovation',
            'tsuboPriceReform',
            'strength1st?',
            'strength2nd?',
            'specializedField1st?',
            'specializedField2nd?',
            'specializedField3rd?',
          ],
        },
      }}
      onClick={(row) => {
        dispatch(setMaker(row.original));
        dispatch(push(`/professional/edit/${row.original.uid}`));
        window.scrollTo(0, 0);
      }}
      classes={{
        root: clsx('table-container', classes.container),
        table: clsx('table', 'is-striped', 'is-hoverable', 'list-table'),
      }}
    />
  );
};
