import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  adArea: {
    display: 'grid',
    justifyContent: 'center',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  adBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid #ccc`,
    background: theme.palette.common.white,
  },
  adTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    background: theme.palette.common.white,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    fontWeight: 'bold',
    borderRadius: theme.shape.borderRadius,
  },
  iconWrap: {
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
  detail: {
    '& > div': {
      padding: 0,
    },
  },
  footer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    textAlign: 'right',
  },
  cwBnrArea: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      gap: '2%',
      justifyContent: 'flex-start',
    },
  },
  cwBnr: {
    display: 'block',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '49%',
    },
  },
}));
