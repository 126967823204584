import React from 'react';
import { convertToHTML } from 'draft-convert';
import { DraftInlineStyleType, EditorState } from 'draft-js';
import LinkConverter from './CustomBlock/Link/Converter';

export default (state: EditorState) => {
  const contentState = state.getCurrentContent();

  return convertToHTML({
    styleToHTML: (style: DraftInlineStyleType | 'marker') => {
      if (style === 'marker') {
        return <span className="marker" />;
      }

      return undefined;
    },
    blockToHTML: (block) => {
      switch (block.type) {
        case 'atomic': {
          const contentBlock = contentState.getBlockForKey(block.key);
          const entity = contentState.getEntity(contentBlock.getEntityAt(0));
          const type = entity.getType();
          switch (type) {
            case 'image': {
              return {
                start: '<figure>',
                end: '</figure>',
                empty: '',
              };
            }
            default: {
              return undefined;
            }
          }
        }
        default: {
          return undefined;
        }
      }
    },
    entityToHTML: (entity, originalText) => {
      switch (entity.type) {
        case 'LINK': {
          return (
            <LinkConverter
              url={entity.data?.url || ''}
              external={entity.data?.external}
            >
              {originalText}
            </LinkConverter>
          );
        }
        case 'image': {
          return <img src={entity.data?.src || ''} alt="" />;
        }
        default: {
          return undefined;
        }
      }
    },
  })(contentState);
};
