import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';
import { v4 } from 'uuid';

import getStorageFIleUrl from '../../utils/getStorageFIleUrl';
import { formats } from '../../data/date';
import { db } from './index';
import { ActualResultType } from '../../re-ducks/actualResult/types';
import { initialState } from '../../re-ducks/actualResult/slice';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';
import { formatValue } from '../../utils/formatValue';

export const actualResultsRepository: Collection<ActualResultType> =
  db.collection<ActualResultType>({
    collectionPath: 'actualResults',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        uid: obj?.uid ? obj.uid : v4(),
        actualDate: obj.actualDate
          ? convertToTimestampFromString(obj.actualDate, formats[0])
          : null,
        createDate: convertToTimestampFromString(obj.createDate),
        updateDate: convertToTimestampFromString(obj.updateDate),
        makerId: formatValue(obj.makerId),
      };
    },
    decode: (doc) => {
      const data = doc.data();
      if (!data) return initialState.actualResult;

      return {
        ...data,
        actualDate: convertToDateFromTimestamp(data.actualDate) || '',
        createDate: convertToDateFromTimestamp(data.createDate) || '',
        updateDate: convertToDateFromTimestamp(data.updateDate) || '',
      };
    },
  });
