import React, { useEffect, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { DragIndicator } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useEffectOnce } from 'react-use';
import ReloadButton from '../../../case/button/ReloadButton';
import { AddButton } from '../../../case/button/AddButton';
import { DotProgress } from '../../../case/feedback/DotProgress';
import {
  fetchFaqCategories,
  fetchFaqItems,
  indexCategories,
} from '../../../../../re-ducks/faq/actions';
import {
  initialState,
  selectFaq,
  moveCategories,
} from '../../../../../re-ducks/faq/slice';
import {
  selectFaqItems,
  selectFaqCategories,
  selectFetching,
  selectCreating,
} from '../../../../../re-ducks/faq/selector';
import moveArray from '../../../../../utils/moveArray';
import FaqItems from './FaqItems';
import useStyles from './useStyles';

const FaqList: VFC = () => {
  const dispatch = useDispatch();
  const faqItems = useSelector(selectFaqItems);
  const faqCategories = useSelector(selectFaqCategories);
  const fetching = useSelector(selectFetching);
  const creating = useSelector(selectCreating);
  const classes = useStyles();

  useEffectOnce(() => {
    dispatch(fetchFaqItems());
    dispatch(fetchFaqCategories());
  });

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const movingCategories = faqCategories.filter((cat) =>
      result?.destination?.droppableId.includes(cat.faqType),
    );
    const movedArray = moveArray(
      movingCategories,
      result.destination.index,
      result.source.index,
    );
    const categories = faqCategories
      .map((cat) => movedArray.find((c) => cat.uid === c.uid) || cat)
      ?.sort((a, b) => (a.order > b.order ? -1 : 1));
    dispatch(moveCategories(categories));
    dispatch(indexCategories(movedArray));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <DotProgress opening={fetching || creating} />
      <Box mb={4}>
        <AddButton
          label="ヘルプを追加"
          onClick={() => {
            dispatch(selectFaq(initialState.selectedFaq));
            dispatch(push('/help/edit'));
          }}
        />
      </Box>
      <Box component="section" mb={4}>
        <div className={classes.faqHeader}>
          <h2 className={classes.faqType}>エンドユーザー側</h2>
          <ReloadButton />
        </div>
        <div className={classes.faqBox}>
          <Droppable droppableId="endUser-faq-category">
            {(droppableProvided) => (
              <ul
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {faqCategories
                  .filter((cat) => cat.faqType === 'endUser')
                  .map((cat, index) => {
                    const { label } = cat;

                    return (
                      <Draggable
                        key={cat.uid}
                        draggableId={cat.uid}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.category}
                          >
                            <header className={classes.categoryHeader}>
                              <DragIndicator fontSize="medium" />
                              <h3 className={classes.categoryName}>{label}</h3>
                            </header>
                            <div className={classes.categoryContent}>
                              {cat.faqItems.length ? (
                                <FaqItems faqItems={faqItems} category={cat} />
                              ) : (
                                <Box pt={2} pb={2}>
                                  このカテゴリーにはヘルプがありません
                                </Box>
                              )}
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
              </ul>
            )}
          </Droppable>
        </div>
      </Box>
      <Box>
        <div className={classes.faqHeader}>
          <h2 className={classes.faqType}>ビルダー側</h2>
          <ReloadButton />
        </div>
        <div className={classes.faqBox}>
          <Droppable droppableId="builder-faq-category">
            {(droppableProvided) => (
              <ul
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {faqCategories
                  .filter((cat) => cat.faqType === 'builder')
                  .map((cat, index) => {
                    const { label } = cat;

                    return (
                      <Draggable key={label} draggableId={label} index={index}>
                        {(provided) => (
                          <li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={classes.category}
                          >
                            <header className={classes.categoryHeader}>
                              <DragIndicator fontSize="medium" />
                              <h3 className={classes.categoryName}>{label}</h3>
                            </header>
                            <div className={classes.categoryContent}>
                              {cat.faqItems.length ? (
                                <FaqItems
                                  faqItems={faqItems}
                                  category={cat}
                                  faqType="builder"
                                />
                              ) : (
                                <Box pt={2} pb={2}>
                                  このカテゴリーにはヘルプがありません
                                </Box>
                              )}
                            </div>
                          </li>
                        )}
                      </Draggable>
                    );
                  })}
              </ul>
            )}
          </Droppable>
        </div>
      </Box>
    </DragDropContext>
  );
};

export default FaqList;
