import { Slider } from '@material-ui/core';
import React, { useState, VFC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDebounce } from 'react-use';
import { TSUBO_PRICES } from '../../../../../re-ducks/maker/types';

interface Props {
  name?: string;
  defaultValue: number[];
}

const TsuboSlider: VFC<Props> = ({
  name = 'tsuboPriceCustomHouse',
  defaultValue,
}) => {
  const [values, setValues] = useState(defaultValue.slice());
  const { control, setValue } = useFormContext();

  useDebounce(
    () => {
      setValue(name, values);
    },
    200,
    [values],
  );

  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <Slider
            name={name}
            value={values}
            valueLabelDisplay="auto"
            step={10}
            min={0}
            max={80}
            marks={Object.entries(TSUBO_PRICES)
              .filter(([key]) =>
                [0, 20, 50, 80].some((num) => Number(key) === num),
              )
              .map(([key, label]) => ({
                label: label as string,
                value: Number(key),
              }))}
            onChange={(__, v) => {
              if (v instanceof Array) setValues(v);
            }}
          />
        );
      }}
      isClearable
    />
  );
};

export default TsuboSlider;
