import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  Typography,
  Box,
  FormGroup,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import moment from 'moment';
import dateFormat from 'date-fns/format';

import { ALL_AREA, NIIGATA_AREA } from '../../../../../data/area';
import FormSection from '../../../case/form/FormSection';
import { createEvent } from '../../../../../re-ducks/event/actions';
import { DateField } from '../../../base/form/DateField';
import { daysOfWeek, formats } from '../../../../../data/date';
import useSnackbar from '../../../../hooks/useSnackbar';
import { Snackbar } from '../../../case/feedback/Snackbar';
import { SaveButton } from '../../../case/button/SaveButton';
import { BackButton } from '../../../case/button/BackButton';
import { DotProgress } from '../../../case/feedback/DotProgress';
import { TextField } from '../../../base/form/TextField';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { SwitchField } from '../../../base/form/SwitchField';
import { selectSelectedMaker } from '../../../../../re-ducks/app/selector';
import { selectRole } from '../../../../../re-ducks/collaborator/selector';
import {
  EventFormType,
  EventTagKeyType,
  EVENT_TAGS,
  EVENT_TYPES,
} from '../../../../../re-ducks/event/types';
import {
  selectCurrentEvent,
  selectCreating,
} from '../../../../../re-ducks/event/selector';
import { initialState } from '../../../../../re-ducks/event/slice';
import { Checkbox } from '../../../base/form/Checkbox';
import { RadioButton } from '../../../base/form/RadioButton';
import { InputLabel } from '../../../base/form/InputLabel';
import { SliderImages } from './SliderImages';
import { EventArea } from './Area';
import { eventsRepository } from '../../../../../repositories/firebase/events';
import { eventLimit } from '../../../../../data/plan';
import { useStyles } from './useStyles';
import { ContentsSection } from './ContentsSection';
import { SelectField } from '../../../base/form/SelectField';

export const EventEditForm: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedMaker = useSelector(selectSelectedMaker);
  const creating = useSelector(selectCreating);
  const currentEvent = useSelector(selectCurrentEvent);
  const myRole = useSelector(selectRole);
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();
  const defaultValues: EventFormType = {
    ...initialState.currentEvent,
    ...currentEvent,
    contents:
      currentEvent.contents.length || currentEvent.title
        ? currentEvent.contents
        : initialState.currentEvent.contents,
    makerId:
      myRole === 'cunelwork'
        ? currentEvent.makerId
        : currentEvent.makerId || (selectedMaker?.uid as string),
    officialEvent: myRole === 'cunelwork' && !currentEvent.makerId,
  };
  const formMethods = useForm<EventFormType>({
    criteriaMode: 'all',
    shouldFocusError: true,
    defaultValues,
  });

  const { register, handleSubmit, errors, control, watch, setValue, trigger } =
    formMethods;

  const onSubmit = handleSubmit(async (data) => {
    const newData = {
      ...defaultValues,
      ...data,
      area: data.area instanceof Array ? data.area : [data.area],
    };
    if (
      defaultValues.acceptingReservations === false &&
      newData.acceptingReservations === true
    ) {
      const events = await eventsRepository
        .where('makerId', '==', defaultValues.makerId)
        .where('acceptingReservations', '==', true)
        .fetch();
      const limit = eventLimit[selectedMaker?.plan || 'free'];
      if (events.length >= limit) {
        openSnackbar({
          variant: 'error',
          message: `ご契約中のプランでは${
            limit + 1
          }件以上受付中に設定できません。現在${
            events.length
          }件受付中に設定されています。`,
        });

        return;
      }
    }
    dispatch(createEvent({ formValue: newData, openSnackbar }));
  });

  const isCreatePage = !currentEvent.uid;

  useEffect(() => {
    register('officialEvent');
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit} noValidate>
        {creating ? <DotProgress opening={creating} /> : null}
        <input
          type="hidden"
          name="makerId"
          value={
            myRole === 'cunelwork'
              ? currentEvent.makerId
              : currentEvent.makerId || (selectedMaker?.uid as string)
          }
          ref={register}
        />
        <ErrorMessage error={errors?.makerId} />

        <FormSection>
          <div>
            <InputLabel required className="mb-2">
              公開状態
            </InputLabel>
            <div>
              <FormControl>
                <FormGroup row>
                  {[
                    { value: true, label: '公開' },
                    { value: false, label: '非公開' },
                  ].map(({ value, label }) => {
                    return (
                      <Controller
                        key={label}
                        name="publishing"
                        control={control}
                        render={() => {
                          return (
                            <RadioButton
                              label={label}
                              radioProps={{
                                name: 'publishing',
                                value,
                                checked: value === watch('publishing'),
                                onChange: () => {
                                  setValue('publishing', value);
                                  trigger('publishing');
                                },
                              }}
                            />
                          );
                        }}
                      />
                    );
                  })}
                  <ErrorMessage error={errors?.eventType} />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          <div className="mt-4">
            <InputLabel required className="mb-2">
              予約受付状態
            </InputLabel>
            <div>
              <FormControl>
                <FormGroup row>
                  {[
                    { value: true, label: '予約受付中' },
                    { value: false, label: '予約受付終了' },
                  ].map(({ value, label }) => {
                    return (
                      <Controller
                        key={label}
                        name="acceptingReservations"
                        control={control}
                        render={() => {
                          return (
                            <RadioButton
                              label={label}
                              radioProps={{
                                name: 'acceptingReservations',
                                value,
                                checked:
                                  value === watch('acceptingReservations'),
                                onChange: () => {
                                  setValue('acceptingReservations', value);
                                  trigger('acceptingReservations');
                                },
                              }}
                            />
                          );
                        }}
                      />
                    );
                  })}
                  <ErrorMessage error={errors?.eventType} />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          <div className="mt-4">
            <TextField
              type="text"
              name="title"
              inputRef={register({
                required: 'イベント名を入力してください',
              })}
              error={!!errors?.title}
              label="イベント名"
              placeholder="オープンハウス開催"
              required
            />
            <ErrorMessage error={errors?.title} />
          </div>
          <div className="mt-4">
            <InputLabel required className="mb-2">
              イベントの種類
            </InputLabel>
            <div>
              <FormControl>
                <FormGroup row>
                  {Object.entries(EVENT_TYPES).map(([key, label]) => {
                    return (
                      <RadioButton
                        key={key}
                        label={label}
                        radioProps={{
                          name: 'eventType',
                          value: key,
                          checked: key === watch('eventType'),
                          inputRef: register,
                          onChange: (event) => {
                            setValue('eventType', event.target.value, {
                              shouldValidate: true,
                            });
                          },
                        }}
                      />
                    );
                  })}
                  <ErrorMessage error={errors?.eventType} />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          <div className="mt-4">
            <InputLabel className="mb-2">イベント内容</InputLabel>
            <div>
              <FormControl>
                <FormGroup row>
                  {Object.entries(EVENT_TAGS).map(([key, label]) => {
                    return (
                      <Checkbox
                        key={key}
                        label={label}
                        checkboxProps={{
                          checked: watch('eventTags').includes(
                            key as EventTagKeyType,
                          ),
                          name: 'eventTags',
                          value: key,
                          inputRef: register,
                          onChange: (event) => {
                            const values = watch('eventTags');
                            setValue(
                              'eventTags',
                              event.target.checked
                                ? [...values, event.target.value]
                                : values.filter(
                                    (v) => v !== event.target.value,
                                  ),
                              {
                                shouldValidate: true,
                              },
                            );
                          },
                        }}
                      />
                    );
                  })}
                  <ErrorMessage error={errors?.eventTags} />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          <div className="mt-4">
            <InputLabel required className="mb-2">
              開催エリア
            </InputLabel>
            <Controller
              name="area"
              control={control}
              rules={{
                validate: {
                  select: (v) => {
                    if (v.length === 1) return true;

                    return 'エリアを1つだけ選択してください';
                  },
                },
              }}
              render={({ name, ref }) => (
                <SelectField
                  ref={ref}
                  name={name}
                  defaultValue={defaultValues.area.map((area) => ({
                    label: ALL_AREA.find((a) => a.id === area)?.label || '',
                    value: area,
                  }))}
                  options={NIIGATA_AREA.map((nArea) => {
                    const areaTitle =
                      ALL_AREA.find((a) => a.name === nArea)?.label || '';
                    const children = ALL_AREA.filter((a) => a.parent === nArea);
                    const options = children.map((child) => ({
                      label: child?.label || '',
                      value: child?.id,
                    }));

                    return { label: areaTitle, options };
                  })}
                  isMulti
                />
              )}
              isClearable
            />
            <ErrorMessage error={errors.area} />
          </div>
        </FormSection>
        <FormSection sectionTitle="イベント画像">
          <SliderImages />
        </FormSection>
        <FormSection sectionTitle="イベント内容説明文">
          <ContentsSection />
        </FormSection>
        <FormSection sectionTitle="開催日時">
          <div>
            <FormControlLabel
              control={
                <SwitchField
                  color="primary"
                  defaultChecked={defaultValues.fullYear}
                  name="fullYear"
                  inputRef={register}
                  onChange={(event) => {
                    setValue('startDate', null);
                    setValue('endDate', null);
                    setValue('fullYear', event.target.checked);
                  }}
                />
              }
              label="通年イベント"
            />
          </div>
          <div className="mt-4">
            <InputLabel>営業日</InputLabel>
            <div>
              <FormControl>
                <FormGroup row>
                  {daysOfWeek.map((label, idx) => {
                    return (
                      <Controller
                        key={label}
                        name="businessDays"
                        control={control}
                        render={(field) => {
                          const values = watch('businessDays');

                          return (
                            <Checkbox
                              key={label}
                              label={`${label}曜日`}
                              checkboxProps={{
                                checked: values.some(
                                  (v: string | number) => Number(v) === idx,
                                ),
                                value: idx,
                                onChange: (__, checked) => {
                                  let value = values;
                                  if (value instanceof Array) {
                                    value = checked
                                      ? value.concat([idx])
                                      : value.filter((v) => v !== idx);

                                    setValue(field.name, value, {
                                      shouldValidate: true,
                                    });
                                    trigger(field.name);
                                  }
                                },
                              }}
                            />
                          );
                        }}
                      />
                    );
                  })}
                  <ErrorMessage error={errors?.businessDays} />
                </FormGroup>
              </FormControl>
            </div>
          </div>
          {watch('fullYear') ? null : (
            <div>
              <Box mt={1} mb={1}>
                <Typography variant="body2">
                  1日だけ開催する場合は開始日と終了日を同じ日付に設定してください。
                </Typography>
              </Box>
              <div className={classes.eventDateWrap}>
                <div>
                  <Controller
                    name="startDate"
                    control={control}
                    rules={{
                      required: '開催開始日を入力してください',
                    }}
                    render={({ name }) => (
                      <DateField
                        DatePickerProps={{
                          label: '開始日',
                          name,
                          value: watch('startDate')
                            ? moment(watch('startDate')).toDate()
                            : null,
                          format: formats[0],
                          error: !!errors.startDate,
                          onChange: (v) =>
                            setValue(name, v ? moment(v).toDate() : null),
                          required: true,
                          placeholder: dateFormat(new Date(), 'yyyy年M月d日'),
                        }}
                      />
                    )}
                    isClearable
                  />
                  <ErrorMessage error={errors?.startDate} />
                </div>
                <div>
                  <Controller
                    name="endDate"
                    control={control}
                    rules={{
                      required: '開催終了日を入力してください',
                      validate: {
                        afterStartDate: (value: Date) => {
                          return moment(watch('startDate')).isSameOrBefore(
                            value,
                            'day',
                          )
                            ? true
                            : '終了日は開催日以降の日付けを入力してください';
                        },
                      },
                    }}
                    render={({ name }) => (
                      <DateField
                        DatePickerProps={{
                          label: '終了日',
                          name,
                          value: watch('endDate')
                            ? moment(watch('endDate')).toDate()
                            : null,
                          format: formats[0],
                          error: !!errors.endDate,
                          onChange: (v) =>
                            setValue(name, v ? moment(v).toDate() : null),
                          required: true,
                          placeholder: dateFormat(new Date(), 'yyyy年M月d日'),
                        }}
                      />
                    )}
                    isClearable
                  />
                  <ErrorMessage error={errors?.endDate} />
                </div>
              </div>
            </div>
          )}

          <div className="mt-4">
            <TextField
              type="text"
              name="time"
              inputRef={register({
                required: '開催時間を入力してください',
              })}
              error={!!errors?.time}
              label="開催時間"
              placeholder="午前の部 10:00 ～12:00／午後の部① 13:00 ～15:00／午後の部② 15:00 〜 17:00"
              required
            />
            <ErrorMessage error={errors?.time} />
          </div>
          <div className="mt-4">
            <TextField
              type="text"
              name="timeRequired"
              inputRef={register}
              error={!!errors?.timeRequired}
              placeholder="1時間"
              label="所要時間"
            />
            <ErrorMessage error={errors?.timeRequired} />
          </div>
        </FormSection>
        <FormSection sectionTitle="参加費">
          <div>
            <TextField
              type="text"
              name="price"
              inputRef={register({
                required: '参加費を入力してください',
              })}
              error={!!errors?.price}
              label="参加費"
              placeholder="無料"
              required
            />
            <ErrorMessage error={errors?.price} />
          </div>
        </FormSection>
        <FormSection sectionTitle="会場">
          <div>
            <TextField
              type="text"
              name="eventPlace"
              inputRef={register({
                required: '住所を入力してください',
              })}
              error={!!errors?.eventPlace}
              label="住所"
              placeholder="新潟市東区物見山2丁目"
              required
            />
            <ErrorMessage error={errors?.eventPlace} />
          </div>
          <div className="mt-4">
            <TextField
              type="text"
              name="eventPlaceNote"
              inputRef={register}
              error={!!errors?.eventPlaceNote}
              label="備考欄"
              placeholder="駐車スペース、近隣駐車場情報があれば表示"
            />
            <ErrorMessage error={errors?.eventPlaceNote} />
          </div>
          <div className="mt-4">
            <TextField
              type="text"
              name="eventMap"
              inputRef={register}
              error={!!errors?.eventMap}
              label="GoogleMap埋め込みコード"
              placeholder={`<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3148.8544557611185!2d138.9948786153207!3d37.88708627973919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff4c87064e47ac1%3A0x4e7349192625d1f3!2z5qCq5byP5Lya56S-44Kv44O844ON44Or44Ov44O844Kv!5e0!3m2!1sja!2sjp!4v1620894451817!5m2!1sja!2sjp" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>`}
            />
            <ErrorMessage error={errors?.eventMap} />
          </div>
        </FormSection>

        <Box mt={2} display="flex" alignItems="center">
          <Box mr={2}>
            <BackButton type="button" onClick={() => dispatch(push('/event'))}>
              一覧へ戻る
            </BackButton>
          </Box>
          <Box>
            <SaveButton type="submit">{`イベントを${
              isCreatePage ? '作成' : '保存'
            }する`}</SaveButton>
          </Box>
        </Box>
        <Snackbar
          variant={snackbarState.variant}
          message={snackbarState.message}
          isOpening={snackbarState.isOpening}
          onClose={closeSnackbar}
        />
      </form>
    </FormProvider>
  );
};
