import { makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 260;

export const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.palette.grey.A400,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9) + 1,
    backgroundColor: theme.palette.grey.A400,
    '& $linkButton': {
      justifyContent: 'center',
    },
    '& $drawerIcon': {
      marginRight: 0,
    },
    '& $linkText': {
      display: 'none',
    },
  },
  linkColor: {
    display: 'block',
    color: theme.palette.grey['300'],
    '&:hover': {
      color: theme.palette.grey['300'],
    },
  },
  linkBorder: {
    borderBottom: `1px solid #666`,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  divider: {
    backgroundColor: theme.palette.grey['500'],
  },
  drawerIcon: {
    width: 36,
    minWidth: 36,
    height: 36,
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },
  linkButton: {},
  linkText: {
    color: theme.palette.common.white,
  },
}));
