import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    background: theme.palette.common.white,
  },
  grid: {
    display: 'grid',
  },
  gridItem: {
    borderBottom: `1px dashed ${theme.palette.grey[300]}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  inner: {
    display: 'block',
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    backgroundColor: theme.palette.common.white,
  },
  date: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    color: theme.palette.grey['500'],
    lineHeight: 1,
    fontFeatureSettings: '"tnum"',
  },
  title: {
    marginTop: theme.spacing(1),
    fontSize: 15,
    fontWeight: 'bold',
    lineHeight: 1.5,
  },
  pagenation: {
    marginTop: theme.spacing(2),
    '& ul': {
      justifyContent: 'center',
    },
  },
}));
