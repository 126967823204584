import { MenuItem, TextField } from '@material-ui/core';
import _ from 'lodash';
import React, { VFC, useState } from 'react';
import { useDebounce } from 'react-use';
import { useDispatch } from 'react-redux';

import {
  ATTENDANCE_STATUS,
  EventReservationsType,
  RESERVATION_STATUS,
} from '../../../../../re-ducks/event/types';
import { updateReservation } from '../../../../../re-ducks/event/actions';
import { Snackbar } from '../../../case/feedback/Snackbar';
import useSnackbar from '../../../../hooks/useSnackbar';
import { useStyles } from './useStyles';

interface Props {
  eventReservation: EventReservationsType;
}

export const TableRow: VFC<Props> = ({ eventReservation }) => {
  const [state, setState] = useState<EventReservationsType>(eventReservation);
  const [isEdited, setIsEdited] = useState(false);
  const dispatch = useDispatch();
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();
  const classes = useStyles();

  useDebounce(
    () => {
      if (!isEdited) return;

      dispatch(
        updateReservation({ reservation: state, openSnackbar, closeSnackbar }),
      );
    },
    1000,
    [state],
  );

  const onChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsEdited(true);
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <tr>
      <td>{eventReservation.reservationName}</td>
      <td>{eventReservation.mailAddress}</td>
      <td>{eventReservation.tel}</td>
      <td>
        {eventReservation.reservationDate}
        {eventReservation.reservationTime}
      </td>
      <td className="textbox">{eventReservation.remarks || '-'}</td>
      <td className="selectbox">
        <TextField
          name="status"
          value={state.status}
          variant="outlined"
          select
          onChange={onChange}
        >
          {Object.entries(RESERVATION_STATUS).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </td>
      <td className="selectbox">
        <TextField
          name="attendanceStatus"
          value={state.attendanceStatus}
          variant="outlined"
          select
          SelectProps={{ displayEmpty: true }}
          onChange={onChange}
        >
          <MenuItem value="">選択してください</MenuItem>
          {Object.entries(ATTENDANCE_STATUS).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </td>
      <td className="textbox">
        <TextField
          name="memo"
          value={state.memo}
          multiline
          rows={4}
          variant="outlined"
          onChange={onChange}
        />
        <Snackbar
          isOpening={snackbarState.isOpening}
          variant={snackbarState.variant}
          message={snackbarState.message}
          onClose={closeSnackbar}
          className={classes.snackbar}
        />
      </td>
    </tr>
  );
};
