import React, { VFC } from 'react';
import { CellProps } from 'react-table';
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    width: 240,
  },
}));

export const TitleCell: VFC<CellProps<any>> = ({ cell }) => {
  const classes = useStyles();

  return <div className={classes.root}>{cell.value}</div>;
};
