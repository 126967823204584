import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Paper, Tabs, Tab, makeStyles, Theme } from '@material-ui/core';

import IconEvent from '../../base/Icon/IconEvent';
import { EventReservations } from '../../domain/event/EventReservations';
import {
  selectFetching,
  selectCurrentEvent,
} from '../../../../re-ducks/event/selector';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { EventEditForm } from '../../domain/event/EventEditForm';
import { DotProgress } from '../../case/feedback/DotProgress';
import { PageTitle } from '../../case/PageTitle';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: theme.spacing(4),
  },
}));

export const EventDetail: FC = () => {
  const [tab, setTab] = React.useState(0);
  const classes = useStyles();
  const fetching = useSelector(selectFetching);
  const currentEvent = useSelector(selectCurrentEvent);

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  return (
    <AdminWrapper>
      <PageTitle Icon={<IconEvent />}>イベント管理</PageTitle>
      {currentEvent.uid ? (
        <Paper>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, currentValue) => setTab(currentValue)}
            aria-label="disabled tabs example"
          >
            <Tab label="イベント情報" />
            <Tab label="予約申込者一覧" />
          </Tabs>
        </Paper>
      ) : null}
      {tab === 0 ? (
        <div>
          <div className={classes.form}>
            <EventEditForm />
          </div>
        </div>
      ) : null}
      {tab === 1 ? <EventReservations /> : null}
    </AdminWrapper>
  );
};
