import React, { VFC, FC, ReactNode } from 'react';

import { useStyles } from './useStyles';

interface Props {
  Icon?: ReactNode;
  children?: ReactNode;
}

export const PageTitle: VFC<Props> = ({ Icon, children }) => {
  const classes = useStyles();

  return (
    <h1 className={classes.root}>
      {Icon}
      <span className={classes.text}>{children}</span>
    </h1>
  );
};
