import React, { VFC } from 'react';
import { BlockComponentProps } from '../types';
import useStyles from './useStyles';

const Image: VFC<BlockComponentProps> = ({ contentState, block }) => {
  const { src } = contentState.getEntity(block.getEntityAt(0)).getData();
  const classes = useStyles();

  return <img src={src} alt="" className={classes.image} />;
};

export default Image;
