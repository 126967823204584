import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import Select, { OptionsType, OptionTypeBase, ValueType } from 'react-select';

export interface SelectFieldProps {
  name: string;
  options: OptionsType<OptionTypeBase>;
  defaultValue?: OptionTypeBase | OptionsType<OptionTypeBase>;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
}

export const SingleSelectField: VFC<SelectFieldProps> = ({
  name,
  placeholder,
  defaultValue,
  options,
  isMulti = false,
  disabled = false,
}) => {
  const { setValue } = useFormContext();

  const handleMultiChange = (
    selectedOption: ValueType<OptionTypeBase, false>,
  ) => {
    if (!selectedOption) return;

    setValue(name, selectedOption.value);
  };

  return (
    <Select
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue || (isMulti ? [] : '')}
      options={options}
      onChange={handleMultiChange}
      isMulti={isMulti}
      isDisabled={disabled}
    />
  );
};
