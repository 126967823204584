export default (size: number) => {
  const kb = 1024;
  const mb = kb ** 2;
  const gb = kb ** 3;
  const tb = kb ** 4;

  const UNITS = ['byte', 'KB', 'MB', 'GB', 'TB'] as const;

  const opt: {
    target: number;
    unit: typeof UNITS[keyof typeof UNITS];
  } = {
    target: 0,
    unit: 'byte',
  };

  if (size >= tb) {
    const [, , , , TB] = UNITS;
    opt.target = tb;
    opt.unit = TB;
  } else if (size >= gb) {
    const [, , , GB] = UNITS;
    opt.target = gb;
    opt.unit = GB;
  } else if (size >= mb) {
    const [, , MB] = UNITS;
    opt.target = mb;
    opt.unit = MB;
  } else if (size >= kb) {
    const [, KB] = UNITS;
    opt.target = kb;
    opt.unit = KB;
  }

  const res =
    opt.target > 0 ? Math.floor((size / opt.target) * 100) / 100 : size;

  return `${String(res) + opt.unit}`;
};
