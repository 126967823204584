import { auth } from '../../repositories/firebase';

export const sendPasswordResetEmail = async (
  email: string,
): Promise<string | Error> => {
  try {
    const result = await auth.sendPasswordResetEmail(email);
    if (typeof result !== 'string') {
      throw result;
    }

    return result;
  } catch (error: any) {
    console.error(error);

    return error;
  }
};
