import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { UserState } from './slice';

export const selectSelf = (state: RootState): UserState => state.user;

export const selectUserTableData = createSelector(
  selectSelf,
  (state) => state.userTableData,
);
export const selectFetching = createSelector(
  selectSelf,
  (state) => state.fetching,
);
export const selectUser = createSelector(selectSelf, (state) => state.user);
