import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { MakerContactsState } from './slice';

export const selectSelf = (state: RootState): MakerContactsState =>
  state.makerContacts;

export const selectFetching = createSelector(
  selectSelf,
  (state) => state.fetching,
);
export const selectMakerContact = createSelector(
  selectSelf,
  (state) => state.makerContact,
);
export const selectMakerContacts = createSelector(
  selectSelf,
  (state) => state.makerContacts,
);
