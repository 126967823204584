import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { AppState } from './types';

export const selectSelf = (state: RootState): AppState => state.app;
export const selectDrawerOpening = createSelector(
  selectSelf,
  (state) => state.drawerOpening,
);
export const selectSelectedMaker = createSelector(
  selectSelf,
  (state) => state.selectedMaker,
);
