import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useFormContext } from 'react-hook-form';
import { AiOutlineEye } from 'react-icons/ai';
import { BiLinkExternal } from 'react-icons/bi';
import { Typography, Link } from '@material-ui/core';

import { selectMaker } from '../../../../../re-ducks/maker/selector';
import { selectRole } from '../../../../../re-ducks/collaborator/selector';
import { SaveButton } from '../../../case/button/SaveButton';
import { BackButton } from '../../../case/button/BackButton';
import { AddPhoto } from '../../../base/form/AddPhoto';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { TextField } from '../../../base/form/TextField';
import { useStyles } from './useStyles';

const Sidebar: FC = () => {
  const dispatch = useDispatch();
  const maker = useSelector(selectMaker);
  const myRole = useSelector(selectRole);
  const classes = useStyles();
  const isCreatePage = !maker.uid;

  const formMethods = useFormContext();
  const { errors, watch, register, getValues, setValue } = formMethods;

  return (
    <div className="sidebar">
      <dl className={classes.information}>
        <dt>
          <span>
            <AiOutlineEye />
            公開状態
          </span>
        </dt>
        <dd>{getValues('publishing') ? '公開' : '非公開'}</dd>
      </dl>
      {maker.publishing && maker.slug ? (
        <Typography variant="body2" className="mb-3">
          <Link
            href={`https://mockhouse.jp/professional/${maker.slug}/`}
            target="_blank"
            rel="noopener noreferrer"
            underline="always"
            className={classes.detailPage}
          >
            <BiLinkExternal size={20} />
            詳細ページにアクセスする
          </Link>
        </Typography>
      ) : null}
      <div>
        <div className={classes.logoImage}>
          <AddPhoto
            name="logoImage"
            jpegUrl={watch('logoImage')}
            setJpegUrl={(url) => {
              setValue('logoImage', url, {
                shouldValidate: true,
                shouldDirty: true,
              });
            }}
            accept="image/png,image/jpeg"
            registerOption={{ required: 'ロゴ画像をアップロードしてください' }}
            passPng
          />
        </div>
        <ErrorMessage error={errors.logoImage} />
      </div>
      <div className="mt-5">
        <TextField
          label="ブランド名"
          type="textarea"
          name="name"
          inputRef={register({
            required: 'ブランド名を入力してください',
          })}
          required
          placeholder="ブランド名"
          error={!!errors?.name}
        />
        <ErrorMessage error={errors?.name} />
      </div>
      <div className={classes.buttonWrap}>
        <SaveButton type="submit">{isCreatePage ? '作成' : '保存'}</SaveButton>
        {myRole === 'cunelwork' ? (
          <BackButton
            type="button"
            onClick={() => dispatch(push('/professional'))}
          >
            一覧へ戻る
          </BackButton>
        ) : null}
      </div>
    </div>
  );
};

export default Sidebar;
