import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  uploadMedia,
  fetchMedias,
  createFaqCategory,
  fetchFaqCategories,
  fetchFaqItems,
  createFaqItem,
  deleteFaqItem,
  sortCategoryFaqItems,
} from './actions';
import { FAQ, FAQCategory, FAQMedia } from './types';

export type FAQState = {
  fetching: boolean;
  creating: boolean;
  faqItems: FAQ[];
  faqMedias: FAQMedia[];
  faqCategories: FAQCategory[];
  selectedFaq: FAQ;
};

export const initialState: FAQState = {
  fetching: false,
  creating: false,
  faqItems: [],
  faqMedias: [],
  faqCategories: [],
  selectedFaq: {
    publishing: false,
    title: '',
    slug: '',
    content: '',
    faqType: 'endUser',
    category: '',
    createDate: '',
    updateDate: '',
    uid: '',
  },
};

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    selectFaq: (state, action: PayloadAction<FAQ>) => {
      state.selectedFaq = action.payload;
    },
    moveCategories: (state, action: PayloadAction<FAQCategory[]>) => {
      state.faqCategories = action.payload;
    },
    moveCategoryFaqItems: (state, action: PayloadAction<FAQCategory>) => {
      state.faqCategories = state.faqCategories.map((cat) =>
        cat.uid === action.payload.uid ? action.payload : cat,
      );
    },
    resetFaq: (state) => {
      state.fetching = false;
      state.creating = false;
      state.faqItems = initialState.faqItems;
      state.faqMedias = initialState.faqMedias;
      state.faqCategories = initialState.faqCategories;
    },
  },
  extraReducers: (builder) => {
    // fetchMedias
    builder.addCase(fetchMedias.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchMedias.fulfilled, (state, action) => {
      state.faqMedias = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchMedias.rejected, (state) => {
      state.fetching = false;
    });
    // uploadMedia
    builder.addCase(uploadMedia.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(uploadMedia.fulfilled, (state) => {
      state.creating = false;
    });
    builder.addCase(uploadMedia.rejected, (state) => {
      state.creating = false;
    });
    // fetchFaqCategories
    builder.addCase(fetchFaqCategories.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchFaqCategories.fulfilled, (state, action) => {
      state.faqCategories = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchFaqCategories.rejected, (state) => {
      state.fetching = false;
    });
    // createFaqCategory
    builder.addCase(createFaqCategory.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(createFaqCategory.fulfilled, (state, action) => {
      state.faqCategories = [...state.faqCategories, action.payload];
      state.creating = false;
    });
    builder.addCase(createFaqCategory.rejected, (state) => {
      state.creating = false;
    });
    // fetchFaqItems
    builder.addCase(fetchFaqItems.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchFaqItems.fulfilled, (state, action) => {
      state.faqItems = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchFaqItems.rejected, (state) => {
      state.fetching = false;
    });
    // createFaqItem
    builder.addCase(createFaqItem.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(createFaqItem.fulfilled, (state, action) => {
      state.selectedFaq = action.payload;
      state.creating = false;
    });
    builder.addCase(createFaqItem.rejected, (state) => {
      state.creating = false;
    });
    // deleteFaqItem
    builder.addCase(deleteFaqItem.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(deleteFaqItem.fulfilled, (state, action) => {
      state.faqItems = action.payload.faqItems;
      state.faqCategories = action.payload.faqCategories;
      state.creating = false;
    });
    builder.addCase(deleteFaqItem.rejected, (state) => {
      state.creating = false;
    });
    builder.addCase(sortCategoryFaqItems.fulfilled, (state, action) => {
      state.faqCategories = state.faqCategories.map((cat) =>
        cat.uid === action.payload.uid ? action.payload : cat,
      );
    });
  },
});

export const { resetFaq, selectFaq, moveCategories, moveCategoryFaqItems } =
  faqSlice.actions;

export default faqSlice;
