import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firestore } from '../../repositories/firebase';
import { selectSelectedMaker } from '../../re-ducks/app/selector';
import { selectMaker } from '../../re-ducks/app/slice';
import { MakerType } from '../../re-ducks/maker/types';

const useSnapshotSelectedMaker = () => {
  const dispatch = useDispatch();
  const selectedMaker = useSelector(selectSelectedMaker);

  useEffect(() => {
    // const unsub = onSnapshot(docRef, (snapshot) => {
    //   const results = [];
    //   snapshot.docs.forEach((doc) => {
    //     results.push({ ...doc.data(), id: doc.id });
    //   });
    //   setDocuments(results);
    // });
    const unsub = firestore.collection('makers').onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === 'removed' || !selectedMaker) return;

        const maker = change.doc.data() as MakerType;
        if (selectedMaker.uid !== maker.uid || !maker) return;

        dispatch(selectMaker({ maker }));
      });
    });

    return () => unsub();
  }, []);

  return {};
};

export default useSnapshotSelectedMaker;
