import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import Select, { OptionsType, OptionTypeBase, ValueType } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { formatValue } from '../../../../../utils/formatValue';

export interface SelectFieldProps {
  name: string;
  options: OptionsType<OptionTypeBase>;
  defaultValue?: readonly OptionTypeBase[] | OptionTypeBase | null;
  placeholder?: string;
  isMulti?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  onCreate?: (inputValue: string) => void;
  ref?: React.MutableRefObject<any>;
}

export const SelectField: VFC<SelectFieldProps> = ({
  name,
  placeholder,
  defaultValue,
  options,
  isMulti = false,
  disabled = false,
  isLoading,
  onCreate,
  ref,
}) => {
  const { setValue } = useFormContext();

  const handleSingleChange = (
    selectedOption: ValueType<OptionTypeBase, false>,
  ) => {
    if (!selectedOption) return;

    setValue(name, selectedOption.value);
  };

  const handleMultiChange = (
    selectedOption: ValueType<OptionTypeBase, boolean>,
  ) => {
    if (!selectedOption || !selectedOption.length) {
      setValue(name, []);

      return;
    }

    setValue(name, formatValue(selectedOption));
  };

  return onCreate ? (
    <CreatableSelect
      ref={ref}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      options={options}
      onChange={isMulti ? handleMultiChange : handleSingleChange}
      isMulti={isMulti}
      isDisabled={disabled}
      isLoading={isLoading}
      onCreateOption={onCreate}
    />
  ) : (
    <Select
      ref={ref}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      options={options}
      onChange={isMulti ? handleMultiChange : handleSingleChange}
      isMulti={isMulti}
      isDisabled={disabled}
    />
  );
};
