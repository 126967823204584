import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.5)',
  },
  heading: {
    height: 36,
    minHeight: 36,
    backgroundColor: theme.palette.grey['200'],
    '&$expanded': {
      height: 36,
      minHeight: 36,
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
    },
  },
  headingContent: {
    margin: `${theme.spacing(1)}px 0`,
    '&$expanded': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  detail: {
    padding: theme.spacing(1),
    display: 'block',
  },
  expanded: {},
  content: {},
}));
