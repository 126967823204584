import React, { useState, VFC } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { DragIndicator, MoreVert } from '@material-ui/icons';
import {
  IconButton,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  deleteFaqItem,
  sortCategoryFaqItems,
} from '../../../../../../re-ducks/faq/actions';
import {
  selectFaq,
  moveCategoryFaqItems,
} from '../../../../../../re-ducks/faq/slice';
import {
  FAQ,
  FAQCategory,
  FaqTypes,
} from '../../../../../../re-ducks/faq/types';
import moveArray from '../../../../../../utils/moveArray';
import useStyles from '../useStyles';

interface Props {
  faqItems: FAQ[];
  category: FAQCategory;
  faqType?: keyof typeof FaqTypes;
}

const FaqItems: VFC<Props> = ({ faqItems, category, faqType = 'endUser' }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<string | null>(null);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newCategory = {
      ...category,
      faqItems: moveArray(
        category.faqItems,
        result.destination.index,
        result.source.index,
      ),
    };
    dispatch(moveCategoryFaqItems(newCategory));
    dispatch(sortCategoryFaqItems(newCategory));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={`${faqType}-faq-item`}>
        {(droppableProvided) => (
          <ul
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {category.faqItems.map((faqId, index) => {
              const faqItem = faqItems.find((faq) => faq.uid === faqId);

              return (
                <Draggable
                  key={faqItem?.uid}
                  draggableId={faqItem?.uid || ''}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classes.faqItem}
                    >
                      <DragIndicator fontSize="small" />
                      <p className={classes.faqTitle}>
                        <span>
                          （{faqItem?.publishing ? '公開' : '非公開'}）
                        </span>
                        <strong>{faqItem?.title}</strong>
                      </p>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => setMenuOpen(faqItem?.uid || '')}
                      >
                        <MoreVert fontSize="small" />
                      </IconButton>
                      {menuOpen === faqItem?.uid ? (
                        <ClickAwayListener
                          onClickAway={() => setMenuOpen(null)}
                        >
                          <MenuList className={classes.menu}>
                            <MenuItem
                              onClick={() => {
                                setMenuOpen(null);
                                if (faqItem) {
                                  dispatch(selectFaq(faqItem));
                                  dispatch(push(`/help/edit/${faqItem.uid}/`));
                                }
                              }}
                            >
                              編集
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setMenuOpen(null);
                                if (faqItem) {
                                  dispatch(deleteFaqItem(faqItem));
                                }
                              }}
                            >
                              削除
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      ) : null}
                    </li>
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default FaqItems;
