import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonWrap: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(3)}px auto 0`,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  imageWrap: {
    width: `min(100%, 600px)`,
  },
  image: {
    width: `100%`,
    aspectRatio: '3/2',
  },
  section: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.common.white,
  },
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  },
  selectors: {
    flex: 1,
  },
  subItems: {
    position: 'relative',
    marginLeft: theme.spacing(2),
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: theme.spacing(-2),
      width: 1,
      height: '100%',
      background: theme.palette.grey['400'],
    },
  },
}));
