import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';

import { IndividualMeetingPlace } from '../../../../../../re-ducks/maker/types';
import { AddPhoto } from '../../../../base/form/AddPhoto';
import { ErrorMessage } from '../../../../base/form/ErrorMessage';
import { useStyles } from './useStyles';
import { TextField } from '../../../../base/form/TextField';
import LoopFieldSection from '../../../../case/form/LoopFieldSection';

export const IndividualMeetingPlaces: VFC = () => {
  const { register, setValue, getValues } = useFormContext();
  const classes = useStyles();

  return (
    <LoopFieldSection<IndividualMeetingPlace>
      name="individualMeetingPlaces"
      label="相談方法・場所"
      initialValue={{
        image: '',
        name: '',
        description: '',
        place: '',
        businessHours: '',
        regularClosingDay: '',
      }}
    >
      {(baseName, baseError, field) => {
        return (
          <div>
            <div className={classes.wrapper}>
              <div className={classes.image}>
                <div className={classes.imageWrap}>
                  <AddPhoto
                    name={`${baseName}.image`}
                    jpegUrl={getValues(`${baseName}.image`)}
                    setJpegUrl={(url) => {
                      setValue(`${baseName}.image`, url, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    registerOption={{
                      required: '画像をアップロードしてください',
                    }}
                    accept="image/png,image/jpeg"
                  />
                </div>
                <ErrorMessage error={baseError?.image} />
              </div>
              <div className={classes.textbox}>
                <div>
                  <TextField
                    label="相談方法・会場名"
                    type="text"
                    name={`${baseName}.name`}
                    inputRef={register({
                      required: '相談方法・会場名を入力してください',
                    })}
                    placeholder="例）新潟営業所、オンライン相談"
                    defaultValue={field.name}
                    error={!!baseError?.name}
                    required
                  />
                  <ErrorMessage error={baseError?.name} />
                </div>
                <div className="mt-5">
                  <TextField
                    label="説明文"
                    name={`${baseName}.description`}
                    inputRef={register({
                      required: '説明文を入力してください',
                    })}
                    placeholder={`・カタログはもちろん、断熱材や制振ダンパー、樹脂サッシ、床材など\n性能関連の設備もご用意しています。\n・スタジオ奥には授乳室やおむつ台をご用意！\n・キッズコーナーではおもちゃのほかアマゾンプライムVIDEOも\nあります。\n・近くにスキップフロアのモデルハウスがあり、ご案内できます！`}
                    defaultValue={field.description}
                    error={!!baseError?.description}
                    required
                    multiline
                    rows={6}
                  />
                  <ErrorMessage error={baseError?.description} />
                </div>
                <div className="mt-5">
                  <TextField
                    label="所在地"
                    name={`${baseName}.place`}
                    inputRef={register()}
                    defaultValue={field.place}
                    error={!!baseError?.place}
                  />
                  <ErrorMessage error={baseError?.place} />
                </div>
                <div className="mt-5">
                  <TextField
                    label="営業時間"
                    name={`${baseName}.businessHours`}
                    inputRef={register({
                      required: '営業時間を入力してください',
                    })}
                    defaultValue={field.businessHours}
                    error={!!baseError?.businessHours}
                    required
                  />
                  <ErrorMessage error={baseError?.businessHours} />
                </div>
                <div className="mt-5">
                  <TextField
                    label="定休日"
                    name={`${baseName}.regularClosingDay`}
                    inputRef={register({
                      required: '定休日を入力してください',
                    })}
                    defaultValue={field.regularClosingDay}
                    error={!!baseError?.regularClosingDay}
                    required
                  />
                  <ErrorMessage error={baseError?.regularClosingDay} />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </LoopFieldSection>
  );
};
