import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Table } from '../../base/Table';
import { DefaultColumnFilter } from '../../base/Table/DefaultColumnFilter';
import { DotProgress } from '../../case/feedback/DotProgress';
import {
  selectUserTableData,
  selectFetching,
} from '../../../../re-ducks/user/selector';
import { UserListTableType } from '../../../../re-ducks/user/types';
import { fetchUsers } from '../../../../re-ducks/user/actions';
import { selectRole } from '../../../../re-ducks/collaborator/selector';
import { selectMakerTableData } from '../../../../re-ducks/maker/selector';
import { selectSelectedMaker } from '../../../../re-ducks/app/selector';
import PhotoCell from './PhotoCell';
import AgeCell from './AgeCell';

export const FollowerListTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userTableData = useSelector(selectUserTableData);
  const fetching = useSelector(selectFetching);
  const selectedMaker = useSelector(selectSelectedMaker);
  const myRole = useSelector(selectRole);
  const makers = useSelector(selectMakerTableData);

  useEffect(() => {
    dispatch(
      fetchUsers({
        whereQueries:
          myRole === 'admin'
            ? [
                {
                  fieldPath: 'followMakers',
                  opStr: 'array-contains',
                  value: selectedMaker?.uid,
                },
              ]
            : [],
        orderQueries: [],
      }),
    );
  }, [makers]);

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  return (
    <Table<UserListTableType>
      tableOptions={{
        data: userTableData,
        columns: [
          {
            Header: 'アイコン',
            accessor: 'photoUrl',
            Cell: PhotoCell,
            disableFilters: true,
            disableSortBy: true,
          },
          {
            Header: '名前',
            accessor: 'name',
            Filter: DefaultColumnFilter,
          },
          {
            Header: '年齢',
            accessor: 'age',
            Filter: DefaultColumnFilter,
            Cell: AgeCell,
          },
          {
            Header: 'フォローしている建築事業者・ブランド',
            accessor: 'makerName',
            Filter: DefaultColumnFilter,
          },
        ],
        initialState: {
          pageSize: 50,
          sortBy: [{ id: 'date', desc: true }],
          hiddenColumns: myRole === 'admin' ? ['uid', 'makerName'] : ['uid'],
        },
      }}
      classes={{
        root: clsx('table-container'),
        table: clsx(
          'table',
          'is-fullwidth',
          'is-scrolled',
          'is-striped',
          'is-hoverable',
          'list-table',
        ),
      }}
      onClick={(row) => {
        history.push(`/users/detail/${row.original.uid}/`);
      }}
    />
  );
};
