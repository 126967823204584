import React, { FC } from 'react';

import { LoginForm } from '../../case/form/LoginForm';
import { useStyles } from './useStyles';
import Logo from '../../../../assets/images/logo.svg';

export const Login: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <img src={Logo} alt="Mockhouse" />
      </div>
      <LoginForm />
    </div>
  );
};
