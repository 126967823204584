import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    maxWidth: 640,
    margin: '0 auto',
    padding: `0 ${theme.spacing(2)}px`,

    '& .MuiTextField-root': {
      marginTop: theme.spacing(4),
    },
  },
  pwForget: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'right',
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1,
  },
  button: {
    display: 'block',
    margin: `${theme.spacing(8)}px auto 0`,
  },
}));
