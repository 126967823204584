import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  datePicker: {
    margin: 0,
    '& label': {
      display: 'flex',
      alignItems: 'center',
      '& span': {
        display: 'none',
      },
      '&::after': {
        display: 'inline-block',
        marginLeft: theme.spacing(2),
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        fontSize: 12,
        fontWeight: 'bold',
        content: '"任意"',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
      },
      '&.Mui-required::after': {
        content: '"必須"',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.error.main,
      },
      '& + div': {
        marginTop: theme.spacing(3),
      },
    },
  },
}));
