import { makeStyles, Theme } from '@material-ui/core';
import BlockQuote from '../../../../assets/images/icon/quotes-left.svg';

export const useStyles = makeStyles((theme: Theme) => ({
  editorContainer: {
    '& .DraftEditor-editorContainer': {
      borderLeft: 'none',
    },
    '& .public-DraftEditor-content': {
      minHeight: 640,
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.grey['400']}`,
      borderTop: 'none',
      borderBottomRightRadius: theme.shape.borderRadius,
      borderBottomLeftRadius: theme.shape.borderRadius,
      background: theme.palette.common.white,

      '& h2': {
        margin: `${theme.spacing(2)}px 0`,
        fontSize: 28,
        fontWeight: 'bold',
      },
      '& h3': {
        margin: `${theme.spacing(2)}px 0`,
        fontSize: 24,
        fontWeight: 'bold',
      },
      '& h4': {
        margin: `${theme.spacing(2)}px 0`,
        fontSize: 20,
        fontWeight: 'bold',
      },
      '& p': {
        margin: `${theme.spacing(2)}px 0`,
      },
      '& ol': {
        listStyle: 'none',
        counterReset: 'item',
        '& ol': {
          paddingLeft: '1em',
        },
        '& li': {
          textIndent: '-1.3em',
          paddingLeft: '1.3em',
        },
        '& li::before': {
          counterIncrement: 'item',
          content: `counter(item) '.'`,
          paddingRight: '0.5em',
          fontWeight: 'bold',
          color: '#b40000',
        },
      },
      '& blockquote': {
        position: 'relative',
        margin: `${theme.spacing(2)}px 0`,
        padding: `${theme.spacing(6)}px ${theme.spacing(2)}px ${theme.spacing(
          2,
        )}px`,
        backgroundColor: theme.palette.grey['200'],
        '&::before': {
          display: 'block',
          content: '""',
          position: 'absolute',
          top: 13,
          left: 15,
          background: `no-repeat center center url(${BlockQuote})`,
          backgroundSize: 'contain',
          width: 30,
          height: 30,
          color: '#cfcfcf',
          fontSize: 28,
          lineHeight: 1,
          fontWeight: 900,
        },
      },
    },
  },
}));

export default useStyles;
