import React, { PropsWithChildren, useCallback, useState } from 'react';
import { TableInstance } from 'react-table';
import { Popper, IconButton, ClickAwayListener, Box } from '@material-ui/core';
import { RiFilter3Fill } from 'react-icons/ri';

import { ButtonPrimary } from '../Button';
import { useStyles } from './useStyles';

// eslint-disable-next-line @typescript-eslint/ban-types
export const Toolbar = <T extends {}>({
  instance,
}: PropsWithChildren<{ instance: TableInstance<T> }>) => {
  const { columns, setAllFilters } = instance;
  const [anchorEl, setAnchorEl] = useState<
    HTMLButtonElement | HTMLAnchorElement | null
  >(null);
  const classes = useStyles();

  const resetFilters = useCallback(() => {
    setAllFilters([]);
  }, [setAllFilters]);

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <div className={classes.toolbar}>
        <Popper
          open={Boolean(anchorEl)}
          placement="bottom-end"
          anchorEl={anchorEl}
          className={classes.filterBox}
        >
          <div className="table-filters">
            {columns
              .filter((it) => it.isVisible && it.canFilter)
              .map((column) => (
                <div className="field" key={column.id}>
                  {column.render('Filter')}
                </div>
              ))}
          </div>
          <Box mt={2} display="flex" justifyContent="center">
            <ButtonPrimary
              type="button"
              onClick={resetFilters}
              color="secondary"
            >
              RESET
            </ButtonPrimary>
          </Box>
        </Popper>
        <IconButton
          area-label="Filter"
          onClick={(event) =>
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }
        >
          <RiFilter3Fill />
        </IconButton>
      </div>
    </ClickAwayListener>
  );
};
