import React, { VFC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import dateFormat from 'date-fns/format';
import {
  selectActualResults,
  selectPublishActualResults,
} from '../../../../../../re-ducks/themes/selector';
import {
  BILLING_STATUS,
  PUBLISHING_STATUS,
  STAFF,
  ThemeActualResult,
  ThemeType,
} from '../../../../../../re-ducks/themes/types';
import { fetchActualResults } from '../../../../../../re-ducks/themes/actions';
import { ErrorMessage } from '../../../../base/form/ErrorMessage';
import { useStyles } from './useStyles';
import LoopFieldSection from '../../../../case/form/LoopFieldSection';
import { TextField } from '../../../../base/form/TextField';
import { SelectField } from '../../../../base/form/SelectField';
import { InputLabel } from '../../../../base/form/InputLabel';
import { ActualImages } from './ActualImages';
import { DateField } from '../../../../base/form/DateField';
import { formats } from '../../../../../../data/date';

interface Props {
  defaultValues: ThemeType;
}

export const ActualResults: VFC<Props> = ({ defaultValues }) => {
  const { register, control, setValue, watch } = useFormContext();
  const actualResults = useSelector(selectActualResults);
  const publishActualResults = useSelector(selectPublishActualResults);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffectOnce(() => {
    dispatch(
      fetchActualResults({
        whereQueries: [{ fieldPath: 'publishing', opStr: '==', value: true }],
        orderQueries: [{ fieldPath: 'createDate', directionStr: 'desc' }],
      }),
    );
  });

  return (
    <LoopFieldSection<ThemeActualResult>
      name="actualResults"
      label="建築実例"
      initialValue={{
        uid: '',
        startDate: null,
        endDate: null,
        makerId: '',
        publishingStatus: 'waiting',
        billingStatus: 'unclaimed',
        staff: '崎本',
        actualResultUid: '',
        actualImages: [],
        comment: '',
      }}
    >
      {(baseName, baseError, field, index) => {
        const publishActualResult = publishActualResults.find(
          ({ actualResultId }) =>
            actualResultId ===
            defaultValues.actualResults[index]?.actualResultUid,
        );
        const actualResult = actualResults.find(
          ({ uid }) =>
            uid === defaultValues.actualResults[index]?.actualResultUid,
        );
        const defaultActualResult = actualResult
          ? {
              label: `${actualResult.title} - ${actualResult.slug}`,
              value: actualResult.uid || '',
            }
          : null;
        const startDateName = `${baseName}.startDate`;
        const endDateName = `${baseName}.endDate`;

        return (
          <div>
            <input
              type="hidden"
              name={`${baseName}.uid`}
              value={publishActualResult?.uid || ''}
              ref={register()}
            />
            <input
              type="hidden"
              name={`${baseName}.makerId`}
              value={actualResult?.makerId || ''}
              ref={register()}
            />
            <div className={classes.dateWrapper}>
              <div>
                <Controller
                  name={startDateName}
                  control={control}
                  defaultValue={watch(startDateName)}
                  rules={{
                    required: '掲載開始日を入力してください',
                  }}
                  render={({ name }) => (
                    <DateField
                      DatePickerProps={{
                        label: '掲載開始日',
                        name,
                        value: watch(startDateName)
                          ? moment(watch(startDateName)).toDate()
                          : null,
                        format: formats[0],
                        error: !!baseError?.startDate,
                        onChange: (v) =>
                          setValue(name, v ? moment(v).toDate() : null),
                        required: true,
                        placeholder: dateFormat(new Date(), 'yyyy年M月d日'),
                      }}
                    />
                  )}
                  isClearable
                />
                <ErrorMessage error={baseError?.startDate} />
              </div>
              <div>
                <Controller
                  name={endDateName}
                  control={control}
                  rules={{
                    required: '掲載終了日を入力してください',
                    validate: {
                      afterStartDate: (value: Date) => {
                        return moment(watch(startDateName)).isSameOrBefore(
                          value,
                          'day',
                        )
                          ? true
                          : '終了日は掲載開始日以降の日付けを入力してください';
                      },
                    },
                  }}
                  render={({ name }) => (
                    <DateField
                      DatePickerProps={{
                        label: '終了日',
                        name,
                        value: watch(endDateName)
                          ? moment(watch(endDateName)).toDate()
                          : null,
                        format: formats[0],
                        error: !!baseError?.endDate,
                        onChange: (v) =>
                          setValue(name, v ? moment(v).toDate() : null),
                        required: true,
                        placeholder: dateFormat(new Date(), 'yyyy年M月d日'),
                      }}
                    />
                  )}
                  defaultValue={watch(endDateName)}
                  isClearable
                />
                <ErrorMessage error={baseError?.endDate} />
              </div>
            </div>
            <div className="mt-5">
              <div className="mb-2">
                <InputLabel required>担当者</InputLabel>
              </div>
              <Controller
                name={`${baseName}.staff`}
                control={control}
                defaultValue={publishActualResult?.staff || '崎本'}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={{
                      label: publishActualResult?.staff || '崎本',
                      value: publishActualResult?.staff || '崎本',
                    }}
                    options={STAFF.map((value) => ({
                      label: value,
                      value,
                    }))}
                  />
                )}
                isClearable
              />
            </div>
            <div className="mt-5">
              <div className="mb-2">
                <InputLabel required>掲載ステータス</InputLabel>
              </div>
              <Controller
                name={`${baseName}.publishingStatus`}
                control={control}
                defaultValue={
                  publishActualResult?.publishingStatus || 'waiting'
                }
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={{
                      label:
                        PUBLISHING_STATUS[
                          publishActualResult?.publishingStatus || 'waiting'
                        ],
                      value: publishActualResult?.publishingStatus || 'waiting',
                    }}
                    options={Object.entries(PUBLISHING_STATUS).map(
                      ([key, value]) => ({
                        label: value,
                        value: key,
                      }),
                    )}
                  />
                )}
                isClearable
              />
            </div>
            <div className="mt-5">
              <div className="mb-2">
                <InputLabel required>請求ステータス</InputLabel>
              </div>
              <Controller
                name={`${baseName}.billingStatus`}
                control={control}
                defaultValue={publishActualResult?.billingStatus || 'unclaimed'}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={{
                      label:
                        BILLING_STATUS[
                          publishActualResult?.billingStatus || 'unclaimed'
                        ],
                      value: publishActualResult?.billingStatus || 'unclaimed',
                    }}
                    options={Object.entries(BILLING_STATUS).map(
                      ([key, value]) => ({
                        label: value,
                        value: key,
                      }),
                    )}
                  />
                )}
                isClearable
              />
            </div>
            <div className="mt-5">
              <div className="mb-2">
                <InputLabel required>建築実例</InputLabel>
              </div>
              <Controller
                name={`${baseName}.actualResultUid`}
                control={control}
                defaultValue={defaultActualResult?.value || ''}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={defaultActualResult}
                    options={Object.entries(
                      groupBy(actualResults, 'makerName'),
                    ).map(([key, values]) => ({
                      label: key,
                      options: values.map(({ uid, title, slug }) => ({
                        label: `${title} - ${slug}`,
                        value: uid,
                      })),
                    }))}
                  />
                )}
                rules={{
                  validate: {
                    required: (v) => (v ? true : '建築実例を選択してください'),
                  },
                }}
                isClearable
              />
              <ErrorMessage error={baseError?.actualResultUid} />
            </div>
            <div className="mt-5">
              <TextField
                label="説明文"
                type="textarea"
                name={`${baseName}.comment`}
                inputRef={register({ required: '説明文を入力してください' })}
                error={!!baseError?.comment}
                defaultValue={field.comment}
                rows={4}
                multiline
                required
              />
              <ErrorMessage error={baseError?.comment} />
            </div>
            <div className="mt-5">
              <ActualImages baseName={baseName} index={index} />
            </div>
          </div>
        );
      }}
    </LoopFieldSection>
  );
};
