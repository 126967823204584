import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import moment from 'moment';
import { v4 } from 'uuid';

import { faqRepository } from '../../repositories/firebase/faq';
import { storage, db } from '../../repositories/firebase';
import { FAQ, FAQCategory, FAQMedia, FaqTypes } from './types';
import { AsyncThunkConfig } from '../store';
import { OpenSnackbar } from '../../presentation/hooks/useSnackbar';

export const fetchMedias = createAsyncThunk<
  FAQMedia[],
  undefined,
  AsyncThunkConfig<undefined>
>('faq/fetchMedias', async (args, thunkAPI) => {
  try {
    const mediaDocs = await db
      .collection<FAQMedia>({ collectionPath: 'faqMedias' })
      .fetchAll();

    return mediaDocs?.length ? mediaDocs : [];
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const uploadMedia = createAsyncThunk<
  undefined,
  {
    dataUrl: string;
    fileName: string;
    callback: (downloadUrl: string) => void;
  },
  AsyncThunkConfig<undefined>
>('faq/uploadMedia', async (args, thunkAPI) => {
  try {
    const { dataUrl, fileName, callback } = args;

    const filePath = `/faq/medias/${fileName}`;
    const result = await storage.ref(filePath).putString(dataUrl, 'data_url');
    const { ref } = result;
    callback(await ref.getDownloadURL());
    const mediaId = v4();
    await db
      .collection<FAQMedia>({ collectionPath: 'faqMedias' })
      .doc(mediaId)
      .set({ uid: mediaId, path: `/${ref.fullPath}` });

    return undefined;
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchFaqCategories = createAsyncThunk<
  FAQCategory[],
  undefined,
  AsyncThunkConfig<undefined>
>('faq/fetchFaqCategories', async (args, thunkAPI) => {
  try {
    const faqCategories = await db
      .collection<FAQCategory>({ collectionPath: 'faqCategories' })
      .orderBy('order', 'asc')
      .fetch();

    return faqCategories?.length ? faqCategories : [];
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const createFaqCategory = createAsyncThunk<
  FAQCategory,
  { value: string; faqType: keyof typeof FaqTypes },
  AsyncThunkConfig<undefined>
>('faq/createFaqCategory', async (args, thunkAPI) => {
  try {
    const category: FAQCategory = {
      label: args.value,
      faqType: args.faqType,
      uid: v4(),
      faqItems: [],
      order: 0,
    };
    await db
      .collection<FAQCategory>({ collectionPath: 'faqCategories' })
      .doc(category.uid)
      .set(category);

    return category;
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchFaqItems = createAsyncThunk<
  FAQ[],
  undefined,
  AsyncThunkConfig<undefined>
>('faq/fetchFaqItems', async (args, thunkAPI) => {
  try {
    const faqItems = await faqRepository.fetchAll();

    return faqItems?.length ? faqItems : [];
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const createFaqItem = createAsyncThunk<
  FAQ,
  { faq: FAQ; openSnackbar: OpenSnackbar },
  AsyncThunkConfig<undefined>
>('faq/createFaqItem', async (args, thunkAPI) => {
  const { faq, openSnackbar } = args;

  try {
    if (faq.uid) {
      await faqRepository.update({ ...faq });
    } else {
      await faqRepository.add({ ...faq });
    }

    openSnackbar({
      variant: 'success',
      message: `FAQの${faq.uid ? '保存' : '作成'}が完了しました`,
    });

    return faq;
  } catch (error: any) {
    console.error(error);
    openSnackbar({
      variant: 'error',
      message: 'FAQの作成が失敗しました',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const deleteFaqItem = createAsyncThunk<
  {
    faqItems: FAQ[];
    faqCategories: FAQCategory[];
  },
  FAQ,
  AsyncThunkConfig<undefined>
>('faq/deleteFaqItem', async (args, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const { faqItems, faqCategories } = state.faq;
    await faqRepository.delete(args.uid);

    const deletedCategories: FAQCategory[] = [];
    const categories = await Promise.all(
      faqCategories.map((cat) => {
        const newCat = {
          ...cat,
          faqItems: cat.faqItems.filter((id) => id !== args.uid),
        };

        if (cat.faqItems.find((id) => id === args.uid)) {
          deletedCategories.push(newCat);
        }

        return newCat;
      }),
    );

    await Promise.all(
      deletedCategories.map(async (cat) => {
        await db
          .collection<FAQCategory>({ collectionPath: 'faqCategories' })
          .doc(cat.uid)
          .set(cat);

        return cat.uid;
      }),
    );

    return {
      faqItems: faqItems.filter((faq) => faq.uid !== args.uid),
      faqCategories: categories,
    };
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const indexCategories = createAsyncThunk<
  FAQCategory[],
  FAQCategory[],
  AsyncThunkConfig<undefined>
>('faq/indexCategories', async (args, thunkAPI) => {
  try {
    const ordered = await Promise.all(
      args.map(async (cat, index) => {
        const newCat = { ...cat, order: index };
        await db
          .collection<FAQCategory>({ collectionPath: 'faqCategories' })
          .doc(newCat.uid)
          .set(newCat);

        return newCat;
      }),
    );

    return ordered;
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const sortCategoryFaqItems = createAsyncThunk<
  FAQCategory,
  FAQCategory,
  AsyncThunkConfig<undefined>
>('faq/sortCategoryFaqItems', async (args, thunkAPI) => {
  try {
    await db
      .collection<FAQCategory>({ collectionPath: 'faqCategories' })
      .doc(args.uid)
      .set(args);

    return args;
  } catch (error: any) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});
