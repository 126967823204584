import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';
import { v4 } from 'uuid';

import { db } from './index';
import { EventReservationsType } from '../../re-ducks/event/types';
import { initialReservation } from '../../re-ducks/event/slice';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';
import { formats } from '../../data/date';

export const eventReservationsRepository: Collection<EventReservationsType> =
  db.collection<EventReservationsType>({
    collectionPath: 'eventReservations',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        uid: obj?.uid ? obj.uid : v4(),
        createDate: convertToTimestampFromString(obj.createDate),
        updateDate: convertToTimestampFromString(''),
        reservationDate: convertToTimestampFromString(
          obj.reservationDate,
          formats[0],
        ),
      };
    },
    decode: (doc) => {
      const data = doc.data();
      if (!data) return initialReservation;

      return {
        ...data,
        createDate: convertToDateFromTimestamp(data.createDate) || '',
        updateDate: convertToDateFromTimestamp(data.updateDate) || '',
        reservationDate:
          convertToDateFromTimestamp(
            convertToTimestampFromString(data.reservationDate, formats[3]),
            formats[0],
          ) || '',
      };
    },
  });
