import React from 'react';
import { convertFromHTML } from 'draft-convert';

export default (html: string) => {
  return convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
      switch (nodeName) {
        case 'a': {
          const entity = createEntity('LINK', 'MUTABLE', {
            url: node.href,
            external: !!node.rel,
          });

          return entity;
        }
        case 'img': {
          const entity = createEntity('image', 'IMMUTABLE', {
            src: node?.src || '',
          });

          return entity;
        }
        default: {
          return undefined;
        }
      }
    },
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === 'span' && node.className.includes('marker')) {
        return currentStyle.add('marker');
      }

      return currentStyle;
    },
    htmlToBlock: (nodeName, node) => {
      switch (nodeName) {
        case 'figure': {
          return '';
        }
        case 'img': {
          return {
            type: 'atomic',
            data: {
              src: node?.src || '',
            },
          };
        }
        default: {
          return undefined;
        }
      }
    },
  })(html);
};
