import React, { FC } from 'react';
import MuiTextField, { TextFieldProps } from '@material-ui/core/TextField';
import { useStyles } from './useStyles';

export type TextBoxProps = TextFieldProps;

export const TextField: FC<TextBoxProps> = (props) => {
  const { multiline } = props;
  const classes = useStyles();

  return (
    <MuiTextField
      fullWidth
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.labelRoot,
          required: classes.required,
          asterisk: classes.asterisk,
        },
      }}
      InputProps={{
        classes: {
          root: multiline ? classes.root : '',
          formControl: classes.formControl,
        },
      }}
      {...props}
    />
  );
};
