import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Feature, FeatureSection } from '../../../../../re-ducks/maker/types';
import { InputLabel } from '../../../base/form/InputLabel';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { TextField } from '../../../base/form/TextField';
import { AddPhoto } from '../../../base/form/AddPhoto';
import LoopFieldSection from '../../../case/form/LoopFieldSection';

export const MakerFeatures: VFC = () => {
  const { register, setValue } = useFormContext();

  return (
    <LoopFieldSection<Feature>
      name="features"
      label="特徴セクション"
      initialValue={{ title: '', sections: [] }}
    >
      {(baseName, baseError, field, index) => {
        return (
          <div>
            <TextField
              label="セクションタイトル"
              type="text"
              name={`${baseName}.title`}
              inputRef={register({
                required: 'セクションタイトルを入力してください',
              })}
              error={!!baseError?.title}
              defaultValue={field.title}
              className="mb-4"
              required
            />
            <ErrorMessage error={baseError?.title} />

            <LoopFieldSection<FeatureSection>
              name={`features[${index}].sections`}
              label="特徴"
              initialValue={{ title: '', image: '', text: '' }}
            >
              {(baseName2, baseError2, field2) => {
                return (
                  <div>
                    <div>
                      <TextField
                        label="タイトル"
                        type="text"
                        name={`${baseName2}.title`}
                        inputRef={register()}
                        error={!!baseError2?.title}
                        defaultValue={field2.title}
                      />
                      <ErrorMessage error={baseError2?.title} />
                    </div>
                    <div className="mt-5">
                      <InputLabel className="mb-2">画像</InputLabel>
                      <div style={{ width: 200, height: 200 }}>
                        <AddPhoto
                          name={`${baseName2}.image`}
                          jpegUrl={field2.image}
                          setJpegUrl={(url) => {
                            setValue(`${baseName2}.image`, url, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          }}
                          accept="image/png,image/jpeg"
                        />
                      </div>
                    </div>
                    <div className="mt-5">
                      <TextField
                        label="説明文"
                        type="text"
                        name={`${baseName2}.text`}
                        inputRef={register({
                          required: '説明文を入力してください',
                        })}
                        error={!!baseError2?.text}
                        defaultValue={field2.text}
                        multiline
                        required
                        rows={5}
                      />
                      <ErrorMessage error={baseError2?.text} />
                    </div>
                  </div>
                );
              }}
            </LoopFieldSection>
          </div>
        );
      }}
    </LoopFieldSection>
  );
};
