import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: 'scroll',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      overflow: 'visible',
      whiteSpace: 'normal',
    },
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 10,
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.5)',
      borderRadius: 20,
      boxShadow: '0 0 1px hsl(0deg 0% 100% / 50%)',
    },
    '&::-webkit-scrollbar-track': {
      background: 'hsla(0,0%,100%,.1)',
      border: 'none',
    },
  },
  table: {
    width: '100%',
    '& thead th': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,

      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },

      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
    '& tbody': {
      '& tr:hover': {
        cursor: 'pointer',
      },
      '& td': {
        verticalAlign: 'middle',
      },
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      minWidth: 'initial !important',
      '& tr': {
        display: 'block',
        width: '100%',
      },
      '& thead th': {
        display: 'none',
      },
      '& tbody': {
        display: 'block',
        width: '100%',
        '& td': {
          display: 'block',
          width: '100%',
          '&:nth-child(1)': {
            borderTop: `7px solid ${theme.palette.primary.main}`,
          },
        },
      },
    },
  },
  columnName: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginBottom: theme.spacing(1),
      fontSize: 14,
      fontWeight: 'bold',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  filterBox: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.common.white,
    '& .label': {
      fontSize: 12,
    },
  },
}));
