import React, { FC, ReactChild } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import useStyles from './useStyles';

interface Props {
  children: ReactChild;
}

const Tooltip: FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <MuiTooltip
      title={children}
      placement="top"
      classes={{ tooltip: classes.root }}
    >
      <HelpIcon fontSize="small" className={classes.icon} />
    </MuiTooltip>
  );
};

export default Tooltip;
