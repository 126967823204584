import React, { VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { push } from 'connected-react-router';
import { useEffectOnce } from 'react-use';
import ReloadButton from '../../../case/button/ReloadButton';
import { AddButton } from '../../../case/button/AddButton';
import { DotProgress } from '../../../case/feedback/DotProgress';
import { setTheme, initialState } from '../../../../../re-ducks/themes/slice';
import {
  selectCreating,
  selectFetching,
  selectThemes,
} from '../../../../../re-ducks/themes/selector';
import { ThemeCategories } from '../../../../../re-ducks/themes/types';
import { fetchThemes } from '../../../../../re-ducks/themes/actions';
import ListItems from './ListItems';
import useStyles from './useStyles';

const ThemeList: VFC = () => {
  const dispatch = useDispatch();
  const fetching = useSelector(selectFetching);
  const creating = useSelector(selectCreating);
  const themes = useSelector(selectThemes);
  const classes = useStyles();

  useEffectOnce(() => {
    dispatch(fetchThemes({ whereQueries: [], orderQueries: [] }));
  });

  return (
    <div>
      <DotProgress opening={fetching || creating} />
      <Box mb={1}>
        <AddButton
          label="テーマを追加する"
          onClick={() => {
            dispatch(setTheme(initialState.theme));
            dispatch(push('/theme/edit'));
          }}
        />
      </Box>
      <Box component="section" mb={4}>
        <div className={classes.themeHeader}>
          <h2 className={classes.themeType}>テーマ一覧</h2>
          <ReloadButton />
        </div>
        <div>
          <ul>
            {ThemeCategories.map((cat) => {
              const catThemes = themes.filter(
                ({ themeCategory }) => themeCategory === cat,
              );

              return (
                <li className={classes.category} key={cat}>
                  <header className={classes.categoryHeader}>
                    <h3 className={classes.categoryName}>{cat}</h3>
                  </header>
                  <div className={classes.categoryContent}>
                    {catThemes.length ? (
                      <div>
                        <ListItems themes={catThemes} category={cat} />
                      </div>
                    ) : (
                      <Box pt={2} pb={2}>
                        このカテゴリーにはテーマありません
                      </Box>
                    )}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Box>
    </div>
  );
};

export default ThemeList;
