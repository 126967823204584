import React, { VFC } from 'react';
import { PageTitle } from '../../case/PageTitle';
import { AdminWrapper } from '../../domain/AdminWrapper';
import IconFaq from '../../base/Icon/IconFaq';
import FaqList from '../../domain/faq/FaqList';

export default () => {
  return (
    <AdminWrapper>
      <PageTitle Icon={<IconFaq />}>ヘルプ</PageTitle>
      <FaqList />
    </AdminWrapper>
  );
};
