import React, { VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { selectRole } from '../../../../re-ducks/collaborator/selector';
import { PageTitle } from '../../case/PageTitle';
import { AddButton } from '../../case/button/AddButton';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { MakerListTable } from '../../domain/maker/MakerListTable';
import { initialState, setMaker } from '../../../../re-ducks/maker/slice';
import IconProfessional from '../../base/Icon/IconProfessional';

export const Professional: VFC = () => {
  const dispatch = useDispatch();
  const role = useSelector(selectRole);

  return (
    <AdminWrapper>
      <PageTitle Icon={<IconProfessional />}>建築事業者・ブランド</PageTitle>
      {role === 'cunelwork' ? (
        <AddButton
          label="建築事業者・ブランドを追加する"
          onClick={() => {
            dispatch(setMaker(initialState.maker));
            dispatch(push('/professional/edit'));
          }}
        />
      ) : null}
      <MakerListTable />
    </AdminWrapper>
  );
};
