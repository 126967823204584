import { SortByFn } from 'react-table';

import { EventListTableType } from '../../../../../re-ducks/event/types';

const sortPublishing: SortByFn<EventListTableType> = (
  nextRow,
  currentRow,
  columnId,
): 0 | 1 | -1 => {
  const columnKey = columnId as Extract<keyof EventListTableType, string>;
  const next = nextRow.original[columnKey] as boolean;
  const current = currentRow.original[columnKey] as boolean;
  if (next === current) return 0;

  return next ? 1 : -1;
};

export default sortPublishing;
