import React, { VFC } from 'react';
import { ColumnInstance } from 'react-table';

export const DefaultColumnFilter: VFC<{ column: ColumnInstance<never> }> = ({
  column,
}) => {
  const { id, render, setFilter, filterValue } = column;

  return (
    <div>
      <div className="label">{render('Header')}</div>
      <div className="control">
        <input
          value={filterValue || ''}
          name={id}
          onChange={(event) => setFilter(event.target.value || '')}
          className="input"
        />
      </div>
    </div>
  );
};
