import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';

import { db } from './index';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';
import { MakerConsultationsType } from '../../re-ducks/makerConsultations/types';

export const MakerConsultationsRepository: Collection<MakerConsultationsType> =
  db.collection<MakerConsultationsType>({
    collectionPath: 'makerConsultations',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        date: convertToTimestampFromString(obj.date),
      };
    },
    decode: (doc) => {
      const data = doc.data();
      if (!data)
        return {
          uid: '',
          date: '',
          name: '',
          mailAddress: '',
          tel: '',
          place: '',
          services: '',
          hasLand: false,
          content: '',
          pageUrl: '',
          makerId: '',
          userAgent: '',
          os: '',
          browser: '',
          memo: '',
        };

      return {
        ...data,
        date: convertToDateFromTimestamp(data.date) || '',
      };
    },
  });
