import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { FirebaseAuthWrapper, FirestoreWrapper } from 'my-firebase-wrapper';

import config from './config';

export const firebaseApp = firebase.apps.length
  ? firebase.apps[0]
  : firebase.initializeApp(config);
export const firebaseAuth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();

export const auth = new FirebaseAuthWrapper(firebaseAuth);
export const db = new FirestoreWrapper(firestore);

export const storage = firebaseApp.storage();

export const { Timestamp } = firebase.firestore;
export type TimestampType = firebase.firestore.Timestamp;
export type FirebaseUser = firebase.User;

firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);
