import { createSlice } from '@reduxjs/toolkit';

import { ActualImageType, ActualResultType } from './types';
import {
  fetchActualResults,
  setActualResult,
  createActualImages,
  createActualResult,
} from './actions';

export type ActualResultState = {
  actualResults: ActualResultType[];
  actualResult: ActualResultType;
  actualImages: ActualImageType[];
  actualImage: ActualImageType;
  fetching: boolean;
  creating: boolean;
};

export const initialState: ActualResultState = {
  actualResults: [],
  actualResult: {
    managementId: '',
    publishing: true,
    title: '',
    contents: [],
    buildingType: '',
    prefecture: '15',
    actualArea: '',
    floorSquare: 0,
    siteSquare: 0,
    familyStructure: '',
    layout: '',
    vibrationResistance: '',
    thermalInsulation: '',
    actualDate: null,
    unitPriceInfo: {
      publishing: false,
      price: 0,
    },
    uid: '',
    makerId: '',
    makerName: '',
    makerSlug: '',
    slug: '',
    images: [],
    primaryImage: '',
    savedUsers: [],
    numOfSavedUsers: 0,
    createDate: '',
    updateDate: '',
  },
  actualImages: [],
  actualImage: {
    description: '',
    roomPlace: 'exterior',
    styleTags: [],
    wallLooks: [],
    floorLooks: [],
    facilitiesAndFunctions: [],
    exteriorNumOfFloors: [],
    exteriorRoofTypes: [],
    kitchenTypes: [],
    kitchenStorages: [],
    ldkTypes: [],
    roomTypes: [],
    // 自動入力
    uid: '',
    publishing: true,
    makerId: '',
    actualResultId: '',
    makerName: '',
    actualResultTitle: '',
    imageUrl: '',
    createDate: '',
    updateDate: '',
    clippedUsers: [],
    numOfClippedUsers: 0,
    slug: '',
  },
  fetching: false,
  creating: false,
};

const actualResultSlice = createSlice({
  name: 'actualResult',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchActualResults
    builder.addCase(fetchActualResults.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchActualResults.fulfilled, (state, action) => {
      state.actualResults = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchActualResults.rejected, (state) => {
      state.actualResults = [];
      state.fetching = false;
    });
    // setActualResult
    builder.addCase(setActualResult.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(setActualResult.fulfilled, (state, action) => {
      state.actualResult = action.payload.actualResult;
      state.actualImages = action.payload.actualImages;
      state.fetching = false;
    });
    builder.addCase(setActualResult.rejected, (state) => {
      state.actualResult = initialState.actualResult;
      state.actualImages = [];
      state.fetching = false;
    });
    // createActualResult
    builder.addCase(createActualResult.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(createActualResult.fulfilled, (state, action) => {
      state.actualResult = action.payload;
      state.creating = false;
    });
    builder.addCase(createActualResult.rejected, (state) => {
      state.actualResult = initialState.actualResult;
      state.creating = false;
    });
    // createActualImages
    builder.addCase(createActualImages.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(createActualImages.fulfilled, (state, action) => {
      state.actualResult = action.payload.actualResult;
      state.actualImages = action.payload.actualImages;
      state.fetching = false;
    });
    builder.addCase(createActualImages.rejected, (state) => {
      state.fetching = false;
    });
  },
});

export default actualResultSlice;
