import React, {
  VFC,
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
} from 'react';
import {
  Popover,
  Paper,
  List,
  ListItem,
  Box,
  Typography,
} from '@material-ui/core';
import { ArrowRight } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { ButtonPrimary } from '../Button';
import { useStyles } from './useStyles';
import { signOut } from '../../../../re-ducks/collaborator/actions';
import { resetFaq } from '../../../../re-ducks/faq/slice';
import {
  resetSelectedMaker,
  selectMaker,
} from '../../../../re-ducks/app/slice';
import { MakerType } from '../../../../re-ducks/maker/types';
import {
  selectRole,
  selectUid,
} from '../../../../re-ducks/collaborator/selector';
import { initialState } from '../../../../re-ducks/maker/slice';
import { makersRepository } from '../../../../repositories/firebase/makers';
import { resetMakerContacts } from '../../../../re-ducks/makerContacts/slice';
import { PLANS } from '../../../../data/plan';

interface SelectMakerFormType {
  makerId: string | null;
}

interface Props {
  anchorEl: HTMLElement | null;
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

const AppBarMenu: VFC<Props> = ({ anchorEl, setAnchorEl }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const collaboratorRole = useSelector(selectRole);
  const collaboratorUid = useSelector(selectUid);
  const [makers, setMakers] = useState<MakerType[]>([]);

  const onSubmit = async (data: SelectMakerFormType) => {
    const newData: {
      maker: MakerType | null;
    } = {
      maker: null,
    };
    if (typeof data.makerId === 'string' && data.makerId) {
      newData.maker =
        makers.find((maker) => maker.uid === data.makerId) ||
        initialState.maker;
    } else {
      return;
    }
    dispatch(resetMakerContacts());
    dispatch(resetFaq());
    dispatch(selectMaker(newData));
    dispatch(push('/'));
  };

  useEffect(() => {
    (async () => {
      if (collaboratorRole === 'admin') {
        const makersSnapshot = await makersRepository
          .where('collaborators', 'array-contains', collaboratorUid)
          .get();
        const fetchedMakers = makersSnapshot.empty
          ? []
          : makersSnapshot.docs.map((doc) => doc.data());
        setMakers(fetchedMakers);
      }
    })();
  }, [makers]);

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      className={classes.appBarMenu}
      classes={{
        paper: classes.appBarMenuPaper,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={() => setAnchorEl(null)}
      disablePortal
    >
      <Paper className={classes.appBarMenuInner}>
        {collaboratorRole === 'admin' ? (
          <Box mb={2}>
            <Box mb={1}>
              <Typography variant="body2">
                建築事業者・ブランドを選択してください
              </Typography>
            </Box>
            <div className={classes.makers}>
              {makers.map((maker) => {
                return (
                  <button
                    key={maker.uid}
                    onClick={() => {
                      setAnchorEl(null);
                      onSubmit({ makerId: maker.uid });
                    }}
                  >
                    <ArrowRight />
                    <span>{`${maker.name}（${
                      PLANS[maker.plan || 'free']
                    }）`}</span>
                  </button>
                );
              })}
            </div>
          </Box>
        ) : null}
        <List className={classes.appBarBottomMenu}>
          <ListItem component="li" disableGutters>
            <ButtonPrimary
              className={classes.logoutButton}
              onClick={async () => {
                dispatch(resetSelectedMaker());
                await dispatch(signOut());
              }}
            >
              ログアウト
            </ButtonPrimary>
          </ListItem>
        </List>
      </Paper>
    </Popover>
  );
};

export default AppBarMenu;
