import { v4 } from 'uuid';
import firebase from 'firebase/app';

import _ from 'lodash';
import { initialState } from '../../re-ducks/maker/slice';
import { firestore } from './index';
import { MakerType } from '../../re-ducks/maker/types';
import { dateFormat, timestamp2Number } from '../../utils/dateFormats';
import { formatValue } from '../../utils/formatValue';

const converter = {
  toFirestore(maker: MakerType): firebase.firestore.DocumentData {
    delete (maker as any)[''];

    return {
      ...maker,
      uid: maker.uid,
      establishmentDate: maker.establishmentDate
        ? dateFormat(maker.establishmentDate)
        : null,
      createDate: maker.createDate ? dateFormat(maker.createDate) : new Date(),
      updateDate: new Date(),
      coverageWorks: maker.coverageWorks.map((work: any) => formatValue(work)),
      approximateAmounts: maker.approximateAmounts.map(
        ({ amount, label }: any) => {
          return {
            label: formatValue(label),
            amount: formatValue(amount),
          };
        },
      ),
      collaborators: maker.collaborators.map((collaborator: any) =>
        formatValue(collaborator),
      ),
      performanceStandards: formatValue(maker.performanceStandards),
      supportMeetings: formatValue(maker.supportMeetings),
      supportMonies: formatValue(maker.supportMonies),
      supportLands: formatValue(maker.supportLands),
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): MakerType {
    const data = snapshot.data(options)! as MakerType;

    return {
      ...initialState.maker,
      ...data,
      establishmentDate: timestamp2Number(data.establishmentDate),
      createDate: timestamp2Number(data.createDate) || 0,
      updateDate: timestamp2Number(data.updateDate) || 0,
    };
  },
};

export const makersRepository = firestore
  .collection('makers')
  .withConverter(converter);
