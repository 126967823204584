import React, { FC } from 'react';
import { useEffectOnce } from 'react-use';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { FaUserFriends } from 'react-icons/fa';
import { format } from 'date-fns';
import { floor } from 'lodash';
import moment from 'moment';
import { firebaseAuth } from '../../../../repositories/firebase';
import { ALL_AREA } from '../../../../data/area';
import { fetchUser } from '../../../../re-ducks/user/actions';
import { selectFetching, selectUser } from '../../../../re-ducks/user/selector';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { PageTitle } from '../../case/PageTitle';
import { useStyles } from './useStyles';

export const UserDetail: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const fetching = useSelector(selectFetching);
  const params = useParams<{ id: string }>();

  useEffectOnce(() => {
    dispatch(fetchUser({ docId: params.id }));
  });

  return (
    <AdminWrapper>
      {fetching && !user ? <CircularProgress /> : null}
      {user ? (
        <div>
          <PageTitle Icon={<FaUserFriends />}>ユーザー情報</PageTitle>
          <table className={classes.table}>
            <tbody>
              <tr>
                <th>会員登録日</th>
                <td>
                  {firebaseAuth.currentUser && user.createDate
                    ? format(
                        new Date(user.createDate || ''),
                        'yyyy-MM-dd HH:mm:ss',
                      )
                    : ''}
                </td>
              </tr>
              <tr>
                <th>名前</th>
                <td>{user.name}</td>
              </tr>
              <tr>
                <th>年齢</th>
                <td>
                  {user.birthday
                    ? `${floor(
                        moment().diff(moment(user.birthday), 'years'),
                        -1,
                      )}代`
                    : null}
                </td>
              </tr>
              <tr>
                <th>現在の状況</th>
                <td>{user.investigationStatus}</td>
              </tr>
              <tr>
                <th>建てたいエリア</th>
                <td>
                  {user.buildArea
                    ?.map(
                      (area) =>
                        ALL_AREA.find(({ id }) => id === area)?.label || '',
                    )
                    .join('/')}
                </td>
              </tr>
              <tr>
                <th>土地の有無</th>
                <td>
                  {user.hasLand
                    ? 'すでに土地を持っている'
                    : '土地を持っていない'}
                </td>
              </tr>
              <tr>
                <th>建築予定時期</th>
                <td>{user.constructionSchedule}</td>
              </tr>
              <tr>
                <th>建築予算</th>
                <td>{user.buildingBudget}</td>
              </tr>
              <tr>
                <th>興味のあること</th>
                <td>{user.interests}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <p>ユーザーが退会済みか情報がありません。</p>
      )}
    </AdminWrapper>
  );
};
