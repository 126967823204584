import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useSnackbar from '../../../../hooks/useSnackbar';
import { TextBox } from '../../../base/form/TextBox';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { LoginButton } from '../../button/LoginButton';
import { Snackbar } from '../../feedback/Snackbar';
import { useStyles } from './useStyles';
import { signIn } from '../../../../../re-ducks/collaborator/actions';

interface LoginFormType {
  email: string;
  password: string;
}

export const LoginForm: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { snackbarState, openSnackbar, closeSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm<LoginFormType>();
  const onSubmit = handleSubmit(async (data) => {
    await dispatch(
      signIn({
        email: data.email,
        password: data.password,
        openSnackbar,
      }),
    );
  });

  return (
    <form className={classes.form} onSubmit={onSubmit} noValidate>
      <TextBox
        type="email"
        name="email"
        label="メールアドレス"
        inputRef={register({ required: 'メールアドレスを入力してください' })}
      />
      <ErrorMessage error={errors.email} />
      <TextBox
        type="password"
        name="password"
        label="パスワード"
        inputRef={register({ required: 'パスワードを入力してください' })}
      />
      <ErrorMessage error={errors.password} />
      <p className={classes.pwForget}>
        <Link to="/pw-forget">パスワードをお忘れの方</Link>
      </p>
      <LoginButton type="submit" className={classes.button} />
      <Snackbar
        variant={snackbarState.variant}
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        onClose={closeSnackbar}
      />
    </form>
  );
};
