import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  contactItem: {
    display: 'block',
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    borderTop: `1px dashed ${theme.palette.grey['300']}`,
    '&:first-child': {
      paddingTop: 0,
      borderTop: 'none',
    },
  },
  contactFrom: {
    marginBottom: theme.spacing(1),
    fontSize: 15,
  },
  contactMeta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    color: theme.palette.grey['500'],
    lineHeight: 1,
    fontFeatureSettings: '"tnum"',
  },
  footer: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.grey['300']}`,
    textAlign: 'right',
  },
  footerLink: {
    display: 'inline-flex',
    alignItems: 'center',
  },
}));
