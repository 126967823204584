import clsx from 'clsx';
import React, { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Table } from '../../../base/Table';
import { DefaultColumnFilter } from '../../../base/Table/DefaultColumnFilter';
import { BooleanColumnFilter } from '../../../base/Table/BooleanColumnFilter';
import { DotProgress } from '../../../case/feedback/DotProgress';
import { PublishingCell } from '../../../case/table/PublishingCell';
import { ImageCell } from '../../../case/table/ImageCell';
import { selectRole } from '../../../../../re-ducks/collaborator/selector';
import { ActualResultListTableType } from '../../../../../re-ducks/actualResult/types';
import {
  selectActualResults,
  selectFetching,
} from '../../../../../re-ducks/actualResult/selector';
import {
  fetchActualResults,
  setActualResult,
} from '../../../../../re-ducks/actualResult/actions';
import { useStyles } from './useStyles';
import { TitleCell } from './TitleCell';
import { selectSelectedMaker } from '../../../../../re-ducks/app/selector';

export const ActualResultListTable: VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const collaboratorRole = useSelector(selectRole);
  const selectedMaker = useSelector(selectSelectedMaker);
  const fetching = useSelector(selectFetching);
  const actualResults = useSelector(selectActualResults);

  useEffect(() => {
    dispatch(
      fetchActualResults({
        whereQueries:
          collaboratorRole === 'admin'
            ? [
                {
                  fieldPath: 'makerId',
                  opStr: '==',
                  value: selectedMaker?.uid || '',
                },
              ]
            : [],
        orderQueries: [],
      }),
    );
  }, []);

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  return (
    <Table<ActualResultListTableType>
      tableOptions={{
        data: actualResults.map((actualResult) => {
          return {
            primaryImage: actualResult.primaryImage,
            managementId: actualResult.managementId,
            makerName:
              collaboratorRole === 'admin'
                ? selectedMaker?.name || actualResult.makerName
                : actualResult.makerName,
            title: actualResult.title,
            actualDate: actualResult.actualDate || '',
            publishing: actualResult.publishing,
            createDate: actualResult.createDate || '',
            updateDate: actualResult.updateDate || '',
            actualResult,
          };
        }),
        columns: [
          {
            Header: '',
            accessor: 'primaryImage',
            Filter: DefaultColumnFilter,
            disableSortBy: true,
            disableFilters: true,
            Cell: ImageCell,
            width: 80,
          },
          {
            Header: '管理番号',
            accessor: 'managementId',
            Filter: DefaultColumnFilter,
            width: 160,
          },
          {
            Header: '建築事業者・ブランド',
            accessor: 'makerName',
            Filter: DefaultColumnFilter,
            width: 240,
          },
          {
            Header: 'タイトル',
            accessor: 'title',
            Filter: DefaultColumnFilter,
            width: 240,
            Cell: TitleCell,
          },
          {
            Header: '公開状態',
            accessor: 'publishing',
            Filter: BooleanColumnFilter,
            Cell: PublishingCell,
            width: 160,
          },
          {
            Header: '作成日',
            accessor: 'createDate',
            disableFilters: true,
            width: 160,
          },
          {
            Header: '更新日',
            accessor: 'updateDate',
            disableFilters: true,
            width: 160,
          },
        ],
        initialState: {
          pageSize: 50,
          sortBy: [{ id: 'updateDate', desc: true }],
          hiddenColumns:
            collaboratorRole === 'admin'
              ? ['actualResult', 'makerName']
              : ['actualResult'],
        },
      }}
      onClick={(row) => {
        dispatch(setActualResult(row.original.actualResult));
      }}
      classes={{
        root: clsx('table-container', classes.container),
        table: clsx(
          'table',
          'is-scrolled',
          'is-striped',
          'is-hoverable',
          'list-table',
        ),
      }}
    />
  );
};
