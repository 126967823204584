import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  themeType: {
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 'bold',
  },
  themeHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  category: {
    '&:first-child': {
      '& $categoryHeader': {
        borderTop: `1px solid ${theme.palette.grey['400']}`,
        borderTopRightRadius: theme.shape.borderRadius,
        borderTopLeftRadius: theme.shape.borderRadius,
      },
    },
    '&:last-child': {
      '& $categoryContent': {
        borderBottom: `1px solid ${theme.palette.grey['400']}`,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
    },
  },
  categoryHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.grey['400']}`,
    borderLeft: `1px solid ${theme.palette.grey['400']}`,
    backgroundColor: theme.palette.primary.light,
    '& svg': {
      color: theme.palette.common.white,
    },
  },
  categoryName: {
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  categoryContent: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRight: `1px solid ${theme.palette.grey['400']}`,
    borderLeft: `1px solid ${theme.palette.grey['400']}`,
    backgroundColor: theme.palette.common.white,
  },
  faqItem: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey['300']}`,
    backgroundColor: theme.palette.common.white,
    '&:last-child': {
      borderBottom: 0,
    },
  },
  faqTitle: {
    flex: 1,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
  },
  menu: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 0 3px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
  },
}));
