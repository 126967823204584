import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  item: {
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    '&:first-child': {
      marginTop: 0,
    },
    '& $item': {
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.common.white,
    },
  },
  addButton: {
    display: 'block',
    margin: `${theme.spacing(2)}px auto 0`,
    '&:first-child': {
      marginTop: 0,
    },
  },
  closeButton: {
    display: 'block',
    marginLeft: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: `space-between`,
    marginBottom: theme.spacing(2),
  },
}));
