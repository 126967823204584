import classNames from 'classnames';
import React, { ReactNode, VFC } from 'react';
import { useStyles } from './useStyles';

interface FormSectionProps {
  className?: string;
  sectionTitle?: ReactNode;
  children: ReactNode;
}

const FormSection: VFC<FormSectionProps> = ({
  className,
  sectionTitle,
  children,
}) => {
  const classes = useStyles();

  return (
    <section className={classNames(classes.section, className)}>
      {sectionTitle && <h2 className={classes.sectionTitle}>{sectionTitle}</h2>}
      <div className={classes.sectionContent}>{children}</div>
    </section>
  );
};

export default FormSection;
