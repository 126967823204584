import { Modal } from '@material-ui/core';
import IconClose from '@material-ui/icons/Close';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { ArrayField, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import getStorageFIleUrl from '../../../../../../utils/getStorageFIleUrl';
import {
  selectImages,
  selectTheme,
} from '../../../../../../re-ducks/themes/selector';
import { ActualImage } from '../../../../../../re-ducks/themes/types';

import { useStyles } from './useStyles';

interface Props {
  selectedActualResult: string;
  baseName: string;
  field: Partial<ArrayField<ActualImage, 'id'>>;
  defaultValue: ActualImage | undefined;
}

const Photo: FC<Props> = ({
  field,
  baseName,
  defaultValue,
  selectedActualResult,
}) => {
  const classes = useStyles();
  const { setValue, control, register } = useFormContext();
  const [modalOpening, setModalOpening] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const images = useSelector(selectImages);
  const uid =
    useWatch({
      name: `${baseName}.uid`,
      control,
      defaultValue: '',
    }) ||
    field.uid ||
    '';
  const imageUrl =
    useWatch({
      name: `${baseName}.imageUrl`,
      control,
      defaultValue: '',
    }) ||
    field.imageUrl ||
    '';

  return (
    <div className={classes.imageWrap}>
      <input
        type="hidden"
        name={`${baseName}.uid`}
        ref={register()}
        value={uid}
      />
      <input
        type="hidden"
        name={`${baseName}.imageUrl`}
        ref={register()}
        value={imageUrl}
      />
      {imageUrl ? (
        <img
          src={getStorageFIleUrl(imageUrl)}
          alt=""
          className={classes.image}
        />
      ) : (
        <button
          type="button"
          className={clsx('button', classes.button)}
          onClick={() => setModalOpening((prev) => !prev)}
        >
          画像を選択する
        </button>
      )}

      <Modal open={modalOpening} onClose={() => setModalOpening(false)}>
        <div className={classes.modalInner}>
          <header className={classes.modalHeader}>
            <h2>メディアを選択</h2>
            <div className={classes.modalAction}>
              <button
                type="button"
                className="button"
                onClick={() => {
                  const img = images[selectedActualResult || '']?.find(
                    (i) => i.uid === selectedImage,
                  );
                  setValue(`${baseName}.uid`, img?.uid || '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue(`${baseName}.imageUrl`, img?.imageUrl || '', {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setModalOpening(false);
                }}
              >
                画像を選択
              </button>
              <IconClose onClick={() => setModalOpening(false)} />
            </div>
          </header>
          <div className={classes.modalBody}>
            {selectedActualResult ? (
              <div className={classes.images}>
                {images[selectedActualResult || '']?.map((img) => (
                  <button
                    key={img.uid}
                    className={clsx(classes.img, {
                      [classes.selectedImg]: img.uid === selectedImage,
                    })}
                    onClick={() => setSelectedImage(img.uid)}
                  >
                    <img src={getStorageFIleUrl(img.imageUrl)} alt={img.uid} />
                  </button>
                ))}
              </div>
            ) : (
              <p>建築実例を選択してください</p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Photo;
