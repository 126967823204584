import React, { FC } from 'react';

import { PwForgetForm } from '../../case/form/PwForgetForm';
import { useStyles } from './useStyles';
import Logo from '../../../../assets/images/logo.svg';

export const PwForget: FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <img src={Logo} alt="Mockhouse" />
      </div>
      <p className={classes.description}>
        以下フォームにメールアドレスを入力し、送信してください。
        <br />
        その後、パスワード再設定の案内が書かれたメールが届きます。
        <br />
        メール内のリンクからパスワードを再設定してください。
      </p>
      <PwForgetForm />
    </div>
  );
};
