import React, { FC, useEffect, useState } from 'react';
import { TextFieldProps } from '@material-ui/core';

import { PasswordStrength } from '../../base/PasswordStrength';
import { PasswordField } from '../../base/form/PasswordField';
import { pwChecker } from '../../../../utils/PasswordValidate';

export type PasswordStrengthFieldProps = {
  textFieldProps: TextFieldProps;
};

export const PasswordStrengthField: FC<PasswordStrengthFieldProps> = ({
  textFieldProps,
}) => {
  const [password, setPassword] = useState(String(textFieldProps?.value) || '');
  const [strength, setStrength] = useState(0);

  useEffect(() => {
    setStrength(pwChecker.checkStrength(password));
  }, [password]);

  return (
    <div>
      <PasswordField
        label=""
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        {...textFieldProps}
      />
      {password ? <PasswordStrength strength={strength} /> : null}
    </div>
  );
};
