import React, { VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { AiOutlineTags } from 'react-icons/ai';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { PageTitle } from '../../case/PageTitle';
import { FAQ as FaqType } from '../../../../re-ducks/faq/types';
import { ThemeType } from '../../../../re-ducks/themes/types';
import ThemeForm from '../../domain/theme/ThemeForm';
import { initialState } from '../../../../re-ducks/themes/slice';
import { selectTheme } from '../../../../re-ducks/themes/selector';

const Detail: VFC = () => {
  const currentTheme = useSelector(selectTheme);
  const defaultValues: ThemeType = {
    ...initialState.theme,
    ...currentTheme,
  };

  const formMethods = useForm<FaqType>({
    criteriaMode: 'all',
    defaultValues,
  });

  useEffectOnce(() => window.scrollTo(0, 0));

  return (
    <AdminWrapper>
      <PageTitle Icon={<AiOutlineTags />}>こだわり特集</PageTitle>
      <FormProvider {...formMethods}>
        <ThemeForm defaultValues={defaultValues} />
      </FormProvider>
    </AdminWrapper>
  );
};

export default Detail;
