import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';
import { v4 } from 'uuid';

import { db, Timestamp } from './index';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';
import { initialState } from '../../re-ducks/faq/slice';
import { FAQ } from '../../re-ducks/faq/types';

export const faqRepository: Collection<FAQ> = db.collection<FAQ>({
  collectionPath: 'faq',
  encode: (obj) => {
    delete (obj as any)[''];

    return {
      ...obj,
      uid: obj?.uid ? obj.uid : v4(),
      createDate: convertToTimestampFromString(obj.createDate),
      updateDate: Timestamp.now(),
    };
  },
  decode: (doc) => {
    const data = doc.data();
    if (!data) return initialState.selectedFaq;

    return {
      ...data,
      createDate: convertToDateFromTimestamp(data.createDate) || '',
      updateDate: convertToDateFromTimestamp(data.updateDate) || '',
    };
  },
});
