import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    display: 'block',
    margin: `0 auto`,
  },
  sectionTitle: {
    margin: 0,
    fontSize: 24,
    fontWeight: 'bold',
    borderBottom: `1px solid #000`,
    display: 'flex',
    alignItems: 'center',
    '&.error': {
      borderBottomColor: theme.palette.error.main,
    },
    '& input': {
      display: 'block',
      width: '100%',
      outline: 'none',
      flex: 1,
      color: '#4a4a4a',
    },
  },
  required: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    transform: 'scale(0.75)',
    transformOrigin: 'top right',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: `space-between`,
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  sectionInner: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
  },
}));
