import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  Tabs,
  Tab,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import { FormProvider, useForm } from 'react-hook-form';
import IconProfessional from '../../base/Icon/IconProfessional';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { PageTitle } from '../../case/PageTitle';
import { selectRole } from '../../../../re-ducks/collaborator/selector';
import MakerBasicForm from '../../domain/maker/MakerBasicForm';
import MakerContactForm from '../../domain/maker/MakerContactForm';
import { MakerCunelworkEditForm } from '../../domain/maker/MakerCunelworkEditForm';
import { MakerType } from '../../../../re-ducks/maker/types';
import {
  selectCreating,
  selectMaker,
} from '../../../../re-ducks/maker/selector';
import useSnackbar from '../../../hooks/useSnackbar';
import { createMaker } from '../../../../re-ducks/maker/actions';
import { Snackbar } from '../../case/feedback/Snackbar';
import { DotProgress } from '../../case/feedback/DotProgress';
import Sidebar from '../../domain/maker/Sidebar';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: theme.spacing(5),
    marginTop: theme.spacing(4),
  },
  main: {
    flex: 1,
  },
  sidebar: {
    position: 'sticky',
    top: Number(theme.mixins.toolbar.minHeight) + theme.spacing(2),
    width: 300,
    padding: `${theme.spacing(2)}px`,
    backgroundColor: theme.palette.common.white,
  },
}));

export const ProfessionalDetail: FC = () => {
  const [tab, setTab] = React.useState(0);
  const dispatch = useDispatch();
  const creating = useSelector(selectCreating);
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();
  const role = useSelector(selectRole);
  const classes = useStyles();
  const maker = useSelector(selectMaker);
  const defaultValues: MakerType = {
    ...maker,
    personTel: maker.personTel || maker.phoneNumber || '',
  };
  const formMethods = useForm<MakerType>({
    criteriaMode: 'all',
    shouldUnregister: false,
    defaultValues,
  });

  const onSubmit = formMethods.handleSubmit((data) => {
    const newData = {
      ...defaultValues,
      ...data,
    };
    dispatch(createMaker({ formValue: newData, openSnackbar }));
  });

  return (
    <AdminWrapper>
      {creating ? <DotProgress opening={creating} /> : null}
      <PageTitle Icon={<IconProfessional />}>建築事業者・ブランド</PageTitle>
      <Paper>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, currentValue) => {
            setTab(currentValue);
            window.scrollTo(0, 0);
          }}
          aria-label="disabled tabs example"
        >
          <Tab label="基本情報" />
          <Tab label="お問い合わせ情報" />
          {role === 'cunelwork' ? <Tab label="CWユーザー入力項目" /> : null}
        </Tabs>
      </Paper>
      <p className="mt-5">
        <Typography component="span" color="error">
          ※
        </Typography>
        は必須項目です
      </p>

      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.main}>
              {tab === 0 ? <MakerBasicForm /> : null}
              {tab === 1 ? <MakerContactForm /> : null}
              {tab === 2 ? <MakerCunelworkEditForm /> : null}
            </div>
            <div className={classes.sidebar}>
              <Sidebar />
            </div>
          </div>
          <Snackbar
            variant={snackbarState.variant}
            message={snackbarState.message}
            isOpening={snackbarState.isOpening}
            onClose={closeSnackbar}
          />
        </form>
      </FormProvider>
    </AdminWrapper>
  );
};
