import React, { VFC } from 'react';
import { ColumnInstance, FilterValue, IdType, Row } from 'react-table';
import moment from 'moment';
import { DateField } from '../../../base/form/DateField';
import { formats } from '../../../../../data/date';
import { useStyles } from './useStyles';

export const monthFilter = (
  rows: Array<Row<any>>,
  columnIds: Array<IdType<any>>,
  filterValue: FilterValue,
): Array<Row<any>> => {
  return rows.filter((row) => {
    const rowDate = moment(row.values[columnIds[0]]);
    const filterValueDate = moment(filterValue);

    return (
      rowDate.year() === filterValueDate.year() &&
      rowDate.month() === filterValueDate.month()
    );
  });
};

export const YearMonthFilter: VFC<{ column: ColumnInstance<never> }> = ({
  column,
}) => {
  const { render, setFilter, filterValue } = column;
  const classes = useStyles();

  return (
    <div>
      <div className="label">{render('Header')}</div>
      <div className="control select is-block">
        <DateField
          DatePickerProps={{
            inputVariant: 'outlined',
            views: ['year', 'month'],
            value: filterValue || null,
            format: formats[0],
            margin: 'none',
            className: classes.root,
            labelFunc: (date, invalidLabel) => {
              if (!date || !date.isValid) {
                return invalidLabel || '';
              }

              return date.format('YYYY年M月');
            },
            onChange: (v) => {
              const value = v ? moment(v).toDate() : undefined;
              setFilter(value);
            },
          }}
        />
      </div>
    </div>
  );
};
