import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonWrap: {
    display: 'grid',
    gap: theme.spacing(2),
    margin: `${theme.spacing(3)}px auto 0`,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  sidebar: {
    position: 'sticky',
    top: Number(theme.mixins.toolbar.minHeight) + theme.spacing(2),
    width: 300,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  main: {
    flex: 1,
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  detailPage: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  square: {
    display: 'grid',
    gap: theme.spacing(3),
  },
}));
