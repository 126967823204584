import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { WordPressState } from './slice';

export const selectSelf = (state: RootState): WordPressState => state.wordpress;

export const selectFetching = createSelector(
  selectSelf,
  (state) => state.fetching,
);

export const selectInformations = createSelector(
  selectSelf,
  (state) => state.informations,
);

export const selectInformation = createSelector(
  selectSelf,
  (state) => state.information,
);
