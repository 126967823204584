import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  contentWrapper: {
    width: `calc(100% - ${260}px)`,
    flex: 1,
  },
}));
