import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';
import { v4 } from 'uuid';

import { PublishActualResult } from '../../re-ducks/themes/types';
import { initialState } from '../../re-ducks/themes/slice';
import { db } from './index';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';

export const publishActualResultsRepository: Collection<PublishActualResult> =
  db.collection<PublishActualResult>({
    collectionPath: 'publishActualResults',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        uid: obj?.uid ? obj.uid : v4(),
        startDate: convertToTimestampFromString(obj.startDate),
        endDate: convertToTimestampFromString(obj.endDate),
      };
    },
    decode: (doc) => {
      const data = doc.data();
      if (!data) return initialState.publishActualResult;

      return {
        ...data,
        startDate: convertToDateFromTimestamp(data.startDate),
        endDate: convertToDateFromTimestamp(data.endDate),
      };
    },
  });
