import clsx from 'clsx';
import React from 'react';
import {
  Row,
  TableOptions,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

import Pagination from './Pagination';
import { useStyles } from './useStyles';
import { Toolbar } from './Toolbar';

// eslint-disable-next-line @typescript-eslint/ban-types
export interface Props<T extends {}> {
  tableOptions: TableOptions<T> & { [key: string]: any };
  onClick?: (row: Row<T>) => void;
  classes: {
    root: string;
    table: string;
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const Table = <T extends {}>({
  tableOptions,
  onClick,
  classes,
}: Props<T>) => {
  const classNames = useStyles();
  const instance = useTable(
    {
      ...tableOptions,
      initialState: tableOptions?.initialState,
    },
    useFilters,
    useSortBy,
    usePagination,
  );
  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    instance;

  return (
    <div>
      <Toolbar instance={instance} />
      <div className={clsx(classes.root, classNames.container)}>
        <table
          {...getTableProps()}
          className={clsx(classes.table, classNames.table)}
          id="table"
        >
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                        className={`column-${column.id}`}
                        style={{
                          width: column.width !== 150 ? column.width : 'auto',
                        }}
                      >
                        <span>{column.render('Header')}</span>
                        {column.isSorted &&
                          (column.isSortedDesc ? (
                            <AiFillCaretDown fontSize="small" />
                          ) : (
                            <AiFillCaretUp fontSize="small" />
                          ))}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => onClick && onClick(row)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={`cell-${cell.column.id}`}
                      >
                        {cell.column.Header ? (
                          <span className={classNames.columnName}>
                            {cell.column.Header}
                          </span>
                        ) : null}
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="table-pagination">
        <Pagination instance={instance} />
      </div>
    </div>
  );
};
