import { createSlice } from '@reduxjs/toolkit';
import { Filters } from 'react-table';

import { UserListTableType, UserType } from './types';
import { fetchUser, fetchUsers } from './actions';

export type UserState = {
  user: UserType;
  userTableData: UserListTableType[];
  creating: boolean;
  fetching: boolean;
  filters: Filters<UserListTableType>;
};

export const initialState: UserState = {
  user: {
    downPayment: 0,
    monthlyPaymentAmount: 0,
    interestRate: 0.725,
    loanYears: 35,
    desiredTsubo: 0,
    desiredArea: [],
    investigationStatus: '',
    buildArea: [],
    hasLand: false,
    constructionSchedule: '',
    buildingBudget: '',
    interests: [],
    allowInfo: false,
    uid: '',
    name: '',
    kana: '',
    phoneNumber: '',
    address: '',
    birthday: null,
    displayName: '',
    mailAddress: '',
    photoUrl: '',
    // 自動入力
    createDate: '',
    updateDate: '',
    followMakers: [],
    savedActualResults: [],
    clippedActualImages: [],
    reservingEvent: [],
  },
  userTableData: [],
  creating: false,
  fetching: false,
  filters: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.user = initialState.user;
      state.userTableData = initialState.userTableData;
    },
  },
  extraReducers: (builder) => {
    // fetchUsers
    builder.addCase(fetchUsers.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.userTableData = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.userTableData = [];
      state.fetching = false;
    });
    // fetchUser
    builder.addCase(fetchUser.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      if (action.payload) state.user = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchUser.rejected, (state) => {
      state.user = initialState.user;
      state.fetching = false;
    });
  },
});

export const { reset } = userSlice.actions;

export default userSlice;
