import { SortByFn } from 'react-table';
import moment from 'moment';

import { EventListTableType } from '../../../../../re-ducks/event/types';

const sortDate: SortByFn<EventListTableType> = (
  nextRow,
  currentRow,
  columnId,
): 0 | 1 | -1 => {
  const columnKey = columnId as Extract<keyof EventListTableType, string>;
  const next = nextRow.original[columnKey] as string;
  const current = currentRow.original[columnKey] as string;
  if (!next || !current) return -1;

  const nextDate = moment(next).toDate();
  const currentDate = moment(current).toDate();

  return moment(nextDate).isAfter(currentDate) ? 1 : -1;
};

export default sortDate;
