import { useDispatch, useSelector } from 'react-redux';
import React, { ReactElement } from 'react';
import { AiOutlineTags } from 'react-icons/ai';

import IconInformation from '../components/base/Icon/IconInformation';
import { RoleType } from '../../re-ducks/collaborator/types';
import { selectSelectedMaker } from '../../re-ducks/app/selector';
import { setMaker } from '../../re-ducks/maker/slice';
import { PlanKeyType, PLANS } from '../../data/plan';
import { ReactComponent as IconTop } from '../../assets/images/icon/icon_top.svg';
import { ReactComponent as IconProfessional } from '../../assets/images/icon/icon_professional.svg';
import { ReactComponent as IconProject } from '../../assets/images/icon/icon_project.svg';
import { ReactComponent as IconEvent } from '../../assets/images/icon/icon_event.svg';
import { ReactComponent as IconFollower } from '../../assets/images/icon/icon_follower.svg';
import { ReactComponent as IconCollaborator } from '../../assets/images/icon/icon_collaborator.svg';
import { ReactComponent as IconContact } from '../../assets/images/icon/icon_contact.svg';
import { ReactComponent as IconSettings } from '../../assets/images/icon/icon_settings.svg';
import { ReactComponent as IconFAQ } from '../../assets/images/icon/icon_faq.svg';

export interface Link {
  viewableRoles: RoleType[];
  plans?: PlanKeyType[];
  pathName: string;
  mathPath?: string;
  Icon: ReactElement;
  primaryText: string;
  isSideMenu: boolean;
  onClick?: () => void;
}

const allPlans = Object.keys(PLANS) as PlanKeyType[];

export default () => {
  const selectedMaker = useSelector(selectSelectedMaker);
  const dispatch = useDispatch();

  const adminLinks: Link[] = [
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: allPlans,
      pathName: '/',
      Icon: <IconTop />,
      primaryText: 'TOP',
      isSideMenu: true,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: ['free'],
      pathName: '/free-plan',
      Icon: <IconTop />,
      primaryText: 'Freeプランのお客様',
      isSideMenu: false,
    },
    {
      viewableRoles: ['cunelwork'],
      plans: allPlans,
      pathName: '/professional',
      Icon: <IconProfessional />,
      primaryText: '建築事業者・ブランド',
      isSideMenu: true,
    },
    {
      viewableRoles: ['admin'],
      plans: allPlans,
      pathName: `/professional/edit/${selectedMaker?.uid || ''}`,
      mathPath: '/professional/edit/:id',
      Icon: <IconProfessional />,
      primaryText: '建築事業者・ブランド',
      isSideMenu: true,
      onClick: () => selectedMaker && dispatch(setMaker(selectedMaker)),
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: allPlans,
      pathName: '/professional/edit',
      mathPath: '/professional/edit/(/:id)?',
      Icon: <IconProfessional />,
      primaryText: '建築事業者・ブランド',
      isSideMenu: false,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: allPlans,
      pathName: '/project',
      Icon: <IconProject />,
      primaryText: '建築実例',
      isSideMenu: true,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: allPlans,
      pathName: '/project/edit',
      mathPath: '/project/edit/(/:id)?',
      Icon: <IconProject />,
      primaryText: '建築実例',
      isSideMenu: false,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: ['standard'],
      pathName: '/event',
      Icon: <IconEvent />,
      primaryText: 'イベント管理',
      isSideMenu: true,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: ['standard'],
      pathName: '/event/edit',
      mathPath: '/event/edit/(/:id)?',
      Icon: <IconEvent />,
      primaryText: 'イベント管理',
      isSideMenu: false,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: allPlans,
      pathName: '/follower',
      Icon: <IconFollower />,
      primaryText: 'フォロワー一覧',
      isSideMenu: true,
    },
    {
      viewableRoles: ['cunelwork'],
      plans: allPlans,
      pathName: '/collaborator',
      Icon: <IconCollaborator />,
      primaryText: 'コラボレーター',
      isSideMenu: true,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: allPlans,
      pathName: '/makerContacts',
      Icon: <IconContact />,
      primaryText: 'お問い合わせ',
      isSideMenu: true,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: allPlans,
      pathName: `/makerContacts/edit`,
      mathPath: '/makerContacts/detail/:id',
      Icon: <IconContact />,
      primaryText: 'お問い合わせ',
      isSideMenu: false,
    },
    {
      viewableRoles: ['cunelwork', 'admin'],
      plans: allPlans,
      pathName: '/information',
      Icon: <IconInformation size={30} />,
      primaryText: 'お知らせ',
      isSideMenu: true,
    },
    {
      viewableRoles: ['cunelwork', 'admin'],
      plans: allPlans,
      pathName: '/information/detail',
      mathPath: '/information/detail/:id',
      Icon: <IconInformation />,
      primaryText: 'お知らせ',
      isSideMenu: false,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: allPlans,
      pathName: '/my-account',
      Icon: <IconSettings />,
      primaryText: 'アカウント設定',
      isSideMenu: true,
    },
    {
      viewableRoles: ['cunelwork'],
      pathName: '/help',
      Icon: <IconFAQ />,
      primaryText: 'ヘルプ',
      isSideMenu: true,
    },
    {
      viewableRoles: ['cunelwork'],
      pathName: `/help/edit`,
      mathPath: '/help/detail/:id',
      Icon: <IconFAQ />,
      primaryText: 'ヘルプ',
      isSideMenu: false,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      pathName: '/theme',
      plans: allPlans,
      Icon: <AiOutlineTags />,
      primaryText: 'こだわり特集',
      isSideMenu: true,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      pathName: `/theme/edit`,
      mathPath: '/theme/detail/:id',
      Icon: <AiOutlineTags />,
      primaryText: 'こだわり特集',
      isSideMenu: false,
    },
    {
      viewableRoles: ['admin', 'cunelwork'],
      plans: allPlans,
      pathName: `/users/edit`,
      mathPath: '/users/detail/:id',
      Icon: <AiOutlineTags />,
      primaryText: '',
      isSideMenu: false,
    },
  ];

  return adminLinks;
};
