import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Box } from '@material-ui/core';
import { push } from 'connected-react-router';

import LoopFieldSection from '../../../case/form/LoopFieldSection';
import useSnackbar from '../../../../hooks/useSnackbar';
import { useStyles } from './useStyles';
import { Snackbar } from '../../../case/feedback/Snackbar';
import { SaveButton } from '../../../case/button/SaveButton';
import { BackButton } from '../../../case/button/BackButton';
import { DotProgress } from '../../../case/feedback/DotProgress';
import {
  ActualImagesFormType,
  ActualImageType,
} from '../../../../../re-ducks/actualResult/types';
import {
  selectActualImages,
  selectActualResult,
  selectCreating,
  selectFetching,
} from '../../../../../re-ducks/actualResult/selector';
import { createActualImages } from '../../../../../re-ducks/actualResult/actions';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { TextBox } from '../../../base/form/TextBox';
import { STYLE_TAGS } from '../../../../../data/styleTags';
import {
  EXTERIOR_NUM_OF_FLOORS,
  EXTERIOR_ROOF_TYPES,
  FACILITIES_AND_FUNCTIONS,
  FLOOR_LOOKS,
  KITCHEN_STORAGES,
  KITCHEN_TYPES,
  LDK_TYPES,
  ROOM_PLACES,
  ROOM_TYPES,
  WALL_LOOKS,
} from '../../../../../data/roomPlaces';
import { SelectField } from '../../../base/form/SelectField';
import { AddPhoto } from '../../../base/form/AddPhoto';

export const ActualImagesEditForm: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fetching = useSelector(selectFetching);
  const creating = useSelector(selectCreating);
  const actualResult = useSelector(selectActualResult);
  const actualImages = useSelector(selectActualImages);
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();
  const defaultValues = {
    images: actualImages,
  };
  const formMethods = useForm<ActualImagesFormType>({
    criteriaMode: 'all',
    defaultValues,
  });

  const { handleSubmit, control, register, setValue, getValues, watch } =
    formMethods;

  const onSubmit = handleSubmit((data) => {
    const newData = {
      ...defaultValues,
      images: data.images || [],
    };
    dispatch(createActualImages({ formValue: newData, openSnackbar }));
  });

  const isCreatePage = !actualResult.uid;

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  const getRoomPlace = (name: string, roomPlace: string) => {
    const v = watch(name);

    return v === roomPlace || (v as any)?.value === roomPlace;
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit} noValidate>
        {creating ? <DotProgress opening={creating} /> : null}

        <div className={classes.section}>
          <LoopFieldSection<ActualImageType>
            name="images"
            label="画像"
            initialValue={{
              description: '',
              roomPlace: 'exterior',
              styleTags: [],
              wallLooks: [],
              floorLooks: [],
              facilitiesAndFunctions: [],
              exteriorNumOfFloors: [],
              exteriorRoofTypes: [],
              kitchenTypes: [],
              kitchenStorages: [],
              ldkTypes: [],
              roomTypes: [],
              uid: '',
              publishing: false,
              makerId: '',
              actualResultId: '',
              makerName: '',
              actualResultTitle: '',
              imageUrl: '',
              createDate: '',
              updateDate: '',
              clippedUsers: [],
              numOfClippedUsers: 0,
              slug: '',
            }}
          >
            {(baseName, baseError, field) => {
              return (
                <Box className={classes.box}>
                  <input
                    type="hidden"
                    name={`${baseName}.uid`}
                    defaultValue={field.uid || getValues(`${baseName}.uid`)}
                    ref={register()}
                  />
                  <input
                    type="hidden"
                    name={`${baseName}.createDate`}
                    defaultValue={
                      field.createDate || getValues(`${baseName}.createDate`)
                    }
                    ref={register()}
                  />
                  <input
                    type="hidden"
                    name={`${baseName}.slug`}
                    defaultValue={field.slug || getValues(`${baseName}.slug`)}
                    ref={register()}
                  />
                  <div className={classes.imageWrap}>
                    <div className={classes.image}>
                      <AddPhoto
                        name={`${baseName}.imageUrl`}
                        jpegUrl={getValues(`${baseName}.imageUrl`)}
                        setJpegUrl={(url) => {
                          setValue(`${baseName}.imageUrl`, url);
                        }}
                        accept="image/png,image/jpeg"
                      />
                      <ErrorMessage error={baseError?.imageUrl} />
                    </div>
                  </div>
                  <div className={classes.selectors}>
                    <div>
                      <TextBox
                        label="説明文"
                        type="text"
                        name={`${baseName}.description`}
                        InputLabelProps={{ shrink: true }}
                        inputRef={register()}
                        error={!!baseError?.description}
                        defaultValue={field.description}
                      />
                      <ErrorMessage error={baseError?.description} />
                    </div>
                    <div className="mt-5">
                      <p className="is-size-7 mb-1">スタイル</p>
                      <Controller
                        name={`${baseName}.styleTags`}
                        control={control}
                        defaultValue={field?.styleTags?.map((styleTag) => ({
                          label: STYLE_TAGS[styleTag],
                          value: styleTag,
                        }))}
                        render={({ name }) => (
                          <SelectField
                            name={name}
                            defaultValue={field?.styleTags?.map((styleTag) => ({
                              label: STYLE_TAGS[styleTag],
                              value: styleTag,
                            }))}
                            options={Object.entries(STYLE_TAGS).map(
                              ([key, label]) => ({
                                label,
                                value: key,
                              }),
                            )}
                            isMulti
                          />
                        )}
                        isClearable
                      />
                      <ErrorMessage error={baseError?.styleTags} />
                    </div>
                    <div className="mt-5">
                      <p className="is-size-7 mb-1">場所</p>
                      <Controller
                        name={`${baseName}.roomPlace`}
                        control={control}
                        defaultValue={
                          field?.roomPlace
                            ? {
                                label: ROOM_PLACES[field.roomPlace],
                                value: field.roomPlace,
                              }
                            : undefined
                        }
                        render={({ name }) => (
                          <SelectField
                            name={name}
                            defaultValue={
                              field?.roomPlace
                                ? {
                                    label: ROOM_PLACES[field.roomPlace],
                                    value: field.roomPlace,
                                  }
                                : undefined
                            }
                            options={Object.entries(ROOM_PLACES).map(
                              ([key, label]) => ({
                                label,
                                value: key,
                              }),
                            )}
                          />
                        )}
                        isClearable
                      />
                      <ErrorMessage error={baseError?.roomPlace} />
                    </div>
                    <div className={classes.subItems}>
                      {getRoomPlace(`${baseName}.roomPlace`, 'exterior') ? (
                        <>
                          <div className="mt-5">
                            <p className="is-size-7 mb-1">外観 - 階数</p>
                            <Controller
                              name={`${baseName}.exteriorNumOfFloors`}
                              control={control}
                              defaultValue={field?.exteriorNumOfFloors?.map(
                                (value) => ({
                                  label: EXTERIOR_NUM_OF_FLOORS[value],
                                  value,
                                }),
                              )}
                              render={({ name }) => (
                                <SelectField
                                  name={name}
                                  defaultValue={field?.exteriorNumOfFloors?.map(
                                    (value) => ({
                                      label: EXTERIOR_NUM_OF_FLOORS[value],
                                      value,
                                    }),
                                  )}
                                  options={Object.entries(
                                    EXTERIOR_NUM_OF_FLOORS,
                                  ).map(([key, label]) => ({
                                    label,
                                    value: key,
                                  }))}
                                  isMulti
                                />
                              )}
                              isClearable
                            />
                            <ErrorMessage
                              error={baseError?.exteriorNumOfFloors}
                            />
                          </div>
                          <div className="mt-5">
                            <p className="is-size-7 mb-1">外観 - 屋根の種類</p>
                            <Controller
                              name={`${baseName}.exteriorRoofTypes`}
                              control={control}
                              defaultValue={field?.exteriorRoofTypes?.map(
                                (value) => ({
                                  label: EXTERIOR_ROOF_TYPES[value],
                                  value,
                                }),
                              )}
                              render={({ name }) => (
                                <SelectField
                                  name={name}
                                  defaultValue={field?.exteriorRoofTypes?.map(
                                    (value) => ({
                                      label: EXTERIOR_ROOF_TYPES[value],
                                      value,
                                    }),
                                  )}
                                  options={Object.entries(
                                    EXTERIOR_ROOF_TYPES,
                                  ).map(([key, label]) => ({
                                    label,
                                    value: key,
                                  }))}
                                  isMulti
                                />
                              )}
                              isClearable
                            />
                            <ErrorMessage
                              error={baseError?.exteriorRoofTypes}
                            />
                          </div>
                        </>
                      ) : null}
                      {getRoomPlace(`${baseName}.roomPlace`, 'kitchen') ? (
                        <>
                          <div className="mt-5">
                            <p className="is-size-7 mb-1">
                              キッチン - キッチンの種類
                            </p>
                            <Controller
                              name={`${baseName}.kitchenTypes`}
                              control={control}
                              defaultValue={field?.kitchenTypes?.map(
                                (value) => ({
                                  label: KITCHEN_TYPES[value],
                                  value,
                                }),
                              )}
                              render={({ name }) => (
                                <SelectField
                                  name={name}
                                  defaultValue={field?.kitchenTypes?.map(
                                    (value) => ({
                                      label: KITCHEN_TYPES[value],
                                      value,
                                    }),
                                  )}
                                  options={Object.entries(KITCHEN_TYPES).map(
                                    ([key, label]) => ({
                                      label,
                                      value: key,
                                    }),
                                  )}
                                  isMulti
                                />
                              )}
                              isClearable
                            />
                            <ErrorMessage error={baseError?.kitchenTypes} />
                          </div>
                          <div className="mt-5">
                            <p className="is-size-7 mb-1">
                              キッチン - キッチンの収納
                            </p>
                            <Controller
                              name={`${baseName}.kitchenStorages`}
                              control={control}
                              defaultValue={field?.kitchenStorages?.map(
                                (value) => ({
                                  label: KITCHEN_STORAGES[value],
                                  value,
                                }),
                              )}
                              render={({ name }) => (
                                <SelectField
                                  name={name}
                                  defaultValue={field?.kitchenStorages?.map(
                                    (value) => ({
                                      label: KITCHEN_STORAGES[value],
                                      value,
                                    }),
                                  )}
                                  options={Object.entries(KITCHEN_STORAGES).map(
                                    ([key, label]) => ({
                                      label,
                                      value: key,
                                    }),
                                  )}
                                  isMulti
                                />
                              )}
                              isClearable
                            />
                            <ErrorMessage error={baseError?.kitchenStorages} />
                          </div>
                        </>
                      ) : null}
                      {getRoomPlace(`${baseName}.roomPlace`, 'ldk') ? (
                        <div className="mt-5">
                          <p className="is-size-7 mb-1">
                            リビング・ダイニング - リビングの種類
                          </p>
                          <Controller
                            name={`${baseName}.ldkTypes`}
                            control={control}
                            defaultValue={field?.ldkTypes?.map((value) => ({
                              label: LDK_TYPES[value],
                              value,
                            }))}
                            render={({ name }) => (
                              <SelectField
                                name={name}
                                defaultValue={field?.ldkTypes?.map((value) => ({
                                  label: LDK_TYPES[value],
                                  value,
                                }))}
                                options={Object.entries(LDK_TYPES).map(
                                  ([key, label]) => ({
                                    label,
                                    value: key,
                                  }),
                                )}
                                isMulti
                              />
                            )}
                            isClearable
                          />
                          <ErrorMessage error={baseError?.ldkTypes} />
                        </div>
                      ) : null}
                      {getRoomPlace(`${baseName}.roomPlace`, 'room') ? (
                        <div className="mt-5">
                          <p className="is-size-7 mb-1">居室 - 部屋の種類</p>
                          <Controller
                            name={`${baseName}.roomTypes`}
                            control={control}
                            defaultValue={field?.roomTypes?.map((value) => ({
                              label: ROOM_TYPES[value],
                              value,
                            }))}
                            render={({ name }) => (
                              <SelectField
                                name={name}
                                defaultValue={field?.roomTypes?.map(
                                  (value) => ({
                                    label: ROOM_TYPES[value],
                                    value,
                                  }),
                                )}
                                options={Object.entries(ROOM_TYPES).map(
                                  ([key, label]) => ({
                                    label,
                                    value: key,
                                  }),
                                )}
                                isMulti
                              />
                            )}
                            isClearable
                          />
                          <ErrorMessage error={baseError?.roomTypes} />
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-5">
                      <p className="is-size-7 mb-1">壁の見た目</p>
                      <Controller
                        name={`${baseName}.wallLooks`}
                        control={control}
                        defaultValue={field?.wallLooks?.map((wallLook) => ({
                          label: WALL_LOOKS[wallLook],
                          value: wallLook,
                        }))}
                        render={({ name }) => (
                          <SelectField
                            name={name}
                            defaultValue={field?.wallLooks?.map((wallLook) => ({
                              label: WALL_LOOKS[wallLook],
                              value: wallLook,
                            }))}
                            options={Object.entries(WALL_LOOKS).map(
                              ([key, label]) => ({
                                label,
                                value: key,
                              }),
                            )}
                            isMulti
                          />
                        )}
                        isClearable
                      />
                      <ErrorMessage error={baseError?.wallLooks} />
                    </div>
                    <div className="mt-5">
                      <p className="is-size-7 mb-1">床の見た目</p>
                      <Controller
                        name={`${baseName}.floorLooks`}
                        control={control}
                        defaultValue={field?.floorLooks?.map((floorLook) => ({
                          label: FLOOR_LOOKS[floorLook],
                          value: floorLook,
                        }))}
                        render={({ name }) => (
                          <SelectField
                            name={name}
                            defaultValue={field?.floorLooks?.map(
                              (floorLook) => ({
                                label: FLOOR_LOOKS[floorLook],
                                value: floorLook,
                              }),
                            )}
                            options={Object.entries(FLOOR_LOOKS).map(
                              ([key, label]) => ({
                                label,
                                value: key,
                              }),
                            )}
                            isMulti
                          />
                        )}
                        isClearable
                      />
                      <ErrorMessage error={baseError?.floorLooks} />
                    </div>
                    <div className="mt-5">
                      <p className="is-size-7 mb-1">設備・機能</p>
                      <Controller
                        name={`${baseName}.facilitiesAndFunctions`}
                        control={control}
                        defaultValue={field?.facilitiesAndFunctions?.map(
                          (value) => ({
                            label: FACILITIES_AND_FUNCTIONS[value],
                            value,
                          }),
                        )}
                        render={({ name }) => (
                          <SelectField
                            name={name}
                            defaultValue={field?.facilitiesAndFunctions?.map(
                              (value) => ({
                                label: FACILITIES_AND_FUNCTIONS[value],
                                value,
                              }),
                            )}
                            options={Object.entries(
                              FACILITIES_AND_FUNCTIONS,
                            ).map(([key, label]) => ({
                              label,
                              value: key,
                            }))}
                            isMulti
                          />
                        )}
                        isClearable
                      />
                      <ErrorMessage error={baseError?.facilitiesAndFunctions} />
                    </div>
                  </div>
                </Box>
              );
            }}
          </LoopFieldSection>
        </div>

        <div className={classes.buttonWrap}>
          <Box mr={2}>
            <BackButton
              type="button"
              onClick={() => dispatch(push('/project'))}
            >
              一覧へ戻る
            </BackButton>
          </Box>
          <Box>
            <SaveButton type="submit">{`建築実例を${
              isCreatePage ? '作成' : '保存'
            }する`}</SaveButton>
          </Box>
        </div>
        <Snackbar
          variant={snackbarState.variant}
          message={snackbarState.message}
          isOpening={snackbarState.isOpening}
          onClose={closeSnackbar}
        />
      </form>
    </FormProvider>
  );
};
