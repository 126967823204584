import { makeStyles, Theme } from '@material-ui/core';

const drawerWidth = 260;

export const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.common.white,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    width: 260,
    [theme.breakpoints.down('xs')]: {
      width: 120,
    },
  },
  account: {
    position: 'relative',
    marginLeft: 'auto',
  },
  accountButton: {
    [theme.breakpoints.down('xs')]: {
      minWidth: 'initial',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(-1),
    },
  },
  accountText: {
    fontSize: theme.typography.pxToRem(15),
    textTransform: 'initial',
  },
  menuButton: {
    marginRight: theme.spacing(4.5),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
}));
