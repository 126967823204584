const moveArray = (
  array: any[],
  targetIndex: number,
  sourceIndex: number,
): any[] => {
  const items = Array.from(array);
  const source = items.splice(sourceIndex, 1);
  items.splice(targetIndex, 0, ...source);

  return items;
};

export default moveArray;
