import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  OrderQueries,
  WhereQueries,
} from 'my-firebase-wrapper/dist/firestore/types';
import firebase from 'firebase/app';
import { v4 } from 'uuid';
import moment from 'moment';

import { storage } from '../../repositories/firebase';
import { makersRepository } from '../../repositories/firebase/makers';
import { AsyncThunkConfig } from '../store';
import {
  MakerBrandFormType,
  MakerCompanyFormType,
  MakerCunelworkFormType,
  MakerImageFormType,
  MakerType,
} from './types';
import { OpenSnackbar } from '../../presentation/hooks/useSnackbar';
import isDataUrlImage from '../../utils/isDataUrlImage';
import { initialState } from './slice';
import { selectMaker } from '../app/slice';

export const fetchMakerTableData = createAsyncThunk<
  MakerType[],
  {
    whereQueries: WhereQueries<MakerType>;
    orderQueries: OrderQueries<MakerType>;
  },
  AsyncThunkConfig<undefined>
>('maker/fetchMakerTableData', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = makersRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });
    const makersSnapshot: firebase.firestore.QuerySnapshot<MakerType> =
      await collectionRef.get();
    const makerTableData: MakerType[] = makersSnapshot.empty
      ? []
      : makersSnapshot.docs.map((doc) => doc.data());

    return makerTableData.length
      ? makerTableData.map((maker) => {
          return {
            ...initialState.maker,
            ...maker,
          };
        })
      : [];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

const uploadImage = async (maker: MakerType): Promise<MakerType> => {
  const { uid } = maker;
  const timestamp = moment().format('YYYYMMDD_HHmmssSS');

  let logoImgUrl = maker.logoImage;
  if (isDataUrlImage(maker.logoImage)) {
    let logoFileName = '';
    if (maker.logoImage.includes('png')) {
      logoFileName = `logo_${timestamp}.png`;
    } else if (
      maker.logoImage.includes('jpg') ||
      maker.logoImage.includes('jpeg')
    ) {
      logoFileName = `logo_${timestamp}.jpg`;
    }
    const logoSnapshot = await storage
      .ref(`/makers/${uid}/makerImages/${logoFileName}`)
      .putString(maker.logoImage, 'data_url');
    logoImgUrl = await logoSnapshot.ref.fullPath;
    logoImgUrl = `/${logoImgUrl}`;
  }

  const images = await Promise.all(
    maker.images.map(async (image, index) => {
      if (isDataUrlImage(image.url)) {
        const snapshot = await storage
          .ref(`/makers/${uid}/makerImages/slider_${index}_${timestamp}.jpg`)
          .putString(image.url, 'data_url');
        const url = await snapshot.ref.fullPath;

        return { url: `/${url}`, description: image.description };
      }

      return image;
    }),
  );

  const documents = await Promise.all(
    maker.documents.map(async (document, index) => {
      if (isDataUrlImage(document.image)) {
        const snapshot = await storage
          .ref(`/makers/${uid}/makerImages/document_${index}_${timestamp}.jpg`)
          .putString(document.image, 'data_url');
        const url = await snapshot.ref.fullPath;

        return {
          image: `/${url}`,
          title: document.title,
          description: document.description,
        };
      }

      return document;
    }),
  );

  let conceptImgUrl = maker.concept.image;
  if (isDataUrlImage(maker.concept.image)) {
    const conceptSnapshot = await storage
      .ref(`/makers/${uid}/makerImages/concept_${timestamp}.jpg`)
      .putString(maker.concept.image, 'data_url');
    conceptImgUrl = await conceptSnapshot.ref.fullPath;
    conceptImgUrl = `/${conceptImgUrl}`;
  }

  const features = maker.features
    ? await Promise.all(
        maker.features.map(async (feature, nestIndex) => {
          if (!feature.sections) return feature;

          const sections = await Promise.all(
            feature.sections.map(async (section, index) => {
              let url = section.image;
              if (isDataUrlImage(section.image)) {
                const snapshot = await storage
                  .ref(
                    `/makers/${uid}/makerImages/feature_${nestIndex}_${index}_${timestamp}.jpg`,
                  )
                  .putString(section.image, 'data_url');
                url = await snapshot.ref.fullPath;
                url = `/${url}`;
              }

              return { ...section, image: url };
            }),
          );

          return { title: feature.title, sections };
        }),
      )
    : [];

  return {
    ...maker,
    logoImage: logoImgUrl,
    images,
    documents,
    concept: {
      ...maker.concept,
      image: conceptImgUrl,
    },
    features,
  };
};

export const createMaker = createAsyncThunk<
  MakerType,
  {
    formValue:
      | MakerImageFormType
      | MakerBrandFormType
      | MakerCompanyFormType
      | MakerCunelworkFormType;
    openSnackbar: OpenSnackbar;
  },
  AsyncThunkConfig<undefined>
>('maker/createMaker', async (args, thunkAPI) => {
  try {
    const { formValue, openSnackbar } = args;
    const currentValue = thunkAPI.getState().maker.maker;
    const newValue = await uploadImage({ ...currentValue, ...formValue });
    if (!newValue.publishing) {
      newValue.followers = [];
      newValue.numOfFollowers = 0;
    }
    if (!newValue.uid) {
      const uid = v4();
      newValue.uid = uid;
      await makersRepository.doc(uid).set(newValue);
      openSnackbar({
        variant: 'success',
        message: '建築事業者・ブランドの作成が完了しました',
      });
      thunkAPI.dispatch(push('/professional'));
    } else {
      await makersRepository.doc(newValue.uid).set(newValue);
      openSnackbar({
        variant: 'success',
        message: '建築事業者・ブランドの更新が完了しました',
      });
    }
    thunkAPI.dispatch(selectMaker({ maker: newValue }));

    return newValue;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        'アカウントの更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});
