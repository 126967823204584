export interface ActualImage {
  uid: string;
  imageUrl: string;
}

export interface ThemeActualResult {
  uid: string;
  startDate: string | null;
  endDate: string | null;
  makerId: string;
  publishingStatus: keyof typeof PUBLISHING_STATUS;
  billingStatus: keyof typeof BILLING_STATUS;
  staff: typeof STAFF[number];
  actualResultUid: string;
  actualImages: ActualImage[];
  comment: string;
}

export const ThemeCategories = [
  '価格帯・グレード',
  '構造・形態',
  '性能',
  'エコロジー',
  '素材',
  'その他',
] as const;

export const PUBLISHING_STATUS = {
  waiting: '掲載準備中',
  publishing: '掲載中',
  completed: '掲載終了',
} as const;

export const BILLING_STATUS = {
  unclaimed: '未請求',
  billed: '請求済',
  subscription: '毎月請求',
} as const;

export const STAFF = ['崎本', '萱森'] as const;

export interface ThemeType {
  publishing: boolean;
  label: string;
  slug: string;
  description: string;
  imageUrl: string;
  bnrUrl: string;
  actualResults: ThemeActualResult[];
  themeCategory: typeof ThemeCategories[number];
  themeSeo: { title: string; description: string }[];
  // 自動入力
  uid: string;
  order: number;
  createDate: string;
  updateDate: string;
}

export interface PublishActualResult {
  agreementMemo: string;
  requestMemo: string;
  // 自動入力
  uid: string;
  themeId: string;
  publishingStatus: keyof typeof PUBLISHING_STATUS;
  billingStatus: keyof typeof BILLING_STATUS;
  staff: typeof STAFF[number];
  actualResultId: string;
  makerId: string;
  startDate: string | null;
  endDate: string | null;
}

export interface PublishActualResultsTableData {
  uid: string;
  themeName: string;
  actualResultName: string;
  makerName: string;
  startDate: string | null;
  endDate: string | null;
  publishingStatus: keyof typeof PUBLISHING_STATUS;
  billingStatus: keyof typeof BILLING_STATUS;
  staff: typeof STAFF[number];
  agreementMemo: string;
  requestMemo: string;
  publishActualResult: PublishActualResult;
}
