import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    maxWidth: 640,
    margin: `${theme.spacing(6)}px auto 0`,
    padding: `0 ${theme.spacing(2)}px`,
  },
  textButton: {
    color: '#3273dc',
    '&:disabled': {
      color: theme.palette.action.disabled,
    },
  },
  button: {
    display: 'block',
    margin: `${theme.spacing(4)}px auto 0`,
  },
  text: {
    fontWeight: 'bold',
    margin: `0 ${theme.spacing(1)}px`,
  },
}));
