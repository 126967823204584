import defaultBlocklist from './blocklist';

export const strength = [0, 1, 2, 3, 4] as const;
export type Strength = typeof strength[number];

interface EnabledValidation {
  hasUppercase: boolean;
  hasLowercase: boolean;
  hasDigits: boolean;
  hasSymbols: boolean;
}

export class PasswordChecker {
  public strength: Strength = 0;
  private min = 6;
  private max = 64;
  private blocklist = defaultBlocklist;
  private enabledValidation: EnabledValidation = {
    hasUppercase: true,
    hasLowercase: true,
    hasDigits: true,
    hasSymbols: true,
  };

  constructor({
    min,
    max,
    blocklist,
    enabledValidation,
  }: {
    min?: number;
    max?: number;
    blocklist?: string[];
    enabledValidation?: EnabledValidation;
  }) {
    if (min) this.min = min;
    if (max) this.max = max;
    if (blocklist) this.blocklist = blocklist;
    if (enabledValidation)
      this.enabledValidation = {
        ...this.enabledValidation,
        ...enabledValidation,
      };
  }

  // {num}文字以上
  public validateMin(value: string): boolean {
    return value.length >= this.min;
  }

  // {num}文字以下
  public validateMax(value: string): boolean {
    return value.length <= this.max;
  }

  // 大文字が入っている
  public hasUppercase(value: string): boolean {
    const uppercase = /[A-Z]/g;

    return uppercase.test(value);
  }

  // 小文字が入っている
  public hasLowercase(value: string): boolean {
    const lowercase = /[a-z]/g;

    return lowercase.test(value);
  }

  // 数字が入っている
  public hasDigits(value: string): boolean {
    const digits = /[0-9]/g;

    return digits.test(value);
  }

  // 記号が入っている
  public hasSymbols(value: string): boolean {
    const symbols = /[`˜!@#$%^&*()_+\-={}[\]\\|:;"'<>,.?/]/g;

    return symbols.test(value);
  }

  // スペースが入っていない
  public hasNotSpace(value: string): boolean {
    const space = /[\s]/g;

    return !space.test(value);
  }

  // ブロックリストに入っていない
  public hasBlocklist(value: string): boolean {
    return !this.blocklist.includes(value);
  }

  public checkStrength(value: string): Strength {
    const results = [
      this.validateMin(value),
      this.validateMax(value),
      this.hasUppercase(value),
      this.hasLowercase(value),
      this.hasDigits(value),
      this.hasSymbols(value),
    ];
    const strgth = results.filter((v) => v === true).length;

    switch (strgth) {
      case 1:
      case 2: {
        this.strength = 0;
        break;
      }
      case 3: {
        this.strength = 1;
        break;
      }
      case 4: {
        this.strength = 2;
        break;
      }
      case 5: {
        this.strength = 3;
        break;
      }
      case 6: {
        this.strength = 4;
        break;
      }
      default: {
        break;
      }
    }

    return this.strength;
  }
}

export const pwChecker = new PasswordChecker({});
