import React, { FC } from 'react';
import clsx from 'clsx';

import { useStyles } from './useStyles';

export interface PasswordStrengthProps {
  strength: number;
}

export const PasswordStrength: FC<PasswordStrengthProps> = ({ strength }) => {
  const classes = useStyles();
  const strengthLabel = ['とても弱い', '弱い', '普通', '強い', 'とても強い'];
  const strengthClasses = [
    'very-week',
    'week',
    'good',
    'strong',
    'very-strong',
  ];

  return (
    <dl className={clsx(classes.root, strengthClasses[strength])}>
      <dt>パスワードの強度</dt>
      <dd>{strengthLabel[strength]}</dd>
    </dl>
  );
};
