import React, { FC } from 'react';

import { PageTitle } from '../../case/PageTitle';
import { AdminWrapper } from '../../domain/AdminWrapper';
import IconInformation from '../../base/Icon/IconInformation';
import Detail from '../../domain/InformationDetail';

const InformationDetail: FC = () => {
  return (
    <AdminWrapper>
      <PageTitle Icon={<IconInformation size={32} />}>お知らせ</PageTitle>
      <Detail />
    </AdminWrapper>
  );
};

export default InformationDetail;
