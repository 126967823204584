import React, { FC } from 'react';

import MuiSnackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export type Variant = 'error' | 'warning' | 'success';

export interface SnackbarProps {
  variant: Variant;
  message: string;
  onClose: () => void;
  isOpening: boolean;
  anchorOrigin?: SnackbarOrigin;
  className?: string;
}

export const Snackbar: FC<SnackbarProps> = ({
  variant,
  message,
  onClose,
  isOpening,
  anchorOrigin,
  className,
}) => {
  return (
    <MuiSnackbar
      open={isOpening}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      classes={{ root: className }}
    >
      <Alert onClose={onClose} severity={variant}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};
