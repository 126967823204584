import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectDrawerOpening } from '../../re-ducks/app/selector';
import useMobileSize from './useMobileSize';

const useDrawerOpening = () => {
  const drawerOpening = useSelector(selectDrawerOpening);
  const [opening, setOpening] = useState(drawerOpening);
  const { isMobileSize } = useMobileSize();

  useEffect(() => {
    if (!isMobileSize) {
      setOpening(drawerOpening);

      return;
    }

    setOpening(false);
  }, [isMobileSize]);

  useEffect(() => {
    setOpening(drawerOpening);
  }, [drawerOpening]);

  return { opening };
};

export default useDrawerOpening;
