import { Box, Switch, Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { useEffectOnce } from 'react-use';
import { useFormContext, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { SaveButton } from '../../../case/button/SaveButton';
import { BackButton } from '../../../case/button/BackButton';
import { DefinitionList } from '../../../base/DefinitionList';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { RadioButton } from '../../../base/form/RadioButton';
import { TextBox } from '../../../base/form/TextBox';
import { RequiredText } from '../../../base/typography/RequiredText';
import { SelectField } from '../../../base/form/SelectField';
import { DotProgress } from '../../../case/feedback/DotProgress';
import {
  selectCreating,
  selectFetching,
  selectFaqCategories,
  selectSelectedFaq,
} from '../../../../../re-ducks/faq/selector';
import {
  createFaqCategory,
  createFaqItem,
  fetchFaqCategories,
} from '../../../../../re-ducks/faq/actions';
import { FaqTypes, FAQ } from '../../../../../re-ducks/faq/types';
import Editor from '../../Editor';
import { useStyles } from './useStyles';
import useSnackbar from '../../../../hooks/useSnackbar';
import { Snackbar } from '../../../case/feedback/Snackbar';

interface Props {
  defaultValues: FAQ;
}

const FaqForm: VFC<Props> = ({ defaultValues }) => {
  const { register, control, errors, watch, setValue, handleSubmit } =
    useFormContext();
  const dispatch = useDispatch();
  const selectedFaq = useSelector(selectSelectedFaq);
  const faqCategories = useSelector(selectFaqCategories);
  const creating = useSelector(selectCreating);
  const fetching = useSelector(selectFetching);
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffectOnce(() => {
    dispatch(fetchFaqCategories());
  });

  const isCreatePage = !selectedFaq?.uid;
  const selectedCategory =
    faqCategories.find((cat) => cat.uid === selectedFaq.category)?.label || '';

  return (
    <form
      onSubmit={handleSubmit((data: FAQ) => {
        dispatch(createFaqItem({ faq: data, openSnackbar }));
      })}
    >
      <DotProgress opening={creating} />
      <Snackbar
        variant={snackbarState.variant}
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        onClose={closeSnackbar}
      />
      <Box position="relative" zIndex={2}>
        <input
          type="hidden"
          name="uid"
          value={selectedFaq.uid}
          ref={register}
        />
        <DefinitionList
          components={[
            {
              dt: <Typography>公開状態</Typography>,
              dd: (
                <div>
                  <Switch
                    color="primary"
                    defaultChecked={defaultValues.publishing}
                    name="publishing"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    inputRef={register}
                  />
                </div>
              ),
            },
            {
              dt: <RequiredText>タイトル</RequiredText>,
              dd: (
                <div>
                  <TextBox
                    type="text"
                    name="title"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: 'タイトルを入力してください',
                    })}
                    error={!!errors?.title}
                  />
                  <ErrorMessage error={errors?.title} />
                </div>
              ),
            },
            {
              dt: <RequiredText>Slug</RequiredText>,
              dd: (
                <div>
                  <TextBox
                    type="text"
                    name="slug"
                    InputLabelProps={{ shrink: true }}
                    inputRef={register({
                      required: 'Slugを入力してください',
                      validate: {
                        space: (value) => {
                          return !value.match(/\s|\u3000/)
                            ? true
                            : '空白文字は入力できません';
                        },
                      },
                    })}
                    error={!!errors?.slug}
                  />
                  <ErrorMessage error={errors?.slug} />
                </div>
              ),
            },
            {
              dt: <RequiredText>ヘルプのターゲット</RequiredText>,
              dd: (
                <div>
                  {Object.entries(FaqTypes).map(([key, value]) => {
                    return (
                      <RadioButton
                        key={key}
                        label={value}
                        radioProps={{
                          name: 'faqType',
                          value: key,
                          checked: key === watch('faqType'),
                          inputRef: register,
                          onChange: (event) => {
                            setValue('faqType', event.target.value, {
                              shouldValidate: true,
                            });
                          },
                        }}
                      />
                    );
                  })}
                </div>
              ),
            },
            {
              dt: <RequiredText>ヘルプのカテゴリー</RequiredText>,
              dd: (
                <div>
                  <Controller
                    name="category"
                    control={control}
                    defaultValue={{
                      label: selectedCategory,
                      value: selectedFaq.category,
                    }}
                    rules={{
                      validate: {
                        required: (value) =>
                          value ? true : 'カテゴリーを選択してください',
                        validType: (value) =>
                          faqCategories.find(
                            (category) => category.uid === value,
                          )?.faqType === watch('faqType')
                            ? true
                            : '選択している「FAQの種類」に属していないカテゴリーです',
                      },
                    }}
                    render={({ name }) => (
                      <SelectField
                        name={name}
                        options={faqCategories
                          .filter(
                            (category) => category.faqType === watch('faqType'),
                          )
                          .map((category) => ({
                            label: category.label,
                            value: category.uid,
                          }))}
                        defaultValue={{
                          label: selectedCategory,
                          value: selectedFaq.category,
                        }}
                        isLoading={fetching || creating}
                        onCreate={(inputValue) =>
                          dispatch(
                            createFaqCategory({
                              value: inputValue,
                              faqType: watch('faqType'),
                            }),
                          )
                        }
                      />
                    )}
                    isClearable
                  />
                  <ErrorMessage error={errors.category} />
                </div>
              ),
            },
          ]}
        />
      </Box>
      <Box mt={2} position="relative" zIndex={1}>
        <Editor editorName="content" defaultValue={defaultValues.content} />
      </Box>
      <div className={classes.buttonWrap}>
        <Box mr={2}>
          <BackButton type="button" onClick={() => dispatch(push('/help'))}>
            一覧へ戻る
          </BackButton>
        </Box>
        <Box>
          <SaveButton type="submit">{`FAQを${
            isCreatePage ? '作成' : '保存'
          }する`}</SaveButton>
        </Box>
      </div>
    </form>
  );
};

export default FaqForm;
