import React, { VFC } from 'react';
import { useSelector } from 'react-redux';
import { CellProps } from 'react-table';
import { isAfter, isBefore } from 'date-fns';
import { selectMakerContacts } from '../../../../../re-ducks/makerContacts/selector';

export const DocumentRequest: VFC<CellProps<any>> = ({ cell }) => {
  const makerContacts = useSelector(selectMakerContacts);
  const { inquiryType, makerName, makerNames, date, uid } = cell.row.original;
  const { userId, makerId } = cell.row.original.contactData;

  // 該当ユーザーが既に資料請求・一括資料請求しているビルダー名
  const alreadyMakerNames: string[] = [];

  // お問い合わせ
  makerContacts.map((makerContact) => {
    const { contactData } = makerContact;
    if (
      !contactData ||
      makerContact.uid === uid ||
      isBefore(new Date(date), new Date(makerContact.date))
    )
      return false;

    // console.log(makerContact.uid);
    const isSameUserId = contactData.userId === userId;
    if (!['資料請求', '一括資料請求'].includes(inquiryType) || !isSameUserId)
      return false;

    if (inquiryType === '一括資料請求') {
      makerNames?.map((name: string) => {
        if (
          makerContact.makerNames?.includes(name) &&
          !alreadyMakerNames.includes(name)
        ) {
          alreadyMakerNames.push(name);
        }

        return false;
      });

      return false;
    }

    if (
      (contactData.makerId === makerId ||
        contactData?.makerIds?.includes(makerId)) &&
      !alreadyMakerNames.includes(makerName)
    ) {
      alreadyMakerNames.push(makerName);
    }

    return false;
  });

  return (
    <ul data-row={uid} data-user={userId}>
      {alreadyMakerNames.map((n) => (
        <li key={n}>{`「${n}」に資料請求をしています`}</li>
      ))}
    </ul>
  );
};
