import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';

import { db } from './index';
import { convertToDateFromTimestamp } from '../../utils/dateFormats';
import { UserType } from '../../re-ducks/user/types';
import { initialState } from '../../re-ducks/user/slice';

export const usersRepository: Collection<UserType> = db.collection<UserType>({
  collectionPath: 'users',
  decode: (doc) => {
    const data = doc.data();
    if (!data) return initialState.user;

    return {
      ...data,
      createDate: convertToDateFromTimestamp(data.createDate) || '',
      updateDate: convertToDateFromTimestamp(data.updateDate) || '',
      birthday: convertToDateFromTimestamp(data.birthday) || '',
    };
  },
});
