import { ValueOf } from '../types/utility';

export const STYLE_TAGS = {
  simple: 'シンプル・ナチュラル',
  modern: 'モダン',
  vintage: 'ヴィンテージ',
  organic: '自然素材',
  industrial: 'インダストリアル・ブルックリンスタイル',
  luxury: 'ラグジュアリー',
  japanese: '和風・和モダン',
  'northern-europe': '北欧風',
  'southern-europe': '南欧風',
  'west-coast': '西海岸風',
} as const;

export type StyleTagKeyType = keyof typeof STYLE_TAGS;
export type StyleTagValueType = ValueOf<typeof STYLE_TAGS>;
