import moment from 'moment';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  Typography,
  Box,
  Switch,
  InputAdornment,
  Link,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { BiLinkExternal } from 'react-icons/bi';

import { THEMES } from '../../../../../data/themes';
import { formats } from '../../../../../data/date';
import useSnackbar from '../../../../hooks/useSnackbar';
import { useStyles } from './useStyles';
import { Snackbar } from '../../../case/feedback/Snackbar';
import { SaveButton } from '../../../case/button/SaveButton';
import { BackButton } from '../../../case/button/BackButton';
import { DotProgress } from '../../../case/feedback/DotProgress';
import FormTable from '../../../case/form/FormTable';
import TableRow from '../../../case/form/FormTable/TableRow';
import { RequiredText } from '../../../base/typography/RequiredText';
import { TextBox } from '../../../base/form/TextBox';
import { DateField } from '../../../base/form/DateField';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { DefinitionList } from '../../../base/DefinitionList';
import { ActualResultFormType } from '../../../../../re-ducks/actualResult/types';
import {
  selectActualResult,
  selectCreating,
} from '../../../../../re-ducks/actualResult/selector';
import { createActualResult } from '../../../../../re-ducks/actualResult/actions';
import { selectMakerTableData } from '../../../../../re-ducks/maker/selector';
import { SelectField } from '../../../base/form/SelectField';
import { ActualResultContents } from '../ActualResultContents';
import { selectSelectedMaker } from '../../../../../re-ducks/app/selector';
import { selectRole } from '../../../../../re-ducks/collaborator/selector';
import { fetchMakerTableData } from '../../../../../re-ducks/maker/actions';
import { InputLabel } from '../../../base/form/InputLabel';
import { TextField } from '../../../base/form/TextField';
import AreaSelect from '../../../case/form/AreaSelect';
import FormSection from '../../../case/form/FormSection';
import Tooltip from '../../../base/Tooltip';

export const ActualResultEditForm: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedMaker = useSelector(selectSelectedMaker);
  const makers = useSelector(selectMakerTableData);
  const myRole = useSelector(selectRole);
  const creating = useSelector(selectCreating);
  const actualResult = useSelector(selectActualResult);
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();
  const defaultValues = {
    makerId:
      myRole === 'admin'
        ? selectedMaker?.uid || actualResult.makerId
        : actualResult.makerId || '',
    makerName:
      myRole === 'admin'
        ? selectedMaker?.name || actualResult.makerName
        : actualResult.makerName || '',
    makerSlug:
      myRole === 'admin'
        ? selectedMaker?.slug || actualResult.makerSlug
        : actualResult.makerSlug || '',
    publishing: actualResult.publishing,
    managementId: actualResult.managementId,
    title: actualResult.title,
    prefecture: actualResult.prefecture,
    actualArea: actualResult.actualArea,
    buildingType: actualResult.buildingType,
    floorSquare: actualResult.floorSquare,
    siteSquare: actualResult.siteSquare,
    familyStructure: actualResult.familyStructure,
    layout: actualResult.layout,
    vibrationResistance: actualResult.vibrationResistance,
    thermalInsulation: actualResult.thermalInsulation,
    actualDate: actualResult.actualDate,
    unitPriceInfo: actualResult.unitPriceInfo,
    contents: actualResult.contents,
  };
  const formMethods = useForm<ActualResultFormType>({
    criteriaMode: 'all',
    defaultValues,
  });

  const { register, handleSubmit, errors, control, watch, setValue } =
    formMethods;

  const onSubmit = handleSubmit((data) => {
    const newData = {
      ...defaultValues,
      ...data,
    };
    dispatch(
      createActualResult({
        formValue: newData,
        maker:
          myRole === 'cunelwork'
            ? makers.find((maker) => maker.uid === watch('makerId'))
            : undefined,
        openSnackbar,
      }),
    );
  });

  const isCreatePage = !actualResult.uid;

  useEffect(() => {
    if (myRole === 'cunelwork') {
      dispatch(fetchMakerTableData({ whereQueries: [], orderQueries: [] }));
    }
  }, []);

  return (
    <FormProvider {...formMethods}>
      {creating ? <DotProgress opening={creating} /> : null}
      <form onSubmit={onSubmit} noValidate className={classes.wrapper}>
        <Box className={classes.sidebar}>
          {selectedMaker?.publishing && selectedMaker?.slug ? (
            <Typography variant="body2" className="mb-5">
              <Link
                href={`https://mockhouse.jp/professional/${selectedMaker.slug}/`}
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
                className={classes.detailPage}
              >
                <BiLinkExternal size={20} />
                詳細ページにアクセスする
              </Link>
            </Typography>
          ) : null}

          {myRole === 'cunelwork' ? (
            <div>
              <InputLabel className="mb-2" required>
                建築事業者・ブランド
              </InputLabel>
              <Controller
                name="makerId"
                control={control}
                rules={{
                  required: 'ビルダーを選択してください',
                }}
                render={({ name }) => (
                  <SelectField
                    name={name}
                    defaultValue={
                      defaultValues.makerId
                        ? {
                            label:
                              makers.find(
                                (maker) => maker.uid === defaultValues.makerId,
                              )?.name || '',
                            value: defaultValues.makerId,
                          }
                        : null
                    }
                    options={makers.map((maker) => ({
                      label: maker.name,
                      value: maker.uid,
                    }))}
                  />
                )}
                isClearable
              />
              <ErrorMessage error={errors?.makerId} />
            </div>
          ) : (
            <div>
              <input
                type="hidden"
                name="makerId"
                value={selectedMaker?.uid || ''}
                ref={register}
              />
              <ErrorMessage error={errors?.makerId} />
            </div>
          )}

          <div className="mt-4">
            <InputLabel>公開状態</InputLabel>
            <Switch
              color="primary"
              defaultChecked={defaultValues.publishing}
              name="publishing"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              inputRef={register}
            />
          </div>

          <div className="mt-4">
            <TextField
              type="text"
              label="管理番号"
              name="managementId"
              inputRef={register}
              error={!!errors?.managementId}
            />
            <ErrorMessage error={errors?.managementId} />
          </div>

          <div className="mt-4">
            <TextField
              type="text"
              label="タイトル"
              name="title"
              inputRef={register({
                required: 'タイトルを入力してください',
              })}
              required
              error={!!errors?.title}
            />
            <ErrorMessage error={errors?.title} />
          </div>

          <div className="mt-4">
            <InputLabel className="mb-2" required>
              施工エリア
            </InputLabel>
            <AreaSelect
              name="actualArea"
              defaultValue={defaultValues.actualArea}
            />
          </div>

          <div className={classes.buttonWrap}>
            <SaveButton type="submit">
              {isCreatePage ? '作成' : '保存'}
            </SaveButton>
            <BackButton
              type="button"
              onClick={() => dispatch(push('/project'))}
            >
              一覧へ戻る
            </BackButton>
          </div>
        </Box>

        <Box className={classes.main}>
          <FormSection sectionTitle="コンセプト">
            <ActualResultContents />
          </FormSection>

          <FormSection sectionTitle="仕様">
            <FormTable>
              <TableRow label={<InputLabel>施工時期</InputLabel>}>
                <div>
                  <Controller
                    name="actualDate"
                    control={control}
                    render={({ name }) => (
                      <DateField
                        DatePickerProps={{
                          name,
                          value: watch('actualDate')
                            ? moment(watch('actualDate')).toDate()
                            : null,
                          format: formats[0],
                          error: !!errors.actualDate,
                          onChange: (v) => setValue(name, v?.toDate()),
                        }}
                      />
                    )}
                    isClearable
                  />
                  <ErrorMessage error={errors?.actualDate} />
                </div>
              </TableRow>
              <TableRow label={<InputLabel>建物種別</InputLabel>}>
                <TextBox
                  type="text"
                  name="buildingType"
                  InputLabelProps={{ shrink: true }}
                  inputRef={register}
                  error={!!errors?.buildingType}
                  placeholder="例）木造2階建て(SE構法)"
                />
                <ErrorMessage error={errors?.buildingType} />
              </TableRow>
              <TableRow
                label={<InputLabel noAsterisk>面積</InputLabel>}
                verticalAlign="top"
              >
                <div className={classes.square}>
                  <div>
                    <InputLabel>延床面積</InputLabel>
                    <TextField
                      type="number"
                      name="floorSquare"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">坪</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        step: 0.01,
                      }}
                      inputRef={register}
                      error={!!errors?.floorSquare}
                    />
                    <ErrorMessage error={errors?.floorSquare} />
                  </div>
                  <div>
                    <InputLabel>敷地面積</InputLabel>
                    <TextField
                      type="number"
                      name="siteSquare"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">坪</InputAdornment>
                        ),
                      }}
                      inputProps={{
                        step: 0.01,
                      }}
                      inputRef={register}
                      error={!!errors?.siteSquare}
                    />
                    <ErrorMessage error={errors?.siteSquare} />
                  </div>
                </div>
              </TableRow>
              <TableRow label={<InputLabel>家族構成</InputLabel>}>
                <TextField
                  type="text"
                  name="familyStructure"
                  inputRef={register}
                  error={!!errors?.familyStructure}
                  placeholder="5人家族"
                />
                <ErrorMessage error={errors?.familyStructure} />
              </TableRow>
              <TableRow label={<InputLabel>間取り</InputLabel>}>
                <TextField
                  type="text"
                  name="layout"
                  inputRef={register}
                  error={!!errors?.layout}
                  placeholder="2LDK"
                />
                <ErrorMessage error={errors?.layout} />
              </TableRow>
              <TableRow label={<InputLabel>耐震性</InputLabel>}>
                <TextField
                  type="text"
                  name="vibrationResistance"
                  inputRef={register}
                  error={!!errors?.vibrationResistance}
                />
                <ErrorMessage error={errors?.vibrationResistance} />
              </TableRow>
              <TableRow label={<InputLabel>断熱性</InputLabel>}>
                <TextField
                  type="text"
                  name="thermalInsulation"
                  inputRef={register}
                  error={!!errors?.thermalInsulation}
                />
                <ErrorMessage error={errors?.thermalInsulation} />
              </TableRow>
            </FormTable>
          </FormSection>
        </Box>

        <Snackbar
          variant={snackbarState.variant}
          message={snackbarState.message}
          isOpening={snackbarState.isOpening}
          onClose={closeSnackbar}
        />
      </form>
    </FormProvider>
  );
};
