import React, { FC } from 'react';
import clsx from 'clsx';
import { Button, ButtonProps } from '@material-ui/core';

import { useStyles } from './useStyles';

export const ButtonPrimary: FC<ButtonProps> = (props) => {
  const { variant = 'contained' } = props;
  const classes = useStyles();

  return (
    <Button
      color="primary"
      {...props}
      variant={variant}
      classes={{ root: classes.root }}
      className={clsx(
        { [`${classes.contained}`]: variant === 'contained' },
        { [`${classes.outlined}`]: variant === 'outlined' },
      )}
    />
  );
};
