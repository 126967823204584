import { Box, Switch } from '@material-ui/core';
import React, { VFC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { AddPhoto } from '../../../base/form/AddPhoto';
import { SaveButton } from '../../../case/button/SaveButton';
import { BackButton } from '../../../case/button/BackButton';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { DotProgress } from '../../../case/feedback/DotProgress';
import {
  selectCreating,
  selectFetching,
  selectTheme,
} from '../../../../../re-ducks/themes/selector';
import {
  createPublishActualResults,
  createTheme,
} from '../../../../../re-ducks/themes/actions';
import { useStyles } from './useStyles';
import useSnackbar from '../../../../hooks/useSnackbar';
import { Snackbar } from '../../../case/feedback/Snackbar';
import {
  ThemeCategories,
  ThemeType,
} from '../../../../../re-ducks/themes/types';
import FormSection from '../../../case/form/FormSection';
import FormTable from '../../../case/form/FormTable';
import TableRow from '../../../case/form/FormTable/TableRow';
import { InputLabel } from '../../../base/form/InputLabel';
import { TextField } from '../../../base/form/TextField';
import { ActualResults } from './ActualResults';
import { SelectField } from '../../../base/form/SelectField';
import { ThemeSeo } from './ThemeSeo';

interface Props {
  defaultValues: ThemeType;
}

const ThemeForm: VFC<Props> = ({ defaultValues }) => {
  const { register, errors, control, setValue, handleSubmit } =
    useFormContext();
  const dispatch = useDispatch();
  const selectedTheme = useSelector(selectTheme);
  const creating = useSelector(selectCreating);
  const fetching = useSelector(selectFetching);
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();
  const classes = useStyles();

  const isCreatePage = !selectedTheme?.uid;

  return (
    <form
      onSubmit={handleSubmit((data: ThemeType) => {
        dispatch(
          createTheme({
            formValue: { ...data, actualResults: data.actualResults || [] },
            openSnackbar,
          }),
        );
      })}
      noValidate
    >
      <DotProgress opening={creating} />
      <Snackbar
        variant={snackbarState.variant}
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        onClose={closeSnackbar}
      />
      <Box position="relative" zIndex={2}>
        <input
          type="hidden"
          name="uid"
          value={selectedTheme.uid}
          ref={register}
        />
        <FormSection sectionTitle="テーマ情報">
          <div>
            <InputLabel required>公開状態</InputLabel>
            <Switch
              color="primary"
              defaultChecked={defaultValues.publishing}
              name="publishing"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              inputRef={register}
            />
          </div>
          <div className="mt-4">
            <InputLabel className="mb-2" required>
              テーマカテゴリ
            </InputLabel>
            <Controller
              name="themeCategory"
              control={control}
              render={({ name }) => (
                <SelectField
                  name={name}
                  defaultValue={
                    defaultValues.themeCategory
                      ? {
                          label: defaultValues.themeCategory,
                          value: defaultValues.themeCategory,
                        }
                      : null
                  }
                  options={ThemeCategories.map((category) => ({
                    label: category,
                    value: category,
                  }))}
                />
              )}
              isClearable
            />
            <ErrorMessage error={errors.companyType} />
          </div>
          <div className="mt-4">
            <TextField
              label="テーマ"
              type="text"
              name="label"
              inputRef={register({ required: 'テーマを入力してください' })}
              placeholder=""
              error={!!errors?.label}
              required
            />
            <ErrorMessage error={errors?.label} />
          </div>
          <div className="mt-5">
            <TextField
              label="Slug"
              type="text"
              name="slug"
              inputRef={register({ required: 'スラッグを入力してください' })}
              placeholder=""
              error={!!errors?.slug}
              required
            />
            <ErrorMessage error={errors?.slug} />
          </div>
          <div className="mt-5">
            <TextField
              label="説明文"
              type="textarea"
              name="description"
              inputRef={register({ required: '説明文を入力してください' })}
              placeholder=""
              error={!!errors?.description}
              rows={4}
              multiline
              required
            />
            <ErrorMessage error={errors?.description} />
          </div>
          <div className="mt-5">
            <InputLabel required>画像</InputLabel>
            <div style={{ width: 300, height: 200 }} className="mt-4">
              <AddPhoto
                name="imageUrl"
                setJpegUrl={(url) => {
                  setValue('imageUrl', url, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                accept="image/png,image/jpeg"
                registerOption={{
                  required: 'テーマ画像をアップロードしてください',
                }}
              />
            </div>
            <ErrorMessage error={errors?.imageUrl} />
          </div>
          <div className="mt-5">
            <InputLabel required>バナー画像</InputLabel>
            <div style={{ width: 360, height: 70 }} className="mt-4">
              <AddPhoto
                name="bnrUrl"
                setJpegUrl={(url) => {
                  setValue('bnrUrl', url, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                }}
                accept="image/png,image/jpeg"
                registerOption={{
                  required: 'テーマ画像をアップロードしてください',
                }}
              />
            </div>
            <ErrorMessage error={errors?.bnrUrl} />
          </div>
          <div className="mt-5">
            <InputLabel className="mb-4" required>
              テーマ用SEO説明文
            </InputLabel>
            <div className={classes.themeSeo}>
              <ThemeSeo />
            </div>
          </div>
        </FormSection>
        <FormSection sectionTitle="建築実例の選択">
          <ActualResults defaultValues={defaultValues} />
        </FormSection>
      </Box>
      <div className={classes.buttonWrap}>
        <Box mr={2}>
          <BackButton type="button" onClick={() => dispatch(push('/theme'))}>
            一覧へ戻る
          </BackButton>
        </Box>
        <Box>
          <SaveButton type="submit">{`テーマを${
            isCreatePage ? '作成' : '保存'
          }する`}</SaveButton>
        </Box>
      </div>
    </form>
  );
};

export default ThemeForm;
