import React, { VFC } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { PageTitle } from '../../case/PageTitle';
import { AddButton } from '../../case/button/AddButton';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { ActualResultListTable } from '../../domain/actualResult/ActualResultListTable';
import { initialState } from '../../../../re-ducks/actualResult/slice';
import { setActualResult } from '../../../../re-ducks/actualResult/actions';
import IconProject from '../../base/Icon/IconProject';

export const Project: VFC = () => {
  const dispatch = useDispatch();

  return (
    <AdminWrapper>
      <PageTitle Icon={<IconProject />}>建築実例</PageTitle>
      <AddButton
        label="建築実例を追加する"
        onClick={() => {
          dispatch(setActualResult(initialState.actualResult));
          dispatch(push('/project/edit'));
        }}
      />
      <ActualResultListTable />
    </AdminWrapper>
  );
};
