import React, { FC } from 'react';

import IconSettings from '../../base/Icon/IconSettings';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { PageTitle } from '../../case/PageTitle';
import { MyAccountForm } from '../../case/form/MyAccountForm';

export const MyAccount: FC = () => {
  return (
    <AdminWrapper>
      <PageTitle Icon={<IconSettings />}>アカウント設定</PageTitle>
      <MyAccountForm />
    </AdminWrapper>
  );
};
