import React, { VFC } from 'react';
import Image from './Image';
import { BlockComponentProps } from './types';

const CustomBlock: VFC<BlockComponentProps> = (props) => {
  const { contentState, block } = props;
  const entity = contentState.getEntity(block.getEntityAt(0));
  const type = entity.getType();
  let media = null;

  if (type === 'image') {
    media = <Image {...props} />;
  }

  return media;
};

export default CustomBlock;
