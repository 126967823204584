import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { CollaboratorState } from './slice';

export const selectSelf = (state: RootState): CollaboratorState => state.maker;

export const selectFetching = createSelector(
  selectSelf,
  (state) => state.fetching,
);
export const selectMaker = createSelector(selectSelf, (state) => state.maker);
export const selectMakerTableData = createSelector(
  selectSelf,
  (state) => state.makerTableData,
);
export const selectCreating = createSelector(
  selectSelf,
  (state) => state.creating,
);
