import { StringMappingType } from 'typescript';
import { PlanKeyType } from '../../data/plan';

export const coverageWorks = {
  'custom-house': '注文住宅',
  renovation: 'リノベーション',
} as const;
export type CoverageWorkTypes = keyof typeof coverageWorks;

export const AMOUNTS = [
  '1,000万円未満',
  '1,000〜1,500万円',
  '1,500〜2,000万円',
  '2,000〜2,500万円',
  '2,500〜3,000万円',
  '3,000〜3,500万円',
  '3,500〜4,000万円',
  '4,000〜4,500万円',
  '4,500〜5,000万円',
  '5,000万円以上',
] as const;

export const COMPANY_TYPES = {
  builder: '工務店',
  'housing-makers': 'ハウスメーカー',
  'architect-office': '設計事務所',
} as const;

export const PERFORMANCE_STANDARDS = {
  'long-term-excellent-house': '長期優良住宅',
  zeh: 'ZEH（ゼッチ）',
  'low-carbon-housing': '低炭素認定住宅',
} as const;

export const SUPPORT_MEETINGS = {
  online: 'オンライン対応可能',
  'meeting-at-home': 'ご自宅での打ち合わせ可能',
  'play-corner': 'キッズスペースあり',
} as const;

export const SUPPORT_MONIES = {
  'financial-planning-consultation': '資金計画の相談が可能',
  'loan-tax-consultation': '住宅ローン・税制の相談可能',
  'affiliated-loan': '提携ローンあり',
} as const;

export const SUPPORT_LANDS = {
  'land-search-consultation': '土地探しの相談が可能',
  'owned-land': '自社所有の土地あり',
} as const;

export const TSUBO_PRICES = {
  0: '未入力',
  10: '10万円台',
  20: '20万円台',
  30: '30万円台',
  40: '40万円台',
  50: '50万円台',
  60: '60万円台',
  70: '70万円台',
  80: '80万円台以上',
} as const;

export type TSUBO_PRICE_TYPE = keyof typeof TSUBO_PRICES;

export const STRENGTHS = {
  'design-and-styling': 'デザイン力',
  'floor-planning': '設計・間取り提案力',
  'designer-respond-directly': '設計士が直接担当',
  'advanced-tech': '自社施工による技術力',
  'cost-performance': 'コストパフォーマンス',
  'after-sales-service': '保証・アフターサービス',
} as const;

export const SPECIALIZED_FIELDS = {
  'natural-materials': '自然素材の家',
  'design-house': 'デザイン住宅',
  'eco-house': '省エネ・エコ住宅',
  'multi-generational-house': '二世帯住宅',
  'compact-house': 'コンパクトハウス',
  'high-performance-house': '高気密・高断熱',
  'low-cost-house': 'ローコスト住宅',
  'house-with-garden': '庭のある家',
  'high-earthquake-resistance': '地震に強い家',
  'excellent-housework-leads': '家事導線・子育てのしやすい家',
} as const;

export interface Amount {
  label: CoverageWorkTypes;
  amount: typeof AMOUNTS[keyof typeof AMOUNTS];
}

export interface Concept {
  title: string;
  youtube: string;
  image: string;
  text: string;
}

export interface FeatureSection {
  image: string;
  title: string;
  text: string;
}

export interface Feature {
  title: string;
  sections: FeatureSection[];
}

export interface SalesOffice {
  name: string;
  postalCode: string;
  address: string;
  googleMapUrl: string;
  area: string;
  mailAddress: string;
  phoneNumber: string;
}

export interface Image {
  url: string;
  description: string;
}

export interface Document {
  title: string;
  description: string;
  image: string;
}

export interface WordComment {
  title: string;
  description: string;
}

export interface IndividualMeetingPlace {
  image: string;
  name: string;
  description: string;
  place: string;
  businessHours: string;
  regularClosingDay: string;
}

export interface MakerImageFormType {
  logoImage: string;
  images: Image[];
}

export interface MakerBrandFormType {
  name: string;
  concept: Concept;
  features: Feature[];
  coverageWorks: CoverageWorkTypes[];
  approximateAmounts: Amount[];
  coverageAreas: string[];
  mailAddress: string;
  phoneNumber: string;
  inquiryAnnotation: string;
  performanceStandards: (keyof typeof PERFORMANCE_STANDARDS)[];
  supportMeetings: (keyof typeof SUPPORT_MEETINGS)[];
  supportMonies: (keyof typeof SUPPORT_MONIES)[];
  supportLands: (keyof typeof SUPPORT_LANDS)[];
  tsuboPriceCustomHouse: number[];
  tsuboPriceRenovation: number[];
  tsuboPriceReform: number[];
  strength1st?: keyof typeof STRENGTHS | null;
  strength2nd?: keyof typeof STRENGTHS | null;
  specializedField1st?: keyof typeof SPECIALIZED_FIELDS | null;
  specializedField2nd?: keyof typeof SPECIALIZED_FIELDS | null;
  specializedField3rd?: keyof typeof SPECIALIZED_FIELDS | null;
  documents: Document[];
}

export interface MakerCompanyFormType {
  companyName: string;
  companyType?: keyof typeof COMPANY_TYPES | null;
  postalCode?: string | null;
  address?: string | null;
  googleMapUrl?: string | null;
  salesOffices: SalesOffice[];
  representative: string;
  establishmentDate: number | null;
  capitalStock: string;
  numberOfEmployees: string;
  business: string;
  approvalLicenses: string;
  webSiteUrl: string;
  fbUrl: string;
  twUrl: string;
  igUrl: string;
}

export interface MakerCunelworkFormType {
  publishing: boolean;
  slug: string;
  collaborators: string[];
  plan: PlanKeyType;
  wordComment: WordComment;
}

export interface MakerType {
  logoImage: string;
  images: Image[];
  name: string;
  concept: Concept;
  features: Feature[];
  coverageWorks: CoverageWorkTypes[];
  approximateAmounts: Amount[];
  coverageAreas: string[];
  performanceStandards: (keyof typeof PERFORMANCE_STANDARDS)[];
  supportMeetings: (keyof typeof SUPPORT_MEETINGS)[];
  supportMonies: (keyof typeof SUPPORT_MONIES)[];
  supportLands: (keyof typeof SUPPORT_LANDS)[];
  tsuboPriceCustomHouse: number[];
  tsuboPriceRenovation: number[];
  tsuboPriceReform: number[];
  strength1st?: keyof typeof STRENGTHS | null;
  strength2nd?: keyof typeof STRENGTHS | null;
  specializedField1st?: keyof typeof SPECIALIZED_FIELDS | null;
  specializedField2nd?: keyof typeof SPECIALIZED_FIELDS | null;
  specializedField3rd?: keyof typeof SPECIALIZED_FIELDS | null;
  // 会社情報
  companyName: string;
  companyType?: keyof typeof COMPANY_TYPES | null;
  postalCode?: string | null;
  address?: string | null;
  googleMapUrl?: string | null;
  salesOffices: SalesOffice[];
  phoneNumber: string;
  representative: string;
  establishmentDate: number | null;
  capitalStock: string;
  numberOfEmployees: string;
  business: string;
  approvalLicenses: string;
  webSiteUrl: string;
  fbUrl: string;
  twUrl: string;
  igUrl: string;
  // お問い合わせ情報
  personName: string;
  personTel: string;
  businessHours: string;
  mailAddress: string;
  inquiryAnnotation: string;
  documents: Document[];
  individualMeetingPlaces: IndividualMeetingPlace[];
  // CW
  publishing: boolean;
  slug: string;
  collaborators: string[];
  plan: PlanKeyType;
  wordComment: WordComment;
  // 自動入力
  uid: string;
  createDate: number;
  updateDate: number;
  followers: string[];
  numOfFollowers: number;
}
