import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagenation from '@material-ui/lab/Pagination';
import { push } from 'connected-react-router';

import moment from 'moment';
import { formats } from '../../../../data/date';
import { useStyles } from './useStyles';
import { fetchInformations } from '../../../../re-ducks/wordpress/actions';
import { selectInformations } from '../../../../re-ducks/wordpress/selector';

interface Props {
  limit?: number;
}

const InformationList: FC<Props> = ({ limit = null }) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const informations = useSelector(selectInformations);
  const { results, maxPages } = informations;
  const classes = useStyles();
  const perPage = 5;

  useEffect(() => {
    dispatch(
      fetchInformations({
        paged: page.toString(),
        per_page: perPage.toString(),
        post_types: 'information',
      }),
    );
  }, [page]);

  return (
    <div className={classes.root}>
      <div className={classes.grid}>
        {results && results?.length
          ? results.map((post) => {
              return (
                <div key={post.id} className={classes.gridItem}>
                  <button
                    className={classes.inner}
                    onClick={() =>
                      dispatch(push(`/information/detail/${post.id}`))
                    }
                  >
                    <p className={classes.date}>
                      {moment(post.date).format(formats[1])}
                    </p>
                    <h3 className={classes.title}>{post.title}</h3>
                  </button>
                </div>
              );
            })
          : null}
      </div>
      {limit ? null : (
        <div className={classes.pagenation}>
          <Pagenation
            count={maxPages}
            page={page}
            color="primary"
            onChange={(e, p) => setPage(p)}
          />
        </div>
      )}
    </div>
  );
};

export default InformationList;
