import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(16),
  },
  logo: {
    maxWidth: 480,
    margin: `0 auto ${theme.spacing(1)}px`,
    textAlign: 'center',

    '& img': {
      maxWidth: '100%',
    },
  },
}));
