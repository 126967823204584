// eslint-disable-next-line
let config: {
  apiKey: string | undefined;
  authDomain: string | undefined;
  databaseURL: string | undefined;
  projectId: string | undefined;
  storageBucket: string | undefined;
  messagingSenderId: string | undefined;
  appId: string | undefined;
} = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_APP_ID,
};

switch (process.env.REACT_APP_ENV) {
  case 'release': {
    config = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
    };
    break;
  }
  case 'staging': {
    config = {
      apiKey: process.env.REACT_APP_STAGING_API_KEY,
      authDomain: process.env.REACT_APP_STAGING_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_STAGING_DATABASE_URL,
      projectId: process.env.REACT_APP_STAGING_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STAGING_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_STAGING_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_STAGING_APP_ID,
    };
    break;
  }
  default: {
    config = {
      apiKey: process.env.REACT_APP_DEV_API_KEY,
      authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
      projectId: process.env.REACT_APP_DEV_PROJECT_ID,
      storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_DEV_APP_ID,
    };
    break;
  }
}

export default config;
