import { sortBy } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { Typography, Switch } from '@material-ui/core';

import { DotProgress } from '../../../case/feedback/DotProgress';
import { RequiredText } from '../../../base/typography/RequiredText';
import { TextBox } from '../../../base/form/TextBox';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { DefinitionList } from '../../../base/DefinitionList';
import {
  selectMaker,
  selectCreating,
} from '../../../../../re-ducks/maker/selector';
import { SelectField } from '../../../base/form/SelectField';
import { fetchCollaborators } from '../../../../../re-ducks/collaborator/actions';
import { selectCollaboratorTableData } from '../../../../../re-ducks/collaborator/selector';
import { SingleSelectField } from '../../../base/form/SelectField/SingleSelectField';
import { PLANS } from '../../../../../data/plan';

export const MakerCunelworkEditForm: FC = () => {
  const dispatch = useDispatch();
  const creating = useSelector(selectCreating);
  const collaborators = useSelector(selectCollaboratorTableData);
  const maker = useSelector(selectMaker);
  const defaultValues = maker;
  const formMethods = useFormContext();

  const { register, errors, control } = formMethods;

  useEffect(() => {
    dispatch(fetchCollaborators({ whereQueries: [], orderQueries: [] }));
  }, []);

  return (
    <div>
      {creating ? <DotProgress opening={creating} /> : null}

      <DefinitionList
        components={[
          {
            dt: <Typography>公開状態</Typography>,
            dd: (
              <div>
                <Switch
                  color="primary"
                  defaultChecked={defaultValues.publishing}
                  name="publishing"
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  inputRef={register}
                />
              </div>
            ),
          },
          {
            dt: <RequiredText>Slug</RequiredText>,
            dd: (
              <div>
                <TextBox
                  type="text"
                  name="slug"
                  InputLabelProps={{ shrink: true }}
                  inputRef={register({ required: 'Slugを入力してください' })}
                  error={!!errors?.slug}
                />
                <ErrorMessage error={errors?.slug} />
              </div>
            ),
          },
          {
            dt: <RequiredText>コラボレーター</RequiredText>,
            dd: (
              <div>
                <Controller
                  name="collaborators"
                  control={control}
                  rules={{
                    validate: {
                      required: (value) =>
                        value.length
                          ? true
                          : 'コラボレーターを選択してください',
                    },
                  }}
                  render={({ name }) => (
                    <SelectField
                      name={name}
                      defaultValue={defaultValues.collaborators.map((uid) => ({
                        label:
                          collaborators.find(
                            (collaborator) => collaborator.uid === uid,
                          )?.name || '',
                        value: uid,
                      }))}
                      options={sortBy(
                        collaborators.filter(
                          (collaborator) => collaborator.role === 'admin',
                        ),
                        ['name'],
                      ).map((collaborator) => ({
                        label: collaborator.name,
                        value: collaborator.uid,
                      }))}
                      isMulti
                    />
                  )}
                  isClearable
                />
                <ErrorMessage error={errors.collaborators} />
              </div>
            ),
          },
          {
            dt: <RequiredText>プラン</RequiredText>,
            dd: (
              <div>
                <Controller
                  name="plan"
                  control={control}
                  rules={{
                    validate: {
                      required: (value) =>
                        value.length ? true : 'プランを選択してください',
                    },
                  }}
                  render={({ name }) => (
                    <SingleSelectField
                      name={name}
                      defaultValue={{
                        label: PLANS[defaultValues.plan],
                        value: defaultValues.plan,
                      }}
                      options={Object.entries(PLANS).map(([key, value]) => ({
                        label: value,
                        value: key,
                      }))}
                    />
                  )}
                  isClearable
                />
                <ErrorMessage error={errors.plan} />
              </div>
            ),
          },
          {
            alignItems: 'flex-start',
            dt: <Typography>運営局からコメント</Typography>,
            dd: (
              <div>
                <div>
                  <TextBox
                    label="キャッチコピー"
                    type="text"
                    name="wordComment.title"
                    defaultValue={defaultValues.wordComment.title}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                  />
                  <ErrorMessage error={errors?.wordComment?.title} />
                </div>
                <div className="mt-5">
                  <TextBox
                    label="説明文"
                    type="textarea"
                    name="wordComment.description"
                    defaultValue={defaultValues.wordComment.description}
                    InputLabelProps={{ shrink: true }}
                    inputRef={register}
                    placeholder=""
                    multiline
                    rows={6}
                  />
                  <ErrorMessage error={errors?.wordComment?.description} />
                </div>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};
