import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  item: {
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
    '&:first-child': {
      marginTop: 0,
    },
  },
  dateWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),
  },
  addButton: {
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: 0,
    },
  },
  closeButton: {
    display: 'block',
    marginLeft: 'auto',
  },
  imageWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    aspectRatio: '3/2',
    verticalAlign: 'middle',
    backgroundColor: theme.palette.grey['400'],
  },
  image: {},
  button: {
    width: '100%',
    margin: `0 ${theme.spacing(2)}px`,
  },
  isSelected: {},
  modalInner: {
    position: 'absolute',
    inset: 0,
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    zIndex: 2,
    '& h2': {
      fontWeight: 'bold',
    },
  },
  modalBody: {
    overflowY: 'auto',
  },
  images: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
    justifyContent: '',
  },
  img: {
    width: 240,
    height: 160,
    border: `1px solid ${theme.palette.grey[300]}`,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  modalAction: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  selectedImg: {
    boxShadow: `0 0 0px 3px #2271b1`,
  },
}));
