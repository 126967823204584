import { ValueOf } from '../../types/utility';

export const EVENT_TYPES = {
  openhouse: '完成見学会',
  modelhouse: 'モデルハウス見学会',
  seminar: 'セミナー・相談会',
  other: 'その他',
} as const;
export type EventTypeKeyType = keyof typeof EVENT_TYPES;
export type EventTypeValueType = ValueOf<typeof EVENT_TYPES>;

export const SCHEDULE_STATUS = {
  accepting: '予約受付中',
  refusing: '予約受付停止中',
};
export type ScheduleStatusKeyType = keyof typeof SCHEDULE_STATUS;
export type ScheduleStatusValueType = ValueOf<typeof SCHEDULE_STATUS>;

export const EVENT_TAGS = {
  beginner: '家づくり初心者向け',
  land: '土地探しについて',
  'financing-plan': '資金計画・ローンについて',
} as const;
export type EventTagKeyType = keyof typeof EVENT_TAGS;
export type EventTagValueType = ValueOf<typeof EVENT_TAGS>;

export interface Content {
  title: string;
  text: string;
}

export interface EventType {
  acceptingReservations: boolean;
  publishing: boolean;
  title: string;
  eventType: EventTypeKeyType;
  eventTags: EventTagKeyType[];
  area: string[];
  fullYear: boolean;
  businessDays: number[];
  startDate: string | null;
  endDate: string | null;
  time: string;
  timeRequired: string;
  images: { imageUrl: string; description: string }[];
  contents: Content[];
  price: string;
  eventPlace: string;
  eventPlaceNote: string;
  eventMap: string;
  // 自動入力
  uid: string;
  createDate: string;
  updateDate: string;
  makerId: string;
  officialEvent: boolean;
  slug: string;
}

export interface EventFormType {
  acceptingReservations: boolean;
  publishing: boolean;
  title: string;
  eventType: EventTypeKeyType;
  eventTags: EventTagKeyType[];
  area: string[];
  fullYear: boolean;
  businessDays: number[];
  startDate: string | null;
  endDate: string | null;
  time: string;
  timeRequired: string;
  eventPlace: string;
  eventMap: string;
  eventPlaceNote: string;
  images: { imageUrl: string; description: string }[];
  contents: Content[];
  price: string;
  // 自動入力
  makerId: string;
  officialEvent: boolean;
}

export interface EventListTableType {
  button: null;
  image: string;
  publishing: boolean;
  acceptingReservations: boolean;
  title: string;
  startDate: string | null;
  endDate: string | null;
  time: string;
  createDate: string;
  updateDate: string;
  makerName: string;
  reservations: number;
  event: EventType;
}

export const RESERVATION_STATUS = {
  applying: '予約申請中',
  approval: '予約確定',
  reject: '予約キャンセル',
};
export type ReservationStatusKeyType = keyof typeof RESERVATION_STATUS;
export type ReservationStatusValueType = ValueOf<typeof RESERVATION_STATUS>;

export const ATTENDANCE_STATUS = {
  attended: '参加',
  absence: 'キャンセル',
};
export type AttendanceStatusKeyType = keyof typeof ATTENDANCE_STATUS;
export type AttendanceStatusValueType = ValueOf<typeof ATTENDANCE_STATUS>;

export interface EventReservationsType {
  uid: string;
  eventId: string;
  userId: string;
  status: ReservationStatusKeyType;
  reservationName: string;
  remarks: string;
  createDate: string;
  updateDate: string;
  mailAddress: string;
  tel: string;
  reservationDate: string;
  reservationTime: string;
  participants: {
    adults: number;
    children: number;
    childAge: string;
  };
  memo: string;
  attendanceStatus: '' | AttendanceStatusKeyType;
}
