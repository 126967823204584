import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import useSnackbar from '../../../../hooks/useSnackbar';
import { TextBox } from '../../../base/form/TextBox';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { PwResetButton } from '../../button/PwResetButton';
import { Snackbar } from '../../feedback/Snackbar';
import { useStyles } from './useStyles';
import { sendPasswordResetEmail } from '../../../../../domain/service/sendPasswordResetEmail';

interface PwForgetFormType {
  email: string;
}

export const PwForgetForm: FC = () => {
  const classes = useStyles();
  const { snackbarState, openSnackbar, closeSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm<PwForgetFormType>();
  const onSubmit = handleSubmit(async (data) => {
    try {
      const email = await sendPasswordResetEmail(data.email);
      if (typeof email !== 'string') throw email;

      openSnackbar({
        variant: 'success',
        message: `${email}にパスワードリセットのメールを送信しました`,
      });
    } catch (error) {
      openSnackbar({
        variant: 'error',
        message:
          error?.message ||
          'ログインできませんでした。もう一度お試しください。',
      });
    }
  });

  return (
    <form className={classes.form} onSubmit={onSubmit} noValidate>
      <TextBox
        type="email"
        name="email"
        label="メールアドレス"
        inputRef={register({ required: 'メールアドレスを入力してください' })}
      />
      <ErrorMessage error={errors.email} />
      <p className={classes.pwForget}>
        <Link to="/login">ログインページに戻る</Link>
      </p>
      <PwResetButton type="submit" className={classes.button} />
      <Snackbar
        variant={snackbarState.variant}
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        onClose={closeSnackbar}
      />
    </form>
  );
};
