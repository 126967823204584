import _ from 'lodash';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router';
import { push } from 'connected-react-router';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';

import { onAuthStateChanged } from '../../../../re-ducks/collaborator/actions';
import { selectRole } from '../../../../re-ducks/collaborator/selector';
import { selectSelectedMaker } from '../../../../re-ducks/app/selector';
import { AppBar } from '../../base/AppBar';
import { Drawer } from '../../base/Drawer';
import { useStyles } from './useStyles';
import useAdminLinks from '../../../hooks/useAdminLinks';
import useSnapshotSelectedMaker from '../../../hooks/useSnapshotSelectedMaker';

export interface AdminWrapperProps {
  paddingWrapping?: boolean;
}

export const AdminWrapper: FC<AdminWrapperProps> = ({
  children,
  paddingWrapping = true,
}) => {
  const collaboratorRole = useSelector(selectRole);
  const selectedMaker = useSelector(selectSelectedMaker);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const adminLinks = useAdminLinks();

  useEffect(() => {
    (async () => {
      await dispatch(onAuthStateChanged());

      if (collaboratorRole === 'cunelwork') return;

      if (
        !adminLinks.some((link) => {
          return (
            pathname === link.pathName ||
            (link.mathPath && matchPath(pathname, { path: link.mathPath }))
          );
        })
      ) {
        dispatch(push('/'));
      }

      if (
        adminLinks.some(
          (link) =>
            pathname === link.pathName &&
            !link.plans?.includes(selectedMaker?.plan || 'free'),
        )
      ) {
        if (selectedMaker?.plan === 'free') {
          dispatch(push('/free-plan'));
        } else {
          dispatch(push('/'));
        }
      }
    })();
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.backgroundColor = '#f0f0f0';
      body.style.minHeight = '100vh';
    }
  }, []);

  useSnapshotSelectedMaker();

  return (
    <div id="admin-wrapper" className={classes.root}>
      <AppBar />
      <Drawer />
      <Box p={paddingWrapping ? 3 : 0} className={classes.contentWrapper}>
        <Toolbar />
        {children}
      </Box>
    </div>
  );
};
