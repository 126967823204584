import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  itemWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  item: {
    background: theme.palette.grey[300],
  },
  imageWrap: {
    width: 234,
    height: 156,
  },
  addButton: {
    marginTop: theme.spacing(2),
    '&:first-child': {
      marginTop: 0,
    },
  },
  closeButton: {
    display: 'block',
    marginLeft: 'auto',
  },
  description: {
    padding: theme.spacing(1),
    '& textarea': {
      fontSize: 14,
    },
    '& label': {
      top: theme.spacing(1),
      left: theme.spacing(1),
    },
  },
}));
