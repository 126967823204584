import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import axios from 'axios';
import { stringify } from 'querystring';
import { wpPostsApi } from '../../data/wordpress';
import { AsyncThunkConfig } from '../store';
import { WpPostDetail, WpPostsResult } from './types';

export const fetchInformations = createAsyncThunk<
  WpPostsResult,
  {
    [k: string]: string | string[] | undefined;
  },
  AsyncThunkConfig<undefined>
>('wordpress/fetchInformations', async (args, thunkAPI) => {
  try {
    const queryParams = {
      post_types: 'information',
      per_page: '5',
      ...args,
    };
    const informations = await axios.get<WpPostsResult>(
      `${wpPostsApi}?${stringify(queryParams)}`,
    );

    return informations.data;
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchTheInformation = createAsyncThunk<
  WpPostDetail,
  {
    postId: string;
  },
  AsyncThunkConfig<undefined>
>('wordpress/fetchTheInformation', async (args, thunkAPI) => {
  try {
    const information = await axios.get<WpPostDetail>(
      `${wpPostsApi}${args.postId}/?post_type=information`,
    );

    return information.data;
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});
