import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';

import { db } from './index';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';
import { BatchRequestType } from '../../re-ducks/batchRequests/types';

export const BatchRequestsRepository: Collection<BatchRequestType> =
  db.collection<BatchRequestType>({
    collectionPath: 'batchRequests',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        date: convertToTimestampFromString(obj.date),
      };
    },
    decode: (doc) => {
      const data = doc.data();
      if (!data)
        return {
          uid: '',
          name: '',
          mailAddress: '',
          tel: '',
          date: convertToDateFromTimestamp(new Date()),
          postalCode: '',
          address: '',
          content: '',
          makerIds: [],
          memo: '',
        };

      return {
        ...data,
        date: convertToDateFromTimestamp(data.date) || '',
      };
    },
  });
