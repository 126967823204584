import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    inset: theme.spacing(4),
    outline: 0,
  },
  container: {
    height: '100%',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  modalHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 36,
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    background: theme.palette.grey['300'],
    position: 'relative',
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      right: 0,
      bottom: 0,
      left: 0,
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.grey['400'],
    },
  },
  tabs: {
    minHeight: 36,
    height: 36,
  },
  tabIndicator: {
    background: '#fff',
  },
  tab: {
    height: 36,
    minHeight: 36,
    boxSizing: 'border-box',
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
  },
  selectedTab: {
    backgroundColor: theme.palette.common.white,
  },
  tabContent: {
    padding: theme.spacing(3),
    height: 'calc(100% - 36px)',
    boxSizing: 'border-box',
  },
}));

export default useStyles;
