import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { CollaboratorState } from './slice';

export const selectSelf = (state: RootState): CollaboratorState =>
  state.collaborator;

export const selectCreating = createSelector(
  selectSelf,
  (state) => state.creating,
);

export const selectCollaborator = createSelector(
  selectSelf,
  (state) => state.collaborator,
);

export const selectUid = createSelector(
  selectSelf,
  (state) => state.collaborator.uid,
);
export const selectName = createSelector(
  selectSelf,
  (state) => state.collaborator.name,
);
export const selectRole = createSelector(
  selectSelf,
  (state) => state.collaborator.role,
);
export const selectMailAddress = createSelector(
  selectSelf,
  (state) => state.collaborator.mailAddress,
);

export const selectCollaboratorTableData = createSelector(
  selectSelf,
  (state) => state.collaboratorTableData,
);
export const selectFetching = createSelector(
  selectSelf,
  (state) => state.fetching,
);
