import React, { useState, VFC } from 'react';
import { CellProps } from 'react-table';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { useDebounce } from 'react-use';
import { isEqual } from 'lodash';
import { selectPublishActualResults } from '../../../../../re-ducks/themes/selector';
import { PublishActualResult } from '../../../../../re-ducks/themes/types';
import { updateRow } from '../../../../../re-ducks/themes/actions';
import useSnackbar from '../../../../hooks/useSnackbar';
import { TextBox } from '../../../base/form/TextBox';

export const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}));

export const MemoCell: VFC<CellProps<any>> = ({ row, cell }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const publishActualResults = useSelector(selectPublishActualResults);
  const [value, setValue] = useState<PublishActualResult>(
    row.original.publishActualResult,
  );
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();

  const [, cancel] = useDebounce(
    () => {
      if (isEqual(row.original.publishActualResult, value)) {
        return;
      }

      dispatch(updateRow({ publishActualResult: value, openSnackbar }));
    },
    1000,
    [value],
  );

  const handleChange = (v: string) => {
    const originalValue = publishActualResults.find(
      ({ uid }) => uid === row.original.publishActualResult.uid,
    );
    if (!originalValue) return;

    const newRowValue = {
      ...originalValue,
      [cell.column.id]: v,
    };
    setValue(newRowValue);
  };

  return (
    <div className={classes.root}>
      <TextBox
        value={value[cell.column.id as keyof PublishActualResult]}
        rows={3}
        onChange={(e) => handleChange(e.target.value)}
        multiline
      />
    </div>
  );
};
