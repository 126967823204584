import React, { VFC, ReactNode } from 'react';
import { TableCell, TableRow as MuiTableRow } from '@material-ui/core';

import classNames from 'classnames';
import useStyles from './style';

export interface Props {
  label: ReactNode;
  children: ReactNode;
  verticalAlign?: string;
}

const TableRow: VFC<Props> = ({ label, children, verticalAlign }) => {
  const classes = useStyles();

  return (
    <MuiTableRow>
      <TableCell
        component="th"
        scope="row"
        className={classNames(classes.tableLabel, {
          [classes.tableLabelTop]: verticalAlign && verticalAlign === 'top',
        })}
      >
        {label}
      </TableCell>
      <TableCell className={classes.tableDetail}>{children}</TableCell>
    </MuiTableRow>
  );
};

export default TableRow;
