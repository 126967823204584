import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  PublishActualResult,
  PublishActualResultsTableData,
  ThemeType,
} from './types';
import {
  createTheme,
  deleteTheme,
  fetchActualImages,
  fetchActualResults,
  fetchPublishActualResults,
  fetchThemes,
  sortThemes,
  createPublishActualResults,
  fetchPublishActualResultsTableData,
  updateRow,
} from './actions';
import { ActualImageType, ActualResultType } from '../actualResult/types';

export type ThemeState = {
  themes: ThemeType[];
  actualResults: ActualResultType[];
  images: { [k: string]: ActualImageType[] };
  theme: ThemeType;
  publishActualResult: PublishActualResult;
  publishActualResults: PublishActualResult[];
  publishActualResultsTableData: PublishActualResultsTableData[];
  fetching: boolean;
  creating: boolean;
};

export const initialState: ThemeState = {
  themes: [],
  actualResults: [],
  images: {},
  theme: {
    publishing: false,
    label: '',
    slug: '',
    description: '',
    imageUrl: '',
    bnrUrl: '',
    actualResults: [],
    themeCategory: 'その他',
    themeSeo: [],
    uid: '',
    order: 0,
    createDate: '',
    updateDate: '',
  },
  publishActualResult: {
    agreementMemo: '',
    requestMemo: '',
    uid: '',
    themeId: '',
    publishingStatus: 'waiting',
    billingStatus: 'unclaimed',
    staff: '崎本',
    actualResultId: '',
    makerId: '',
    startDate: '',
    endDate: '',
  },
  publishActualResults: [],
  publishActualResultsTableData: [],
  fetching: false,
  creating: false,
};

const themeSlice = createSlice({
  name: 'actualResult',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeType>) => {
      state.theme = action.payload;
    },
    setThemes: (state, action: PayloadAction<ThemeType[]>) => {
      state.themes = [
        ...state.themes.filter(({ uid }) =>
          action.payload.every((theme) => theme.uid !== uid),
        ),
        ...action.payload,
      ];
    },
  },
  extraReducers: (builder) => {
    // fetchThemes
    builder.addCase(fetchThemes.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchThemes.fulfilled, (state, action) => {
      state.themes = action.payload.sort((a, b) =>
        a.order > b.order ? 1 : -1,
      );
      state.fetching = false;
    });
    builder.addCase(fetchThemes.rejected, (state) => {
      state.actualResults = [];
      state.fetching = false;
    });
    // fetchActualResults
    builder.addCase(fetchActualResults.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchActualResults.fulfilled, (state, action) => {
      state.actualResults = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchActualResults.rejected, (state) => {
      state.actualResults = [];
      state.fetching = false;
    });
    // fetchActualImages
    builder.addCase(fetchActualImages.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchActualImages.fulfilled, (state, action) => {
      state.images = { ...state.images, ...action.payload };
      state.fetching = false;
    });
    builder.addCase(fetchActualImages.rejected, (state) => {
      state.actualResults = [];
      state.fetching = false;
    });
    // createTheme
    builder.addCase(createTheme.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(createTheme.fulfilled, (state, action) => {
      state.theme = action.payload;
      state.creating = false;
    });
    builder.addCase(createTheme.rejected, (state) => {
      state.theme = initialState.theme;
      state.creating = false;
    });
    // createPublishActualResults
    builder.addCase(createPublishActualResults.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(createPublishActualResults.fulfilled, (state, action) => {
      state.publishActualResults = action.payload;
      state.creating = false;
    });
    builder.addCase(createPublishActualResults.rejected, (state) => {
      state.theme = initialState.theme;
      state.creating = false;
    });
    // deleteFaqItem
    builder.addCase(deleteTheme.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(deleteTheme.fulfilled, (state, action) => {
      state.themes = action.payload.themes;
      state.creating = false;
    });
    builder.addCase(deleteTheme.rejected, (state) => {
      state.creating = false;
    });
    // fetchPublishActualResults
    builder.addCase(fetchPublishActualResults.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchPublishActualResults.fulfilled, (state, action) => {
      state.publishActualResults = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchPublishActualResults.rejected, (state) => {
      state.publishActualResults = [];
      state.fetching = false;
    });
    // fetchPublishActualResultsTableData
    builder.addCase(fetchPublishActualResultsTableData.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(
      fetchPublishActualResultsTableData.fulfilled,
      (state, action) => {
        state.publishActualResultsTableData = action.payload;
        state.fetching = false;
      },
    );
    builder.addCase(fetchPublishActualResultsTableData.rejected, (state) => {
      state.publishActualResults = [];
      state.fetching = false;
    });
    // updateRow
    builder.addCase(updateRow.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(updateRow.fulfilled, (state, action) => {
      state.publishActualResultsTableData =
        state.publishActualResultsTableData.map((publishActualResult) => {
          if (publishActualResult.uid === action.payload.uid) {
            return action.payload;
          }

          return publishActualResult;
        });
      state.creating = false;
    });
    builder.addCase(updateRow.rejected, (state) => {
      state.creating = false;
    });
    // sortThemes
    builder.addCase(sortThemes.fulfilled, (state, action) => {
      state.themes = [
        ...state.themes.filter(({ uid }) =>
          action.payload.every((theme) => theme.uid !== uid),
        ),
        ...action.payload,
      ];
    });
  },
});

export const { setTheme, setThemes } = themeSlice.actions;

export default themeSlice;
