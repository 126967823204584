import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  const animation = (delay: number) =>
    `$scale 0.75s ${delay}s infinite cubic-bezier(.2, .68, .18, 1.08)`;

  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',

      '& span': {
        display: 'block',
        width: 12,
        height: 12,
        margin: 4,
        borderRadius: '50%',
        background: theme.palette.primary.main,
        '&:nth-child(1)': {
          animation: animation(-0.24),
        },
        '&:nth-child(2)': {
          animation: animation(-0.12),
        },
        '&:nth-child(3)': {
          animation: animation(0),
        },
      },
    },
    '@keyframes scale': {
      '30%': { transform: 'scale(0.3)' },
      '100%': { transform: 'scale(1)' },
    },
  };
});
