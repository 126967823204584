import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    display: 'flex',
    alignItems: 'center',

    '& label': {
      marginRight: 0,
    },

    '& span': {
      fontWeight: 'bold',
    },
  },
  areaChecks: {
    marginLeft: theme.spacing(2),
  },
}));
