import createMuiTheme, {
  Theme,
  ThemeOptions,
} from '@material-ui/core/styles/createTheme';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

export default (theme?: ThemeOptions): Theme => {
  const lightPalette: PaletteOptions = {
    type: 'light',
    primary: {
      dark: '#FF4D30',
      main: '#F27405',
      light: '#FF9C4F',
      contrastText: '#fafafa',
    },
    secondary: {
      dark: '#61542f',
      main: '#8B7844',
      light: '#a29369',
      contrastText: '#fafafa',
    },
    action: {
      hover: 'rgba(105, 189, 92, 0.12)',
      selected: 'rgba(105, 189, 92, 0.3)',
    },
  };

  return createMuiTheme({
    palette: lightPalette,
    mixins: {
      toolbar: {
        minHeight: 88,
        '@media (min-width:0px)': {
          minHeight: 64,
        },
        '@media (min-width:600px)': {
          minHeight: 88,
        },
      },
    },
    ...theme,
  });
};
