export interface MakerContactType {
  uid: string;
  inquiryType: 'お問い合わせ' | '資料請求';
  name: string;
  mailAddress: string;
  tel: string;
  content: string;
  date: string;
  postalCode: string;
  address: string;
  pageUrl: string;
  makerId: string;
  userAgent: string;
  os: string;
  browser: string;
  memo: string;
}

export const INQUIRY_TYPES = [
  'お問い合わせ',
  '資料請求',
  '一括資料請求',
  '個別相談',
  'イベント予約',
] as const;

export interface MakerContactTableData {
  uid: string;
  makerName: string;
  inquiryType: typeof INQUIRY_TYPES[number];
  name: string;
  date: string;
  mailAddress: string;
  tel: string;
  documentRequest: any;
  contactData: any;
  makerNames: string[];
}
