import React, { VFC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import { useStyles } from './useStyles';
import Logo from '../../../../assets/images/logo.svg';
import { SelectMakerForm } from '../../case/form/SelectMakerForm';
import { selectRole } from '../../../../re-ducks/collaborator/selector';
import { onAuthStateChanged } from '../../../../re-ducks/collaborator/actions';

export const SelectMaker: VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const collaboratorRole = useSelector(selectRole);

  useEffect(() => {
    if (collaboratorRole === 'cunelwork') dispatch(push('/'));

    (async () => {
      await dispatch(onAuthStateChanged());
    })();
  }, [collaboratorRole]);
  if (collaboratorRole === 'cunelwork') return null;

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <img src={Logo} alt="Mockhouse" />
      </div>
      <SelectMakerForm />
    </div>
  );
};
