import moment from 'moment';
import React, { VFC } from 'react';
import {
  ColumnInstance,
  FilterValue,
  IdType,
  Row,
  TableInstance,
} from 'react-table';

import { EventListTableType } from '../../../../../re-ducks/event/types';
import { formats } from '../../../../../data/date';
import { DateField } from '../../../base/form/DateField';

export const dateAfterFilter = (
  rows: Array<Row<any>>,
  columnIds: Array<IdType<any>>,
  filterValue: FilterValue,
): Array<Row<any>> => {
  return rows.filter((row) => {
    return moment(row.values[columnIds[0]]).isSameOrAfter(filterValue, 'day');
  });
};

export const dateBeforeFilter = (
  rows: Array<Row<any>>,
  columnIds: Array<IdType<any>>,
  filterValue: FilterValue,
): Array<Row<any>> => {
  return rows.filter((row) => {
    return moment(row.values[columnIds[0]]).isSameOrBefore(filterValue, 'day');
  });
};

export const DateColumnFilter: VFC<
  TableInstance<any> & { column: ColumnInstance<never> }
> = ({ column }) => {
  const { render, filterValue, setFilter } = column;

  return (
    <div>
      <div className="label" style={{ marginBottom: 0 }}>
        {render('Header')}
      </div>
      <div className="control">
        <DateField
          DatePickerProps={{
            value: filterValue || null,
            format: formats[0],
            margin: 'none',
            onChange: (v) => {
              const value = v ? moment(v).toDate() : null;
              setFilter(value);
            },
          }}
        />
      </div>
    </div>
  );
};
