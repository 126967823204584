export type AreaType = {
  id?: string;
  name: string;
  label: string;
  parent?: string;
};

export const PREFECTURES = {
  hokkaido: '北海道',
  aomori: '青森県',
  iwate: '岩手県',
  miyagi: '宮城県',
  akita: '秋田県',
  yamagata: '山形県',
  fukushima: '福島県',
  ibaraki: '茨城県',
  tochigi: '栃木県',
  gunma: '群馬県',
  saitama: '埼玉県',
  chiba: '千葉県',
  tokyo: '東京都',
  kanagawa: '神奈川県',
  niigata: '新潟県',
  toyama: '富山県',
  ishikawa: '石川県',
  fukui: '福井県',
  yamanashi: '山梨県',
  nagano: '長野県',
  gifu: '岐阜県',
  shizuoka: '静岡県',
  aichi: '愛知県',
  mie: '三重県',
  shiga: '滋賀県',
  kyoto: '京都府',
  osaka: '大阪府',
  hyogo: '兵庫県',
  nara: '奈良県',
  wakayama: '和歌山県',
  tottori: '鳥取県',
  shimane: '島根県',
  okayama: '岡山県',
  hiroshima: '広島県',
  yamaguchi: '山口県',
  tokushima: '徳島県',
  kagawa: '香川県',
  ehime: '愛媛県',
  kochi: '高知県',
  fukuoka: '福岡県',
  saga: '佐賀県',
  nagasaki: '長崎県',
  kumamoto: '熊本県',
  oita: '大分県',
  miyazaki: '宮崎県',
  kagoshima: '鹿児島県',
  okinawa: '沖縄県',
};

export const ALL_AREA: AreaType[] = [
  { id: '06', name: 'yamagatq', label: '山形' },
  { id: '15', name: 'niigata', label: '新潟県' },
  { name: 'niigata-city', label: '新潟市', parent: 'niigata' },
  {
    id: '151017',
    name: 'kita-ward',
    label: '新潟市北区',
    parent: 'niigata-city',
  },
  {
    id: '151025',
    name: 'higashi-ward',
    label: '新潟市東区',
    parent: 'niigata-city',
  },
  {
    id: '151033',
    name: 'chuo-ward',
    label: '新潟市中央区',
    parent: 'niigata-city',
  },
  {
    id: '151041',
    name: 'konan-ward',
    label: '新潟市江南区',
    parent: 'niigata-city',
  },
  {
    id: '151050',
    name: 'akiha-ward',
    label: '新潟市秋葉区',
    parent: 'niigata-city',
  },
  {
    id: '151068',
    name: 'minami-ward',
    label: '新潟市南区',
    parent: 'niigata-city',
  },
  {
    id: '151076',
    name: 'nishi-ward',
    label: '新潟市西区',
    parent: 'niigata-city',
  },
  {
    id: '151084',
    name: 'nishikan-ward',
    label: '新潟市西蒲区',
    parent: 'niigata-city',
  },
  { name: 'joetsu', label: '上越', parent: 'niigata' },
  { id: '152226', name: 'joetsu-city', label: '上越市', parent: 'joetsu' },
  { id: '152161', name: 'itoigawa-city', label: '糸魚川市', parent: 'joetsu' },
  { id: '152170', name: 'myoko-city', label: '妙高市', parent: 'joetsu' },
  { name: 'chuetsu', label: '中越', parent: 'niigata' },
  { id: '152021', name: 'nagaoka-city', label: '長岡市', parent: 'chuetsu' },
  { id: '152048', name: 'sanjo-city', label: '三条市', parent: 'chuetsu' },
  {
    id: '152056',
    name: 'kashiwazaki-city',
    label: '柏崎市',
    parent: 'chuetsu',
  },
  { id: '152081', name: 'ojiya-city', label: '小千谷市', parent: 'chuetsu' },
  { id: '152099', name: 'kamo-city', label: '加茂市', parent: 'chuetsu' },
  {
    id: '152102',
    name: 'tokamachi-city',
    label: '十日町市',
    parent: 'chuetsu',
  },
  { id: '152111', name: 'mitsuke-city', label: '見附市', parent: 'chuetsu' },
  { id: '152251', name: 'uonuma-city', label: '魚沼市', parent: 'chuetsu' },
  {
    id: '152269',
    name: 'minamiuonuma-city',
    label: '南魚沼市',
    parent: 'chuetsu',
  },
  { id: '153613', name: 'tagami-town', label: '田上町', parent: 'chuetsu' },
  {
    id: '154059',
    name: 'izumozaki-town',
    label: '出雲崎町',
    parent: 'chuetsu',
  },
  { id: '154610', name: 'yuzawa-town', label: '湯沢町', parent: 'chuetsu' },
  { id: '154822', name: 'tsunan-town', label: '津南町', parent: 'chuetsu' },
  { id: '155047', name: 'kariwa-village', label: '刈羽村', parent: 'chuetsu' },
  { name: 'kaetsu', label: '下越', parent: 'niigata' },
  { id: '152064', name: 'shibata-city', label: '新発田市', parent: 'kaetsu' },
  { id: '152129', name: 'murakami-city', label: '村上市', parent: 'kaetsu' },
  { id: '152137', name: 'tsubame-city', label: '燕市', parent: 'kaetsu' },
  { id: '152188', name: 'gosen-city', label: '五泉市', parent: 'kaetsu' },
  { id: '152234', name: 'agano-city', label: '阿賀野市', parent: 'kaetsu' },
  { id: '152277', name: 'tainai-city', label: '胎内市', parent: 'kaetsu' },
  { id: '153079', name: 'seiro-town', label: '聖籠町', parent: 'kaetsu' },
  { id: '153427', name: 'yahiko-village', label: '弥彦村', parent: 'kaetsu' },
  { id: '153851', name: 'aga-town', label: '阿賀町', parent: 'kaetsu' },
  { id: '155811', name: 'sekikawa-village', label: '関川村', parent: 'kaetsu' },
  {
    id: '155861',
    name: 'awashimaura-village',
    label: '粟島浦村',
    parent: 'kaetsu',
  },
  { name: 'sado', label: '佐渡', parent: 'niigata' },
  { id: '152242', name: 'sado-city', label: '佐渡市', parent: 'sado' },
];

export const getAreaLabel = (
  id = '',
  niigataCityPrefix = true,
): string | undefined => {
  const area = ALL_AREA.find((v) => v?.id === id);

  if (!area) {
    return undefined;
  }

  if (!niigataCityPrefix) {
    return area.label.replace('新潟市', '');
  }

  return area.label;
};

export const NIIGATA_AREA = [
  'niigata-city',
  'joetsu',
  'chuetsu',
  'kaetsu',
  'sado',
];
