export const FaqTypes = {
  endUser: 'ユーザー向け',
  builder: 'ビルダー向け',
} as const;

export interface FAQ {
  publishing: boolean;
  title: string;
  slug: string;
  content: string;
  faqType: keyof typeof FaqTypes;
  category: string;
  createDate: string;
  updateDate: string;
  uid: string;
}

export interface FAQMedia {
  uid: string;
  path: string;
}

export interface FAQCategory {
  label: string;
  faqType: keyof typeof FaqTypes;
  uid: string;
  faqItems: string[];
  order: number;
}
