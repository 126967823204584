import React, { FC } from 'react';

import IconFollower from '../../base/Icon/IconFollower';
import { FollowerListTable } from '../../domain/FollowerListTable';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { PageTitle } from '../../case/PageTitle';

export const Follower: FC = () => {
  return (
    <AdminWrapper>
      <PageTitle Icon={<IconFollower />}>フォロワー一覧</PageTitle>
      <FollowerListTable />
    </AdminWrapper>
  );
};
