import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  tableContainer: {
    whiteSpace: 'normal',
    '& table': {
      minWidth: 2000,
      width: '100%',
    },
  },
}));
