import React, { useState, VFC } from 'react';
import { CellProps } from 'react-table';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { useDebounce } from 'react-use';
import { isEqual } from 'lodash';
import { selectPublishActualResultsTableData } from '../../../../../re-ducks/themes/selector';
import { BILLING_STATUS } from '../../../../../re-ducks/themes/types';
import { updateRow } from '../../../../../re-ducks/themes/actions';
import useSnackbar from '../../../../hooks/useSnackbar';

export const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}));

export const BillingStatusCell: VFC<CellProps<any>> = ({ row, cell }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const publishActualResults = useSelector(selectPublishActualResultsTableData);

  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();

  const handleChange = (v: keyof typeof BILLING_STATUS) => {
    const originalValue = publishActualResults.find(
      ({ uid }) => uid === row.original.publishActualResult.uid,
    );
    if (!originalValue || originalValue.billingStatus === v) return;

    const newRowValue = {
      ...row.original.publishActualResult,
      [cell.column.id]: v,
    };
    dispatch(updateRow({ publishActualResult: newRowValue, openSnackbar }));
  };

  return (
    <div className={classes.root}>
      <div className="control select is-block">
        <select
          className="select column is-full"
          value={row.original.billingStatus}
          onChange={(e) =>
            handleChange(e.target.value as keyof typeof BILLING_STATUS)
          }
        >
          <option value="">選択してくだい</option>
          {Object.entries(BILLING_STATUS).map(([key, v]) => (
            <option key={key} value={key}>
              {v}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
