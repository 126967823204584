import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    backgroundColor: theme.palette.grey['500'],
    '&:hover': {
      backgroundColor: theme.palette.grey['600'],
    },
  },
}));
