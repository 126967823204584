export const formatValue = (selectOption: any): string => {
  if (!selectOption) return '';

  if (typeof selectOption === 'string') return selectOption;

  return selectOption instanceof Array
    ? selectOption.map((value) => {
        const keys = Object.keys(value);

        return keys.includes('value') ? value.value : value;
      })
    : selectOption.value;
};
