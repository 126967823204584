import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MakerType } from '../maker/types';
import { AppState } from './types';

const initialState: AppState = {
  drawerOpening: true,
  selectedMaker: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetSelectedMaker: (state) => {
      state.selectedMaker = null;
    },
    toggleDrawer(state: AppState) {
      state.drawerOpening = !state.drawerOpening;
    },
    selectMaker(
      state: AppState,
      action: PayloadAction<{ maker: MakerType | null }>,
    ) {
      state.selectedMaker = action.payload.maker;
    },
  },
});
export const { resetSelectedMaker, toggleDrawer, selectMaker } =
  appSlice.actions;
export default appSlice;
