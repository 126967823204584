import { useState } from 'react';

import { SnackbarProps } from '../components/case/feedback/Snackbar';

export type SnackbarState = Omit<SnackbarProps, 'onClose'>;

const initialState: SnackbarState = {
  isOpening: false,
  variant: 'error',
  message: '',
};

export type OpenSnackbar = (
  changedState: Omit<SnackbarState, 'isOpening'>,
) => void;

export default (): {
  snackbarState: SnackbarState;
  closeSnackbar: () => void;
  openSnackbar: OpenSnackbar;
} => {
  const [state, setState] = useState(initialState);

  const closeSnackbar = () =>
    setState({ ...initialState, variant: state.variant });

  const openSnackbar: OpenSnackbar = ({ variant, message }) =>
    setState({
      isOpening: true,
      variant,
      message,
    });

  return { snackbarState: state, closeSnackbar, openSnackbar };
};
