import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  contained: {
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  outlined: {
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
