import React, { useCallback, useContext, VFC } from 'react';
import { Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { EditorState, AtomicBlockUtils } from 'draft-js';

import { uploadMedia } from '../../../../../../re-ducks/faq/actions';
import useStyles from './useStyles';
import useMediaUpload from '../../../../../hooks/useMediaUpload';
import { EditorContext } from '../../EditorContext';

const DropZone: VFC = () => {
  const dispatch = useDispatch();
  const { upload } = useMediaUpload();
  const { editorState, setEditorState, setModalOpen } =
    useContext(EditorContext);
  const onDrop = useCallback((acceptedFiles) => {
    upload(acceptedFiles, (dataUrl, fileName) => {
      dispatch(
        uploadMedia({
          dataUrl,
          fileName,
          callback: (downloadUrl) => {
            const contentStateWithEntity = editorState
              .getCurrentContent()
              .createEntity('image', 'IMMUTABLE', { src: downloadUrl });
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, {
              currentContent: contentStateWithEntity,
            });
            setModalOpen(false);

            return setEditorState(
              AtomicBlockUtils.insertAtomicBlock(
                newEditorState,
                entityKey,
                ' ',
              ),
            );
          },
        }),
      );
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const classes = useStyles();

  return (
    <div {...getRootProps()} className={classes.root}>
      <input {...getInputProps()} />
      <div className={classes.content}>
        <h3>ファイルをドロップしてアップロード</h3>
        <p>または</p>
        <Button variant="outlined" className={classes.button}>
          ファイルを選択
        </Button>
      </div>
    </div>
  );
};

export default DropZone;
