import React, { VFC } from 'react';
import { CellProps } from 'react-table';
import { makeStyles, Theme } from '@material-ui/core';
import getStorageFIleUrl from '../../../../utils/getStorageFIleUrl';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    [theme.breakpoints.down('xs')]: {
      width: 150,
      height: 150,
      margin: '0 auto',
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: '100%',
      verticalAlign: 'middle',
      objectFit: 'contain',
    },
  },
}));

const placeholder = 'http://placehold.jp/150x150.png?text=No Image';

export const ImageCell: VFC<CellProps<any>> = ({ cell }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img
        src={cell.value ? getStorageFIleUrl(cell.value) : placeholder}
        alt=""
      />
    </div>
  );
};
