import clsx from 'clsx';
import React, { VFC } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Typography, IconButton, Link } from '@material-ui/core';
import { Close, DragIndicator } from '@material-ui/icons';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import { DefinitionList } from '../../../../base/DefinitionList';
import { Content } from '../../../../../../re-ducks/actualResult/types';
import { TextBox } from '../../../../base/form/TextBox';
import { TextField } from '../../../../base/form/TextField';
import { ErrorMessage } from '../../../../base/form/ErrorMessage';
import { useStyles } from './useStyles';

export const ContentsSection: VFC = () => {
  const { control, errors, register } = useFormContext();
  const { fields, append, remove, move } = useFieldArray<Content>({
    control,
    name: 'contents',
  });
  const classes = useStyles();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    move(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId="contents">
          {(droppableProvided) => (
            <ul
              className="contents"
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {fields.map((field, index) => {
                const baseName = `contents[${index}]`;
                const baseError = errors?.contents
                  ? errors?.contents[index]
                  : undefined;

                return (
                  <Draggable
                    key={field.id}
                    draggableId={field.id || ''}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        className={classes.section}
                        key={field.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className={classes.sectionInner}>
                          <header className={classes.header}>
                            <DragIndicator fontSize="medium" />
                            <Link
                              component="button"
                              type="button"
                              underline="always"
                              variant="body2"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              削除
                            </Link>
                          </header>
                          <div className="mt-2">
                            <h2
                              className={clsx(classes.sectionTitle, {
                                error: baseError?.title,
                              })}
                            >
                              <input
                                type="text"
                                name={`${baseName}.title`}
                                ref={register({
                                  required: '見出しを入力してください',
                                })}
                                placeholder="イベント説明の見出し"
                                defaultValue={field.title}
                              />
                              <span className={classes.required}>必須</span>
                            </h2>
                            <ErrorMessage error={baseError?.title} />
                          </div>
                          <div className="mt-5">
                            <TextField
                              label="イベントの詳細文"
                              type="text"
                              name={`${baseName}.text`}
                              inputRef={register({
                                required: '説明文を入力してください',
                              })}
                              error={!!baseError?.text}
                              defaultValue={field.text}
                              multiline
                              required
                              rows={8}
                              placeholder={`イベントの説明文を入力してください。\nイベントの説明文を入力してください。\nイベントの説明文を入力してください`}
                            />
                            <ErrorMessage error={baseError?.text} />
                          </div>
                        </div>
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </ul>
          )}
        </Droppable>

        <button
          type="button"
          className={clsx('button', classes.addButton)}
          onClick={() =>
            append({ title: '', text: '', image: '', youtube: '' })
          }
        >
          説明文セクションを追加
        </button>
      </div>
    </DragDropContext>
  );
};
