import React, { FC } from 'react';
import Backdrop from '@material-ui/core/Backdrop';

import { useStyles } from './useStyles';

export interface DotProgressProps {
  opening: boolean;
  onClose?: () => void;
}

export const DotProgress: FC<DotProgressProps> = ({ opening, onClose }) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={opening} onClick={onClose}>
      <span />
      <span />
      <span />
    </Backdrop>
  );
};
