import React, { VFC } from 'react';
import { IoReload } from 'react-icons/io5';
import IconButton from '@material-ui/core/IconButton';

import { useStyles } from './useStyles';

export interface ReloadButtonProps {
  label?: string;
}

const ReloadButton: VFC<ReloadButtonProps> = ({ label }) => {
  const classes = useStyles();
  const onClick = () => window.location.reload();

  if (label) {
    <button className="button" onClick={onClick}>
      <IoReload />
      <span className={classes.text}>{label}</span>
    </button>;
  }

  return (
    <IconButton onClick={onClick}>
      <IoReload />
    </IconButton>
  );
};

export default ReloadButton;
