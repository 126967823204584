import React, { VFC } from 'react';
import { CellProps } from 'react-table';
import Select, { OptionTypeBase, ValueType } from 'react-select';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { selectRole } from '../../../../../re-ducks/collaborator/selector';
import { updateRow } from '../../../../../re-ducks/event/actions';
import { selectEvents } from '../../../../../re-ducks/event/selector';
import { selectSelectedMaker } from '../../../../../re-ducks/app/selector';
import useSnackbar from '../../../../hooks/useSnackbar';
import { Snackbar } from '../../../case/feedback/Snackbar';
import { eventLimit } from '../../../../../data/plan';

export const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}));

export const AcceptingReservationsCell: VFC<CellProps<any>> = ({
  row,
  cell,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedRole = useSelector(selectRole);
  const selectedEvents = useSelector(selectEvents);
  const selectedMaker = useSelector(selectSelectedMaker);
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();

  const handleSingleChange = (
    selectedOption: ValueType<OptionTypeBase, false>,
  ) => {
    const originalEvent = row.original.event;
    const newRowValue = {
      ...originalEvent,
      acceptingReservations: !!selectedOption?.value,
    };
    if (
      selectedRole !== 'cunelwork' &&
      row.original.acceptingReservations === false &&
      newRowValue.acceptingReservations === true
    ) {
      const events = selectedEvents.filter(
        ({ acceptingReservations }) => acceptingReservations,
      );
      const limit = eventLimit[selectedMaker?.plan || 'free'];
      if (events.length >= limit) {
        openSnackbar({
          variant: 'error',
          message: `ご契約中のプランでは${
            limit + 1
          }件以上受付中に設定できません。現在${
            events.length
          }件受付中に設定されています。`,
        });

        return;
      }
    }
    dispatch(updateRow({ event: newRowValue, openSnackbar }));
  };

  return (
    <div className={classes.root}>
      <Select
        name=""
        value={{
          label: cell.value ? '受付中' : '受付終了',
          value: cell.value,
        }}
        options={[
          { label: '受付中', value: true },
          { label: '受付終了', value: false },
        ]}
        onChange={handleSingleChange}
        isMulti={false}
        isDisabled={false}
        styles={{
          control: (styles) => ({ ...styles, cursor: 'pointer' }),
        }}
      />
      <Snackbar
        variant={snackbarState.variant}
        message={snackbarState.message}
        isOpening={snackbarState.isOpening}
        onClose={closeSnackbar}
      />
    </div>
  );
};
