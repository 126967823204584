import React, { FC } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import { AddPhoto } from '../../../../base/form/AddPhoto';
import { TextBox } from '../../../../base/form/TextBox';
import { ErrorMessage } from '../../../../base/form/ErrorMessage';
import { useStyles } from './useStyles';

interface Props {
  name?: string;
}

export const SliderImages: FC<Props> = ({ name = 'images' }) => {
  const { control, errors, register, watch } = useFormContext();
  const { fields, append, remove, move } = useFieldArray<{
    imageUrl: string;
    description: string;
  }>({ control, name });
  const classes = useStyles();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    move(result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId="images" direction="horizontal">
          {(droppableProvided) => (
            <ul
              className={classes.itemWrap}
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {fields.map((field, index) => {
                const baseName = `${name}[${index}]`;
                const baseError = errors?.images
                  ? errors?.images[index]
                  : undefined;

                return (
                  <Draggable
                    key={field.id}
                    draggableId={field.id || ''}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        key={field.id}
                        className={classes.item}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <input
                          type="hidden"
                          name={`${baseName}.imageUrl`}
                          value={watch(`${baseName}.imageUrl`)}
                          ref={register({
                            required: '画像を選択してください',
                          })}
                        />
                        <input
                          type="hidden"
                          name={`${baseName}.description`}
                          defaultValue={field.description}
                          ref={register()}
                        />
                        <div className={classes.imageWrap}>
                          <AddPhoto
                            name={`${baseName}.imageUrl`}
                            jpegUrl={watch(`${baseName}.imageUrl`) || ''}
                            setJpegUrl={() => ''}
                            accept="image/png,image/jpeg"
                            onClose={() => {
                              remove(index);
                            }}
                          />
                        </div>
                        <TextBox
                          name={`${baseName}.description`}
                          defaultValue={watch(`${baseName}.description`) || ''}
                          label="説明文"
                          multiline
                          maxRows={5}
                          minRows={5}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputRef={register()}
                          className={classes.description}
                        />
                        <ErrorMessage error={baseError?.imageUrl} />
                      </li>
                    )}
                  </Draggable>
                );
              })}
              <li className={classes.item}>
                <div className={classes.imageWrap}>
                  <AddPhoto
                    name=""
                    setJpegUrl={(url) => {
                      append({ imageUrl: url, description: '' });
                    }}
                    accept="image/png,image/jpeg"
                  />
                </div>
              </li>
              {droppableProvided.placeholder}
            </ul>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );
};

SliderImages.defaultProps = { name: 'images' };
