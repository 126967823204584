import { createSlice } from '@reduxjs/toolkit';
import { Filters } from 'react-table';

import { CollaboratorListTableType, CollaboratorType } from './types';
import {
  signIn,
  signOut,
  onAuthStateChanged,
  setMyAccount,
  fetchCollaborators,
  setCollaborator,
} from './actions';

export type CollaboratorState = {
  collaborator: CollaboratorType;
  collaboratorTableData: CollaboratorListTableType[];
  creating: boolean;
  fetching: boolean;
  filters: Filters<CollaboratorListTableType>;
};

const initialState: CollaboratorState = {
  collaborator: { uid: '', name: '', role: 'admin', mailAddress: '' },
  collaboratorTableData: [],
  creating: false,
  fetching: false,
  filters: [],
};

const collaboratorSlice = createSlice({
  name: 'collaborator',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // signIn
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.collaborator = action.payload;
    });
    builder.addCase(signIn.rejected, (state) => {
      state.collaborator = initialState.collaborator;
    });

    // signOut
    builder.addCase(signOut.fulfilled, (state) => {
      state.collaborator = initialState.collaborator;
    });
    builder.addCase(signOut.rejected, (state) => {
      state.collaborator = initialState.collaborator;
    });

    // onAuthStateChanged
    builder.addCase(onAuthStateChanged.fulfilled, (state) => {
      state.collaborator = { ...state.collaborator };
    });
    builder.addCase(onAuthStateChanged.rejected, (state) => {
      state.collaborator = initialState.collaborator;
    });

    // setMyAccount
    builder.addCase(setMyAccount.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(setMyAccount.fulfilled, (state, action) => {
      state.collaborator = { ...state.collaborator, ...action.payload };
      state.creating = false;
    });
    builder.addCase(setMyAccount.rejected, (state) => {
      state.collaborator = { ...state.collaborator };
      state.creating = false;
    });

    // fetchCollaborators
    builder.addCase(fetchCollaborators.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchCollaborators.fulfilled, (state, action) => {
      state.collaboratorTableData = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchCollaborators.rejected, (state) => {
      state.collaboratorTableData = [];
      state.fetching = false;
    });

    // setCollaborator
    builder.addCase(setCollaborator.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(setCollaborator.fulfilled, (state) => {
      state.creating = false;
    });
    builder.addCase(setCollaborator.rejected, (state) => {
      state.creating = false;
    });
  },
});

export default collaboratorSlice;
