import { ValueOf } from '../types/utility';

export const OUTDOORS = {
  exterior: '外観',
  balcony: 'ベランダ・バルコニー',
  garden: '庭・デッキ・縁側',
  porch: 'ポーチ',
  garage: 'ガレージ',
} as const;
export type OutdoorKeyType = keyof typeof OUTDOORS;
export type OutdoorValueType = ValueOf<typeof OUTDOORS>;

export const INDOORS = {
  entrance: '玄関',
  kitchen: 'キッチン',
  ldk: 'リビング・ダイニング',
  'loft-mezzanine': 'ロフト・中二階',
  storage: '収納スペース',
  room: '居室',
  bathroom: '風呂・浴室',
  washroom: '洗面所・脱衣所',
  toilet: 'トイレ',
  stairs: '階段',
  corridor: '廊下',
} as const;
export type IndoorKeyType = keyof typeof INDOORS;
export type IndoorValueType = ValueOf<typeof INDOORS>;
export const ROOM_PLACES = { ...OUTDOORS, ...INDOORS };
export type RoomPlaceType = IndoorKeyType | OutdoorKeyType;

/**
 * 共通
 */
/* 壁の見た目 */
export const WALL_LOOKS = {
  wallpaper: '壁紙',
  wood: '板張り・木目調',
  concrete: 'コンクリート',
  brick: 'レンガ',
  tile: 'タイル',
  mortar: 'モルタル',
  plaster: '漆喰・珪藻土',
  metal: '金属系',
  stone: '石材',
} as const;
export type WallLooksKeyType = keyof typeof WALL_LOOKS;
export type WallLooksValueType = ValueOf<typeof WALL_LOOKS>;
/* 床の見た目 */
export const FLOOR_LOOKS = {
  'light-flooring': 'フローリング(白)',
  'natural-flooring': 'フローリング(茶)',
  'dark-flooring': 'フローリング(濃茶)',
  tile: 'タイル',
  concrete: 'コンクリート',
  marble: '大理石',
  stone: '石材',
  tatami: '畳',
};
export type FloorLooksKeyType = keyof typeof FLOOR_LOOKS;
export type FloorLooksValueType = ValueOf<typeof FLOOR_LOOKS>;
/* 設備・機能 */
export const FACILITIES_AND_FUNCTIONS = {
  stairwell: '吹き抜け',
  'pellet-stove': '薪ストーブ・ペレットストーブ',
  'home-theater': 'ホームシアター',
  'indirect-lighting': '間接照明',
  'shoes-cloak': 'シューズクローク',
  'ordered-shelf': '造作家具（棚）',
  'ordered-tv-stand': '造作家具（テレビ台）',
  'ordered-washbasin': '造作家具（洗面台）',
  'ordered-desk': '造作家具（カウンター・デスク）',
  'ordered-others': '造作家具（その他）',
};
export type FacilitiesAndFunctionsKeyType =
  keyof typeof FACILITIES_AND_FUNCTIONS;
export type FacilitiesAndFunctionsValueType = ValueOf<
  typeof FACILITIES_AND_FUNCTIONS
>;
/**
 * 外観
 */
/* 階数 */
export const EXTERIOR_NUM_OF_FLOORS = {
  'one-story': '平屋',
  'two-story': '2階建て',
  'three-story-more': '3階建て以上',
} as const;
export type ExteriorNumOfFloorKeyType = keyof typeof EXTERIOR_NUM_OF_FLOORS;
export type ExteriorNumOfFloorValueType = keyof typeof EXTERIOR_NUM_OF_FLOORS;
/* 屋根の種類 */
export const EXTERIOR_ROOF_TYPES = {
  'flat-roof': '箱型',
  'gable-roof': '切妻屋根',
  'one-sided-roof': '片流れ屋根',
  'yose-roof': '寄棟屋根',
} as const;
export type ExteriorRoofTypesKeyType = keyof typeof EXTERIOR_ROOF_TYPES;
export type ExteriorRoofTypesValueType = keyof typeof EXTERIOR_ROOF_TYPES;
/**
 * キッチン
 */
/* キッチンの種類 */
export const KITCHEN_TYPES = {
  'wall-mounted': '壁付キッチン',
  'island-kitchen': 'アイランドキッチン',
  'counter-kitchen': 'カウンターキッチン',
} as const;
export type KitchenTypesKeyType = keyof typeof KITCHEN_TYPES;
export type KitchenTypesValueType = ValueOf<typeof KITCHEN_TYPES>;
/* キッチンの収納 */
export const KITCHEN_STORAGES = {
  pantry: 'パントリー',
  cupboard: 'カップボード',
} as const;
export type KitchenStoragesKeyType = keyof typeof KITCHEN_STORAGES;
export type KitchenStoragesValueType = ValueOf<typeof KITCHEN_STORAGES>;
/**
 * リビング・ダイニング
 */
/* リビングの種類 */
export const LDK_TYPES = {
  'open-style': 'オープンリビング',
  'low-style': 'ロースタイルリビング',
} as const;
export type LdkTypesKeyType = keyof typeof LDK_TYPES;
export type LdkTypesValueType = ValueOf<typeof LDK_TYPES>;
/**
 * 居室
 */
/* 部屋の種類 */
export const ROOM_TYPES = {
  bedroom: '寝室',
  'children-room': '子供部屋',
  'study-room': '書斎',
  'hobby-room': '趣味部屋',
  'japanese-room': '和室',
} as const;
export type RoomTypesKeyType = keyof typeof ROOM_TYPES;
export type RoomTypesValueType = ValueOf<typeof ROOM_TYPES>;
