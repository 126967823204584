import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  OrderQueries,
  WhereQueries,
} from 'my-firebase-wrapper/dist/firestore/types';
import _ from 'lodash';

import { BatchRequestType } from '../batchRequests/types';
import { EventReservationsType } from '../event/types';
import { MakerConsultationsRepository } from '../../repositories/firebase/makerConsultations';
import { eventReservationsRepository } from '../../repositories/firebase/eventReservations';
import { BatchRequestsRepository } from '../../repositories/firebase/batchRequests';
import { eventsRepository } from '../../repositories/firebase/events';
import { makersRepository } from '../../repositories/firebase/makers';
import { makerContactsRepository } from '../../repositories/firebase/makerContacts';
import { MakerContactType, MakerContactTableData } from './types';
import { AsyncThunkConfig } from '../store';
import { MakerType } from '../maker/types';
import { MakerConsultationsType } from '../makerConsultations/types';
import { OpenSnackbar } from '../../presentation/hooks/useSnackbar';

export const fetchMakerContacts = createAsyncThunk<
  MakerContactTableData[],
  {
    whereQueries: WhereQueries<MakerContactType>;
  },
  AsyncThunkConfig<undefined>
>('makerContacts/fetchMakerContacts', async (args, thunkAPI) => {
  try {
    const { whereQueries } = args;
    let collectionRef: any = makerContactsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    const makerContacts: MakerContactType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    collectionRef = MakerConsultationsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    const makerConsultations: MakerConsultationsType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    collectionRef = eventReservationsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    const eventReservations: EventReservationsType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    collectionRef = BatchRequestsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where('makerIds', 'array-contains', value);
    });
    const batchRequests: BatchRequestType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    const makersSnapshot = await makersRepository
      .where('publishing', '==', true)
      .get();
    const makers = makersSnapshot.docs.length
      ? makersSnapshot.docs.map((doc) => doc.data())
      : [];

    const makerContactTableData: MakerContactTableData[] = makerContacts?.length
      ? makerContacts?.map((contact) => {
          const maker = makers.find((m) => m.uid === contact.makerId);

          return {
            uid: contact.uid,
            makerName: maker?.name,
            inquiryType: contact.inquiryType,
            name: contact.name,
            date: contact.date,
            mailAddress: contact.mailAddress,
            tel: contact.tel,
            contactData: contact,
          } as MakerContactTableData;
        })
      : [];
    const makerConsultationsTableData: MakerContactTableData[] =
      makerConsultations?.length
        ? makerConsultations?.map((contact) => {
            const maker = makers.find((m) => m.uid === contact.makerId);

            return {
              uid: contact.uid,
              makerName: maker?.name,
              inquiryType: '個別相談',
              name: contact.name,
              date: contact.date,
              mailAddress: contact.mailAddress,
              tel: contact.tel,
              contactData: contact,
            } as MakerContactTableData;
          })
        : [];
    const eventTableData = eventReservations?.length
      ? await Promise.all(
          eventReservations.map(async (contact) => {
            const event = await eventsRepository.fetchByDocId(contact.eventId);
            const maker = makers.find((m) => m.uid === event?.makerId);

            return {
              uid: contact.uid,
              makerName: maker?.name,
              inquiryType: 'イベント予約',
              name: contact.reservationName,
              date: contact.createDate,
              mailAddress: contact.mailAddress,
              tel: contact.tel,
              contactData: { ...contact, eventName: event?.title },
            } as MakerContactTableData;
          }),
        )
      : [];
    const batchRequestTableData = batchRequests?.length
      ? await Promise.all(
          batchRequests?.map(async (contact) => {
            const makerNames = makers
              .filter((m) => contact.makerIds.some((muid) => muid === m.uid))
              .map(({ name }) => name);

            return {
              uid: contact.uid,
              makerName: `${makerNames.length}件`,
              inquiryType: '一括資料請求',
              name: contact.name,
              date: contact.date,
              mailAddress: contact.mailAddress,
              tel: contact.tel,
              documentRequest: undefined,
              contactData: contact,
              makerNames,
            } as MakerContactTableData;
          }),
        )
      : [];

    return [
      ...makerContactTableData,
      ...makerConsultationsTableData,
      ...batchRequestTableData,
      ...eventTableData,
    ];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const updateBatchRequest = createAsyncThunk<
  BatchRequestType,
  {
    batchRequest: BatchRequestType;
    openSnackbar: OpenSnackbar;
    closeSnackbar: () => void;
  },
  AsyncThunkConfig<undefined>
>('makerContacts/updateBathRequest', async (args, thunkAPI) => {
  try {
    await BatchRequestsRepository.update(args.batchRequest);

    args.openSnackbar({
      variant: 'success',
      message: '一括資料請求の更新が完了しました',
    });

    setTimeout(() => {
      args.closeSnackbar();
    }, 3000);

    return args.batchRequest;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        '一括資料請求の更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const updateConsultation = createAsyncThunk<
  MakerConsultationsType,
  {
    consultation: MakerConsultationsType;
    openSnackbar: OpenSnackbar;
    closeSnackbar: () => void;
  },
  AsyncThunkConfig<undefined>
>('makerContacts/updateConsultation', async (args, thunkAPI) => {
  try {
    await MakerConsultationsRepository.update(args.consultation);

    args.openSnackbar({
      variant: 'success',
      message: '個別相談の更新が完了しました',
    });

    setTimeout(() => {
      args.closeSnackbar();
    }, 3000);

    return args.consultation;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        '個別相談の更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const updateContact = createAsyncThunk<
  MakerContactType,
  {
    makerContact: MakerContactType;
    openSnackbar: OpenSnackbar;
    closeSnackbar: () => void;
  },
  AsyncThunkConfig<undefined>
>('makerContacts/updateMakerContact', async (args, thunkAPI) => {
  try {
    await makerContactsRepository.update(args.makerContact);

    args.openSnackbar({
      variant: 'success',
      message: `${args.makerContact.inquiryType}の更新が完了しました`,
    });

    setTimeout(() => {
      args.closeSnackbar();
    }, 3000);

    return args.makerContact;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        `${args.makerContact.inquiryType}の更新ができませんでした。もう一度お試しください。`,
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const updateReservation = createAsyncThunk<
  EventReservationsType,
  {
    reservation: EventReservationsType;
    openSnackbar: OpenSnackbar;
    closeSnackbar: () => void;
  },
  AsyncThunkConfig<undefined>
>('makerContacts/updateReservation', async (args, thunkAPI) => {
  try {
    await eventReservationsRepository.update(args.reservation);

    args.openSnackbar({
      variant: 'success',
      message: '予約の更新が完了しました',
    });

    setTimeout(() => {
      args.closeSnackbar();
    }, 3000);

    return args.reservation;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        '予約の更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});
