import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { fetchEventReservations } from '../../../../../re-ducks/event/actions';
import {
  selectCurrentEvent,
  selectEventReservations,
} from '../../../../../re-ducks/event/selector';
import { useStyles } from './useStyles';
import { TableRow } from './TableRow';

export const EventReservations = () => {
  const currentEvent = useSelector(selectCurrentEvent);
  const eventReservations = useSelector(selectEventReservations);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      fetchEventReservations({
        whereQueries: [
          {
            fieldPath: 'eventId',
            opStr: '==',
            value: currentEvent.uid,
          },
        ],
        orderQueries: [],
      }),
    );
  }, []);

  return (
    <div className={clsx('mt-5', classes.tableWrap)}>
      {eventReservations.length ? (
        <table className={clsx('table', classes.table)}>
          <thead>
            <tr>
              <th>予約者名</th>
              <th>メールアドレス</th>
              <th>電話番号</th>
              <th>予約日時</th>
              <th>ご希望など</th>
              <th>予約ステータス</th>
              <th>参加ステータス</th>
              <th>メモ</th>
            </tr>
          </thead>
          <tbody>
            {eventReservations.map((eventReservation) => {
              return (
                <TableRow
                  key={eventReservation.uid}
                  eventReservation={eventReservation}
                />
              );
            })}
          </tbody>
        </table>
      ) : (
        <p>予約申込者が存在しません</p>
      )}
    </div>
  );
};
