export const wpBaseApi = `https://magazine.mockhouse.jp/wp-json`;

export const wpEndpoint = `${wpBaseApi}/wp/v2`;

export const magazineEndpoint = `${wpBaseApi}/magazine/v1`;

export const wpPostsApi = `${magazineEndpoint}/posts/`;

export const wpCategoriesApi = `${magazineEndpoint}/categories/`;

export const wpTagsApi = `${magazineEndpoint}/tags`;
