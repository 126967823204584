import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  appBarMenu: {
    width: '100%',
  },
  appBarMenuPaper: {
    width: 320,
  },
  appBarMenuInner: {
    padding: `${theme.spacing(2)}px`,
    color: theme.palette.text.primary,
    background: theme.palette.common.white,
  },
  appBarMenuItem: {
    fontSize: theme.typography.pxToRem(16),
  },
  appBarMenuItemLink: {
    color: theme.palette.text.primary,
  },
  makers: {
    '& button': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: `${theme.spacing(1)}px 0`,
      borderTop: `1px solid #eee`,
      borderBottom: `1px solid #eee`,
      fontSize: 12,
      textAlign: 'left',
    },
  },
  appBarBottomMenu: {
    margin: `0`,
    padding: 0,
    '& li': {
      padding: 0,
    },
  },
  logoutButton: {
    margin: `0 auto`,
  },
}));
