import React, { FC } from 'react';
import { FieldError, FieldErrors } from 'react-hook-form';

import { useStyles } from './useStyles';

export interface ErrorMessageProps {
  error?: FieldError | FieldErrors<any>;
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ error }) => {
  const classes = useStyles();
  if (!error?.types) return null;

  if (error instanceof Array && error.length) {
    return <div>{error[0].message}</div>;
  }

  return (
    <div>
      {Object.entries(error.types).map(([key, value]) => (
        <p key={key} className={classes.error}>
          {typeof value === 'string' ? value : ''}
        </p>
      ))}
    </div>
  );
};
