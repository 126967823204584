import React, { useState, VFC } from 'react';
import { AiOutlineTags } from 'react-icons/ai';
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { PageTitle } from '../../case/PageTitle';
import { AdminWrapper } from '../../domain/AdminWrapper';
import ThemeList from '../../domain/theme/ThemeList';
import PublishActualResults from '../../domain/theme/PublishActualResults';
import { selectRole } from '../../../../re-ducks/collaborator/selector';

export const Theme: VFC = () => {
  const [tab, setTab] = useState(0);
  const role = useSelector(selectRole);

  return (
    <AdminWrapper>
      <PageTitle Icon={<AiOutlineTags />}>こだわり特集</PageTitle>

      {role === 'cunelwork' ? (
        <>
          <Paper>
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, currentValue) => setTab(currentValue)}
              aria-label="disabled tabs example"
            >
              <Tab label="テーマ一覧" />
              <Tab label="掲載履歴" />
            </Tabs>
          </Paper>

          <Box mt={2}>
            {tab === 0 ? <ThemeList /> : null}
            {tab === 1 ? <PublishActualResults /> : null}
          </Box>
        </>
      ) : (
        <PublishActualResults />
      )}
    </AdminWrapper>
  );
};
