import React, { VFC } from 'react';
import { useSelector } from 'react-redux';
import { CellProps } from 'react-table';

import { selectRole } from '../../../../../re-ducks/collaborator/selector';

export const MakerNameCell: VFC<CellProps<any>> = ({ cell }) => {
  const myRole = useSelector(selectRole);
  const isCw = myRole === 'cunelwork';
  const { inquiryType, makerNames } = cell.row.original;
  if (!isCw || inquiryType !== '一括資料請求') return <>{cell.value}</>;

  return (
    <ul>
      {makerNames?.map((name: string) => (
        <li key={name}>{name}</li>
      ))}
    </ul>
  );
};
