import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  currentPassword: {
    marginTop: theme.spacing(3),
  },
  note: {
    marginTop: theme.spacing(1),
    listStyle: 'none',
  },
  buttonWrap: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(3)}px auto 0`,
  },
}));
