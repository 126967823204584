import React, { VFC } from 'react';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';

import { PLANS } from '../../../../data/plan';
import { MakerType } from '../../../../re-ducks/maker/types';
import { SingleSelectField } from '../../base/form/SelectField/SingleSelectField';

interface Props {
  makers: MakerType[];
}

const MakerSelect: VFC<Props> = ({ makers }) => {
  const formMethods = useFormContext();
  const { control } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <Controller
        name="makerId"
        control={control}
        defaultValue=""
        rules={{
          validate: {
            required: (value) => {
              return value && makers.find((maker) => maker.uid === value)
                ? true
                : 'ビルダーを選択してください';
            },
          },
        }}
        render={({ name }) => (
          <SingleSelectField
            name={name}
            options={makers.map((maker) => ({
              label: `${maker.name}（${PLANS[maker.plan || 'free']}）`,
              value: maker.uid,
            }))}
            disabled={!makers.length}
          />
        )}
        isClearable
      />
    </FormProvider>
  );
};

export default MakerSelect;
