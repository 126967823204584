import React, { ReactElement, ReactNode, VFC } from 'react';

interface Props {
  url: string;
  external: boolean;
  children: ReactNode;
}

const LinkConverter: VFC<Props> = ({ url, external, children }) => {
  const props = external
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  return (
    <a href={url} {...props}>
      {children}
    </a>
  );
};

export default LinkConverter;
