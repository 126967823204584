import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';

import useQueryWithMyRole from '../../../../hooks/useQueryWithMyRole';
import { Table } from '../../../base/Table';
import { DefaultColumnFilter } from '../../../base/Table/DefaultColumnFilter';
import { DotProgress } from '../../../case/feedback/DotProgress';
import { MakerIdCell } from '../MakerIdCell';
import { MakerIdFilter } from '../MakerIdFilter';
import { setMakerContact } from '../../../../../re-ducks/makerContacts/slice';
import {
  MakerContactTableData,
  MakerContactType,
} from '../../../../../re-ducks/makerContacts/types';
import { fetchMakerContacts } from '../../../../../re-ducks/makerContacts/actions';
import {
  selectMakerContacts,
  selectFetching,
} from '../../../../../re-ducks/makerContacts/selector';
import { selectSelectedMaker } from '../../../../../re-ducks/app/selector';
import { selectRole } from '../../../../../re-ducks/collaborator/selector';
import { inquiryTypeFilter, InquiryTypeFilter } from '../InquiryTypeFilter';
import { monthFilter, YearMonthFilter } from '../YearMonthFilter';
import { MakerNameCell } from '../MakerNameCell';
import { DocumentRequest } from '../DocumentRequestCell';

export const MakerContactListTable = () => {
  const dispatch = useDispatch();
  const myRole = useSelector(selectRole);
  const selectedMaker = useSelector(selectSelectedMaker);
  const makerContacts = useSelector(selectMakerContacts);
  const fetching = useSelector(selectFetching);

  const onlyMyMakersContactsQuery = useQueryWithMyRole<MakerContactType>({
    fieldPath: 'makerId',
    opStr: '==',
    value: selectedMaker?.uid,
  });

  useEffect(() => {
    dispatch(
      fetchMakerContacts({
        whereQueries: onlyMyMakersContactsQuery
          ? [onlyMyMakersContactsQuery]
          : [],
      }),
    );
  }, []);

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  return (
    <Table<MakerContactTableData>
      tableOptions={{
        data: makerContacts,
        columns: [
          {
            Header: '名前',
            accessor: 'name',
            Filter: DefaultColumnFilter,
          },
          {
            Header: 'お問い合わせ種別',
            accessor: 'inquiryType',
            Filter: InquiryTypeFilter,
            filter: inquiryTypeFilter,
          },
          {
            Header: 'お問合せ先建築事業者・ブランド',
            accessor: 'makerName',
            Filter: MakerIdFilter,
            Cell: MakerNameCell,
          },
          {
            Header: '資料請求済み',
            accessor: 'documentRequest',
            disableFilters: true,
            disableSortBy: true,
            Cell: DocumentRequest,
          },
          {
            Header: 'お問い合わせ月',
            accessor: 'date',
            Filter: YearMonthFilter,
            filter: monthFilter,
          },
          {
            Header: 'メールアドレス',
            accessor: 'mailAddress',
            Filter: DefaultColumnFilter,
          },
          {
            Header: '電話番号',
            accessor: 'tel',
            Filter: DefaultColumnFilter,
          },
        ],
        initialState: {
          pageSize: 50,
          sortBy: [{ id: 'date', desc: true }],
          hiddenColumns:
            myRole === 'admin'
              ? [
                  'uid',
                  'content',
                  'pageUrl',
                  'userAgent',
                  'os',
                  'browser',
                  'contactData',
                  'makerNames',
                  'documentRequest',
                ]
              : [
                  'uid',
                  'content',
                  'pageUrl',
                  'userAgent',
                  'os',
                  'browser',
                  'contactData',
                  'makerNames',
                ],
        },
      }}
      onClick={(row) => {
        dispatch(setMakerContact(row.original));
        dispatch(push(`/makerContacts/detail/${row.original.uid}`));
      }}
      classes={{
        root: clsx('table-container'),
        table: clsx(
          'table',
          'is-fullwidth',
          'is-scrolled',
          'is-striped',
          'is-hoverable',
          'list-table',
        ),
      }}
    />
  );
};
