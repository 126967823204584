import React, { VFC } from 'react';
import { CellProps } from 'react-table';

export interface AvatarProps {
  userId: string;
  size?: string;
  src?: string;
}

const AgeCell: VFC<CellProps<any>> = ({ cell }) => {
  return <span>{cell.value ? `${cell.value}代` : '未設定'}</span>;
};

export default AgeCell;
