import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    lineHeight: 1.75,
  },
  asterisk: {
    display: 'none',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.common.black,
    transform: 'initial',
    position: 'relative',
    '& svg': {
      order: 3,
      marginLeft: theme.spacing(2),
    },
    '&::after': {
      order: 2,
      display: 'inline-block',
      marginLeft: theme.spacing(2),
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      fontSize: 12,
      fontWeight: 'bold',
      content: '"任意"',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[500],
    },
    '& + $formControl': {
      marginTop: theme.spacing(0),
    },
  },
  required: {
    '&::after': {
      content: '"必須"',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
  },
  formControl: {},
}));
