import React, { VFC, ReactNode } from 'react';
import {
  TypographyProps,
  Table as MuiTable,
  TableBody,
  TableContainer,
} from '@material-ui/core';

import useStyles from './style';

export interface Props {
  children: ReactNode;
}

const FormTable: VFC<Props & TypographyProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <MuiTable className={classes.table} aria-label="table">
        <TableBody>{children}</TableBody>
      </MuiTable>
    </TableContainer>
  );
};

export default FormTable;
