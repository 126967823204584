import React, { FC } from 'react';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import { useStyles } from './useStyles';

export type SwitchFieldProps = SwitchProps;

export const SwitchField: FC<SwitchFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};
