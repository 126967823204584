import React, { VFC, ReactNode } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

import { useStyles } from './useStyles';

export interface DashboardAccordionProps {
  heading: ReactNode;
  detail: ReactNode;
}

export const DashboardAccordion: VFC<DashboardAccordionProps> = ({
  heading,
  detail,
}) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.root} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{
          root: classes.heading,
          expanded: classes.expanded,
          content: classes.headingContent,
        }}
      >
        <div>{heading}</div>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.detail }}>
        {detail}
      </AccordionDetails>
    </Accordion>
  );
};
