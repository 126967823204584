import { DropResult } from 'react-beautiful-dnd';

export default (
  move: (indexA: number, indexB: number) => void,
): {
  onDragEnd: (result: DropResult) => void;
} => {
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    move(result.source.index, result.destination.index);
  };

  return { onDragEnd };
};
