import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Document } from '../../../../../../re-ducks/maker/types';
import { AddPhoto } from '../../../../base/form/AddPhoto';
import { ErrorMessage } from '../../../../base/form/ErrorMessage';
import { useStyles } from './useStyles';
import { TextField } from '../../../../base/form/TextField';
import LoopFieldSection from '../../../../case/form/LoopFieldSection';

export const MakerDocuments: VFC = () => {
  const { register, setValue, getValues } = useFormContext();
  const classes = useStyles();

  return (
    <LoopFieldSection<Document>
      name="documents"
      label="資料"
      initialValue={{ title: '', description: '', image: '' }}
    >
      {(baseName, baseError, field) => {
        return (
          <div>
            <div className={classes.wrapper}>
              <div className={classes.image}>
                <div className={classes.imageWrap}>
                  <AddPhoto
                    name={`${baseName}.image`}
                    jpegUrl={getValues(`${baseName}.image`)}
                    setJpegUrl={(url) => {
                      setValue(`${baseName}.image`, url, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }}
                    registerOption={{
                      required: '画像をアップロードしてください',
                    }}
                    accept="image/png,image/jpeg"
                  />
                </div>
                <ErrorMessage error={baseError?.image} />
              </div>
              <div className={classes.textbox}>
                <div>
                  <TextField
                    label="タイトル"
                    type="text"
                    name={`${baseName}.title`}
                    inputRef={register({
                      required: 'タイトルを入力してください',
                    })}
                    defaultValue={field.title}
                    error={!!baseError?.title}
                  />
                  <ErrorMessage error={baseError?.title} />
                </div>
                <div className="mt-5">
                  <TextField
                    label="説明文"
                    name={`${baseName}.description`}
                    inputRef={register({
                      required: '説明文を入力してください',
                    })}
                    defaultValue={field.description}
                    error={!!baseError?.description}
                    multiline
                    rows={3}
                  />
                  <ErrorMessage error={baseError?.description} />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </LoopFieldSection>
  );
};
