import { createSlice } from '@reduxjs/toolkit';

import { WpPostDetail, WpPostsResult } from './types';
import { fetchInformations, fetchTheInformation } from './actions';

export type WordPressState = {
  informations: WpPostsResult;
  information: WpPostDetail | null;
  fetching: boolean;
};

export const initialState: WordPressState = {
  informations: {
    results: [],
    foundPosts: 0,
    maxPages: 0,
  },
  information: null,
  fetching: false,
};

const wordpressSlice = createSlice({
  name: 'wordpress',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchInformations
    builder.addCase(fetchInformations.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchInformations.fulfilled, (state, action) => {
      state.informations = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchInformations.rejected, (state) => {
      state.informations = initialState.informations;
      state.fetching = false;
    });
    // fetchTheInformation
    builder.addCase(fetchTheInformation.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchTheInformation.fulfilled, (state, action) => {
      state.information = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchTheInformation.rejected, (state) => {
      state.informations = initialState.informations;
      state.fetching = false;
    });
  },
});

export default wordpressSlice;
