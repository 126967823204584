import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import IconCollaborator from '../../base/Icon/IconCollaborator';
import { PageTitle } from '../../case/PageTitle';
import { AddButton } from '../../case/button/AddButton';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { CollaboratorListTable } from '../../domain/CollaboratorListTable';

export const Collaborator: FC = () => {
  const dispatch = useDispatch();

  return (
    <AdminWrapper>
      <PageTitle Icon={<IconCollaborator />}>コラボレーター</PageTitle>
      <AddButton
        label="コラボレーターを追加する"
        onClick={() => dispatch(push('/collaborator/edit'))}
      />
      <CollaboratorListTable />
    </AdminWrapper>
  );
};
