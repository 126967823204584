import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createEvent,
  fetchEvents,
  updateRow,
  fetchEventReservations,
  updateReservation,
} from './actions';

import { EventListTableType, EventReservationsType, EventType } from './types';

export type EventState = {
  events: EventType[];
  currentEvent: EventType;
  eventReservations: EventReservationsType[];
  eventTableData: EventListTableType[];
  fetching: boolean;
  creating: boolean;
};

export const initialState: EventState = {
  events: [],
  currentEvent: {
    acceptingReservations: false,
    publishing: false,
    title: '',
    eventType: 'openhouse',
    eventTags: [],
    area: [],
    fullYear: false,
    businessDays: [0, 1, 2, 3, 4, 5, 6],
    startDate: null,
    endDate: null,
    time: '',
    timeRequired: '',
    images: [],
    contents: [
      {
        title: '',
        text: '',
      },
      { title: '', text: '' },
    ],
    price: '',
    eventPlace: '',
    eventMap: '',
    eventPlaceNote: '',
    // 自動入力
    uid: '',
    createDate: '',
    updateDate: '',
    makerId: '',
    officialEvent: false,
    slug: '',
  },
  eventReservations: [],
  eventTableData: [],
  fetching: false,
  creating: false,
};

export const initialReservation: EventReservationsType = {
  uid: '',
  eventId: '',
  userId: '',
  status: 'applying',
  reservationName: '',
  remarks: '',
  createDate: '',
  updateDate: '',
  mailAddress: '',
  tel: '',
  reservationDate: '',
  reservationTime: '',
  participants: {
    adults: 0,
    children: 0,
    childAge: '',
  },
  memo: '',
  attendanceStatus: '',
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvent: (state, action: PayloadAction<EventType>) => {
      state.currentEvent = action.payload;
    },
    setEventTableData: (state, action: PayloadAction<EventListTableType[]>) => {
      state.eventTableData = action.payload;
    },
    setEventReservations: (state) => {
      state.eventReservations = initialState.eventReservations;
    },
    reset: (state) => {
      state.creating = initialState.creating;
      state.fetching = initialState.fetching;
      state.events = initialState.events;
      state.currentEvent = initialState.currentEvent;
      state.eventReservations = initialState.eventReservations;
    },
  },
  extraReducers: (builder) => {
    // fetchEvents
    builder.addCase(fetchEvents.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.events = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchEvents.rejected, (state) => {
      state.events = [];
      state.fetching = false;
    });
    // createMaker
    builder.addCase(createEvent.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(createEvent.fulfilled, (state, action) => {
      state.currentEvent = action.payload;
      state.creating = false;
    });
    builder.addCase(createEvent.rejected, (state) => {
      state.creating = false;
    });
    // updateRow
    builder.addCase(updateRow.pending, (state) => {
      state.creating = true;
    });
    builder.addCase(updateRow.fulfilled, (state, action) => {
      state.currentEvent = action.payload;
      state.events = state.events.map((event) => {
        if (event.uid === action.payload.uid) {
          return action.payload;
        }

        return event;
      });
      state.eventTableData = state.eventTableData.map((table) => {
        if (table.event.uid === action.payload.uid) {
          return {
            ...table,
            publishing: action.payload.publishing,
            acceptingReservations: action.payload.acceptingReservations,
          };
        }

        return table;
      });
      state.creating = false;
    });
    builder.addCase(updateRow.rejected, (state) => {
      state.creating = false;
    });
    // fetchEventReservations
    builder.addCase(fetchEventReservations.fulfilled, (state, action) => {
      state.eventReservations = action.payload;
    });
    builder.addCase(fetchEventReservations.rejected, (state) => {
      state.events = [];
    });
    // updateReservation
    builder.addCase(updateReservation.fulfilled, (state, action) => {
      state.eventReservations = state.eventReservations.map((reservation) => {
        if (reservation.uid === action.payload.uid) return action.payload;

        return reservation;
      });
    });
  },
});

export const { setEvent, setEventTableData, setEventReservations, reset } =
  eventSlice.actions;

export default eventSlice;
