import React, { VFC } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { ReactComponent as Icon } from '../../../../assets/images/icon/icon_follower.svg';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 42,
    height: 42,

    '& .target': {
      fill: '#4a4a4a',
    },
  },
}));

interface Props {
  className?: string;
}

const IconFollower: VFC<Props> = ({ className }) => {
  const classes = useStyles();

  return <Icon className={clsx(classes.root, className)} />;
};

export default IconFollower;
