import { createSelector } from '@reduxjs/toolkit';
import { RouterState } from 'connected-react-router';

import { RootState } from '../store';

export const selectSelf = (state: RootState): RouterState => state.router;

export const selectLocationState = createSelector(
  selectSelf,
  (state) => state.location.state as any,
);

export const selectPathname = createSelector(
  selectSelf,
  (state) => state.location.pathname,
);
