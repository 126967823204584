import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import { useFormContext, useFieldArray, ArrayField } from 'react-hook-form';
import { Link } from '@material-ui/core';
import { DragIndicator } from '@material-ui/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import useDragEnd from '../../../../hooks/useDragEnd';
import { useStyles } from './useStyles';

interface Props<T> {
  name: string;
  label: string;
  initialValue: T;
  children: (
    baseName: string,
    baseError: any | undefined,
    field: Partial<ArrayField<T, 'id'>>,
    index: number,
  ) => ReactNode;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const LoopFieldImage = <T extends {}>({
  name,
  label,
  initialValue,
  children,
}: Props<T>): ReactElement => {
  const { control, errors } = useFormContext();
  const { fields, append, remove, move } = useFieldArray<T>({
    control,
    name,
  });
  const { onDragEnd } = useDragEnd(move);
  const classes = useStyles();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId={name} direction="horizontal">
          {(droppableProvided) => (
            <ul
              {...droppableProvided.droppableProps}
              className={classes.list}
              ref={droppableProvided.innerRef}
            >
              {fields.map((field, index) => {
                const baseName = `${name}[${index}]`;
                const baseError =
                  errors[name] && errors[name][index]
                    ? errors[name][index]
                    : undefined;

                return (
                  <Draggable
                    key={field.id}
                    draggableId={field.id || ''}
                    index={index}
                  >
                    {(provided) => (
                      <li
                        key={field.id}
                        className={classes.item}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <header className={classes.header}>
                          <DragIndicator fontSize="small" />
                          <Link
                            component="button"
                            type="button"
                            underline="always"
                            variant="body2"
                            onClick={() => {
                              remove(index);
                            }}
                            className={classes.closeButton}
                          >
                            削除
                          </Link>
                        </header>
                        {children(baseName, baseError, field, index)}
                      </li>
                    )}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </ul>
          )}
        </Droppable>

        <button
          type="button"
          className={clsx('button', classes.addButton)}
          onClick={() => append(initialValue)}
        >
          {label}を追加
        </button>
      </div>
    </DragDropContext>
  );
};

export default LoopFieldImage;
