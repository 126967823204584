import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MakerContactTableData } from './types';
import {
  fetchMakerContacts,
  updateBatchRequest,
  updateConsultation,
  updateContact,
  updateReservation,
} from './actions';

export type MakerContactsState = {
  makerContacts: MakerContactTableData[];
  makerContact: MakerContactTableData;
  fetching: boolean;
};

export const initialState: MakerContactsState = {
  makerContacts: [],
  makerContact: {
    uid: '',
    makerName: '',
    inquiryType: 'お問い合わせ',
    name: '',
    date: '',
    mailAddress: '',
    tel: '',
    documentRequest: '',
    contactData: '',
    makerNames: [],
  },
  fetching: false,
};

const makerContactsSlice = createSlice({
  name: 'makerContacts',
  initialState,
  reducers: {
    resetMakerContacts: (state) => {
      state.fetching = false;
      state.makerContact = initialState.makerContact;
      state.makerContacts = initialState.makerContacts;
    },
    setMakerContact: (state, action: PayloadAction<MakerContactTableData>) => {
      state.makerContact = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetchMakerContacts
    builder.addCase(fetchMakerContacts.pending, (state) => {
      state.fetching = true;
    });
    builder.addCase(fetchMakerContacts.fulfilled, (state, action) => {
      state.makerContacts = action.payload;
      state.fetching = false;
    });
    builder.addCase(fetchMakerContacts.rejected, (state) => {
      state.makerContacts = [];
      state.fetching = false;
    });
    // updateReservation
    builder.addCase(updateReservation.fulfilled, (state, action) => {
      state.makerContact = {
        ...state.makerContact,
        contactData: action.payload,
      };
    });
    // updateBatchRequest
    builder.addCase(updateBatchRequest.fulfilled, (state, action) => {
      state.makerContact = {
        ...state.makerContact,
        contactData: action.payload,
      };
    });
    // updateConsultation
    builder.addCase(updateConsultation.fulfilled, (state, action) => {
      state.makerContact = {
        ...state.makerContact,
        contactData: action.payload,
      };
    });
    // updateContact
    builder.addCase(updateContact.fulfilled, (state, action) => {
      state.makerContact = {
        ...state.makerContact,
        contactData: action.payload,
      };
    });
  },
});

export const { setMakerContact, resetMakerContacts } =
  makerContactsSlice.actions;

export default makerContactsSlice;
