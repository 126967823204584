import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    margin: `${theme.spacing(-0.5)}px ${theme.spacing(-1)}px`,
    '&::after': {
      display: 'block',
      content: '""',
      width: '100%',
      height: 1,
      margin: `0 ${theme.spacing(1)}px`,
      backgroundColor: theme.palette.grey['400'],
    },
    '&:last-child': {
      marginTop: theme.spacing(0.5),
      '&::after': {
        display: 'none',
      },
    },
    '& > *': {
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
  },
  button: {
    boxSizing: 'border-box',
    lineHeight: 1,
    '& .MuiButton-label': {
      display: 'block',
    },
    '& svg': {
      verticalAlign: 'middle',
    },
    '& svg + span': {
      marginLeft: theme.spacing(1),
      lineHeight: '24px',
      verticalAlign: 'middle',
    },
    '& svg + span.sub': {
      marginLeft: 0,
      verticalAlign: 'sub',
    },
  },
}));

export default useStyles;
