import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    width: '100%',
    maxWidth: 750,
    margin: `0 auto`,
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
  },
  date: {
    marginBottom: theme.spacing(1),
    color: theme.palette.grey[400],
  },
  title: {
    marginBottom: theme.spacing(2),
    fonWeight: 'bold',
  },
  image: {
    display: 'block',
    '& img': {
      height: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
}));
