import React, { VFC } from 'react';
import { CellProps } from 'react-table';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { FaPen } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import {
  setEvent,
  setEventReservations,
} from '../../../../../re-ducks/event/slice';

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

export const ButtonCell: VFC<CellProps<any>> = ({ row, cell }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setEvent(row.original.event));
    dispatch(setEventReservations());
    dispatch(push(`/event/edit/${row.original.event.uid}`));
  };

  return (
    <div>
      <button className={clsx('button', classes.button)} onClick={handleClick}>
        <FaPen />
        編集
      </button>
    </div>
  );
};
