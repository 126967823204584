import React, { VFC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { Typography } from '@material-ui/core';
import { selectTheme } from '../../../../../../re-ducks/themes/selector';
import { ActualImage } from '../../../../../../re-ducks/themes/types';
import { fetchActualImages } from '../../../../../../re-ducks/themes/actions';

import LoopFieldImage from '../../../../case/form/LoopFieldImage';
import Photo from './ActualImage';

interface Props {
  baseName: string;
  index: number;
}

export const ActualImages: VFC<Props> = ({ baseName, index }) => {
  const { control, setValue, register, watch } = useFormContext();
  const dispatch = useDispatch();
  const { actualResults } = useSelector(selectTheme);

  const selectedActualResult: string =
    useWatch({
      name: `${baseName}.actualResultUid`,
      control,
      defaultValue: '',
    }) || '';

  useDebounce(
    () => {
      dispatch(
        fetchActualImages({
          actualResultId: selectedActualResult,
        }),
      );
    },
    200,
    [selectedActualResult],
  );

  return (
    <div>
      <Typography variant="body2" className="mb-2 has-text-weight-bold">
        こだわり特集ページに表示する画像を選択してください（複数選択可能）
      </Typography>
      <LoopFieldImage<ActualImage>
        name={`${baseName}.actualImages`}
        label="写真"
        initialValue={{
          uid: '',
          imageUrl: '',
        }}
      >
        {(baseName2, baseError2, field2, index2) => {
          return (
            <div>
              <Photo
                defaultValue={
                  actualResults
                    ? actualResults[index]?.actualImages[index2]
                    : undefined
                }
                baseName={baseName2}
                field={field2}
                selectedActualResult={selectedActualResult}
              />
            </div>
          );
        }}
      </LoopFieldImage>
    </div>
  );
};
