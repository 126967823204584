import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';

import { db } from './index';
import { MakerContactType } from '../../re-ducks/makerContacts/types';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';

export const makerContactsRepository: Collection<MakerContactType> =
  db.collection<MakerContactType>({
    collectionPath: 'makerContacts',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        date: convertToTimestampFromString(obj.date),
      };
    },
    decode: (doc) => {
      const data = doc.data();
      if (!data)
        return {
          uid: '',
          name: '',
          date: '',
          mailAddress: '',
          tel: '',
          inquiryType: 'お問い合わせ',
          content: '',
          postalCode: '',
          address: '',
          pageUrl: '',
          makerId: '',
          userAgent: '',
          os: '',
          browser: '',
          memo: '',
        };

      return {
        ...data,
        inquiryType: data.inquiryType || 'お問い合わせ',
        date: convertToDateFromTimestamp(data.date) || '',
      };
    },
  });
