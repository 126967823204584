import React, { VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { PageTitle } from '../../case/PageTitle';
import FAQ from '../../base/Icon/IconFaq';
import { FAQ as FaqType } from '../../../../re-ducks/faq/types';
import FaqForm from '../../domain/faq/FaqForm';
import { selectSelectedFaq } from '../../../../re-ducks/faq/selector';

const Detail: VFC = () => {
  const selectedFaq = useSelector(selectSelectedFaq);
  const defaultValues: FaqType = {
    publishing: selectedFaq.publishing,
    title: selectedFaq.title,
    slug: selectedFaq.slug,
    content: selectedFaq.content,
    faqType: selectedFaq.faqType || 'endUser',
    category: selectedFaq.category,
    createDate: selectedFaq.createDate,
    updateDate: selectedFaq.updateDate,
    uid: selectedFaq.uid,
  };
  const formMethods = useForm<FaqType>({
    criteriaMode: 'all',
    defaultValues,
  });

  useEffectOnce(() => window.scrollTo(0, 0));

  return (
    <AdminWrapper>
      <PageTitle Icon={<FAQ />}>ヘルプ</PageTitle>
      <FormProvider {...formMethods}>
        <FaqForm defaultValues={defaultValues} />
      </FormProvider>
    </AdminWrapper>
  );
};

export default Detail;
