import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { Typography, Box } from '@material-ui/core';
import { push } from 'connected-react-router';

import useSnackbar from '../../../hooks/useSnackbar';
import { useStyles } from './useStyles';
import { Snackbar } from '../../case/feedback/Snackbar';
import { SaveButton } from '../../case/button/SaveButton';
import { BackButton } from '../../case/button/BackButton';
import { DotProgress } from '../../case/feedback/DotProgress';
import { PasswordStrengthField } from '../../case/PasswordStrengthField';
import { TextBox } from '../../base/form/TextBox';
import { ErrorMessage } from '../../base/form/ErrorMessage';
import { DefinitionList } from '../../base/DefinitionList';
import { selectCreating } from '../../../../re-ducks/collaborator/selector';
import { setCollaborator } from '../../../../re-ducks/collaborator/actions';
import {
  CollaboratorFormType,
  roles,
  RoleType,
} from '../../../../re-ducks/collaborator/types';
import { selectLocationState } from '../../../../re-ducks/router/selector';
import { pwChecker } from '../../../../utils/PasswordValidate';
import { formatValue } from '../../../../utils/formatValue';
import { SelectField } from '../../base/form/SelectField';

export const CollaboratorEditForm: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locationState = useSelector(selectLocationState);
  const creating = useSelector(selectCreating);
  const defaultCollaborator = {
    uid: locationState?.collaborator.uid,
    name: locationState?.collaborator.name,
    role: locationState?.collaborator.role,
    mailAddress: locationState?.collaborator.mailAddress,
  };
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();
  const formMethods = useForm<CollaboratorFormType>({
    criteriaMode: 'all',
    defaultValues: {
      ...defaultCollaborator,
      password: '',
    },
  });

  const { register, handleSubmit, watch, errors, control } = formMethods;

  const onSubmit = handleSubmit((data) => {
    dispatch(
      setCollaborator({
        formValue: {
          ...defaultCollaborator,
          ...data,
          role: formatValue(data.role) as RoleType,
        },
        openSnackbar,
      }),
    );
  });

  const isCreatePage = !defaultCollaborator?.uid;

  const components = [
    {
      dt: <Typography>名前</Typography>,
      dd: (
        <div>
          <TextBox
            type="text"
            name="name"
            InputLabelProps={{ shrink: true }}
            inputRef={register({ required: '名前を入力してください' })}
          />
          <ErrorMessage error={errors?.name} />
        </div>
      ),
    },
    {
      dt: <Typography>権限</Typography>,
      dd: (
        <div>
          <Controller
            name="role"
            control={control}
            rules={{
              validate: {
                required: (value) => (value ? true : '権限を選択してください'),
              },
            }}
            render={({ name }) => (
              <SelectField
                name={name}
                defaultValue={{
                  label: defaultCollaborator.role,
                  value: defaultCollaborator.role,
                }}
                options={roles.map((role) => ({ label: role, value: role }))}
              />
            )}
            isClearable
          />
          <ErrorMessage error={errors.role} />
        </div>
      ),
    },
    {
      dt: <Typography>メールアドレス</Typography>,
      dd: (
        <div>
          <TextBox
            type="text"
            name="mailAddress"
            InputLabelProps={{ shrink: true }}
            inputRef={register({
              required: 'メールアドレスを入力してください',
            })}
          />
          <ErrorMessage error={errors?.mailAddress} />
        </div>
      ),
    },
    {
      dt: <Typography>パスワード</Typography>,
      dd: (
        <div>
          <PasswordStrengthField
            textFieldProps={{
              name: 'password',
              value: watch('password'),
              InputLabelProps: { shrink: true },
              inputRef: register({
                validate: {
                  min: (value: string) =>
                    pwChecker.validateMin(value)
                      ? true
                      : 'パスワードは6文字以上を設定してください',
                  max: (value: string) =>
                    pwChecker.validateMax(value)
                      ? true
                      : 'パスワードは64文字以内を設定してください',
                  hasNotSpace: (value: string) =>
                    pwChecker.hasNotSpace(value)
                      ? true
                      : 'パスワードにスペースを設定しないでください',
                },
              }),
            }}
          />
          <ErrorMessage error={errors?.password} />
        </div>
      ),
    },
  ];

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit} noValidate>
        {creating ? <DotProgress opening={creating} /> : null}
        <input type="hidden" name="uid" ref={register} />
        <DefinitionList components={components} />
        <div className={classes.buttonWrap}>
          <Box mr={2}>
            <BackButton
              type="button"
              onClick={() => dispatch(push('/collaborator'))}
            >
              一覧へ戻る
            </BackButton>
          </Box>
          <Box>
            <SaveButton type="submit">{`コラボレーターを${
              isCreatePage ? '作成' : '保存'
            }する`}</SaveButton>
          </Box>
        </div>
        <Snackbar
          variant={snackbarState.variant}
          message={snackbarState.message}
          isOpening={snackbarState.isOpening}
          onClose={closeSnackbar}
        />
      </form>
    </FormProvider>
  );
};
