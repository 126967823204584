import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';

import { Content } from '../../../../../re-ducks/actualResult/types';
import { AddPhoto } from '../../../base/form/AddPhoto';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { useStyles } from './useStyles';
import LoopFieldSection from '../../../case/form/LoopFieldSection';
import { TextField } from '../../../base/form/TextField';

export const ActualResultContents: VFC = () => {
  const { register, setValue, watch } = useFormContext();
  const classes = useStyles();

  return (
    <LoopFieldSection<Content>
      name="contents"
      label="コンセプト"
      initialValue={{ title: '', text: '', image: '', youtube: '' }}
    >
      {(baseName, baseError, field) => {
        return (
          <div>
            <div className={classes.image}>
              <AddPhoto
                name={`${baseName}.image`}
                jpegUrl={watch(`${baseName}.image`)}
                setJpegUrl={(url) => {
                  setValue(`${baseName}.image`, url);
                }}
                accept="image/png,image/jpeg"
              />
            </div>
            <div className="mt-5">
              <TextField
                label="YouTube共有コード"
                type="text"
                name={`${baseName}.youtube`}
                inputRef={register()}
                error={!!baseError?.youtube}
                defaultValue={field.youtube}
              />
              <ErrorMessage error={baseError?.youtube} />
            </div>
            <div className="mt-5">
              <TextField
                label="見出し"
                type="text"
                name={`${baseName}.title`}
                inputRef={register()}
                error={!!baseError?.title}
                defaultValue={field.title}
              />
              <ErrorMessage error={baseError?.title} />
            </div>
            <div className="mt-5">
              <TextField
                label="詳細文"
                type="text"
                name={`${baseName}.text`}
                inputRef={register({
                  required: '説明文を入力してください',
                })}
                error={!!baseError?.text}
                defaultValue={field.text}
                multiline
                required
                rows={4}
              />
              <ErrorMessage error={baseError?.text} />
            </div>
          </div>
        );
      }}
    </LoopFieldSection>
  );
};
