import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  tableWrap: {
    overflowX: 'scroll',
  },
  table: {
    width: 1600,
    whiteSpace: 'nowrap',
    '& .MuiTextField-root': {
      width: '100%',
    },
    '& .selectbox': {
      width: 200,
    },
    '& .textbox': {
      width: 300,
    },
    '& tr': {
      position: 'relative',
    },
    '& th, & td': {
      verticalAlign: 'middle',
    },
  },
  snackbar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: 100,
    transform: 'initial',
    '& .MuiAlert-standardSuccess': {
      backgroundColor: 'rgba(237, 247, 237, 0.8)',
    },
  },
}));
