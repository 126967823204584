import React, { FC } from 'react';

import IconCollaborator from '../../base/Icon/IconCollaborator';
import { PageTitle } from '../../case/PageTitle';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { CollaboratorEditForm } from '../../domain/CollaboratorEditForm';

export const CollaboratorDetail: FC = () => {
  return (
    <AdminWrapper>
      <PageTitle Icon={<IconCollaborator />}>コラボレーター</PageTitle>
      <CollaboratorEditForm />
    </AdminWrapper>
  );
};
