import React, { VFC } from 'react';
import { CellProps } from 'react-table';
import format from 'date-fns/format';

export const DateCell: VFC<CellProps<any>> = ({ cell }) => {
  const date =
    typeof cell.value === 'number'
      ? format(cell.value, 'yyyy-MM-dd HH:mm:ss')
      : '';

  return <div>{date}</div>;
};
