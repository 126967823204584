import React, { VFC } from 'react';

import IconContact from '../../base/Icon/IconContact';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { MakerContactListTable } from '../../domain/MakerContacts/MakerContactListTable';
import { PageTitle } from '../../case/PageTitle';

export const MakerContacts: VFC = () => {
  return (
    <AdminWrapper>
      <PageTitle Icon={<IconContact />}>お問い合わせ</PageTitle>
      <MakerContactListTable />
    </AdminWrapper>
  );
};
