import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  section: {
    marginBottom: theme.spacing(4),
    padding: `${theme.spacing(2)}px`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  sectionTitle: {
    margin: 0,
    marginBottom: theme.spacing(2),
    fontSize: 24,
    fontWeight: 'bold',
    borderBottom: `1px solid #000`,
  },
  sectionContent: {
    marginTop: theme.spacing(1),
  },
}));
