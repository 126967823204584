import React, { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Table } from '../../../base/Table';
import { PublishActualResultsTableData } from '../../../../../re-ducks/themes/types';
import {
  fetchPublishActualResults,
  fetchPublishActualResultsTableData,
} from '../../../../../re-ducks/themes/actions';
import {
  selectCreating,
  selectFetching,
  selectPublishActualResultsTableData,
} from '../../../../../re-ducks/themes/selector';
import { DefaultColumnFilter } from '../../../base/Table/DefaultColumnFilter';
import {
  dateAfterFilter,
  dateBeforeFilter,
  DateColumnFilter,
} from '../../event/EventListTable/DateColumnFilter';
import { DateCell } from './DateCell';
import sortDate from './sortDate';
import { MemoCell } from './MemoCell';
import { useStyles } from './useStyles';
import { selectRole } from '../../../../../re-ducks/collaborator/selector';
import { selectSelectedMaker } from '../../../../../re-ducks/app/selector';
import { PublishingStatusFilter } from './PublishingStatusFilter';
import { PublishingStatusCell } from './PublishingStatusCell';
import { BillingStatusFilter } from './BillingStatusFilter';
import { BillingStatusCell } from './BillingStatusCell';
import { StaffFilter } from './StaffFilter';
import { StaffCell } from './StaffCell';
import { DotProgress } from '../../../case/feedback/DotProgress';

const PublishActualResults: VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const role = useSelector(selectRole);
  const selectedMaker = useSelector(selectSelectedMaker);
  const publishActualResults = useSelector(selectPublishActualResultsTableData);
  const fetching = useSelector(selectFetching);
  const creating = useSelector(selectCreating);

  useEffect(() => {
    dispatch(
      fetchPublishActualResults({
        whereQueries: [],
        orderQueries: [],
      }),
    );

    dispatch(
      fetchPublishActualResultsTableData({
        whereQueries:
          role === 'admin'
            ? [
                {
                  fieldPath: 'makerId',
                  opStr: '==',
                  value: selectedMaker?.uid,
                },
              ]
            : [],
        orderQueries: [],
      }),
    );
  }, []);

  return (
    <div className={classes.tableContainer}>
      <DotProgress opening={fetching || creating} />
      <Table<PublishActualResultsTableData>
        tableOptions={{
          data: publishActualResults || [],
          columns: [
            {
              Header: '掲載テーマ',
              accessor: 'themeName',
              Filter: DefaultColumnFilter,
              width: 300,
            },
            {
              Header: '建築実例名',
              accessor: 'actualResultName',
              Filter: DefaultColumnFilter,
            },
            {
              Header: 'ビルダー名',
              accessor: 'makerName',
              Filter: DefaultColumnFilter,
            },
            {
              Header: '掲載開始日',
              accessor: 'startDate',
              Filter: DateColumnFilter,
              filter: dateAfterFilter,
              width: 160,
              Cell: DateCell,
              sortType: sortDate,
            },
            {
              Header: '掲載終了日',
              accessor: 'endDate',
              Filter: DateColumnFilter,
              filter: dateBeforeFilter,
              width: 160,
              Cell: DateCell,
              sortType: sortDate,
            },
            {
              Header: '掲載ステータス',
              accessor: 'publishingStatus',
              Filter: PublishingStatusFilter,
              width: 160,
              Cell: PublishingStatusCell,
            },
            {
              Header: '請求ステータス',
              accessor: 'billingStatus',
              Filter: BillingStatusFilter,
              width: 160,
              Cell: BillingStatusCell,
            },
            {
              Header: '担当者',
              accessor: 'staff',
              Filter: StaffFilter,
              width: 160,
              Cell: StaffCell,
            },
            {
              Header: '契約メモ',
              accessor: 'agreementMemo',
              disableFilters: true,
              Cell: MemoCell,
              width: 320,
            },
            {
              Header: '請求メモ',
              accessor: 'requestMemo',
              disableFilters: true,
              Cell: MemoCell,
              width: 320,
            },
          ],
          initialState: {
            pageSize: 50,
            sortBy: [{ id: 'themeName', desc: true }],
            hiddenColumns: ['uid', 'publishActualResult'],
          },
        }}
        onClick={(_) => ''}
        classes={{
          root: clsx('table-container'),
          table: clsx(
            'table',
            'is-fullwidth',
            'is-scrolled',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
      />
    </div>
  );
};

export default PublishActualResults;
