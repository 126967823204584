import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Typography,
  Button,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import Logo from '../../../../assets/images/logo.svg';
import {
  selectCollaborator,
  selectRole,
} from '../../../../re-ducks/collaborator/selector';
import { selectSelectedMaker } from '../../../../re-ducks/app/selector';
import { toggleDrawer } from '../../../../re-ducks/app/slice';
import { useStyles } from './useStyles';
import AppBarMenu from '../AppBarMenu';
import useDrawerOpening from '../../../hooks/useDrawerOpening';

export const AppBar: FC = () => {
  const collaborator = useSelector(selectCollaborator);
  const myRole = useSelector(selectRole);
  const selectedMaker = useSelector(selectSelectedMaker);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();
  const { opening } = useDrawerOpening();

  return (
    <MuiAppBar
      position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: opening,
      })}
    >
      <Toolbar>
        <IconButton
          color="primary"
          aria-label="open drawer"
          onClick={() => dispatch(toggleDrawer())}
          edge="start"
          className={classNames(classes.menuButton, {
            [classes.hide]: opening,
          })}
        >
          <Menu />
        </IconButton>
        <img src={Logo} alt="Mockhouse" className={classes.logo} />
        <div className={classes.account}>
          <Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className={classes.accountButton}
          >
            <Typography className={classes.accountText}>
              <span style={{ fontWeight: 'bold' }}>{collaborator.name}</span>
              {myRole === 'cunelwork' ? '（Cunelworkアカウント）' : null}
              {myRole === 'admin' ? `（${selectedMaker?.name}）` : null}
            </Typography>
          </Button>
          <AppBarMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
        </div>
      </Toolbar>
    </MuiAppBar>
  );
};
