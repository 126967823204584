import React, { useState, useContext } from 'react';
import Popover from '@material-ui/core/Popover';
import { EditorState, RichUtils } from 'draft-js';
import { BiHeading, BiParagraph, BiBold, BiLink } from 'react-icons/bi';
import { ImQuotesLeft } from 'react-icons/im';
import { FaHighlighter } from 'react-icons/fa';
import { GoFileMedia, GoListUnordered, GoListOrdered } from 'react-icons/go';
import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { EditorContext } from '../EditorContext';
import { ButtonProps } from '../EditorButtonGroup';
import { ButtonPrimary } from '../../../base/Button';
import useStyles from './useStyles';

export default () => {
  const {
    editorState,
    setEditorState,
    editorRef,
    setModalOpen,
    anchorEle,
    setAnchorEle,
  } = useContext(EditorContext);
  const [linkValue, setLinkValue] = useState<{
    urlValue: string;
    external: boolean;
  }>({ urlValue: '', external: false });
  const classes = useStyles();

  const blockStyleButtons: ButtonProps[] = [
    {
      id: 'heading2',
      label: (
        <>
          <BiHeading size={24} />
          <span>大見出し</span>
        </>
      ),
      setFunc: () =>
        setEditorState(RichUtils.toggleBlockType(editorState, 'header-two')),
    },
    {
      id: 'heading3',
      label: (
        <>
          <BiHeading size={18} />
          <span>中見出し</span>
        </>
      ),
      setFunc: () =>
        setEditorState(RichUtils.toggleBlockType(editorState, 'header-three')),
    },
    {
      id: 'heading4',
      label: (
        <>
          <BiHeading size={14} />
          <span>小見出し</span>
        </>
      ),
      setFunc: () =>
        setEditorState(RichUtils.toggleBlockType(editorState, 'header-four')),
    },
    {
      id: 'blockquote',
      label: (
        <>
          <ImQuotesLeft size={24} />
          <span>引用</span>
        </>
      ),
      setFunc: () =>
        setEditorState(RichUtils.toggleBlockType(editorState, 'blockquote')),
    },
    {
      id: 'paragraph',
      label: (
        <>
          <BiParagraph size={24} />
          <span>段落</span>
        </>
      ),
      setFunc: () =>
        setEditorState(RichUtils.toggleBlockType(editorState, 'paragraph')),
    },
    {
      id: 'ul',
      label: (
        <>
          <GoListUnordered size={24} />
          <span>順番なしリスト</span>
        </>
      ),
      setFunc: () =>
        setEditorState(
          RichUtils.toggleBlockType(editorState, 'unordered-list-item'),
        ),
    },
    {
      id: 'ol',
      label: (
        <>
          <GoListOrdered size={24} />
          <span>番号付きリスト</span>
        </>
      ),
      setFunc: () =>
        setEditorState(
          RichUtils.toggleBlockType(editorState, 'ordered-list-item'),
        ),
    },
    {
      id: 'media',
      label: (
        <>
          <GoFileMedia size={24} />
          <span>画像を追加</span>
        </>
      ),
      setFunc: () => {
        if (!editorRef || !editorRef?.current) {
          return;
        }
        editorRef.current.blur();
        setModalOpen(true);
      },
    },
  ];

  const inlineStyleButtons: ButtonProps[] = [
    {
      id: 'bold',
      label: (
        <>
          <BiBold size={24} />
          <span>太字</span>
        </>
      ),
      setFunc: () =>
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD')),
    },
    {
      id: 'marker',
      label: (
        <>
          <FaHighlighter size={24} />
          <span>マーカー</span>
        </>
      ),
      setFunc: () =>
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'marker')),
    },
    {
      id: 'link',
      label: (
        <>
          <BiLink size={24} />
          <span>リンク</span>
        </>
      ),
      setFunc: (event) => {
        setAnchorEle(anchorEle ? null : event.currentTarget);
      },
      siblingElement: (
        <Popover
          id="simple-popover"
          open={Boolean(anchorEle)}
          onClose={() => setAnchorEle(null)}
          anchorEl={anchorEle}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.link}>
            <input
              type="text"
              className="input"
              placeholder="https://example.com/"
              value={linkValue.urlValue}
              onChange={(event) =>
                setLinkValue((props) => ({
                  ...props,
                  urlValue: event.target.value,
                }))
              }
            />
            <Box mt={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={linkValue.external}
                    size="small"
                    onChange={(event) =>
                      setLinkValue((props) => ({
                        ...props,
                        external: event.target.checked,
                      }))
                    }
                  />
                }
                label="別タブで開く"
              />
              <ButtonPrimary
                onClick={() => {
                  const contentState = editorState.getCurrentContent();
                  const contentStateWithEntity = contentState.createEntity(
                    'LINK',
                    'MUTABLE',
                    {
                      url: linkValue.urlValue,
                      external: linkValue.external,
                    },
                  );
                  const entityKey =
                    contentStateWithEntity.getLastCreatedEntityKey();
                  // Apply entity
                  let newEditorState = EditorState.set(editorState, {
                    currentContent: contentStateWithEntity,
                  });
                  // Apply selection
                  newEditorState = RichUtils.toggleLink(
                    newEditorState,
                    newEditorState.getSelection(),
                    entityKey,
                  );
                  setEditorState(newEditorState);
                  setAnchorEle(null);
                  setLinkValue({ urlValue: '', external: false });
                }}
              >
                リンクを追加
              </ButtonPrimary>
            </Box>
          </div>
        </Popover>
      ),
    },
  ];

  return { blockStyleButtons, inlineStyleButtons };
};
