import { useDispatch } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import collaboratorSlice from './collaborator/slice';
import makerSlice from './maker/slice';
import actualResultSlice from './actualResult/slice';
import makerContactsSlice from './makerContacts/slice';
import appSlice from './app/slice';
import userSlice from './user/slice';
import eventSlice from './event/slice';
import faqSlice from './faq/slice';
import themeSlice from './themes/slice';
import wordpressSlice from './wordpress/slice';

export const history = createBrowserHistory();
const reducer = combineReducers({
  router: connectRouter(history),
  app: appSlice.reducer,
  collaborator: collaboratorSlice.reducer,
  maker: makerSlice.reducer,
  actualResult: actualResultSlice.reducer,
  makerContacts: makerContactsSlice.reducer,
  user: userSlice.reducer,
  event: eventSlice.reducer,
  faq: faqSlice.reducer,
  theme: themeSlice.reducer,
  wordpress: wordpressSlice.reducer,
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export type AsyncThunkConfig<T = unknown> = {
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: T;
};

export const store = configureStore({
  reducer,
  preloadedState: load(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(routerMiddleware(history)).concat(save()),
});

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
