import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    position: 'sticky',
    top: 90,
    border: `1px solid ${theme.palette.grey['400']}`,
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    backgroundColor: '#f0f0f0',
    zIndex: 1000,
  },
  link: {
    padding: theme.spacing(1),
  },
}));

export default useStyles;
