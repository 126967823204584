import { createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import {
  OrderQueries,
  WhereQueries,
} from 'my-firebase-wrapper/dist/firestore/types';
import _ from 'lodash';
import moment from 'moment';

import { makersRepository } from '../../repositories/firebase/makers';
import isDataUrlImage from '../../utils/isDataUrlImage';
import nanoid from '../../utils/nanoid';
import { OpenSnackbar } from '../../presentation/hooks/useSnackbar';
import { storage } from '../../repositories/firebase';
import { eventsRepository } from '../../repositories/firebase/events';
import { eventReservationsRepository } from '../../repositories/firebase/eventReservations';
import { AsyncThunkConfig } from '../store';
import {
  EventFormType,
  EventListTableType,
  EventReservationsType,
  EventType,
} from './types';
import { initialReservation, initialState, setEventTableData } from './slice';

export const fetchEvents = createAsyncThunk<
  EventType[],
  {
    whereQueries: WhereQueries<EventType>;
    orderQueries: OrderQueries<EventType>;
  },
  AsyncThunkConfig<undefined>
>('event/fetchEvents', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = eventsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });
    const events: EventType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();
    const eventTableData: EventListTableType[] = [];

    const eventData = events.length
      ? await Promise.all(
          events.map(async (event) => {
            const newData = {
              ...initialState.currentEvent,
              ...event,
            };
            const makerSnapshot =
              event.makerId && event.officialEvent === false
                ? await makersRepository.doc(event.makerId).get()
                : undefined;
            const maker = makerSnapshot ? makerSnapshot.data() : undefined;
            const reservations = await eventReservationsRepository
              .where('eventId', '==', event.uid)
              .fetch();

            eventTableData.push({
              button: null,
              image: event.images.length ? event.images[0].imageUrl : '',
              publishing: event.publishing,
              acceptingReservations: event.acceptingReservations || false,
              title: event.title,
              startDate: event.startDate ? event.startDate : null,
              endDate: event.endDate ? event.endDate : null,
              time: event.time,
              createDate: event.createDate,
              updateDate: event.updateDate,
              makerName: maker?.name || 'Mockhouse公式イベント',
              reservations: reservations.length || 0,
              event,
            });

            return newData;
          }),
        )
      : [];

    thunkAPI.dispatch(setEventTableData(eventTableData));

    return eventData;
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const setEvent = createAsyncThunk<
  EventType,
  EventType,
  AsyncThunkConfig<undefined>
>('event/setEvent', async (args, thunkAPI) => {
  try {
    if (!args.uid) {
      thunkAPI.dispatch(push('/event/edit'));

      return initialState.currentEvent;
    }

    thunkAPI.dispatch(push(`/event/edit/${args.uid}`));

    return args;
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

const uploadImages = async (event: EventType): Promise<EventType> => {
  const { uid, makerId } = event;
  const makerUid =
    typeof makerId === 'string' ? makerId : (makerId as any).value;

  const images = await Promise.all(
    event.images.map(async (image, index) => {
      const timestamp = moment().format('YYYYMMDD_HHmmssSS');

      if (isDataUrlImage(image.imageUrl)) {
        const snapshot = await storage
          .ref(
            `/makers/${makerUid}/events/${uid}/content_${index}_${timestamp}.jpg`,
          )
          .putString(image.imageUrl, 'data_url');
        let url = await snapshot.ref.fullPath;
        url = `/${url}`;

        return { ...image, imageUrl: url };
      }

      return image;
    }),
  );

  return {
    ...event,
    images,
  };
};

export const createEvent = createAsyncThunk<
  EventType,
  {
    formValue: EventFormType;
    openSnackbar: OpenSnackbar;
  },
  AsyncThunkConfig<undefined>
>('event/createEvent', async (args, thunkAPI) => {
  try {
    const { formValue, openSnackbar } = args;
    const currentValue = thunkAPI.getState().event.currentEvent;
    const newValue = await uploadImages({
      ...initialState.currentEvent,
      ...currentValue,
      ...formValue,
    });
    newValue.slug = newValue.slug || nanoid();
    if (!newValue.uid) {
      const uid = await eventsRepository.add(newValue);
      newValue.uid = uid;
      openSnackbar({
        variant: 'success',
        message: 'イベントの作成が完了しました',
      });
      thunkAPI.dispatch(push('/event'));
    } else {
      await eventsRepository.update(newValue);
      openSnackbar({
        variant: 'success',
        message: 'イベントの更新が完了しました',
      });
    }

    return newValue;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        'イベントの更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const updateRow = createAsyncThunk<
  EventType,
  {
    event: EventType;
    openSnackbar: OpenSnackbar;
  },
  AsyncThunkConfig<undefined>
>('event/updateRow', async (args, thunkAPI) => {
  try {
    await eventsRepository.update(args.event);

    args.openSnackbar({
      variant: 'success',
      message: 'イベントの更新が完了しました',
    });

    return args.event;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        'イベントの更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchEventReservations = createAsyncThunk<
  EventReservationsType[],
  {
    whereQueries: WhereQueries<EventReservationsType>;
    orderQueries: OrderQueries<EventReservationsType>;
  },
  AsyncThunkConfig<undefined>
>('event/fetchEventReservations', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = eventReservationsRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });
    const eventReservations: EventReservationsType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    return eventReservations.length
      ? eventReservations.map((eventReservation) => {
          return {
            ...initialReservation,
            ...eventReservation,
          };
        })
      : [];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const updateReservation = createAsyncThunk<
  EventReservationsType,
  {
    reservation: EventReservationsType;
    openSnackbar: OpenSnackbar;
    closeSnackbar: () => void;
  },
  AsyncThunkConfig<undefined>
>('event/updateReservation', async (args, thunkAPI) => {
  try {
    await eventReservationsRepository.update(args.reservation);

    args.openSnackbar({
      variant: 'success',
      message: '予約の更新が完了しました',
    });

    setTimeout(() => {
      args.closeSnackbar();
    }, 3000);

    return args.reservation;
  } catch (error: any) {
    args.openSnackbar({
      variant: 'error',
      message:
        error?.message ||
        '予約の更新ができませんでした。もう一度お試しください。',
    });

    return thunkAPI.rejectWithValue(undefined);
  }
});
