import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  group: {
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: 14,
    fontWeight: 'bold',
  },
}));
