import React, { VFC } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import IconEvent from '../../base/Icon/IconEvent';
import { PageTitle } from '../../case/PageTitle';
import { AddButton } from '../../case/button/AddButton';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { initialState, setEvent } from '../../../../re-ducks/event/slice';
import { EventListTable } from '../../domain/event/EventListTable';

export const Event: VFC = () => {
  const dispatch = useDispatch();

  return (
    <AdminWrapper>
      <PageTitle Icon={<IconEvent />}>イベント管理</PageTitle>
      <AddButton
        label="イベントを追加する"
        onClick={() => {
          dispatch(setEvent(initialState.currentEvent));
          dispatch(push('/event/edit'));
        }}
      />
      <EventListTable />
    </AdminWrapper>
  );
};
