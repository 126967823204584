import React, { FC } from 'react';
import { ButtonProps } from '@material-ui/core';

import { ButtonPrimary } from '../../../base/Button';

export type LoginButtonProps = Omit<ButtonProps, 'children'>;

export const LoginButton: FC<LoginButtonProps> = (props) => {
  return <ButtonPrimary {...props}>ログイン</ButtonPrimary>;
};
