import clsx from 'clsx';
import React, { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { CircularProgress } from '@material-ui/core';
import { eventLimit } from '../../../../../data/plan';
import { Table } from '../../../base/Table';
import { DefaultColumnFilter } from '../../../base/Table/DefaultColumnFilter';
import { BooleanColumnFilter } from '../../../base/Table/BooleanColumnFilter';
import { DotProgress } from '../../../case/feedback/DotProgress';
import { PublishingCell } from './PublishingCell';
import { ImageCell } from '../../../case/table/ImageCell';
import {
  selectCreating,
  selectEvents,
  selectEventTableData,
  selectFetching,
} from '../../../../../re-ducks/event/selector';
import { fetchEvents } from '../../../../../re-ducks/event/actions';
import { useStyles } from './useStyles';
import { selectSelectedMaker } from '../../../../../re-ducks/app/selector';
import { EventListTableType } from '../../../../../re-ducks/event/types';
import { DateCell } from './DateCell';
import {
  dateAfterFilter,
  dateBeforeFilter,
  DateColumnFilter,
} from './DateColumnFilter';
import { selectRole } from '../../../../../re-ducks/collaborator/selector';
import { ButtonCell } from './ButtonCell';
import sortDate from './sortDate';
import { TitleCell } from './TitleCell';
import sortPublishing from './sortPublishing';
import { AcceptingReservationsCell } from './AcceptinReservationsCell';

export const EventListTable: VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedRole = useSelector(selectRole);
  const selectedMaker = useSelector(selectSelectedMaker);
  const fetching = useSelector(selectFetching);
  const creating = useSelector(selectCreating);
  const events = useSelector(selectEvents);
  const eventTableData = useSelector(selectEventTableData);

  useEffect(() => {
    dispatch(
      fetchEvents({
        whereQueries:
          selectedRole === 'admin'
            ? [
                {
                  fieldPath: 'makerId',
                  opStr: '==',
                  value: selectedMaker?.uid || '',
                },
              ]
            : [],
        orderQueries: [],
      }),
    );
  }, []);

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  return (
    <div>
      {creating ? <DotProgress opening={creating} onClose={() => ''} /> : null}
      {selectedRole === 'admin' ? (
        <div className={classes.eventToolBox}>
          <div className={classes.eventLimit}>
            <p>予約受付中のイベント</p>
            <p className={classes.numEvent}>
              {events.filter((event) => event.acceptingReservations)?.length}/
              {selectedMaker?.plan ? eventLimit[selectedMaker.plan] : ''}
            </p>
          </div>
        </div>
      ) : null}
      <Table<EventListTableType>
        tableOptions={{
          data: eventTableData && eventTableData.length ? eventTableData : [],
          columns: [
            {
              Header: '',
              accessor: 'button',
              disableSortBy: true,
              disableFilters: true,
              Cell: ButtonCell,
            },
            {
              Header: '',
              accessor: 'image',
              Filter: DefaultColumnFilter,
              disableSortBy: true,
              disableFilters: true,
              Cell: ImageCell,
              width: 160,
            },
            {
              Header: '建築事業者・ブランド',
              accessor: 'makerName',
              Filter: DefaultColumnFilter,
              width: 240,
            },
            {
              Header: '予約申込数',
              accessor: 'reservations',
              Filter: DefaultColumnFilter,
              width: 80,
            },
            {
              Header: '公開状態',
              accessor: 'publishing',
              Filter: BooleanColumnFilter,
              Cell: PublishingCell,
              width: 240,
              sortType: sortPublishing,
            },
            {
              Header: '予約受付状態',
              accessor: 'acceptingReservations',
              Filter: BooleanColumnFilter,
              Cell: AcceptingReservationsCell,
              width: 240,
              sortType: sortPublishing,
            },
            {
              Header: 'タイトル',
              accessor: 'title',
              Filter: DefaultColumnFilter,
              width: 240,
              Cell: TitleCell,
            },
            {
              Header: '開催開始日',
              accessor: 'startDate',
              Filter: DateColumnFilter,
              filter: dateAfterFilter,
              width: 160,
              Cell: DateCell,
              sortType: sortDate,
            },
            {
              Header: '開催終了日',
              accessor: 'endDate',
              Filter: DateColumnFilter,
              filter: dateBeforeFilter,
              width: 160,
              Cell: DateCell,
              sortType: sortDate,
            },
            {
              Header: '開催時間',
              accessor: 'time',
              disableFilters: true,
              width: 160,
            },
            {
              Header: '作成日',
              accessor: 'createDate',
              disableFilters: true,
              width: 160,
            },
            {
              Header: '更新日',
              accessor: 'updateDate',
              disableFilters: true,
              width: 160,
            },
          ],
          initialState: {
            pageSize: 50,
            sortBy: [{ id: 'updateDate', desc: true }],
            hiddenColumns:
              selectedRole === 'admin' ? ['event', 'makerName'] : ['event'],
          },
        }}
        classes={{
          root: clsx('table-container', classes.container),
          table: clsx(
            'table',
            'is-scrolled',
            'is-striped',
            'is-hoverable',
            'list-table',
          ),
        }}
      />
    </div>
  );
};
