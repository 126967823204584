import React, { VFC } from 'react';
import { LinkComponentProps } from '../types';

const Link: VFC<LinkComponentProps> = ({
  contentState,
  entityKey,
  children,
}) => {
  const { url, external } = contentState.getEntity(entityKey).getData();
  const props = external
    ? { target: '_blank', rel: 'noopener noreferrer' }
    : {};

  return (
    <a href={url} {...props}>
      {children}
    </a>
  );
};

export default Link;
