import React from 'react';
import { FormControlLabel, Radio, RadioProps } from '@material-ui/core';
import { useStyles } from './useStyles';

export interface RadioButtonProps {
  label: string;
  radioProps: RadioProps;
}

export const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  radioProps,
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      label={label}
      className={classes.controlLabel}
      control={
        <Radio
          color="primary"
          {...radioProps}
          classes={{ root: classes.root }}
          TouchRippleProps={{ classes: { root: 'ripple-root' } }}
        />
      }
    />
  );
};
