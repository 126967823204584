import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';

import { db } from './index';
import { CollaboratorType } from '../../re-ducks/collaborator/types';
import { formatValue } from '../../utils/formatValue';

export const collaboratorRepository: Collection<CollaboratorType> =
  db.collection<CollaboratorType>({
    collectionPath: 'collaborators',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        role: formatValue(obj.role),
      };
    },
  });
