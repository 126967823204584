import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';
import { v4 } from 'uuid';

import { ThemeType } from '../../re-ducks/themes/types';
import { initialState } from '../../re-ducks/themes/slice';
import { db } from './index';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';

export const themesRepository: Collection<ThemeType> = db.collection<ThemeType>(
  {
    collectionPath: 'themes',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        uid: obj?.uid ? obj.uid : v4(),
        createDate: convertToTimestampFromString(obj.createDate),
        updateDate: new Date(),
        actualResults: obj.actualResults.map((actualResult: any) => {
          return {
            ...actualResult,
            startDate: convertToTimestampFromString(actualResult.startDate),
            endDate: convertToTimestampFromString(actualResult.endDate),
          };
        }),
      };
    },
    decode: (doc) => {
      const data = doc.data();
      if (!data) return initialState.theme;

      return {
        ...data,
        createDate: convertToDateFromTimestamp(data.createDate) || '',
        updateDate: convertToDateFromTimestamp(data.updateDate) || '',
        actualResults: data.actualResults.map((actualResult) => {
          return {
            ...actualResult,
            startDate: convertToDateFromTimestamp(actualResult.startDate),
            endDate: convertToDateFromTimestamp(actualResult.endDate),
          };
        }),
      };
    },
  },
);
