import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { ActualResultState } from './slice';

export const selectSelf = (state: RootState): ActualResultState =>
  state.actualResult;

export const selectFetching = createSelector(
  selectSelf,
  (state) => state.fetching,
);
export const selectActualResults = createSelector(
  selectSelf,
  (state) => state.actualResults,
);
export const selectActualResult = createSelector(
  selectSelf,
  (state) => state.actualResult,
);
export const selectActualImages = createSelector(
  selectSelf,
  (state) => state.actualImages,
);
export const selectCreating = createSelector(
  selectSelf,
  (state) => state.creating,
);
