import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import useStyles from './useStyles';

interface Props {
  href: string;
}

const LinkText: FC<Props> = ({ href }) => {
  const classes = useStyles();

  return (
    <Link to={href} className={classes.root}>
      <ChevronRight fontSize="small" />
      <Typography variant="body2" component="span">
        お問い合わせ一覧へ
      </Typography>
    </Link>
  );
};

export default LinkText;
