import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { FAQState } from './slice';

export const selectSelf = (state: RootState): FAQState => state.faq;

export const selectFetching = createSelector(
  selectSelf,
  (state) => state.fetching,
);
export const selectCreating = createSelector(
  selectSelf,
  (state) => state.creating,
);
export const selectFaqItems = createSelector(
  selectSelf,
  (state) => state.faqItems,
);
export const selectFaqMedias = createSelector(
  selectSelf,
  (state) => state.faqMedias,
);
export const selectFaqCategories = createSelector(
  selectSelf,
  (state) => state.faqCategories,
);
export const selectSelectedFaq = createSelector(
  selectSelf,
  (state) => state.selectedFaq,
);
