import React, { VFC } from 'react';
import { ColumnInstance } from 'react-table';

import { RadioButton } from '../form/RadioButton';

export const BooleanColumnFilter: VFC<{ column: ColumnInstance<never> }> = ({
  column,
}) => {
  const { id, render, setFilter, filterValue } = column;

  let trueLabel = '';
  let falseLabel = '';
  switch (column.id) {
    case 'acceptingReservations': {
      trueLabel = '受付中';
      falseLabel = '受付終了';
      break;
    }
    default: {
      trueLabel = '公開';
      falseLabel = '非公開';
      break;
    }
  }

  return (
    <div>
      <div className="label">{render('Header')}</div>
      <div className="control">
        <RadioButton
          label={trueLabel}
          radioProps={{
            id,
            name: id,
            value: true,
            checked: filterValue === 'true',
            inputRef: null,
            onChange: (event) => setFilter(event.target.value),
          }}
        />
        <RadioButton
          label={falseLabel}
          radioProps={{
            id,
            name: id,
            value: false,
            checked: filterValue === 'false',
            inputRef: null,
            onChange: (event) => setFilter(event.target.value),
          }}
        />
      </div>
    </div>
  );
};
