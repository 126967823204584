import clsx from 'clsx';
import React, { useEffect, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { push } from 'connected-react-router';
import { Table } from '../../base/Table';
import { DefaultColumnFilter } from '../../base/Table/DefaultColumnFilter';
import { CollaboratorListTableType } from '../../../../re-ducks/collaborator/types';
import {
  selectCollaboratorTableData,
  selectFetching,
} from '../../../../re-ducks/collaborator/selector';
import { fetchCollaborators } from '../../../../re-ducks/collaborator/actions';
import { DotProgress } from '../../case/feedback/DotProgress';
import { useStyles } from './useStyles';

export const CollaboratorListTable: VFC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const collaboratorTableData = useSelector(selectCollaboratorTableData);
  const fetching = useSelector(selectFetching);

  useEffect(() => {
    dispatch(
      fetchCollaborators({
        whereQueries: [],
        orderQueries: [{ fieldPath: 'uid', directionStr: 'desc' }],
      }),
    );
  }, []);

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  return (
    <Table<CollaboratorListTableType>
      tableOptions={{
        data: collaboratorTableData,
        columns: [
          { Header: '名前', accessor: 'name', Filter: DefaultColumnFilter },
          {
            Header: 'メールアドレス',
            accessor: 'mailAddress',
            Filter: DefaultColumnFilter,
            width: 280,
          },
          {
            Header: '権限',
            accessor: 'role',
            Filter: DefaultColumnFilter,
            width: 160,
          },
        ],
        initialState: {
          pageSize: 50,
          sortBy: [{ id: 'name' }],
          hiddenColumns: ['uid'],
        },
      }}
      onClick={(row) => {
        dispatch(
          push(`/collaborator/edit/${row.original.uid}`, {
            collaborator: row.original,
          }),
        );
      }}
      classes={{
        root: clsx('table-container', classes.container),
        table: clsx(
          'table',
          'is-fullwidth',
          'is-striped',
          'is-hoverable',
          'list-table',
        ),
      }}
    />
  );
};
