import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'initial',
      whiteSpace: 'normal',
      textAlign: 'left',
    },
  },
  text: {
    marginLeft: theme.spacing(1),
  },
}));
