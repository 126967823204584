import moment from 'moment';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  OrderQueries,
  WhereQueries,
} from 'my-firebase-wrapper/dist/firestore/types';

import _ from 'lodash';
import { AsyncThunkConfig } from '../store';
import { UserListTableType, UserType } from './types';
import { usersRepository } from '../../repositories/firebase/users';
import { makersRepository } from '../../repositories/firebase/makers';

export const fetchUsers = createAsyncThunk<
  UserListTableType[],
  {
    whereQueries: WhereQueries<UserType>;
    orderQueries: OrderQueries<UserType>;
  },
  AsyncThunkConfig<undefined>
>('user/fetchUsers', async (args, thunkAPI) => {
  try {
    const { whereQueries, orderQueries } = args;
    let collectionRef: any = usersRepository;
    whereQueries.forEach(({ fieldPath, opStr, value }) => {
      collectionRef = collectionRef.where(fieldPath, opStr, value);
    });
    orderQueries.forEach(({ fieldPath, directionStr }) => {
      collectionRef = collectionRef.orderBy(fieldPath, directionStr);
    });
    const users: UserType[] =
      'fetch' in collectionRef
        ? await collectionRef.fetch()
        : await collectionRef.fetchAll();

    return users.length
      ? await Promise.all(
          users.map(async (user) => {
            const makers = await Promise.all(
              user.followMakers.map(async (makerId) => {
                const makerSnapshot = await makersRepository
                  .where('uid', '==', makerId)
                  .get();
                const maker = makerSnapshot.empty
                  ? undefined
                  : makerSnapshot.docs.map((doc) => doc.data())[0];

                return maker?.name || '';
              }),
            );

            return {
              uid: user.uid,
              photoUrl: user.photoUrl,
              name: user.name,
              age: user.birthday
                ? _.floor(moment().diff(moment(user.birthday), 'years'), -1)
                : 0,
              makerName: makers.join(', '),
            };
          }),
        )
      : [];
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});

export const fetchUser = createAsyncThunk<
  UserType | null,
  {
    docId: string;
  },
  AsyncThunkConfig<undefined>
>('user/fetchUser', async (args, thunkAPI) => {
  try {
    const users = await usersRepository.where('uid', '==', args.docId).fetch();
    const user = users.length ? users[0] : null;

    return user;
  } catch (error) {
    console.error(error);

    return thunkAPI.rejectWithValue(undefined);
  }
});
