import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';
import { v4 } from 'uuid';

import { db } from './index';
import { ActualImageType } from '../../re-ducks/actualResult/types';
import { initialState } from '../../re-ducks/actualResult/slice';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';
import { formatValue } from '../../utils/formatValue';
import getStorageFIleUrl from '../../utils/getStorageFIleUrl';

export const actualImagesRepository: Collection<ActualImageType> =
  db.collection<ActualImageType>({
    collectionPath: 'actualImages',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        createDate: convertToTimestampFromString(obj.createDate),
        updateDate: convertToTimestampFromString(obj.updateDate),
        styleTags: obj.styleTags.map((value: any) => formatValue(value)),
        roomPlace: formatValue(obj.roomPlace),
        wallLooks: obj.wallLooks.map((value: any) => formatValue(value)),
        floorLooks: obj.floorLooks.map((value: any) => formatValue(value)),
        facilitiesAndFunctions: obj.facilitiesAndFunctions.map((value: any) =>
          formatValue(value),
        ),
        exteriorNumOfFloors: obj.exteriorNumOfFloors.map((value: any) =>
          formatValue(value),
        ),
        exteriorRoofTypes: obj.exteriorRoofTypes.map((value: any) =>
          formatValue(value),
        ),
        kitchenTypes: obj.kitchenTypes.map((value: any) => formatValue(value)),
        kitchenStorages: obj.kitchenStorages.map((value: any) =>
          formatValue(value),
        ),
        ldkTypes: obj.ldkTypes.map((value: any) => formatValue(value)),
        roomTypes: obj.roomTypes.map((value: any) => formatValue(value)),
      };
    },
    decode: (doc) => {
      const data = doc.data();
      if (!data) return initialState.actualImage;

      return {
        ...data,
        createDate: convertToDateFromTimestamp(data.createDate) || '',
        updateDate: convertToDateFromTimestamp(data.updateDate) || '',
      };
    },
  });
