import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { SelectField } from '../../../base/form/SelectField';
import { ALL_AREA, NIIGATA_AREA } from '../../../../../data/area';
import { ErrorMessage } from '../../../base/form/ErrorMessage';

interface Props {
  name: string;
  defaultValue: string;
}

const AreaSelect: FC<Props> = ({ name, defaultValue }) => {
  const { control, errors, watch } = useFormContext();

  const area = ALL_AREA.find((a) => a.id === defaultValue);

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: {
            select: (v) => {
              if (
                (v instanceof Array && v?.length === 1) ||
                (typeof v === 'string' && v)
              )
                return true;

              return 'エリアを1つだけ選択してください';
            },
          },
        }}
        render={({ ref }) => (
          <SelectField
            ref={ref}
            name={name}
            defaultValue={{
              label: area?.label || '',
              value: area?.id,
            }}
            options={NIIGATA_AREA.map((nArea) => {
              const areaTitle =
                ALL_AREA.find((a) => a.name === nArea)?.label || '';
              const children = ALL_AREA.filter((a) => a.parent === nArea);
              const options = children.map((child) => ({
                label: child?.label || '',
                value: child?.id,
              }));

              return { label: areaTitle, options };
            })}
          />
        )}
        isClearable
      />
      <ErrorMessage error={errors[name]} />
    </>
  );
};

export default AreaSelect;
