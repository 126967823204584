import React, { useState, VFC } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { DragIndicator, MoreVert } from '@material-ui/icons';
import {
  IconButton,
  MenuList,
  MenuItem,
  ClickAwayListener,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ThemeType } from '../../../../../re-ducks/themes/types';
import moveArray from '../../../../../utils/moveArray';
import useStyles from './useStyles';
import { setTheme, setThemes } from '../../../../../re-ducks/themes/slice';
import {
  deleteTheme,
  fetchPublishActualResults,
  sortThemes,
} from '../../../../../re-ducks/themes/actions';

interface Props {
  themes: ThemeType[];
  category: string;
}

const ListItems: VFC<Props> = ({ themes, category }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [menuOpen, setMenuOpen] = useState<string | null>(null);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedThemes = themes.sort((a, b) => (a.order > b.order ? 1 : -1));
    const sortedThemes = moveArray(
      orderedThemes,
      result.destination.index,
      result.source.index,
    ).map((theme, index) => {
      return { ...theme, order: index };
    });

    dispatch(sortThemes(sortedThemes));
    dispatch(setThemes(sortedThemes));
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={`${category}-themes`}>
        {(droppableProvided) => (
          <ul
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {themes.map((theme, index) => {
              return (
                <Draggable
                  key={theme.uid}
                  draggableId={theme.uid}
                  index={index}
                >
                  {(provided) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={classes.faqItem}
                    >
                      <DragIndicator fontSize="small" />
                      <p className={classes.faqTitle}>
                        <span>（{theme.publishing ? '公開' : '非公開'}）</span>
                        <strong>{theme.label}</strong>
                        <span className="ml-2">{theme.slug}</span>
                      </p>
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => setMenuOpen(theme?.uid || '')}
                      >
                        <MoreVert fontSize="small" />
                      </IconButton>
                      {menuOpen === theme?.uid ? (
                        <ClickAwayListener
                          onClickAway={() => setMenuOpen(null)}
                        >
                          <MenuList className={classes.menu}>
                            <MenuItem
                              onClick={() => {
                                setMenuOpen(null);
                                if (theme) {
                                  dispatch(setTheme(theme));
                                  dispatch(
                                    fetchPublishActualResults({
                                      whereQueries: [
                                        {
                                          fieldPath: 'themeId',
                                          opStr: '==',
                                          value: theme.uid,
                                        },
                                      ],
                                      orderQueries: [],
                                    }),
                                  );
                                  dispatch(push(`/theme/edit/${theme.uid}/`));
                                }
                              }}
                            >
                              編集
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setMenuOpen(null);
                                if (theme) {
                                  dispatch(deleteTheme(theme));
                                }
                              }}
                            >
                              削除
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      ) : null}
                    </li>
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ListItems;
