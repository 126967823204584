import React, { useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { push } from 'connected-react-router';
import { useDebounce } from 'react-use';
import { Link } from 'react-router-dom';
import {
  updateBatchRequest,
  updateConsultation,
  updateContact,
  updateReservation,
} from '../../../../re-ducks/makerContacts/actions';
import { MakerContactType } from '../../../../re-ducks/makerContacts/types';
import { MakerConsultationsType } from '../../../../re-ducks/makerConsultations/types';
import { BatchRequestType } from '../../../../re-ducks/batchRequests/types';
import {
  ATTENDANCE_STATUS,
  EventReservationsType,
  RESERVATION_STATUS,
} from '../../../../re-ducks/event/types';
import IconContact from '../../base/Icon/IconContact';
import { BackButton } from '../../case/button/BackButton';
import { PageTitle } from '../../case/PageTitle';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { selectMakerContact } from '../../../../re-ducks/makerContacts/selector';
import { selectRole } from '../../../../re-ducks/collaborator/selector';
import { Snackbar } from '../../case/feedback/Snackbar';
import useSnackbar from '../../../hooks/useSnackbar';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    width: '100%',
    boxShadow: `0 0 0 1px ${theme.palette.grey['400']}`,
    '& th': {
      whiteSpace: 'nowrap',
    },
    '& th, & td': {
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.grey['400']}`,
      backgroundColor: theme.palette.grey['100'],
      whiteSpace: 'pre-wrap',
    },
    '& tr:last-child': {
      '& th, & td': {
        borderBottom: 'none',
      },
    },
  },
  buttonWrap: {
    '& button': {
      width: 'auto',
    },
  },
  note: {
    fontSize: 12,
  },
}));

export const MakerContactsDetail: VFC = () => {
  const makerContact = useSelector(selectMakerContact);
  const [state, setState] = useState<
    | EventReservationsType
    | MakerContactType
    | MakerConsultationsType
    | BatchRequestType
  >(makerContact.contactData);
  const [isEdited, setIsEdited] = useState(false);
  const myRole = useSelector(selectRole);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();

  useDebounce(
    () => {
      if (!isEdited) return;

      switch (makerContact.inquiryType) {
        case '資料請求':
        case 'お問い合わせ':
          dispatch(
            updateContact({
              makerContact: state as MakerContactType,
              openSnackbar,
              closeSnackbar,
            }),
          );
          break;
        case '個別相談':
          dispatch(
            updateConsultation({
              consultation: state as MakerConsultationsType,
              openSnackbar,
              closeSnackbar,
            }),
          );
          break;
        case 'イベント予約':
          dispatch(
            updateReservation({
              reservation: state as EventReservationsType,
              openSnackbar,
              closeSnackbar,
            }),
          );
          break;
        case '一括資料請求':
          dispatch(
            updateBatchRequest({
              batchRequest: state as BatchRequestType,
              openSnackbar,
              closeSnackbar,
            }),
          );
          break;
        default:
          break;
      }
    },
    1000,
    [state],
  );

  const onChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setIsEdited(true);
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <AdminWrapper>
      <PageTitle Icon={<IconContact />}>お問い合わせ</PageTitle>
      <table className={classes.table}>
        <tbody>
          <tr>
            <th>お問い合わせ種別</th>
            <td>{makerContact.inquiryType}</td>
          </tr>
          {myRole === 'admin' && makerContact.inquiryType === '一括資料請求' ? (
            <tr>
              <th>同時一括資料請求件数</th>
              <td>
                <p>{makerContact.contactData?.makerIds.length}件</p>
                <p className={classes.note}>
                  ※一括資料請求の際、同時に請求されたビルダーの件数を表示しています
                </p>
              </td>
            </tr>
          ) : null}
          {myRole === 'cunelwork' ? (
            <tr>
              <th>お問い合わせ先建築事業者・ブランド</th>
              <td>
                {makerContact.inquiryType === '一括資料請求'
                  ? makerContact.makerNames.join('\n')
                  : makerContact?.makerName}
              </td>
            </tr>
          ) : null}
          <tr>
            <th>お問い合わせ日時</th>
            <td>{makerContact.date}</td>
          </tr>
          <tr>
            <th>名前</th>
            <td>
              {makerContact.name}
              <Typography>
                <Link to={`/users/detail/${makerContact.contactData.userId}/`}>
                  ユーザーの情報
                  {makerContact.contactData?.userId ? '' : '（退会済み）'}
                </Link>
              </Typography>
            </td>
          </tr>
          <tr>
            <th>メールアドレス</th>
            <td>{makerContact.mailAddress}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{makerContact.tel}</td>
          </tr>
          {makerContact.inquiryType === 'お問い合わせ' ? (
            <tr>
              <th>お問い合わせ内容</th>
              <td>{makerContact.contactData?.content}</td>
            </tr>
          ) : null}
          {makerContact.inquiryType === '資料請求' ||
          makerContact.inquiryType === '一括資料請求' ? (
            <>
              <tr>
                <th>郵便番号</th>
                <td>{makerContact.contactData?.postalCode}</td>
              </tr>
              <tr>
                <th>住所</th>
                <td>{makerContact.contactData?.address}</td>
              </tr>
            </>
          ) : null}
          {makerContact.inquiryType === '個別相談' ? (
            <>
              <tr>
                <th>相談内容</th>
                <td>{makerContact.contactData?.services}</td>
              </tr>
              <tr>
                <th>土地の有無</th>
                <td>
                  {makerContact.contactData?.hasLand
                    ? 'すでに土地を持っている'
                    : '土地を持っていない'}
                </td>
              </tr>
              <tr>
                <th>相談内容・その他ご希望など</th>
                <td>{makerContact.contactData?.content}</td>
              </tr>
            </>
          ) : null}
          {makerContact.inquiryType === '一括資料請求' ? (
            <>
              <tr>
                <th>お問い合わせ内容</th>
                <td>{makerContact.contactData?.content}</td>
              </tr>
              <tr>
                <th>請求ページ</th>
                <td>{makerContact.contactData?.fromPage}</td>
              </tr>
            </>
          ) : null}
          {makerContact.inquiryType === 'イベント予約' ? (
            <>
              <tr>
                <th>イベント名</th>
                <td>{makerContact.contactData?.eventName}</td>
              </tr>
              <tr>
                <th>ご予約者名</th>
                <td>{makerContact.contactData?.reservationName}</td>
              </tr>
              <tr>
                <th>予約日時</th>
                <td>{`${makerContact.contactData?.reservationDate} ${makerContact.contactData?.reservationTime}`}</td>
              </tr>
              <tr>
                <th>参加人数</th>
                <td>
                  {makerContact.contactData?.participants?.adults ? (
                    <p>大人：{makerContact.contactData?.participants.adults}</p>
                  ) : (
                    ''
                  )}
                  {makerContact.contactData?.participants?.children ? (
                    <p>
                      子ども：${makerContact.contactData?.participants.children}
                    </p>
                  ) : (
                    ''
                  )}
                  {makerContact.contactData?.participants?.childAge ? (
                    <p>
                      子どもの年齢：
                      {makerContact.contactData?.participants.childAge}
                    </p>
                  ) : (
                    ''
                  )}
                </td>
              </tr>
              <tr>
                <th>ご希望など</th>
                <td>{makerContact.contactData?.remarks}</td>
              </tr>
              <tr>
                <th>予約ステータス</th>
                <td>
                  <TextField
                    name="status"
                    value={'status' in state ? state?.status : 'applying'}
                    variant="outlined"
                    select
                    onChange={onChange}
                    fullWidth
                  >
                    {Object.entries(RESERVATION_STATUS).map(([key, label]) => (
                      <MenuItem key={key} value={key}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                </td>
              </tr>
              <tr>
                <th>参加ステータス</th>
                <td>
                  <TextField
                    name="attendanceStatus"
                    value={
                      'attendanceStatus' in state && state?.attendanceStatus
                        ? state?.attendanceStatus
                        : 'attended'
                    }
                    variant="outlined"
                    select
                    SelectProps={{ displayEmpty: true }}
                    onChange={onChange}
                    fullWidth
                  >
                    <MenuItem value="">選択してください</MenuItem>
                    {Object.entries(ATTENDANCE_STATUS).map(([key, label]) => (
                      <MenuItem key={key} value={key}>
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                </td>
              </tr>
            </>
          ) : null}
          <tr>
            <th>メモ</th>
            <td>
              <TextField
                name="memo"
                value={state.memo}
                variant="outlined"
                onChange={onChange}
                rows={4}
                fullWidth
                multiline
              />
            </td>
          </tr>
        </tbody>
      </table>
      <Box mt={3} className={classes.buttonWrap}>
        <BackButton
          type="button"
          onClick={() => dispatch(push('/makerContacts'))}
          fullWidth
        >
          一覧へ戻る
        </BackButton>
      </Box>
      <Snackbar
        isOpening={snackbarState.isOpening}
        variant={snackbarState.variant}
        message={snackbarState.message}
        onClose={closeSnackbar}
      />
    </AdminWrapper>
  );
};
