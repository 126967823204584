import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(16),
  },
  logo: {
    maxWidth: 480,
    margin: `0 auto ${theme.spacing(1)}px`,

    '& img': {
      maxWidth: '100%',
    },
  },
  description: {
    width: '100%',
    maxWidth: 640,
    margin: `${theme.spacing(6)}px auto 0`,
    padding: `0 ${theme.spacing(2)}px`,
    textAlign: 'center',
  },
}));
