import React, { FC } from 'react';
import { ButtonProps } from '@material-ui/core';
import { Save } from '@material-ui/icons';

import { ButtonPrimary } from '../../../base/Button';

export type SaveButtonProps = Omit<ButtonProps, 'children' | 'startIcon'>;

export const SaveButton: FC<SaveButtonProps> = (props) => {
  const { children } = props;

  return (
    <ButtonPrimary {...props} startIcon={<Save />}>
      {children || '保存'}
    </ButtonPrimary>
  );
};
