import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Typography, Box } from '@material-ui/core';
import { push } from 'connected-react-router';

import { makersRepository } from '../../../../../repositories/firebase/makers';
import useSnackbar from '../../../../hooks/useSnackbar';
import { Snackbar } from '../../feedback/Snackbar';
import { useStyles } from './useStyles';
import { MakerType } from '../../../../../re-ducks/maker/types';
import { ButtonPrimary } from '../../../base/Button';
import { selectMaker } from '../../../../../re-ducks/app/slice';
import { initialState } from '../../../../../re-ducks/maker/slice';
import {
  selectRole,
  selectUid,
} from '../../../../../re-ducks/collaborator/selector';
import MakerSelect from '../../MakerSelect';

interface SelectMakerFormType {
  makerId: string | null;
}

export const SelectMakerForm: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const collaboratorRole = useSelector(selectRole);
  const collaboratorUid = useSelector(selectUid);
  const [makers, setMakers] = useState<MakerType[]>([]);
  const { snackbarState, openSnackbar, closeSnackbar } = useSnackbar();
  const formMethods = useForm<SelectMakerFormType>({
    defaultValues: {
      makerId: null,
    },
  });
  const { handleSubmit, errors } = formMethods;

  const onSubmit = async (data: SelectMakerFormType) => {
    const newData: {
      maker: MakerType | null;
    } = {
      maker: null,
    };
    if (typeof data.makerId === 'string' && data.makerId) {
      newData.maker =
        makers.find((maker) => maker.uid === data.makerId) ||
        initialState.maker;
    } else {
      openSnackbar({
        variant: 'error',
        message: '建築事業者・ブランドを選択してください',
      });

      return;
    }
    dispatch(selectMaker(newData));
    dispatch(push('/'));
  };

  useEffect(() => {
    (async () => {
      if (collaboratorRole === 'admin') {
        const makersSnapshot = await makersRepository
          .where('collaborators', 'array-contains', collaboratorUid)
          .get();
        const fetchedMakers = makersSnapshot.empty
          ? []
          : makersSnapshot.docs.map((doc) => doc.data());
        setMakers(fetchedMakers);
      }
    })();
  }, [makers]);

  return (
    <FormProvider {...formMethods}>
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Box mb={2} textAlign="center">
          <Typography>
            ログインする
            <span className={classes.text}>建築事業者・ブランド</span>
            を選択してください。
          </Typography>
        </Box>
        <div>
          <MakerSelect makers={makers} />
          <Box mt={1}>
            <Typography color="error" variant="body2">
              <ErrorMessage name="makerId" errors={errors} />
            </Typography>
          </Box>
        </div>
        <ButtonPrimary type="submit" className={classes.button}>
          ビルダーを選択する
        </ButtonPrimary>
        <Snackbar
          variant={snackbarState.variant}
          message={snackbarState.message}
          isOpening={snackbarState.isOpening}
          onClose={closeSnackbar}
        />
      </form>
    </FormProvider>
  );
};
