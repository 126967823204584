import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  controlLabel: {
    marginLeft: -6,
  },
  root: {
    padding: theme.spacing(0.5),
    '& .ripple-root': {
      top: theme.spacing(-0.5),
      right: theme.spacing(-0.5),
      bottom: theme.spacing(-0.5),
      left: theme.spacing(-0.5),
    },
  },
}));
