import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ja';
import React from 'react';
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DatePickerProps as PickerProps,
} from '@material-ui/pickers';
import classNames from 'classnames';
import { useStyles } from './useStyles';

moment.locale('ja');

class ExtendedMomentUtils extends MomentUtils {
  getCalendarHeaderText(date: moment.Moment): string {
    return this.format(date, 'YYYY年 MMM');
  }

  getDatePickerHeaderText(date: moment.Moment) {
    return this.format(date, 'MMMD日dddd');
  }
}

export interface DateFieldProps {
  DatePickerProps: PickerProps;
}

export const DateField: React.FC<DateFieldProps> = ({ DatePickerProps }) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={ExtendedMomentUtils}>
      <DatePicker
        animateYearScrolling={false}
        leftArrowIcon={<div>←</div>}
        rightArrowIcon={<div>→</div>}
        InputLabelProps={{ shrink: true }}
        autoOk
        margin="normal"
        fullWidth
        clearable
        {...DatePickerProps}
        okLabel="決定"
        cancelLabel="キャンセル"
        clearLabel="削除"
        className={classNames(classes.datePicker, DatePickerProps.className)}
      />
    </MuiPickersUtilsProvider>
  );
};
