import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';

import { SalesOffice } from '../../../../../re-ducks/maker/types';
import { ErrorMessage } from '../../../base/form/ErrorMessage';
import { TextField } from '../../../base/form/TextField';
import LoopFieldSection from '../../../case/form/LoopFieldSection';

export const MakerSalesOfficeInput: VFC = () => {
  const { register } = useFormContext();

  return (
    <LoopFieldSection<SalesOffice>
      name="salesOffices"
      label="営業所"
      initialValue={{
        area: '',
        name: '',
        postalCode: '',
        address: '',
        googleMapUrl: '',
        mailAddress: '',
        phoneNumber: '',
      }}
    >
      {(baseName, baseError, field) => {
        return (
          <div>
            <input type="hidden" name={`${baseName}.area`} value="" />
            <div>
              <div>
                <TextField
                  label="営業所名"
                  type="text"
                  name={`${baseName}.name`}
                  inputRef={register({
                    required: '営業所名を入力してください',
                  })}
                  required
                  error={!!baseError?.name}
                  defaultValue={field.name}
                />
                <ErrorMessage error={baseError?.name} />
              </div>
              <div className="mt-5">
                <TextField
                  label="郵便番号"
                  type="text"
                  name={`${baseName}.postalCode`}
                  inputRef={register({
                    required: '郵便番号を入力してください',
                  })}
                  error={!!baseError?.postalCode}
                  defaultValue={field.postalCode}
                  required
                />
                <ErrorMessage error={baseError?.postalCode} />
              </div>
              <div className="mt-5">
                <TextField
                  label="住所"
                  type="text"
                  name={`${baseName}.address`}
                  inputRef={register({
                    required: '住所を入力してください',
                  })}
                  error={!!baseError?.address}
                  defaultValue={field.address}
                  required
                />
                <ErrorMessage error={baseError?.address} />
              </div>
              <div className="mt-5">
                <TextField
                  label="Googleマップの共有リンク"
                  type="text"
                  name={`${baseName}.googleMapUrl`}
                  inputRef={register({
                    required: 'Googleマップの共有リンクを入力してください',
                  })}
                  error={!!baseError?.googleMapUrl}
                  defaultValue={field.googleMapUrl}
                  placeholder="https://goo.gl/maps/SUNFKSt9X7NJHjgd8"
                  required
                />
                <ErrorMessage error={baseError?.googleMapUrl} />
              </div>
              <div className="mt-5">
                <TextField
                  label="メールアドレス"
                  type="text"
                  name={`${baseName}.mailAddress`}
                  inputRef={register({
                    required: 'メールアドレスを入力してください',
                  })}
                  error={!!baseError?.mailAddress}
                  defaultValue={field.mailAddress}
                  required
                />
                <ErrorMessage error={baseError?.mailAddress} />
              </div>
              <div className="mt-5">
                <TextField
                  label="電話番号"
                  type="text"
                  name={`${baseName}.phoneNumber`}
                  inputRef={register({
                    required: '電話番号を入力してください',
                  })}
                  error={!!baseError?.phoneNumber}
                  defaultValue={field.phoneNumber}
                  required
                />
                <ErrorMessage error={baseError?.phoneNumber} />
              </div>
            </div>
          </div>
        );
      }}
    </LoopFieldSection>
  );
};
