import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonWrap: {
    display: 'grid',
    gap: theme.spacing(2),
    margin: `${theme.spacing(3)}px auto 0`,
  },
  logoImage: {
    aspectRatio: '1 / 1',
    width: '100%',
  },
  information: {
    marginBottom: theme.spacing(2),
    fontSize: theme.typography.body2.fontSize,
    '& dt': {
      float: 'left',
      height: '1em',
      '& span': {
        display: 'inline-flex',
        alignItems: 'center',
        gap: theme.spacing(0.5),
      },
      '&::after': {
        content: '"："',
      },
    },
    '& dd': {
      height: '1em',
    },
  },
  detailPage: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}));
