import clsx from 'clsx';
import React, { ReactNode } from 'react';

import useStyles from './useStyles';

export interface DefinitionListProps {
  components: {
    alignItems?: 'flex-start' | 'flex-end' | 'center';
    dt: ReactNode;
    dd: ReactNode;
  }[];
}

export const DefinitionList: React.FC<DefinitionListProps> = (props) => {
  const { components } = props;
  const classes = useStyles();

  return (
    <dl className={classes.dl}>
      {components.map(({ alignItems, dt, dd }, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={index}>
            <dt
              className={clsx(classes.dt, {
                'flex-start': alignItems === 'flex-start',
                'flex-end': alignItems === 'flex-end',
              })}
            >
              {dt}
            </dt>
            <dd className={classes.dd}>{dd}</dd>
          </React.Fragment>
        );
      })}
    </dl>
  );
};
