import { useMediaQuery, Theme } from '@material-ui/core';

const useMobileSize = () => {
  const isMobileSize = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );

  return { isMobileSize };
};

export default useMobileSize;
