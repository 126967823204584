import {
  EditorState,
  Editor as DraftEditor,
  ContentBlock,
  DefaultDraftBlockRenderMap,
  DraftBlockRenderMap,
  CompositeDecorator,
} from 'draft-js';
import Immutable from 'immutable';
import React, { createRef, useEffect, useState, VFC } from 'react';
import 'draft-js/dist/Draft.css';
import { convertFromHTML } from 'draft-convert';
import { useFormContext } from 'react-hook-form';

import useStyles from './useStyles';
import { EditorContext } from './EditorContext';
import ButtonArea from './ButtonArea';
import Media from './Media';
import CustomBlock from './CustomBlock';
import Link from './CustomBlock/Link';
import findLinkEntities from './CustomBlock/Link/findLinkEntities';
import convertToHtmlFromEditorState from './convertToHtmlFromEditorState';
import convertToRawFromHtml from './convertToRawFromHtml';

interface Props {
  editorName: string;
  defaultValue: string;
}

const Editor: VFC<Props> = ({ editorName, defaultValue }) => {
  const { setValue, register } = useFormContext();
  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);
  const [editorState, setEditorState] = useState(
    defaultValue
      ? EditorState.createWithContent(
          convertToRawFromHtml(defaultValue),
          decorator,
        )
      : EditorState.createEmpty(decorator),
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [anchorEle, setAnchorEle] = useState<HTMLButtonElement | null>(null);
  const editorRef = createRef<DraftEditor>();
  const classes = useStyles();

  useEffect(() => {
    register(editorName);
  }, []);

  const myBlockRenderer = (block: ContentBlock) => {
    const blockType = block.getType();
    if (blockType === 'atomic') {
      return {
        component: CustomBlock,
        editable: false,
      };
    }

    return null;
  };

  const myBlockRendererMap: DraftBlockRenderMap = Immutable.Map({
    ...DefaultDraftBlockRenderMap.toJS(),
    unstyled: {
      element: 'p',
    },
    paragraph: {
      element: 'p',
    },
  });

  const styleMap = {
    marker: {
      background: 'linear-gradient(transparent 50%, #FCE4CC 0%)',
    },
  };

  return (
    <EditorContext.Provider
      value={{
        editorState,
        setEditorState,
        editorRef,
        isModalOpen,
        setModalOpen,
        anchorEle,
        setAnchorEle,
      }}
    >
      <Media />
      <ButtonArea />
      <div className={classes.editorContainer}>
        <DraftEditor
          ref={editorRef}
          editorState={editorState}
          onChange={(state) => {
            setEditorState(state);
            setValue('content', convertToHtmlFromEditorState(state));
          }}
          customStyleMap={styleMap}
          blockRendererFn={myBlockRenderer}
          blockRenderMap={myBlockRendererMap}
        />
      </div>
    </EditorContext.Provider>
  );
};

export default Editor;
