import React, { VFC } from 'react';
import { useSelector } from 'react-redux';
import { ColumnInstance } from 'react-table';

import { selectMakerTableData } from '../../../../../re-ducks/maker/selector';

export const MakerIdFilter: VFC<{ column: ColumnInstance<never> }> = ({
  column,
}) => {
  const { id, render, setFilter } = column;
  const makers = useSelector(selectMakerTableData);

  return (
    <div>
      <div className="label">{render('Header')}</div>
      <div className="control">
        <input
          name={id}
          onChange={(event) => {
            const maker = makers.find((m) => m.name === event.target.value);
            setFilter(maker ? maker.uid : '');
          }}
          className="input"
        />
      </div>
    </div>
  );
};
