import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  numberFormatWrapper: {
    '& .MuiTextField-root .MuiInputBase-input': {
      paddingRight: theme.spacing(3),
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
    },
  },
  eventDateWrap: {
    display: 'grid',
    gridTemplateColumns: `1fr 1fr`,
    gridGap: theme.spacing(2),
  },
}));
