import { Box } from '@material-ui/core';
import React, { VFC } from 'react';
import EditorButtonGroup from '../EditorButtonGroup';
import useButtonItems from './useButtonItems';
import useStyles from './useStyles';

const ButtonArea: VFC = () => {
  const classes = useStyles();
  const { blockStyleButtons, inlineStyleButtons } = useButtonItems();

  return (
    <Box className={classes.buttonGroup}>
      <EditorButtonGroup buttonItems={blockStyleButtons} />
      <EditorButtonGroup buttonItems={inlineStyleButtons} />
    </Box>
  );
};

export default ButtonArea;
