import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';

import { ErrorMessage } from '../../../../base/form/ErrorMessage';
import LoopFieldSection from '../../../../case/form/LoopFieldSection';
import { TextField } from '../../../../base/form/TextField';

export const ThemeSeo: VFC = () => {
  const { register } = useFormContext();

  return (
    <LoopFieldSection<{ title: string; description: string }>
      name="themeSeo"
      label="テーマSEO用説明文"
      initialValue={{ title: '', description: '' }}
    >
      {(baseName, baseError, field) => {
        return (
          <div>
            <div className="mt-5">
              <TextField
                label="見出し"
                type="text"
                name={`${baseName}.title`}
                inputRef={register({ required: '見出しを入力してください' })}
                error={!!baseError?.title}
                defaultValue={field.title}
                required
              />
              <ErrorMessage error={baseError?.title} />
            </div>
            <div className="mt-5">
              <TextField
                label="詳細文"
                type="description"
                name={`${baseName}.description`}
                inputRef={register({
                  required: '説明文を入力してください',
                })}
                error={!!baseError?.description}
                defaultValue={field.description}
                multiline
                required
                rows={4}
              />
              <ErrorMessage error={baseError?.text} />
            </div>
          </div>
        );
      }}
    </LoopFieldSection>
  );
};
