import React, { useContext, useEffect, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import clsx from 'clsx';
import { AtomicBlockUtils, EditorState } from 'draft-js';
import { Box, CircularProgress } from '@material-ui/core';
import { DotProgress } from '../../../../case/feedback/DotProgress';
import getStorageFIleUrl from '../../../../../../utils/getStorageFIleUrl';
import { fetchMedias } from '../../../../../../re-ducks/faq/actions';
import {
  selectFaqMedias,
  selectFetching,
} from '../../../../../../re-ducks/faq/selector';
import useStyles from './useStyles';
import { ButtonPrimary } from '../../../../base/Button';
import { storage } from '../../../../../../repositories/firebase';
import { FAQMedia } from '../../../../../../re-ducks/faq/types';
import getFileSize from '../../../../../../utils/getFileSize';
import { EditorContext } from '../../EditorContext';

const MediaList: VFC = () => {
  const dispatch = useDispatch();
  const { editorState, setEditorState, setModalOpen } =
    useContext(EditorContext);
  const faqMedias = useSelector(selectFaqMedias);
  const fetching = useSelector(selectFetching);
  const classes = useStyles();
  const [selectMedia, setSelectMedia] = useState<FAQMedia | null>(null);
  const [fileData, setFileData] = useState({ fileName: '', size: '' });
  const [loading, setLoading] = useState(false);

  useEffectOnce(() => {
    dispatch(fetchMedias());
  });

  useEffect(() => {
    if (!selectMedia?.uid) return;

    (async () => {
      setLoading(true);
      const result = await storage.ref(selectMedia.path).getMetadata();
      setFileData({
        fileName: result?.name || '',
        size:
          result?.size && typeof result?.size === 'number'
            ? getFileSize(result?.size)
            : '',
      });
      setLoading(false);
    })();
  }, [selectMedia]);

  if (fetching) {
    <DotProgress opening={fetching} />;
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.contents}>
        <div className={classes.grid}>
          {faqMedias.map((media) => {
            return (
              <button
                key={media.uid}
                className={clsx(classes.media, {
                  'is-select': media.uid === selectMedia?.uid,
                })}
                onClick={() => setSelectMedia(media)}
              >
                <img src={getStorageFIleUrl(media.path)} alt="" />
              </button>
            );
          })}
        </div>
      </div>
      <div className={classes.sidebar}>
        {selectMedia ? (
          <div>
            <div className={classes.selectedImage}>
              <img src={getStorageFIleUrl(selectMedia.path)} alt="" />
            </div>
            {loading ? (
              <Box textAlign="center" mt={1} mb={1}>
                <CircularProgress />
              </Box>
            ) : (
              <div className={classes.fileMeta}>
                <p className={classes.fileName}>{fileData.fileName}</p>
                <p className={classes.fileSize}>{fileData.size}</p>
              </div>
            )}
            <ButtonPrimary
              color="primary"
              variant="outlined"
              onClick={() => {
                const contentStateWithEntity = editorState
                  .getCurrentContent()
                  .createEntity('image', 'IMMUTABLE', {
                    src: getStorageFIleUrl(selectMedia.path),
                  });
                const entityKey =
                  contentStateWithEntity.getLastCreatedEntityKey();
                const newEditorState = EditorState.set(editorState, {
                  currentContent: contentStateWithEntity,
                });
                setModalOpen(false);

                return setEditorState(
                  AtomicBlockUtils.insertAtomicBlock(
                    newEditorState,
                    entityKey,
                    ' ',
                  ),
                );
              }}
            >
              この画像を挿入
            </ButtonPrimary>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MediaList;
