import React, { VFC, useState, useContext } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Modal, IconButton } from '@material-ui/core';
import { AiFillCloseCircle } from 'react-icons/ai';
import useStyles from './useStyles';
import { EditorContext } from '../EditorContext';
import DropZone from './Dropzone';
import MediaList from './MediaList';

const TabPanel: VFC<{ index: number }> = ({ index }) => {
  if (index === 0) {
    return <DropZone />;
  }

  if (index === 1) {
    return <MediaList />;
  }

  return null;
};

const Media: VFC = () => {
  const [index, setIndex] = useState(1);
  const classes = useStyles();
  const { isModalOpen, setModalOpen } = useContext(EditorContext);

  if (!isModalOpen) return null;

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        setModalOpen((props) => !props);
      }}
    >
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.modalHeader}>
            <Tabs
              value={index}
              onChange={(_, newValue) => setIndex(newValue)}
              classes={{ root: classes.tabs, indicator: classes.tabIndicator }}
            >
              <Tab
                label="ファイルをアップロード"
                classes={{ root: classes.tab, selected: classes.selectedTab }}
              />
              <Tab
                label="ファイル一覧"
                classes={{ root: classes.tab, selected: classes.selectedTab }}
              />
            </Tabs>
            <IconButton onClick={() => setModalOpen(false)}>
              <AiFillCloseCircle fontSize={24} />
            </IconButton>
          </div>
          <div className={classes.tabContent}>
            <TabPanel index={index} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Media;
