import React, { FC } from 'react';
import { ButtonProps } from '@material-ui/core';

import { ButtonPrimary } from '../../../base/Button';
import { useStyles } from './useStyles';

export type BackButtonProps = Omit<ButtonProps, 'children'>;

export const BackButton: FC<BackButtonProps> = (props) => {
  const className = useStyles();
  const { children } = props;

  return (
    <ButtonPrimary {...props} color="default" className={className.root}>
      {children || '戻る'}
    </ButtonPrimary>
  );
};
