import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontSize: 12,
    color: theme.palette.common.white,

    '&.very-week': {
      backgroundColor: theme.palette.error.dark,
    },
    '&.week': {
      backgroundColor: theme.palette.error.main,
    },
    '&.good': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.strong': {
      backgroundColor: theme.palette.success.main,
    },
    '&.very-strong': {
      backgroundColor: theme.palette.success.dark,
    },

    '& dt': {
      '&::after': {
        content: '"："',
      },
    },
  },
}));
