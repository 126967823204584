import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  imageWrap: {
    width: '100%',
    height: 200,
  },
  image: {
    width: 300,
  },
  textbox: {
    flex: 1,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
  },
}));
