import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { EventState } from './slice';

export const selectSelf = (state: RootState): EventState => state.event;

export const selectFetching = createSelector(
  selectSelf,
  (state) => state.fetching,
);
export const selectCreating = createSelector(
  selectSelf,
  (state) => state.creating,
);

export const selectCurrentEvent = createSelector(
  selectSelf,
  (state) => state.currentEvent,
);
export const selectEvents = createSelector(selectSelf, (state) => state.events);
export const selectEventReservations = createSelector(
  selectSelf,
  (state) => state.eventReservations,
);
export const selectEventTableData = createSelector(
  selectSelf,
  (state) => state.eventTableData,
);
