import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& input': {
      height: 40,
      boxSizing: 'border-box',
    },
  },
}));
