import React, { VFC } from 'react';
import classNames from 'classnames';
import { ImPlus } from 'react-icons/im';

import { useStyles } from './useStyles';

export interface AddButtonProps {
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const AddButton: VFC<AddButtonProps> = ({ label, onClick }) => {
  const classes = useStyles();

  return (
    <button className={classNames('button', classes.button)} onClick={onClick}>
      <ImPlus />
      <span className={classes.text}>{label}</span>
    </button>
  );
};
