import React, { VFC } from 'react';
import { CellProps } from 'react-table';
import Select, { OptionTypeBase, ValueType } from 'react-select';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { updateRow } from '../../../../../re-ducks/event/actions';
import useSnackbar from '../../../../hooks/useSnackbar';

export const useStyles = makeStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}));

export const PublishingCell: VFC<CellProps<any>> = ({ row, cell }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { snackbarState, closeSnackbar, openSnackbar } = useSnackbar();

  const handleSingleChange = (
    selectedOption: ValueType<OptionTypeBase, false>,
  ) => {
    const originalEvent = row.original.event;
    const newRowValue = {
      ...originalEvent,
      publishing: !!selectedOption?.value,
    };
    dispatch(updateRow({ event: newRowValue, openSnackbar }));
  };

  return (
    <div className={classes.root}>
      <Select
        name=""
        value={{
          label: cell.value ? '公開' : '非公開',
          value: cell.value,
        }}
        options={[
          { label: '公開', value: true },
          { label: '非公開', value: false },
        ]}
        onChange={handleSingleChange}
        isMulti={false}
        isDisabled={false}
        styles={{
          control: (styles) => ({ ...styles, cursor: 'pointer' }),
        }}
      />
    </div>
  );
};
