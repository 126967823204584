import Compressor from 'compressorjs';

const useMediaUpload = () => {
  const upload = async (
    acceptedFiles: File[],
    callback: (dataUrl: string, fileName: string) => void,
  ) => {
    await Promise.all(
      acceptedFiles.map(async (file) => {
        const resultCompressor = new Compressor(file, {
          maxWidth: 1200,
          maxHeight: 1200,
          quality: 0.75,
          mimeType: file.type,
          success: (b) => {
            const reader = new FileReader();
            reader.readAsDataURL(b);
            reader.onloadend = () => {
              const { result } = reader;
              if (typeof result !== 'string') {
                return;
              }

              callback(result, file.name);
            };
          },
        });

        return resultCompressor;
      }),
    );
  };

  return { upload };
};

export default useMediaUpload;
