import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../store';
import { ThemeState } from './slice';

export const selectSelf = (state: RootState): ThemeState => state.theme;

export const selectFetching = createSelector(
  selectSelf,
  (state) => state.fetching,
);
export const selectThemes = createSelector(selectSelf, (state) => state.themes);
export const selectTheme = createSelector(selectSelf, (state) => state.theme);
export const selectActualResults = createSelector(
  selectSelf,
  (state) => state.actualResults,
);
export const selectPublishActualResults = createSelector(
  selectSelf,
  (state) => state.publishActualResults,
);
export const selectPublishActualResultsTableData = createSelector(
  selectSelf,
  (state) => state.publishActualResultsTableData,
);
export const selectImages = createSelector(selectSelf, (state) => state.images);
export const selectCreating = createSelector(
  selectSelf,
  (state) => state.creating,
);
