import { ValueOf } from '../types/utility';

export const PLANS = {
  free: '無料プラン',
  standard: '成果報酬プラン',
} as const;

export type PlanKeyType = keyof typeof PLANS;
export type PlanValueType = ValueOf<typeof PLANS>;

export const eventLimit: { [k: string]: number } = {
  free: 0,
  standard: 10000,
};
