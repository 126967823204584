export const roles = ['cunelwork', 'admin'] as const;
export type RoleType = typeof roles[number];

export interface CollaboratorType {
  uid: string;
  name: string;
  role: RoleType;
  mailAddress: string;
}
export interface MyAccountFormType extends CollaboratorType {
  password: string;
  currentPassword: string;
}

export interface CollaboratorFormType extends CollaboratorType {
  password: string;
}

export type CollaboratorListTableType = CollaboratorType;
