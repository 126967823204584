import { Collection } from 'my-firebase-wrapper/dist/firestore/collection';
import { v4 } from 'uuid';

import { db } from './index';
import { EventType } from '../../re-ducks/event/types';
import { initialState } from '../../re-ducks/event/slice';
import {
  convertToDateFromTimestamp,
  convertToTimestampFromString,
} from '../../utils/dateFormats';

export const eventsRepository: Collection<EventType> = db.collection<EventType>(
  {
    collectionPath: 'events',
    encode: (obj) => {
      delete (obj as any)[''];

      return {
        ...obj,
        uid: obj?.uid ? obj.uid : v4(),
        createDate: convertToTimestampFromString(obj.createDate),
        updateDate: new Date(),
        startDate:
          obj.startDate && !obj.fullYear
            ? convertToTimestampFromString(obj.startDate)
            : new Date('1950-01-01'),
        endDate:
          obj.endDate && !obj.fullYear
            ? convertToTimestampFromString(obj.endDate)
            : new Date('2100-01-01'),
        businessDays: obj.businessDays?.length
          ? obj.businessDays.map((v: string) => Number(v))
          : initialState.currentEvent.businessDays,
      };
    },
    decode: (doc) => {
      const data = doc.data();
      if (!data) return initialState.currentEvent;

      return {
        ...data,
        createDate: convertToDateFromTimestamp(data.createDate) || '',
        updateDate: convertToDateFromTimestamp(data.updateDate) || '',
        startDate: convertToDateFromTimestamp(data.startDate) || '',
        endDate: convertToDateFromTimestamp(data.endDate) || '',
      };
    },
  },
);
