import { auth } from 'firebase-admin';
import firebase from 'firebase';
import { functions } from '../../repositories/firebase';

export const updateUser = async (
  userId: string,
  properties: auth.UpdateRequest,
): Promise<string | Error> => {
  try {
    const updateAuthUser: firebase.functions.HttpsCallable =
      functions.httpsCallable('updateUser');
    const result = await updateAuthUser({ userId, properties });
    if (typeof result !== 'string') {
      throw result;
    }

    return result;
  } catch (error: any) {
    console.error(error);

    return error;
  }
};
