import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  Paper,
  Tabs,
  Tab,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import IconProject from '../../base/Icon/IconProject';
import { PageTitle } from '../../case/PageTitle';
import { selectFetching } from '../../../../re-ducks/actualResult/selector';
import { AdminWrapper } from '../../domain/AdminWrapper';
import { ActualImagesEditForm } from '../../domain/actualResult/ActualImagesEditForm';
import { ActualResultEditForm } from '../../domain/actualResult/ActualResultEditForm';
import { DotProgress } from '../../case/feedback/DotProgress';

export const useStyles = makeStyles((theme: Theme) => ({
  form: {
    marginTop: theme.spacing(4),
  },
}));

export const ProjectDetail: FC = () => {
  const [tab, setTab] = React.useState(0);
  const classes = useStyles();
  const fetching = useSelector(selectFetching);

  if (fetching) return <DotProgress opening={fetching} onClose={() => ''} />;

  return (
    <AdminWrapper>
      <PageTitle Icon={<IconProject />}>建築実例</PageTitle>
      <Paper>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_, currentValue) => setTab(currentValue)}
          aria-label="disabled tabs example"
        >
          <Tab label="基本情報" />
          <Tab label="写真" />
        </Tabs>
      </Paper>
      <ul>
        <li>
          <Typography variant="body2" className="mt-5">
            ※先に
            <span className="is-underlined has-text-weight-bold">
              「基本情報」{' '}
            </span>
            を入力して保存してください。
          </Typography>
        </li>
      </ul>
      <div className={classes.form}>
        {tab === 0 ? <ActualResultEditForm /> : null}
        {tab === 1 ? <ActualImagesEditForm /> : null}
      </div>
    </AdminWrapper>
  );
};
