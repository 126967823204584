import React from 'react';
import 'ress';
import 'bulma';
import { MuiThemeProvider } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import generateTheme from './generateTheme';
import { history, store } from './re-ducks/store';
import { Login } from './presentation/components/pages/Login';
import { PwForget } from './presentation/components/pages/PwForget';
import { Top } from './presentation/components/pages/Top';
import { MyAccount } from './presentation/components/pages/MyAccount';
import { Collaborator } from './presentation/components/pages/Collaborator';
import { CollaboratorDetail } from './presentation/components/pages/Collaborator/Detail';
import { Professional } from './presentation/components/pages/Professional';
import { ProfessionalDetail } from './presentation/components/pages/Professional/Detail';
import { Project } from './presentation/components/pages/Project';
import { ProjectDetail } from './presentation/components/pages/Project/Detail';
import { Event } from './presentation/components/pages/Event';
import { EventDetail } from './presentation/components/pages/Event/Detail';
import { MakerContacts } from './presentation/components/pages/MakerContacts';
import { MakerContactsDetail } from './presentation/components/pages/MakerContacts/Detail';
import { Follower } from './presentation/components/pages/Follower';
import { SelectMaker } from './presentation/components/pages/Login/SelectMaker';
import { FreePlan } from './presentation/components/pages/FreePlan';
import FaqList from './presentation/components/pages/FAQ';
import FAQDetail from './presentation/components/pages/FAQ/Detail';
import { Theme } from './presentation/components/pages/Theme';
import ThemeDetail from './presentation/components/pages/Theme/Detail';
import Information from './presentation/components/pages/Information';
import InformationDetail from './presentation/components/pages/Information/Detail';
import { UserDetail } from './presentation/components/pages/Users/Detail';

const App: React.FC = () => {
  const theme = generateTheme();

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Switch>
              <Route path="/login" component={Login} exact />
              <Route path="/selectMaker" component={SelectMaker} exact />
              <Route path="/pw-forget" component={PwForget} exact />
              <Route path="/" component={() => <Top />} exact />
              <Route path="/free-plan" component={() => <FreePlan />} exact />
              <Route path="/my-account" component={() => <MyAccount />} exact />
              <Route path="/follower" component={() => <Follower />} exact />
              <Route
                path="/information"
                component={() => <Information />}
                exact
              />
              <Route
                path="/information/detail/:id"
                component={() => <InformationDetail />}
              />
              <Route
                path="/collaborator"
                component={() => <Collaborator />}
                exact
              />
              <Route
                path="/collaborator/edit(/:id)?"
                component={() => <CollaboratorDetail />}
              />
              <Route
                path="/professional"
                component={() => <Professional />}
                exact
              />
              <Route
                path="/professional/edit(/:id)?"
                component={() => <ProfessionalDetail />}
              />
              <Route path="/project" component={() => <Project />} exact />
              <Route
                path="/project/edit(/:id)?"
                component={() => <ProjectDetail />}
              />
              <Route path="/event" component={() => <Event />} exact />
              <Route
                path="/event/edit(/:id)?"
                component={() => <EventDetail />}
              />
              <Route
                path="/makerContacts"
                component={() => <MakerContacts />}
                exact
              />
              <Route
                path="/makerContacts/detail/:id"
                component={() => <MakerContactsDetail />}
              />
              <Route path="/help" component={() => <FaqList />} exact />
              <Route path="/help/edit(/:id)?" component={() => <FAQDetail />} />
              <Route path="/theme" component={() => <Theme />} exact />
              <Route
                path="/theme/edit(/:id)?"
                component={() => <ThemeDetail />}
              />
              <Route
                path="/users/detail/:id"
                component={() => <UserDetail />}
              />
            </Switch>
          </div>
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
};

export default App;
